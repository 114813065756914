import { makeAutoObservable } from "mobx";
import { Availability } from "../Availability";
import { DeliveryPolicy } from "../DeliveryPolicy";
export class DeliveryAddress {
    constructor(params) {
        this.streetAddress = params.streetAddress;
        this.apartmentInfo = params.apartmentInfo;
        this.status = params.status;
        makeAutoObservable(this);
    }
    get deliveryPolicy() {
        const { state } = this.status;
        switch (state.type) {
            case "RestaurantDelivers":
                return DeliveryPolicy.paid({
                    cost: state.deliveryCost,
                    freeDeliveryThreshold: state.freeDeliveryThreshold,
                    minOrderValue: state.minOrderValue,
                });
            case "RestaurantDoesntDeliverTo":
            case "InvalidAddress":
            case "AddressDoesNotExistOrInaccurate":
            case "ChangeRestaurant":
            case "ChooseRestaurant":
            case "NetworkError":
                return DeliveryPolicy.Impossible;
            case "NotRequested":
            case "ChangedAndNotValidatedYet":
            case "Loading":
                return null;
        }
    }
    get availability() {
        return Availability.composite([
            this.streetAddress.availability,
            this.apartmentInfo.availability,
            this.status.availability,
        ]);
    }
    get checkoutData() {
        return {
            ...this.streetAddress.checkoutData,
            apartmentNumber: this.apartmentInfo.checkoutData.aptNumber,
            floor: this.apartmentInfo.checkoutData.floor,
            country: this.streetAddress.country,
            // Additional country-specific information
            extra: this.apartmentInfo.checkoutData,
            // Used in RO country to show custom delivery address in PR
            formattedAddress: countrySpecificFormattedAddress(this.streetAddress.country, this.streetAddress.checkoutData, this.apartmentInfo.checkoutData),
            coordinates: this.status.coordinates,
        };
    }
}
function countrySpecificFormattedAddress(country, streetAddress, apartmentInfo) {
    switch (country) {
        case "RO":
            return formatRomanianAddress(streetAddress, apartmentInfo);
        default:
            return null;
    }
}
function formatRomanianAddress(streetAddress, apartmentInfo) {
    const result = {
        value: "",
        concat(value, label) {
            if (value.length > 0) {
                this.value = `${this.value}, ${label} ${value}`;
            }
            return this;
        },
    };
    // Street, city
    result.value = defaultFormatBuildingAddress(streetAddress);
    // Sector
    result.concat(apartmentInfo.sector, "Sec.");
    if (apartmentInfo.houseOrBlock === "Block") {
        // Bloc
        result.concat(apartmentInfo.block, "Bl.");
        // Scara
        result.concat(apartmentInfo.staircase, "Sc.");
        // Etaj
        result.concat(apartmentInfo.floor, "Et.");
        // Apt number
        result.concat(apartmentInfo.aptNumber, "Ap.");
        // Interfon
        result.concat(apartmentInfo.interphone, "Intf.");
    }
    // Alte detali/reper (additional info)
    result.concat(apartmentInfo.additionalInfo, "Reper:");
    return result.value;
}
function defaultFormatBuildingAddress(streetAddress) {
    const { street, streetNumber, city, postCode } = streetAddress;
    const postCodeAndCity = postCode.length > 0 && city.length > 0
        ? `${postCode} ${city}`
        : city || postCode;
    return `${street} ${streetNumber}, ${postCodeAndCity}`;
}
