import { translate, t } from "../../../I18n";
import { makeAutoObservable } from "mobx";
import { FullName, } from "../../../ordering";
import { toPairs } from "../../../core";
import { Email, ErrorBox, PhoneFieldController, TextFieldController, } from "../../../form";
export class OnlineContactFormController {
    constructor(params) {
        this.type = "Online";
        this.contactDetails = params.contactDetails;
        this.fullName = new TextFieldController({
            initialValue: this.contactDetails.fullName.value,
            onUpdate: (str) => (this.contactDetails.fullName = new FullName(str)),
            errorBox: new ErrorBox({
                domainObjects: [this.contactDetails],
                errorMessages: {
                    FullNameMissing: translate(t._common.form.errors.required),
                    InvalidFullName: translate(t.errors.messages.invalid),
                },
            }),
        });
        this.email = new TextFieldController({
            initialValue: this.contactDetails.email.value,
            onUpdate: (str) => (this.contactDetails.email = Email.create(str)),
            errorBox: new ErrorBox({
                domainObjects: [this.contactDetails],
                errorMessages: {
                    EmailMissing: translate(t._common.form.errors.required),
                    InvalidEmail: translate(t.validations.invalid_email),
                },
            }),
        });
        this.phone = new PhoneFieldController({
            initialValue: this.contactDetails.phone.value,
            initialCountry: this.contactDetails.phone.country,
            onUpdate: (str, country) => {
                this.contactDetails.setPhone(str, country);
            },
            errorBox: new ErrorBox({
                domainObjects: [this.contactDetails],
                errorMessages: {
                    PhoneMissing: translate(t._common.form.errors.required),
                    InvalidPhoneNumber: translate(t.activerecord.errors.models.order.attributes.user.attributes
                        .parsed_phone.invalid),
                },
            }),
        });
        if (!this.contactDetails.isEmpty) {
            this.submit();
        }
        makeAutoObservable(this);
    }
    get fields() {
        return {
            fullName: this.fullName,
            email: this.email,
            phone: this.phone,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.contactDetails.availability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.contactDetails;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
}
