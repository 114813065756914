import { observer } from "mobx-react";
import { h } from "preact";
import AvailabilityDebugInfo from "../../components/AvailabilityDebugInfo";
import CreatorCustomParameterChoice from "./CreatorCustomParameterChoice";
import CreatorConfigMessage from "./CreatorConfigMessage";
function CreatorCustomParameter(props) {
    const { fieldIdPrefix, customParameterInstance } = props;
    const fieldId = `${fieldIdPrefix}customParameters.${customParameterInstance.typeId}`;
    const error = customParameterInstance.error;
    const hasError = error.type !== "None";
    const isRequired = customParameterInstance.isRequired;
    return (h("div", { class: "m-product-card__content" },
        h("div", { class: "row" },
            h("fieldset", { "data-field-id": fieldId, "data-field-type": "radioGroup", class: `col-md-12 ${hasError ? "has-error" : ""}` },
                h("legend", { class: "h4 u-legend-float" }, customParameterInstance.name),
                h(AvailabilityDebugInfo, { fieldId: `${fieldId}.availability`, name: "CustomParameterInstance", availability: customParameterInstance.availability }),
                h(CreatorConfigMessage, { error: error, isRequired: isRequired }),
                h("div", { class: "m-form__group" }, customParameterInstance.choices.map((choice) => {
                    const value = choice.id.toString();
                    const availability = customParameterInstance.choiceAvailability(choice.id);
                    return (h(CreatorCustomParameterChoice, { key: value, value: value, label: choice.name, name: customParameterInstance.instanceId.toString(), isDisabled: availability.isAvailable.isDefinitelyFalse, isChecked: customParameterInstance.isSelected(choice.id), onChange: () => customParameterInstance.choose(choice.id) }));
                }))))));
}
export default observer(CreatorCustomParameter);
