import { observer } from "mobx-react";
import { Fragment, h } from "preact";
import { translate, t } from "restaumatic-client";
function ProductTimeBasedAvailability(props) {
    const { productAvailabilityInfo } = props;
    switch (productAvailabilityInfo.type) {
        case "Available":
            return h(Fragment, null);
        case "TemporarilyUnavailable":
            return (h(Fragment, null,
                h("span", { "data-field-id": "productAvailability.severity", "data-field-type": "info", "data-value": "unavailable" }),
                h("span", { "data-field-id": "productAvailability", "data-field-type": "info", class: "m-availability m-availability--not-clickable" },
                    h("span", { class: "label label-dish m-availability__label m-availability__label--unavailable u-ml1" }, translate(t.menu.productTemporarilyUnavailable)))));
        case "Schedule": {
            const { available, hours } = productAvailabilityInfo;
            if (available.isDefinitelyTrue) {
                return h(Fragment, null);
            }
            const labelClass = available.isDefinitelyFalse
                ? "m-availability__label--unavailable"
                : "m-availability__label--maybe-available";
            const text = available.isDefinitelyFalse && hours.isEmpty
                ? translate(t.theme_defaults.dish_label.unavailable)
                : hours.raw.map((x) => x.toString()).join(", ");
            return (h(Fragment, null,
                h("span", { "data-field-id": "productAvailability.severity", "data-field-type": "info", "data-value": available.isDefinitelyFalse ? "unavailable" : "maybe" }),
                h("span", { "data-field-id": "productAvailability", "data-field-type": "info", class: `label label-dish m-availability__label ${labelClass}` }, text)));
        }
    }
}
export default observer(ProductTimeBasedAvailability);
