import { observer } from "mobx-react";
import { h } from "preact";
import { Suspense, lazy } from "preact/compat";
import { useMemo } from "preact/hooks";
import { translate, t } from "restaumatic-client";
import CrossSellingItem from "./CrossSellingItem";
// TODO: Extract the Slider component if it will be used in more places
function CrossSellingList(props) {
    const { fieldId, items } = props;
    const isSmallMin = useMemo(() => {
        var _a;
        return (_a = Skubacz.Device.screen.isSmallMin()) !== null && _a !== void 0 ? _a : false;
    }, []);
    // Lazy load Splide slider component with library for desktop (~35kB JS)
    if (isSmallMin) {
        const CrossSellingListSlider = lazy(() => import("./CrossSellingListSlider"));
        return (h(Suspense, { fallback: undefined },
            h(CrossSellingListSlider, { fieldId: fieldId, items: items })));
    }
    // Simple list for mobile
    return h(SimpleList, { fieldId: fieldId, items: items });
}
const SimpleList = observer((props) => {
    const { fieldId, items } = props;
    return (h("fieldset", { "data-field-id": fieldId, class: "m-form__group m-form__group--spacing-top" },
        h("div", { class: "m-crossup m-crossup--redesigned m-crossup--with-title" },
            h("div", { class: "row" },
                h("div", { class: "col-md-12" },
                    h("div", { class: "m-crossup__header" },
                        h("h4", { class: "m-crossup__title" }, translate(t.crossup.title))))),
            h("div", { class: "row" },
                h("div", { class: "col-md-12" },
                    h("div", { class: "m-crossup__body" },
                        h("div", { class: "m-crossup__list" }, items.map((item) => (h(CrossSellingItem, { item: item, key: item.id }))))))))));
});
export default observer(CrossSellingList);
