import { makeAutoObservable } from "mobx";
import { InternalParameterSet, ParameterSet } from "../ParameterSet";
export class PurchaseOrderScope {
    constructor(params) {
        this.restaurant = params.restaurant;
        this.salesChannel = params.salesChannel;
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.now = params.now;
        makeAutoObservable(this);
    }
    get parameterSet() {
        return new ParameterSet({
            internal: new InternalParameterSet({
                orderOrigin: this.salesChannel.menuOrderOrigin,
                fulfillmentMethod: this.fulfillmentInstructions.fulfillmentMethod,
                splitToHalves: null,
            }),
        });
    }
    get activeSchedules() {
        const { fulfillmentTime } = this;
        if (fulfillmentTime === null) {
            return null;
        }
        return this.restaurant.menu.schedules.filter((schedule) => schedule.includes(fulfillmentTime));
    }
    get fulfillmentTime() {
        const fulfillmentTime = this.fulfillmentInstructions.fulfillmentTime;
        if (fulfillmentTime === null) {
            return null;
        }
        switch (fulfillmentTime.type) {
            case "ASAP":
                return this.now;
            case "OnTime":
                return fulfillmentTime.date;
        }
    }
}
