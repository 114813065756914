import { Tracking } from "./Tracking";
export const FulfillmentSettingsTracking = {
    chooseMethodEvent,
    chooseAddressEvent,
    chooseDateEvent,
};
function chooseMethodEvent(fulfillmentMethodType, page) {
    switch (fulfillmentMethodType) {
        case "Delivery":
        case "NoContactDelivery":
            return Tracking.trackEvent({
                tag: "ChooseDelivery",
                contents: { page },
            });
        case "Takeaway":
            return Tracking.trackEvent({
                tag: "ChooseTakeaway",
                contents: { page },
            });
        case "DineIn":
            return Tracking.trackEvent({
                tag: "ChooseDineIn",
                contents: { page },
            });
    }
}
function chooseAddressEvent(page) {
    Tracking.trackEvent({
        tag: "ChooseAddress",
        contents: { page },
    });
}
function chooseDateEvent(page) {
    Tracking.trackEvent({
        tag: "ChooseDate",
        contents: { page },
    });
}
