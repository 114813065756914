import { makeAutoObservable } from "mobx";
import { DEFAULT_APARTMENT_INFO, } from "./ApartmentInfo";
import { Availability } from "../Availability";
export class RomanianApartmentInfo {
    constructor(data) {
        this.type = "Romanian";
        this.data = data;
        makeAutoObservable(this);
    }
    static empty() {
        return new RomanianApartmentInfo({ ...DEFAULT_APARTMENT_INFO });
    }
    static fromStorageData(storageData) {
        return new RomanianApartmentInfo({
            sector: storageData.sector,
            houseOrBlock: storageData.houseOrBlock,
            block: storageData.block,
            staircase: storageData.staircase,
            floor: storageData.floor,
            aptNumber: storageData.aptNumber,
            interphone: storageData.interphone,
            additionalInfo: storageData.additionalInfo,
        });
    }
    clone() {
        return new RomanianApartmentInfo({ ...this.data });
    }
    get isEmpty() {
        return (this.data.block.length === 0 &&
            this.data.staircase.length === 0 &&
            this.data.floor.length === 0 &&
            this.data.aptNumber.length === 0 &&
            this.data.interphone.length === 0 &&
            this.data.additionalInfo.length === 0);
    }
    get isBlock() {
        return this.data.houseOrBlock === "Block";
    }
    setData(updated) {
        this.data = { ...this.data, ...updated };
    }
    get availability() {
        return Availability.boolean({
            BlockMissing: this.isBlock ? this.data.block.length === 0 : false,
            StaircaseMissing: this.isBlock ? this.data.staircase.length === 0 : false,
            FloorMissing: this.isBlock ? this.data.floor.length === 0 : false,
            AptNumberMissing: this.isBlock ? this.data.aptNumber.length === 0 : false,
        });
    }
    get checkoutData() {
        return this.isBlock
            ? { ...this.data }
            : {
                ...DEFAULT_APARTMENT_INFO,
                sector: this.data.sector,
                houseOrBlock: this.data.houseOrBlock,
                interphone: this.data.interphone,
                additionalInfo: this.data.additionalInfo,
            };
    }
    get storageData() {
        return {
            type: "Romanian",
            ...this.data,
        };
    }
}
