import { makeImmutable } from "../../core/makeImmutable";
export class CategoryBasedProductSelector {
    constructor(productCategoryIds) {
        this.kind = "FromCategory";
        this.productCategoryIds = productCategoryIds;
        makeImmutable(this);
    }
    test(productInstance) {
        return this.productCategoryIds.includes(productInstance.productCategoryId);
    }
}
