export class LocalStorage {
    constructor(storage) {
        this.storage = storage;
    }
    static create() {
        if (typeof window === "undefined" ||
            typeof window.localStorage === "undefined") {
            throw new Error("LocalStorage is not available in this environment");
        }
        return new LocalStorage(window.localStorage);
    }
    store(key, value) {
        this.storage.setItem(key, value);
    }
    restore(key) {
        return this.storage.getItem(key);
    }
    erase(key) {
        this.storage.removeItem(key);
    }
}
