export class ProductInstanceName {
    constructor(params) {
        this.restaurant = params.restaurant;
    }
    productInstanceName(productInstance) {
        return productInstance.parts.parts.raw
            .map((part) => {
            return this.restaurant.productTypes.get(part.productType.id).name;
        })
            .join(" / ");
    }
}
