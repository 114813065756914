import { makeImmutable } from "../../core";
import { NullPreorderBuffer } from "./NullPreorderBuffer";
export class PreorderBuffer {
    constructor(params) {
        this.minDays = params.minDays;
        this.orderingHours = params.orderingHours;
        makeImmutable(this);
    }
    static create(params) {
        if (params.minDays > 0) {
            return new PreorderBuffer(params);
        }
        return NullPreorderBuffer;
    }
    min(other) {
        if (this.minDays < other.minDays) {
            return this;
        }
        return other;
    }
    eq(other) {
        return this.minDays === other.minDays;
    }
    firstDate(now) {
        var _a, _b;
        return (_b = (_a = this.orderingHours.addWorkdays(now, this.minDays + 1)) === null || _a === void 0 ? void 0 : _a.begin) !== null && _b !== void 0 ? _b : null;
    }
}
