import { observer } from "mobx-react";
import { Fragment, h } from "preact";
import CreatorModifierItem from "./CreatorModifierItem";
function CreatorModifierSections(props) {
    const { modifierFieldId, modifierInstance } = props;
    return (h(Fragment, null, modifierInstance.sections.map((section) => (h("fieldset", { class: "row" },
        h("div", { class: "col-md-12" },
            h("legend", { class: "topping-group-name h5" }, section.hasName && section.name),
            h("div", { class: `m-form__group ${modifierInstance.isWrapping ? "m-form__group--flex" : ""}` }, section.items.map((item) => (h(CreatorModifierItem, { key: item.instanceId.toString(), modifierFieldId: modifierFieldId, modifierItemInstance: item }))))))))));
}
export default observer(CreatorModifierSections);
