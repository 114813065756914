import { makeImmutable } from "../core";
import { ModifierType, ModifierTypeId, Quantity } from "../ordering";
import { IdMap, RArray } from "../collections";
export class ModifierTypeParser {
    constructor(params) {
        this.parametrizationParser = params.parametrizationParser;
        this.modifierItemTypeParser = params.modifierItemTypeParser;
        makeImmutable(this);
    }
    parse(id, modifier) {
        const flattenedSections = modifier.sections.flatMap((section) => section.items);
        const itemTypes = IdMap.fromIterable(new RArray(flattenedSections).mapOptional(([id, item]) => this.modifierItemTypeParser.parse(id, item)));
        return new ModifierType({
            id: new ModifierTypeId(id),
            itemTypes,
            behaviour: modifier.displayMode === "ModifierPizzaGrid"
                ? "Wrapping"
                : modifier.displayMode === "ModifierRadioButtonList"
                    ? "SingleChoice"
                    : modifier.displayMode === "ModifierCheckboxList"
                        ? "MultipleChoice"
                        : "Numeric",
            maxDuplicatesPerItem: modifier.maxDuplicatesPerItem === null
                ? null
                : Quantity.fromNumber(modifier.maxDuplicatesPerItem),
            maxItems: modifier.maxItems === null
                ? null
                : Quantity.fromNumber(modifier.maxItems),
            minItems: Quantity.fromNumber(modifier.minItems),
            isDivisible: modifier.divisible,
            availabilityParametrization: this.parametrizationParser.parse(modifier.availableWhen),
            editabilityParametrization: this.parametrizationParser.parse(modifier.userEditableWhen),
            exchangePolicy: modifier.exchangePolicy,
        });
    }
}
