import { makeAutoObservable } from "mobx";
import { Email, PhoneNumber } from "../../form";
import { Availability } from "../Availability";
import { FullName } from "./FullName";
/**
 * Contact details needed for an online order.
 */
export class OnlineContactDetails {
    constructor(params) {
        this.type = "Online";
        this.contactDetailsLock = params.contactDetailsLock;
        this.fullName = params.fullName;
        this._email = params.email;
        this._phone = params.phone;
        makeAutoObservable(this);
    }
    static empty(validatePhoneService, contactDetailsLock) {
        return new OnlineContactDetails({
            contactDetailsLock,
            fullName: FullName.empty(),
            email: Email.Empty,
            phone: PhoneNumber.empty(validatePhoneService),
        });
    }
    get isEmpty() {
        return this.fullName.isEmpty && this.email.isEmpty && this.phone.isEmpty;
    }
    static fromStorageData({ validatePhoneService, contactDetailsLock, storageData, }) {
        return new OnlineContactDetails({
            contactDetailsLock,
            fullName: new FullName(storageData.fullName),
            email: Email.create(storageData.email),
            phone: new PhoneNumber({
                validatePhoneService,
                country: storageData.phoneCountry,
                value: storageData.phoneNumber,
            }),
        });
    }
    setData(data) {
        if (this.locked)
            return;
        if (data.fullName !== undefined) {
            this.fullName = data.fullName;
        }
        if (data.email !== undefined) {
            this.email = data.email;
        }
        if (data.phone !== undefined) {
            this.setPhone(data.phone.value, data.phone.country);
        }
    }
    set email(value) {
        if (this.locked) {
            throw Error("Can't change email, ContactDetails locked");
        }
        this._email = value;
    }
    get email() {
        return this._email;
    }
    setPhone(value, country) {
        if (this.locked) {
            throw new Error("Can't change phone number, ContactDetails locked");
        }
        this._phone.value = value;
        this._phone.country = country;
    }
    get phone() {
        return this._phone;
    }
    get locked() {
        return this.contactDetailsLock.locked;
    }
    unlock() {
        this.contactDetailsLock.unlock();
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        var _a, _b, _c;
        return {
            email: (_a = this.email.value) !== null && _a !== void 0 ? _a : null,
            fullName: (_b = this.fullName.value) !== null && _b !== void 0 ? _b : "",
            phone: (_c = this.phone.value) !== null && _c !== void 0 ? _c : null,
        };
    }
    get storageData() {
        return {
            type: "Online",
            fullName: this.fullName.value,
            email: this.email.value,
            phoneNumber: this.phone.value,
            phoneCountry: this.phone.country,
        };
    }
    get availability() {
        return Availability.composite([
            this.fullName.availability,
            this.email.availability,
            this.phone.availability,
        ]);
    }
}
