import { VatId } from "./VatId";
import { makeImmutable } from "../../core/makeImmutable";
export const EMPTY_INVOICE_REQUEST_STORAGE_DATA = {
    isInvoiceNeeded: false,
    vatId: null,
};
/**
 * Request for an invoice.
 */
export class InvoiceRequest {
    constructor(params) {
        this._vatId = params.vatId;
        makeImmutable(this);
    }
    static fromStorageData(storageData) {
        if (storageData.isInvoiceNeeded === false || storageData.vatId === null) {
            return null;
        }
        return new InvoiceRequest({
            vatId: new VatId(storageData.vatId),
        });
    }
    get vatId() {
        return this._vatId;
    }
    get availability() {
        return this._vatId.availability;
    }
    get checkoutData() {
        return {
            vatId: this._vatId.value,
        };
    }
    get storageData() {
        return {
            isInvoiceNeeded: true,
            vatId: this._vatId.value,
        };
    }
}
