import { translate, t } from "../../I18n";
import { makeAutoObservable } from "mobx";
import { toPairs } from "../../core";
import { ErrorBox, SelectableFieldController, TextFieldController, PostCode, CheckboxFieldController, } from "../../form";
import { StreetAddressFormController } from "./StreetAddressFormController";
import { DefaultApartmentInfoController } from "./ApartmentInfoController";
export class PaymentFormController {
    constructor(params) {
        var _a, _b, _c, _d;
        this.payment = params.payment;
        const initialValue = this.getInitialValue(params.paymentMediums, params.payment.medium);
        this.mediumController = new SelectableFieldController({
            initialValue,
            onUpdate: (value) => {
                if (value.kind !== "OnlineGroup") {
                    params.payment.medium = value.domainValue;
                }
            },
            onClear: () => {
                params.payment.medium = null;
            },
            errorBox: new ErrorBox({
                domainObjects: [params.payment],
                errorMessages: {
                    PaymentMediumMissing: translate(t.orders.checkout.payment_method.required),
                    OnlinePaymentMethodRequired: translate(t.orders.checkout.payment_method.online_payment_method_required),
                },
            }),
        });
        this.differentThanDeliveryAddress = new CheckboxFieldController({
            initialValue: (_b = (_a = this.payment.address) === null || _a === void 0 ? void 0 : _a.isDifferentThanDeliveryAddress) !== null && _b !== void 0 ? _b : false,
            onUpdate: (value) => {
                params.payment.address.isDifferentThanDeliveryAddress = value;
            },
        });
        this.postCodeController = new TextFieldController({
            initialValue: (_d = (_c = this.payment.address) === null || _c === void 0 ? void 0 : _c.postCode) === null || _d === void 0 ? void 0 : _d.toString(),
            onUpdate: (str) => {
                params.payment.address.postCode = new PostCode(str);
            },
            errorBox: new ErrorBox({
                domainObjects: [params.payment],
                errorMessages: {
                    PostCodeMissing: translate(t._common.form.errors.required),
                    InvalidPostCode: translate(t.validations.invalid_postcode),
                },
            }),
        });
        this.streetAddressController = new StreetAddressFormController({
            streetAddress: this.payment.address.streetAddress,
        });
        this.apartmentInfoController = new DefaultApartmentInfoController({
            apartmentInfo: this.payment.address.apartmentInfo,
        });
        // TODO: should we make a global rule for this (if restored from storage -> touch form to display errors)?
        if (initialValue !== null) {
            this.mediumController.blur();
        }
        makeAutoObservable(this);
    }
    get fields() {
        return {
            medium: this.mediumController,
            differentThanDeliveryAddress: this.differentThanDeliveryAddress,
            ...this.streetAddressController.fields,
            ...this.apartmentInfoController.fields,
            postCode: this.postCodeController, // overwrite postCode from streetAddress to use PostCodemodel in the payment address
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        return this.payment.availability.isAvailable.isDefinitelyFalse;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.payment;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
    get isPaymentAddressRequired() {
        return this.payment.isAddressRequired;
    }
    get canBeDifferentThanDeliveryAddress() {
        return this.payment.address.canBeDifferentThanDeliveryAddress;
    }
    get isFullAddressRequired() {
        return this.payment.address.isFullAddressRequired;
    }
    getInitialValue(paymentMediums, domain) {
        if (paymentMediums === null || domain === null) {
            return null;
        }
        const medium = paymentMediums.findWhere((medium) => medium.isSelected(domain)) || null;
        if (medium === null) {
            return null;
        }
        return medium.kind === "OnlineGroup" ? medium.getChannel(domain) : medium;
    }
}
