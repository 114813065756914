import { IdMap, RMap, RMutableMap, RSet } from "../collections";
import { makeImmutable, toPairs } from "../core";
import { ModifierItemTypeId, ModifierTypeId, Money, PackagingContainerType, PackagingContainerTypeId, ProductTypeId, } from "../ordering";
import { VolumeParser } from "./VolumeParser";
export class PackagingContainerParser {
    constructor(params) {
        this.catalogue = params.catalogue;
        makeImmutable(this);
    }
    parse() {
        const containers = RMap.fromIterable(toPairs(this.catalogue.packagingContainers).map(([id, container]) => [
            new PackagingContainerTypeId(id),
            {
                price: Money.fromBackend(container.price),
                capacity: VolumeParser.parse(container.capacity),
                allowedProductTypes: [],
                allowedModifierItemTypes: RMutableMap.empty(),
            },
        ]));
        toPairs(this.catalogue.products).forEach(([id, product]) => {
            var _a, _b, _c, _d;
            const productId = new ProductTypeId(id);
            const productCategory = this.catalogue.categories[product.category];
            const categoryContainers = (_b = (_a = productCategory.packagingContainersConfig) === null || _a === void 0 ? void 0 : _a.allowedContainers) !== null && _b !== void 0 ? _b : [];
            const productContainers = (_d = (_c = product.packagingContainersConfigOverride) === null || _c === void 0 ? void 0 : _c.allowedContainers) !== null && _d !== void 0 ? _d : [];
            const finalContainers = productContainers.length > 0 ? productContainers : categoryContainers;
            finalContainers.forEach((containerId) => {
                containers.rawGet(containerId).allowedProductTypes.push(productId);
            });
        });
        toPairs(this.catalogue.modifiers).forEach(([modifierId, modifier]) => {
            const modifierTypeId = new ModifierTypeId(modifierId);
            modifier.sections.forEach((section) => {
                section.items.forEach(([itemId, modifierItemType]) => {
                    var _a, _b;
                    const modifierItemTypeId = new ModifierItemTypeId(itemId);
                    const modifierItemContainers = (_b = (_a = modifierItemType.packagingContainersConfig) === null || _a === void 0 ? void 0 : _a.allowedContainers) !== null && _b !== void 0 ? _b : [];
                    modifierItemContainers.forEach((containerId) => {
                        containers
                            .rawGet(containerId)
                            .allowedModifierItemTypes.getOrCreate(modifierTypeId, () => [])
                            .push(modifierItemTypeId);
                    });
                });
            });
        });
        return IdMap.fromIterable(containers.entries.map(([id, params]) => new PackagingContainerType({
            id,
            ...params,
            allowedProductTypes: RSet.fromIterable(params.allowedProductTypes, {
                ignoreDuplicates: true,
            }),
            allowedModifierItemTypes: params.allowedModifierItemTypes
                .freeze()
                .map((allowedModifierItemTypes) => RSet.fromIterable(allowedModifierItemTypes, {
                ignoreDuplicates: true,
            })),
        })));
    }
}
