import { DateRange, DysjointDateRanges } from "../../core";
export class Asap {
    constructor(params) {
        this.orderingHours = params.orderingHours;
        this.scope = params.scope;
        this.allowOrderingInClosedRestaurant =
            params.allowOrderingInClosedRestaurant;
        this.enableDelayedDelivery = params.enableDelayedDelivery;
    }
    isPlacingOrderPossibleAsap() {
        var _a, _b;
        const event = this.orderingHours.nextEventAtDate(this.scope.now);
        switch (event.type) {
            // NOTICE Ordering is closed, but will begin at this date and restaurant allows placing orders outside ordering hours
            case "OrderingBegins":
                return (this.allowOrderingInClosedRestaurant &&
                    !this.enableDelayedDelivery &&
                    ((_b = (_a = event.at) === null || _a === void 0 ? void 0 : _a.isToday) !== null && _b !== void 0 ? _b : false));
            // NOTICE Ordering is open and will end in the future
            case "OrderingEnds":
                return true;
        }
    }
    preorderDateRanges(preorderSettings) {
        return preorderSettings.dateRanges(this.scope.now);
    }
    asapOptionAvailable(preorderSettings) {
        const endOfDay = this.orderingHours.endOfDay(this.scope.now);
        if (endOfDay === null) {
            return false;
        }
        const todayRanges = DysjointDateRanges.singleton(DateRange.fromDates({
            begin: this.scope.now,
            end: endOfDay,
        }));
        const hasTodayDate = !this.preorderDateRanges(preorderSettings).intersection(todayRanges)
            .isEmpty;
        return this.isPlacingOrderPossibleAsap() && hasTodayDate;
    }
}
