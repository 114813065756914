function isDigit(c) {
    return c >= "0" && c <= "9";
}
export function* naturalKey(s) {
    for (let i = 0; i < s.length;) {
        const start = i;
        const currentChunkIsDigit = isDigit(s.charAt(i));
        do {
            i++;
        } while (i < s.length && isDigit(s.charAt(i)) === currentChunkIsDigit);
        const chunk = s.substring(start, i);
        const res = currentChunkIsDigit
            ? [true, parseInt(chunk)]
            : [false, chunk];
        yield res;
    }
}
export function naturalCompare(a, b) {
    const aKey = naturalKey(a);
    const bKey = naturalKey(b);
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const aChunk = aKey.next();
        const bChunk = bKey.next();
        if (aChunk.done === true) {
            if (bChunk.done === true) {
                return 0;
            }
            else {
                return -1;
            }
        }
        else {
            if (bChunk.done === true) {
                return 1;
            }
        }
        const [aIsNumber, aValue] = aChunk.value;
        const [bIsNumber, bValue] = bChunk.value;
        if (aIsNumber) {
            if (bIsNumber) {
                const diff = aValue - bValue;
                if (diff !== 0) {
                    return diff;
                }
            }
            else {
                return -1;
            }
        }
        else {
            if (bIsNumber) {
                return 1;
            }
            else {
                const diff = aValue.localeCompare(bValue);
                if (diff !== 0) {
                    return diff;
                }
            }
        }
    }
}
