import { makeAutoObservable } from "mobx";
import { Availability } from "../Availability";
const POST_CODE_REQUIRED_COUNTRIES = ["GB", "DE"];
/**
 * Container for city, street and street number information.
 */
export class StreetAddress {
    constructor(params) {
        this.country = params.country;
        this.street = params.street;
        this.streetNumber = params.streetNumber;
        this.city = params.city;
        this.postCode = params.postCode;
        makeAutoObservable(this);
    }
    static empty(country) {
        return new StreetAddress({
            country,
            street: "",
            streetNumber: "",
            city: "",
            postCode: "",
        });
    }
    static fromStorageData(params) {
        if (params.storageData === null) {
            return StreetAddress.empty(params.country);
        }
        return new StreetAddress({
            country: params.country,
            ...params.storageData,
        });
    }
    get data() {
        return {
            street: this.street,
            streetNumber: this.streetNumber,
            city: this.city,
            postCode: this.postCode,
        };
    }
    get isEmpty() {
        return (this.street.length === 0 &&
            this.streetNumber.length === 0 &&
            this.city.length === 0 &&
            this.postCode.length === 0);
    }
    clone() {
        return new StreetAddress(this);
    }
    setData(data) {
        var _a, _b, _c, _d;
        this.street = (_a = data.street) !== null && _a !== void 0 ? _a : this.street;
        this.streetNumber = (_b = data.streetNumber) !== null && _b !== void 0 ? _b : this.streetNumber;
        this.city = (_c = data.city) !== null && _c !== void 0 ? _c : this.city;
        this.postCode = (_d = data.postCode) !== null && _d !== void 0 ? _d : this.postCode;
    }
    eq(other) {
        return (this.street === other.street &&
            this.streetNumber === other.streetNumber &&
            this.city === other.city &&
            this.postCode === other.postCode);
    }
    // TODO: as a input?
    get isPostCodeRequired() {
        return POST_CODE_REQUIRED_COUNTRIES.indexOf(this.country) !== -1;
    }
    get availability() {
        return Availability.boolean({
            CityMissing: this.city.length === 0,
            StreetMissing: this.street.length === 0,
            StreetNumberMissing: this.streetNumber.length === 0,
            PostCodeMissing: this.isPostCodeRequired && this.postCode.length === 0,
        });
    }
    get checkoutData() {
        return this.data;
    }
    get storageData() {
        return this.data;
    }
}
