import { makeImmutable } from "../core";
import { MenuArragmentId, MenuArrangment } from "../ordering";
import { IdMap, RArray } from "../collections";
export class MenuArrangmentParser {
    constructor(params) {
        this.menuSections = params.menuSections;
        makeImmutable(this);
    }
    parse(id, arragment) {
        return new MenuArrangment({
            id: new MenuArragmentId(id),
            sections: IdMap.fromIterable(new RArray(arragment.sections).mapOptional((sectionId) => this.menuSections.rawFind(sectionId))),
        });
    }
}
