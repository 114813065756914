import { h } from "preact";
import { t, translate } from "restaumatic-client";
import ButtonPrice from "../../components/ButtonPrice";
function CreatorFooter(props) {
    const { price, submitLabel, isSubmitVisible, isSubmitDisabled, onSubmit } = props;
    return (h("div", { class: "modal-footer modal-footer--sticky" },
        h("div", { class: "modal-footer__actions" }, isSubmitVisible ? (h("button", { class: `btn btn-primary ${isSubmitDisabled && "disabled"}`, type: "button", "data-field-id": "Creator.addToCart", onClick: onSubmit },
            submitLabel,
            h(ButtonPrice, { dataFieldId: "Creator.price", price: price }))) : (h("button", { "data-bs-dismiss": "modal", class: "btn btn-default", type: "button", "data-field-id": "Creator.backToMenu" }, translate(t.theme_defaults.creator.back_to_menu))))));
}
export default CreatorFooter;
