import { makeAutoObservable } from "mobx";
import { toPairs } from "../../core";
import { CompositeAvailability } from "./CompositeAvailability";
import { Unavailable } from "./Unavailable";
export class BooleanAvailability {
    constructor(booleanMap) {
        this.availability = new CompositeAvailability(toPairs(booleanMap)
            .filtered(([_, include]) => include)
            .raw.map((x) => new Unavailable(x[0])));
        makeAutoObservable(this);
    }
    get unavailabilityReasons() {
        return this.availability.unavailabilityReasons;
    }
    get isAvailable() {
        return this.availability.isAvailable;
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitBoolean(this);
    }
}
