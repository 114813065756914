import { action, makeAutoObservable } from "mobx";
import { Maybe } from "./Maybe";
/*
 * Asynchronous Availability strategy. Allows to provide asynchronously loaded unavailability reason
 */
export class AsyncAvailability {
    constructor(params) {
        this._asyncAvailability = new Maybe(params.pendingUnavailability);
        makeAutoObservable(this);
        params.promise.then(action((result) => {
            this._asyncAvailability = result;
        }), undefined);
    }
    get unavailabilityReasons() {
        return this._asyncAvailability.unavailabilityReasons;
    }
    get isAvailable() {
        return this._asyncAvailability.isAvailable;
    }
    get asyncAvailability() {
        return this._asyncAvailability;
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitAsync(this);
    }
}
