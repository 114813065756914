import { translate, t } from "../../I18n";
import { makeAutoObservable } from "mobx";
import { TextFieldController, ErrorBox } from "../../form";
import { UserNote } from "../../ordering";
import { toPairs } from "../../core";
export class UserNoteController {
    constructor(params) {
        var _a, _b;
        this.userNoteMaxLength = UserNote.MAX_LENGTH;
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.userNote = new TextFieldController({
            initialValue: (_b = (_a = this.fulfillmentInstructions.userNote) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "",
            onUpdate: (value) => {
                this.fulfillmentInstructions.userNote = new UserNote({ value });
            },
            errorBox: new ErrorBox({
                domainObjects: [this.fulfillmentInstructions],
                errorMessages: {
                    MaxLengthExceeded: translate(t.orders.checkout.max_length, {
                        l: UserNote.MAX_LENGTH.toString(),
                    }),
                },
            }),
        });
        makeAutoObservable(this);
    }
    get fields() {
        return {
            userNote: this.userNote,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.fulfillmentInstructions.userNote.availability.isAvailable
            .isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.fulfillmentInstructions;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
}
