import { Fragment, h } from "preact";
function Alert(props) {
    var _a;
    const { message, type = "info", extraClasses = "u-mt2", icon, link, fieldId, } = props;
    if (!message) {
        return null;
    }
    const fieldIdProps = fieldId
        ? { "data-field-id": fieldId, "data-field-type": "info" }
        : {};
    const hasIcon = icon !== undefined;
    const hasLink = link !== undefined;
    return (h("div", { class: `alert alert-${type} ${extraClasses}`, ...fieldIdProps },
        hasIcon && h("i", { class: `icon-${icon}`, "aria-hidden": "true" }),
        message,
        hasLink && (h(Fragment, null,
            " ",
            h("a", { href: link.path, class: (_a = link.customClass) !== null && _a !== void 0 ? _a : "alert-link", "data-field-id": link.fieldId, onClick: link.onClick, "data-ga-action": link.gaAction }, link.label)))));
}
export default Alert;
