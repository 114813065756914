export class CartStorageSerializer {
    serializeForStorage(cart) {
        return JSON.stringify(cart);
    }
    deserializeFromStorage(stringValue) {
        try {
            const cart = JSON.parse(stringValue);
            this.assertValid(cart);
            // Support legacy format
            if ("cart" in cart && "savedAt" in cart) {
                return cart.cart;
            }
            return cart;
        }
        catch (error) {
            // TODO: inject Sentry to not crash the tests
            // Sentry.logError(error);
            return null;
        }
    }
    // TODO: make it better (e.g. with zod?)
    assertValid(data) {
        if (typeof data !== "object" || data == null) {
            throw new Error("Invalid cart data");
        }
    }
}
