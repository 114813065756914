import { Fragment, h } from "preact";
import { observer } from "mobx-react";
import WeeklySchedulePopover from "../views/Cart/WeeklySchedulePopover";
function ProductLabels(props) {
    const { labelsCreator, schedule } = props;
    return (h(Fragment, null, labelsCreator.labels.map((label, i) => {
        const spanClass = "label label-dish m-availability__label m-availability__label--unavailable";
        return schedule !== undefined && label.enabledOnClick ? (h(WeeklySchedulePopover, { id: `label_schedule_${schedule.id}`, weeklySchedule: schedule.schedule },
            h("span", { class: spanClass, key: `label_${i}`, "data-field-id": "productAvailability", "data-field-type": "info", "data-value": "unavailable" },
                label.message,
                " >"))) : (h("span", { class: "m-availability m-availability--not-clickable" },
            h("span", { class: spanClass, key: `label_${i}`, "data-field-id": "productAvailability", "data-field-type": "info", "data-value": "unavailable" }, label.message)));
    })));
}
export default observer(ProductLabels);
