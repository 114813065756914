/**
 * Checks for a valid selector.
 */
export default function (selector) {
    if (typeof selector !== "string") {
        return false;
    }
    try {
        document.querySelector(selector);
    }
    catch (e) {
        return false;
    }
    return true;
}
