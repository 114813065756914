/**
 * Checks for a local URL.
 */
export default function (url) {
    if (typeof url !== "string") {
        return false;
    }
    // Local URL starts with `/` (but not `//`) or `./` or with the website domain.
    if ((/^(\/|\.\/)/.test(url) && url.substring(0, 2) !== "//") ||
        url.startsWith(`${window.location.origin}`)) {
        return true;
    }
    else {
        return false;
    }
}
