import { makeImmutable, toPairs } from "../core";
import { IdMap } from "../collections";
import { Schedule, ScheduleId, WeeklySchedule } from "../ordering";
export class ScheduleParser {
    constructor() {
        makeImmutable(this);
    }
    parse(availabilitySchedules) {
        const schedules = toPairs(availabilitySchedules).map(([id, schedule]) => new Schedule({
            id: new ScheduleId(id),
            schedule: WeeklySchedule.fromWeeklyAvailability(schedule.availability),
        }));
        return IdMap.fromIterable(schedules);
    }
}
