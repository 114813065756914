import { Fragment, h } from "preact";
import { observer } from "mobx-react";
function ButtonPrice(props) {
    const { dataFieldId, price } = props;
    // Empty field so that structure is the same
    if (price === null) {
        return h("span", { "data-field-id": dataFieldId, "data-field-type": "info" });
    }
    return (h(Fragment, null,
        " (",
        h("span", { "data-field-id": dataFieldId, "data-field-type": "info", class: "u-text-nowrap" }, price),
        ")"));
}
export default observer(ButtonPrice);
