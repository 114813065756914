import { makeAutoObservable } from "mobx";
import { LabelAvailabilityVisitor, RSet, } from "..";
import * as NotAvailableFulfillmentMethod from "./NotAvailableFulfillmentMethod";
import * as NotAvailableNowLabel from "./NotAvailableNowLabel";
import * as TemporarilyUnavailableLabel from "./TemporarilyUnavailableLabel";
import * as OrderForLabel from "./OrderForLabel";
const labelCreators = [
    TemporarilyUnavailableLabel.createLabel(),
    NotAvailableNowLabel.createLabel(),
    NotAvailableFulfillmentMethod.createLabel(),
    OrderForLabel.createLabel(),
];
export class LabelsCreator {
    constructor(params) {
        this.scope = params.scope;
        this.productInstances = params.productInstances;
        this.view = params.view;
        makeAutoObservable(this);
    }
    intersectAvailabilityReasonsForAllProductInstance() {
        const reasonArr = this.productInstances.map((productInstance) => {
            const visitor = new LabelAvailabilityVisitor();
            productInstance.availability.accept(visitor);
            return visitor.reasons;
        });
        return reasonArr.length > 0
            ? reasonArr
                .slice(1)
                .reduce((acc, el) => acc.intersection(el), reasonArr[0])
            : RSet.empty();
    }
    get labels() {
        return labelCreators
            .map((labelCreator) => {
            return labelCreator({
                now: this.scope.now,
                fulfillmentTime: this.scope.fulfillmentTime,
                reasons: this.intersectAvailabilityReasonsForAllProductInstance(),
                productInstances: this.productInstances,
                view: this.view,
            });
        })
            .filter((label) => label !== null);
    }
}
