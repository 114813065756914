import { makeAutoObservable } from "mobx";
import { PModifierSectionInstance } from "./PModifierSectionInstance";
import { PPrice } from "./PPrice";
import { PProductError } from "./PProductError";
import { PModifierInstanceId } from "./PModifierInstanceId";
import { PDescription } from "./PDescription";
import { ModifierInstancePrice } from "../pricing/ModifierInstancePrice";
import { PTemporarilyUnavailableIngredients } from "./PTemporarilyUnavailableIngredients";
export class PModifierInstance {
    constructor(params) {
        this.instanceId = new PModifierInstanceId();
        this.domain = params.domain;
        this.modifierType = params.modifierType;
        this.localizationProvider = params.localizationProvider;
        this._sections = this.modifierType.sections.map((modifierItemSection) => PModifierSectionInstance.create({
            modifierItemSection,
            domain: params.domain,
            localizationProvider: params.localizationProvider,
        }));
        this._touch = false;
        this._isEditorVisible = !this.isWrapping;
        makeAutoObservable(this);
    }
    get isVisible() {
        return this.sections.length > 0;
    }
    get isWrapping() {
        return this.domain.behaviour === "Wrapping";
    }
    get isEditable() {
        return this.domain.editability.isPossiblyEditable;
    }
    get isTogglingEditorPossible() {
        return this.isWrapping && this.isEditable;
    }
    get isEditorVisible() {
        return this.isEditable && this._isEditorVisible;
    }
    toggleEditor() {
        this._isEditorVisible = !this._isEditorVisible;
    }
    get typeId() {
        return this.domain.typeId;
    }
    get name() {
        return this.modifierType.name;
    }
    get sections() {
        return this._sections.filter((section) => section.isVisible);
    }
    get isRequired() {
        return !this.modifierType.domain.minItems.isZero;
    }
    touch() {
        this._touch = true;
    }
    get touched() {
        return this._touch || this.sections.some((section) => section.touched);
    }
    get error() {
        if (!this.touched) {
            return PProductError.none();
        }
        const { minItems, maxItems } = this.modifierType.domain;
        const reasons = this.domain.availability.unavailabilityReasons;
        if (reasons.includes("NotExpectedItems")) {
            return PProductError.notExpectedItems(minItems);
        }
        if (reasons.includes("NotEnoughItems")) {
            return PProductError.notEnoughItems(minItems);
        }
        if (reasons.includes("MaxItemsExceeded") && maxItems !== null) {
            return PProductError.maxItemsExceeded(maxItems);
        }
        return PProductError.none();
    }
    get price() {
        const p = new PPrice(this.localizationProvider, ModifierInstancePrice.price(ModifierInstancePrice.priceSelector(this.domain)).spectrum).priceValue;
        return p === null ? "" : ` (${p})`;
    }
    get availability() {
        return this.domain.availability;
    }
    get selectedItemsDescription() {
        return PDescription.modifier(this.domain, this.modifierType);
    }
    get description() {
        return this.modifierType.description;
    }
    get displayMode() {
        return this.modifierType.displayMode;
    }
    get temporarilyUnavailableIngredients() {
        const itemInstances = this._sections.flatMap((modifierSection) => modifierSection.allItems);
        return PTemporarilyUnavailableIngredients.fromInstances(this.modifierType, itemInstances);
    }
}
