import BSModal from "bootstrap/js/dist/modal";
import { observer } from "mobx-react";
import { h } from "preact";
import { createPortal, useEffect, useMemo, useState } from "preact/compat";
import { t, translate } from "restaumatic-client";
function PreactModal(props) {
    const { id, isOpen, modalClass, onShown, onHidden, children } = props;
    // Active state of the modal is used to prevent rendering of the modal content when it's not visible.
    // It combines `isOpen: true` state and show/hide modal animations.
    const [isActive, setIsActive] = useState(false);
    // Prepare modal container element
    const modalElement = useMemo(() => {
        const element = document.createElement("div");
        element.id = props.id;
        element.classList.add("modal", "fade");
        element.tabIndex = -1;
        return element;
    }, [id]);
    // Attach & detach modal element & it's events
    useEffect(() => {
        const handleShow = () => {
            setIsActive(true);
        };
        const handleShown = () => {
            if (onShown)
                onShown();
        };
        const handleHidden = () => {
            setIsActive(false);
            onHidden();
        };
        const rootElement = document.body;
        rootElement.appendChild(modalElement);
        modalElement.addEventListener("show.bs.modal", handleShow);
        modalElement.addEventListener("shown.bs.modal", handleShown);
        modalElement.addEventListener("hidden.bs.modal", handleHidden);
        return () => {
            const modalInstance = BSModal.getOrCreateInstance(modalElement);
            modalInstance.hide();
            modalElement.removeEventListener("show.bs.modal", handleShow);
            modalElement.removeEventListener("shown.bs.modal", handleShown);
            modalElement.removeEventListener("hidden.bs.modal", handleHidden);
            rootElement.removeChild(modalElement);
        };
    }, [modalElement]);
    // Trigger show/hide BSModal actions
    useEffect(() => {
        const modalInstance = BSModal.getOrCreateInstance(modalElement);
        if (isOpen) {
            modalInstance.show();
        }
        else {
            modalInstance.hide();
        }
    }, [modalElement, isOpen]);
    return createPortal(h("div", { class: `modal-dialog ${modalClass !== null && modalClass !== void 0 ? modalClass : ""}`, role: "document" },
        h("div", { class: "modal-content" }, isActive ? children : null)), modalElement);
}
export const ModalHeader = (props) => {
    const { title, titleId, closeLabel = translate(t.generic.close), closeGaAction, closeFieldId, children, } = props;
    return (h("div", { class: "modal-header" },
        h("button", { type: "button", "data-bs-dismiss": "modal", "aria-label": closeLabel, "data-ga-action": closeGaAction, "data-field-id": closeFieldId, class: "modal-close" },
            h("span", { "aria-hidden": "true", class: "icon-cancel" })),
        h("div", { class: "modal-title-wrapper" },
            h("h4", { id: titleId, class: "modal-title" }, title),
            " ",
            children)));
};
export default observer(PreactModal);
