import { Holidays } from "../../ordering";
export class HolidayService {
    constructor(params) {
        this.rpcService = params.rpcService;
    }
    async getHolidays(restaurantId, timezone) {
        const holidays = await this.rpcService.rpc("GetHolidaysRequest", {
            restaurantId: restaurantId.value,
        });
        if (holidays === null) {
            throw new Error("Unable to load restaurant holidays");
        }
        return Holidays.create(timezone, holidays);
    }
}
