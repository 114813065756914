import { h } from "preact";
import { useEffect, useRef } from "preact/compat";
function PreactTooltip(props) {
    var _a;
    const tooltipRef = useRef(null);
    const mountTooltip = (initTooltipElement) => {
        if (!tooltipRef.current)
            return;
        initTooltipElement(tooltipRef.current);
    };
    const unmountTooltip = (disposeTooltip) => {
        if (!tooltipRef.current)
            return;
        disposeTooltip(tooltipRef.current);
    };
    useEffect(() => {
        const { initTooltipElement, disposeTooltip } = require("../utils/Tooltip");
        mountTooltip(initTooltipElement);
        return () => {
            unmountTooltip(disposeTooltip);
        };
    }, []);
    return (h("span", { ref: tooltipRef, class: (_a = props.class) !== null && _a !== void 0 ? _a : "", title: props.title, "data-bs-placement": props.placement, tabIndex: 0 }, props.children));
}
export default PreactTooltip;
