import { makeAutoObservable } from "mobx";
import { ProductInstancePrice } from "../pricing/ProductInstancePrice";
import { PPrice } from "./PPrice";
export class PProductInstanceVariant {
    constructor(params) {
        this.instance = params.instance;
        this._creator = params.creator;
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        this.variantParameterChoiceName = params.variantParameterChoiceName;
        this.localizationProvider = params.localizationProvider;
        makeAutoObservable(this);
    }
    creator() {
        return this._creator();
    }
    get isAvailable() {
        return true;
    }
    get inCartQuantity() {
        return this.purchaseOrderPayload.cartCount(this.instance).value;
    }
    get price() {
        return new PPrice(this.localizationProvider, ProductInstancePrice.price(ProductInstancePrice.priceSelector(this.instance)).spectrum);
    }
    get fieldId() {
        const productIds = this.instance.productTypeIds.rawValues();
        const choiceIds = this.instance.customParameterChoiceIds.rawValues();
        if (choiceIds.length > 0) {
            // TODO: think about the format for field-id's like this one (with choices)
            return `${productIds.join(".")}.choices.${choiceIds.join(".")}`;
        }
        else {
            return `${productIds.join(".")}`;
        }
    }
}
