import { makeAutoObservable } from "mobx";
import { RMap } from "../collections";
import { toPairs } from "../core";
import { ProductCategoryId, ProductTypeId, } from "../ordering";
export class AutomaticCrossSellingWeightsParser {
    constructor(params) {
        this.weights = params.weights;
        makeAutoObservable(this);
    }
    parse() {
        if (this.weights === null) {
            return RMap.empty();
        }
        return RMap.fromIterable(toPairs(this.weights).map(([categoryId, rawCategoryWeights]) => {
            const categoryWeights = RMap.fromIterable(toPairs(rawCategoryWeights).map(([productTypeId, weight]) => [
                new ProductTypeId(productTypeId),
                weight,
            ]));
            return [new ProductCategoryId(categoryId), categoryWeights];
        }));
    }
}
