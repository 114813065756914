import { unTranslationKey, } from "./generated-i18n/TranslationKeys";
import { I18n } from "./services/I18n";
import { t as pendolino } from "./generated-i18n/pendolino/Keys";
import { t as common } from "./generated-i18n/common/Keys";
import { t as core_types_sites } from "./generated-i18n/core_types_sites/Keys";
export const t = new Proxy({
    toString: function () {
        return "";
    },
}, {
    get: function (_, prop) {
        var _a, _b;
        return (
        // NOTICE `pendolino` is generated without prefix, `common` and `core types sites` have prefixes and that is why it works correctly
        (_b = (_a = pendolino[prop]) !== null && _a !== void 0 ? _a : common[prop]) !== null && _b !== void 0 ? _b : core_types_sites[prop]);
    },
});
export function translate(key, params) {
    return I18n.t(unTranslationKey(key), params !== null && params !== void 0 ? params : {});
}
