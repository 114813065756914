import { datadogLogs } from "@datadog/browser-logs";
class DdLogger {
    constructor(logger) {
        this.logger = logger;
    }
    debug(message, messageContext, error) {
        this.logger.debug(message, messageContext, error);
    }
    info(message, messageContext, error) {
        this.logger.info(message, messageContext, error);
    }
    warn(message, messageContext, error) {
        this.logger.warn(message, messageContext, error);
    }
    error(message, messageContext, error) {
        this.logger.error(message, messageContext, error);
    }
}
class ConsoleLogger {
    debug(message, messageContext, error) {
        console.debug(message, messageContext, error);
    }
    info(message, messageContext, error) {
        console.log(message, messageContext, error);
    }
    warn(message, messageContext, error) {
        console.warn(message, messageContext, error);
    }
    error(message, messageContext, error) {
        console.error(message, messageContext, error);
    }
}
class NullLogger {
    debug(message, messageContext, error) { }
    info(message, messageContext, error) { }
    warn(message, messageContext, error) { }
    error(message, messageContext, error) { }
}
const LOGGER_LOCAL_STORAGE_KEY = "client_logger";
const env = typeof Skubacz !== "undefined"
    ? Skubacz.configuration.environment
    : "development";
function selectLogger(originalLogger) {
    var _a;
    const clientLoggerType = typeof localStorage !== "undefined"
        ? (_a = localStorage.getItem(LOGGER_LOCAL_STORAGE_KEY)) === null || _a === void 0 ? void 0 : _a.toLowerCase()
        : undefined;
    if (clientLoggerType === "null") {
        return new NullLogger();
    }
    else if (clientLoggerType === "console") {
        return new ConsoleLogger();
    }
    else if (clientLoggerType === "dd" || clientLoggerType === "datadog") {
        return new DdLogger(originalLogger);
    }
    else {
        if (env === "production") {
            return new DdLogger(originalLogger);
        }
        else {
            return new NullLogger();
        }
    }
}
datadogLogs.init({
    clientToken: "pub8cebd29b3c5b3e1acf8fa02d922e3060",
    site: "datadoghq.eu",
    env,
    service: "NewRestaumaticClient",
    //forwardErrorsToLogs: true,
    //sessionSampleRate: 100,
});
function createHttpLogger() {
    return selectLogger(datadogLogs.createLogger("httpLogger", {
        context: { env },
    }));
}
export const httpLogger = createHttpLogger();
