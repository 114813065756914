import { makeAutoObservable, runInAction } from "mobx";
import { ErrorBox } from "./ErrorBox";
export class TextFieldController {
    constructor(params) {
        var _a, _b;
        this._value = (_a = params.initialValue) !== null && _a !== void 0 ? _a : "";
        this._isDirty = false;
        this._isTouched = false;
        this.onUpdate = params.onUpdate;
        this.onBlur = params.onBlur;
        this.errorBox = (_b = params.errorBox) !== null && _b !== void 0 ? _b : new ErrorBox({ errorMessages: {} });
        makeAutoObservable(this);
    }
    get value() {
        return this._value;
    }
    get isDirty() {
        return this._isDirty;
    }
    get isTouched() {
        return this._isTouched;
    }
    get isValid() {
        return this.errorBox.isEmpty;
    }
    get isInvalid() {
        return !this.errorBox.isEmpty;
    }
    get errors() {
        return this.errorBox.errors;
    }
    blur() {
        this._isTouched = true;
        if (this.onBlur !== undefined) {
            this.onBlur();
        }
    }
    clear() {
        this._value = "";
        this._isDirty = false;
        this._isTouched = false;
        this.updateValue("");
    }
    change(value) {
        this._value = value;
        this._isDirty = true;
        this.updateValue(value);
    }
    updateValue(value) {
        runInAction(() => {
            if (this.onUpdate !== undefined) {
                this.onUpdate(value);
            }
        });
    }
}
