import { t, translate } from "../I18n";
import * as TemporarilyUnavailableLabel from "./TemporarilyUnavailableLabel";
export function createLabel() {
    const notAvailableNowOrThenError = (labelParams) => {
        const message = labelParams.now.compare(labelParams.fulfillmentTime) !== 0
            ? translate(t.client.labels.not_available_now.preorder_text)
            : translate(t.client.labels.not_available_now.text);
        return {
            message,
            enabledOnClick: true,
            type: "error",
        };
    };
    return (labelParams) => {
        if (labelParams.fulfillmentTime === null) {
            return null;
        }
        if (labelParams.view === "Cart") {
            return null;
        }
        if (TemporarilyUnavailableLabel.createLabel()(labelParams) !== null) {
            return null;
        }
        const reasons = labelParams.reasons;
        if (reasons.includes("Schedule") && !reasons.includes("Preorder")) {
            return notAvailableNowOrThenError(labelParams);
        }
        if (reasons.includes("Preorder")) {
            if (labelParams.productInstances.length === 0) {
                return notAvailableNowOrThenError(labelParams);
            }
            const preorderSettings = labelParams.productInstances[0].preorderSettings;
            if (preorderSettings.dateRanges(labelParams.now).ranges.length > 1 ||
                preorderSettings.isSameDayOnly) {
                return notAvailableNowOrThenError(labelParams);
            }
        }
        return null;
    };
}
