const AUTOMATIC_CROSS_SELLING_CATEGORY_KEYWORDS = [
    "alkohol",
    "bajgle",
    "bezalkoholowe",
    "bułeczki",
    "bułeczka",
    "bułka",
    "bułki",
    "buły",
    "buła",
    "ciasta",
    "ciasto",
    "deser",
    "dodatki",
    "dodatkowe",
    "dodatek",
    "dressing",
    "drink",
    "finger",
    "frytki",
    "herbata",
    "herbaty",
    "kawa",
    "kawy",
    "koktajle",
    "lemoniady",
    "lemoniada",
    "nachos",
    "napoje",
    "napój",
    "oliwy",
    "oliwa",
    "owoców",
    "owocow",
    "pancake",
    "pieczywo",
    "piwa",
    "piwo",
    "przekąski",
    "przystawki",
    "sałatka",
    "sałatki",
    "sałaty",
    "smoothie",
    "soki",
    "sosy",
    "starter",
    "surówka",
    "surówki",
    "słodkie",
    "słodko",
    "słodkości",
    "tapas",
    "wina",
    "wino",
    "wypieki",
    "zakąski",
];
export function automaticCrossSellingCategoryFilter(categoryName) {
    const lowerCaseCategoryName = categoryName.toLowerCase();
    return AUTOMATIC_CROSS_SELLING_CATEGORY_KEYWORDS.some((keyword) => lowerCaseCategoryName.includes(keyword));
}
