import { makeImmutable } from "../../core/makeImmutable";
export class CompositeProductSelector {
    constructor(childSelectors) {
        this.kind = "Composite";
        this.selectors = childSelectors;
        makeImmutable(this);
    }
    test(productInstance) {
        return this.selectors.every((selector) => selector.test(productInstance));
    }
}
