import { makeImmutable } from "../core/makeImmutable";
export class PMenuSection {
    constructor(params) {
        this.domain = params.domain;
        this._name = params.name;
        this._description = params.description;
        this.photoUrn = params.photoUrn;
        this.items = params.items;
        this.visibilityScheduleId = params.visibilityScheduleId;
        this.sectionVisibilityService = params.sectionVisibilityService;
        makeImmutable(this);
    }
    get id() {
        return this.domain.id;
    }
    get name() {
        return this._name.value;
    }
    get description() {
        return this._description.value;
    }
    get isVisible() {
        return this.sectionVisibilityService.isSectionVisible(this.visibilityScheduleId);
    }
}
