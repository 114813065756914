import { RomanianApartmentInfoController } from "./RomanianApartmentInfoController";
import { DefaultApartmentInfoController } from "./DefaultApartmentInfoController";
export const ApartmentInfoController = {
    create(params) {
        switch (params.apartmentInfo.type) {
            case "Default":
                return new DefaultApartmentInfoController({
                    apartmentInfo: params.apartmentInfo,
                    onBlur: params.onBlur,
                });
            case "Romanian":
                return new RomanianApartmentInfoController({
                    apartmentInfo: params.apartmentInfo,
                    onBlur: params.onBlur,
                });
        }
    },
};
