export class StorageVersionManager {
    constructor(params) {
        this.storage = params.storage;
    }
    /**
     * Checks the storage version and if it's not valid erases provided storage managers data
     */
    checkStorageFormatVersion(...storageManagersToErase) {
        if (this.storage.restore(StorageVersionManager.STORAGE_FORMAT_VERSION_KEY) !==
            StorageVersionManager.CURRENT_STORAGE_VERSION) {
            storageManagersToErase.forEach((storageManager) => storageManager.erase());
            this.storage.store(StorageVersionManager.STORAGE_FORMAT_VERSION_KEY, StorageVersionManager.CURRENT_STORAGE_VERSION);
        }
    }
}
StorageVersionManager.CURRENT_STORAGE_VERSION = "1";
StorageVersionManager.STORAGE_FORMAT_VERSION_KEY = "client_storageFormatVersion";
