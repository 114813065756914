import { RArray } from "../../collections";
import { makeImmutable } from "../../core";
export class ManualCrossSellingListProvider {
    constructor(crossSellingListRepository) {
        this.crossSellingListRepository = crossSellingListRepository;
        makeImmutable(this);
    }
    items(inputProductTypeIds) {
        const crossSellingListIds = RArray.unique(new RArray(inputProductTypeIds).mapOptional((productTypeId) => this.crossSellingListRepository.getByProductTypeId(productTypeId)));
        return crossSellingListIds.flatMap((crossSellingListId) => this.crossSellingListRepository.get(crossSellingListId).items);
    }
}
