import { makeImmutable } from "../../core/makeImmutable";
export class EveryOrderSelector {
    constructor() {
        this.type = "EveryOrder";
        makeImmutable(this);
    }
    test(purchaseOrderPayload) {
        // NOTICE Dummy condition just to register correct MobX dependency
        return !purchaseOrderPayload.isEmpty;
    }
}
