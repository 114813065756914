import { ModifierExchangePolicy } from "./ModifierExchangePolicy";
import { ModifierItemInstancePrice, } from "./ModifierItemInstancePrice";
export const ModifierInstancePrice = {
    priceSelector,
    price,
};
function priceSelector(modifierInstance) {
    return {
        id: modifierInstance.modifierType.id.value,
        exchangePolicy: modifierInstance.modifierType.exchangePolicy,
        modifierItemInstancesParams: modifierInstance.items.objects.map((itemInstance) => ModifierItemInstancePrice.priceSelector(itemInstance)),
        freeItems: modifierInstance.freeItems,
    };
}
function price(params) {
    return new ModifierExchangePolicy({
        type: params.exchangePolicy,
    }).price(params.modifierItemInstancesParams, params.freeItems);
}
