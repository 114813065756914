import { makeImmutable } from "../../core/makeImmutable";
import { Money } from "../../ordering/Money";
import { ScopedPriceImpl } from "./ScopedPriceImpl";
import { UnscopedPriceImpl } from "./UnscopedPriceImpl";
/**
 * A price of an item.
 *
 * Price is always parametric, so it can never be assumed to have only one concrete value.
 * Negative prices are intepreted as discounts
 */
export class Price {
    constructor(impl) {
        this.impl = impl;
        makeImmutable(this);
    }
    /**
     * Add another Price
     */
    add(other) {
        return new Price(this.impl.combine(other.impl, (a, b) => a.add(b)));
    }
    sub(other) {
        return new Price(this.impl.combine(other.impl, (a, b) => a.sub(b)));
    }
    /**
     * Get the largest of two Pirces
     */
    max(other) {
        return new Price(this.impl.combine(other.impl, (a, b) => a.max(b)));
    }
    /**
     * Get the less of two Pirces
     */
    min(other) {
        return new Price(this.impl.combine(other.impl, (a, b) => a.min(b)));
    }
    /**
     * Multiply by a Quantity
     */
    multiply(quantity) {
        return new Price(this.impl.map((value) => value.multiply(quantity)));
    }
    percentage(percentage) {
        return new Price(this.impl.map((value) => value.percentage(percentage)));
    }
    half() {
        return new Price(this.impl.map((value) => value.half()));
    }
    compare(otherPrice) {
        return this.impl.compare(otherPrice.impl);
    }
    eq(other) {
        return this.compare(other) == 0;
    }
    get negated() {
        return new Price(this.impl.map((value) => value.negated));
    }
    /**
     * Sum provided prices
     */
    static total(prices) {
        return [...prices].reduce((a, b) => a.add(b), Price.free());
    }
    static free() {
        return Price.unscoped(Money.zero());
    }
    static unscoped(value) {
        return new Price(new UnscopedPriceImpl(value));
    }
    static scoped(pricing, parameterSet) {
        return new Price(ScopedPriceImpl.fromPricing(pricing, parameterSet));
    }
    /**
     * Get the spectrum of the price
     *
     * Since a parametric value (price) can never be assumed to be fully applied,
     * this is the most convinient way to get the value of the price.
     */
    get spectrum() {
        return this.impl.spectrum;
    }
    get finalValue() {
        return this.impl.finalValue;
    }
    get hasPossibleValues() {
        return this.impl.hasPossibleValues;
    }
    test(predicate) {
        return this.impl.test(predicate);
    }
}
