const defaultOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 30000,
};
/**
 * Web implementation of geolocation service to get user device coordinates
 */
export class WebGeolocationService {
    constructor(options) {
        this.options = { ...defaultOptions, ...options };
    }
    getCurrentPosition() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((location) => resolve(location.coords), reject, this.options);
        });
    }
}
