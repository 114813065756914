import { IdMap } from "../../collections";
import { makeImmutable } from "../../core/makeImmutable";
export class HiddenModifierItemAvailabilitySpec {
    constructor() {
        this.type = "HiddenModifierItem";
        makeImmutable(this);
    }
    get customParameterTypes() {
        return IdMap.empty();
    }
}
/**
 * A single choice in a ModifierType.
 *
 * For example: a "pork" choice in a "meat" ModifierType.
 */
export class ModifierItemType {
    constructor(params) {
        this.id = params.id;
        this.pricing = params.pricing;
        this.availabilitySpec = params.availabilitySpec;
        this.volume = params.volume;
        this.defaultSelection = params.defaultSelection;
        this.irremovable = params.irremovable;
        makeImmutable(this);
    }
    get customParameterTypes() {
        return this.availabilitySpec.customParameterTypes.union(this.pricing.customParameterTypes);
    }
}
