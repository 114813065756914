import { IdMap } from "../../collections";
import { makeImmutable } from "../../core/makeImmutable";
/*
 * Availability specification of a domain object that is "temporarily unavailable"
 */
export class TemporarilyUnavailableSpec {
    constructor() {
        this.type = "TemporarilyUnavailable";
        makeImmutable(this);
    }
    get customParameterTypes() {
        return IdMap.empty();
    }
}
/*
 * Availability specification of a domain object that is conditionally available (based on parameter values)
 */
export class ConditionallyAvailableSpec {
    constructor(params) {
        this.type = "ConditionallyAvailable";
        this.availabilityParametrization = params.availabilityParametrization;
        this.availabilitySchedule = params.availabilitySchedule;
        makeImmutable(this);
    }
    get customParameterTypes() {
        return this.availabilityParametrization.customParameterTypes;
    }
}
