import { DefaultApartmentInfo, } from "./DefaultApartmentInfo";
import { RomanianApartmentInfo, } from "./RomanianApartmentInfo";
export const DEFAULT_APARTMENT_INFO = {
    sector: "",
    houseOrBlock: "House",
    block: "",
    staircase: "",
    floor: "",
    aptNumber: "",
    interphone: "",
    additionalInfo: "",
};
export const ApartmentInfo = {
    empty(country) {
        return country === "RO"
            ? RomanianApartmentInfo.empty()
            : DefaultApartmentInfo.empty();
    },
    fromStorageData({ country, storageData, }) {
        if (storageData === null) {
            return ApartmentInfo.empty(country);
        }
        if (country !== "RO") {
            return DefaultApartmentInfo.fromStorageData({
                type: "Default",
                floor: storageData.floor,
                aptNumber: storageData.aptNumber,
            });
        }
        switch (country) {
            case "RO":
                return storageData.type === "Romanian"
                    ? RomanianApartmentInfo.fromStorageData(storageData)
                    : RomanianApartmentInfo.empty();
            default:
                return DefaultApartmentInfo.fromStorageData({
                    type: "Default",
                    floor: storageData.floor,
                    aptNumber: storageData.aptNumber,
                });
        }
    },
};
