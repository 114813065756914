import { DysjointDateRanges, makeImmutable, } from "../../core";
export class AbsoluteDatePreorderSetting {
    constructor(params) {
        this.type = "AbsoluteDate";
        this.isSameDayOnly = false;
        this.preorderBuffer = params.preorderBuffer;
        this.range = params.range;
        makeImmutable(this);
    }
    eq(other) {
        return (this.preorderBuffer.eq(other.preorderBuffer) && this.range.eq(other.range));
    }
    dateRanges(now) {
        const start = this.preorderBuffer.firstDate(now);
        const trimmed = start === null ? null : this.range.trimBegin(start);
        return trimmed === null
            ? DysjointDateRanges.Empty
            : DysjointDateRanges.singleton(trimmed);
    }
}
