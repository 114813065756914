import { Tracking } from "./Tracking";
export const PurchaseOrderTracking = {
    trackPurchase,
};
function trackPurchase(orderUuid, orderInfo, siteName) {
    var _a, _b;
    Tracking.trackEventUnique(orderUuid, {
        tag: "Purchase",
        contents: {
            affiliation: siteName !== null && siteName !== void 0 ? siteName : "",
            shipping: orderInfo.deliveryCost,
            transactionId: orderUuid,
            total: orderInfo.total,
            items: orderInfo.items
                .map((item) => mkTrackingItem(item))
                .concat(orderInfo.discounts.map((discount) => mkTrackingItemFromDiscount(discount))),
            coupon: (_b = (_a = orderInfo.coupons[0]) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : null,
        },
    });
}
function mkTrackingItem(item) {
    var _a;
    return {
        name: item.name,
        price: item.unitPrice,
        quantity: item.quantity,
        category: (_a = item.category) !== null && _a !== void 0 ? _a : "",
        ids: item.productId ? [item.productId.toString()] : [],
        list: item.list,
    };
}
function mkTrackingItemFromDiscount(item) {
    return {
        name: item.name,
        price: -item.value,
        quantity: 1,
        category: "Promotional",
        ids: [],
        list: null,
    };
}
