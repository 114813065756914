import { observer } from "mobx-react";
import { h } from "preact";
import CreatorChips from "./CreatorChips";
import ProductTimeBasedAvailability from "../../components/ProductTimeBasedAvailability";
import ProductLabels from "../../components/ProductLabels";
function CreatorDetails(props) {
    const { tags, freeModifierItemsCount, productCartCreator, productAvailabilityInfo, } = props;
    return (h("div", { class: "m-product-card__details" },
        h(CreatorChips, { tags: tags, freeModifierItemsCount: freeModifierItemsCount }),
        Skubacz.configuration.menu_fulfillment_settings ? (h(ProductLabels, { labelsCreator: productCartCreator.labelsCreator })) : (productAvailabilityInfo && (h(ProductTimeBasedAvailability, { productAvailabilityInfo: productAvailabilityInfo })))));
}
export default observer(CreatorDetails);
