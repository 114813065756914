const containerSelectorClass = "js-tabs"; // Assign this to your tabs container where are nav & panels
const itemSelectorClass = "js-tabs-item"; // Assign this to your nav item
const triggerSelectorClass = "js-tabs-link"; // Assign this to your nav item anchor which is child of nav item
const contentSelectorClass = "js-tabs-panel"; // Assign this to related content panel
const activeClass = "is-active";
function initTabs() {
    const tabsInstances = document.querySelectorAll(`.${containerSelectorClass}`);
    tabsInstances.forEach(tabs => {
        const tabsLinks = tabs.querySelectorAll(`.${triggerSelectorClass}`);
        tabsLinks.forEach(item => {
            item.addEventListener("click", (e) => {
                var _a, _b, _c;
                e.preventDefault();
                const link = e.target;
                const panelID = link.getAttribute("aria-controls");
                const isLinkToCurrentActivePanel = (_a = tabs.querySelector(`#${panelID}`)) === null || _a === void 0 ? void 0 : _a.classList.contains(activeClass);
                const isTabLink = link.classList.contains(triggerSelectorClass);
                if (isTabLink && !isLinkToCurrentActivePanel) {
                    // Handle active state of tab nav item
                    const navItems = tabs.querySelectorAll(`.${itemSelectorClass}`);
                    navItems.forEach(item => {
                        item.classList.remove(activeClass);
                    });
                    (_b = link.parentElement) === null || _b === void 0 ? void 0 : _b.classList.add(activeClass);
                    // Handle active state of tab nav link
                    const navLinks = tabs.querySelectorAll(`.${triggerSelectorClass}`);
                    navLinks.forEach(item => {
                        item.classList.remove(activeClass);
                        item.setAttribute("aria-expanded", "false");
                    });
                    link === null || link === void 0 ? void 0 : link.classList.add(activeClass);
                    link === null || link === void 0 ? void 0 : link.setAttribute("aria-expanded", "true");
                    // Handle active state of tab panel
                    const tabPanels = tabs.querySelectorAll(`.${contentSelectorClass}`);
                    tabPanels.forEach(item => {
                        item.classList.remove(activeClass);
                    });
                    (_c = tabs.querySelector(`#${panelID}`)) === null || _c === void 0 ? void 0 : _c.classList.add(activeClass);
                }
            });
        });
    });
}
/**
 * Tabs.
 */
export default {
    init: function () {
        initTabs();
    },
};
