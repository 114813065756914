import { h } from "preact";
function CreatorSelect(props) {
    const { value, options, onChange } = props;
    const handleChange = (event) => {
        const value = event.target.value;
        onChange(value);
    };
    return (h("select", { class: "m-select__select", value: value, onChange: handleChange }, options.map((opt) => (h("option", { disabled: opt.disabled, value: opt.value, "data-option-id": opt.value, key: opt.value }, opt.label)))));
}
export default CreatorSelect;
