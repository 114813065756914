import { IdMap, RArray } from "../../collections";
import { PPromotionType } from "../../presentation";
export class PPromotionParser {
    constructor(params) {
        this.domain = params.domain;
        this.translatedParser = params.translatedParser;
    }
    parsePromotion(promotion) {
        const domain = this.domain.parse(promotion);
        if (domain === null) {
            return null;
        }
        return new PPromotionType({
            domain,
            name: this.translatedParser.parse(promotion.name),
            description: this.translatedParser.parse(promotion.description),
            isCoupon: promotion.promotionType === "RequiresCoupon",
        });
    }
    parsePromotions(promotions) {
        return IdMap.fromIterable(new RArray(promotions).mapOptional((promotion) => this.parsePromotion(promotion)));
    }
}
