/**
 * Get the page main content element.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/main
 * @returns {HTMLElement}
 */
export default function () {
  return (
    document.getElementsByTagName("main")[0] ||
    document.getElementById("content") ||
    document.getElementsByTagName("body")[0]
  );
}
