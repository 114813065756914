import { makeImmutable } from "../../core";
export class PromotionFreebie {
    constructor(params) {
        this.name = params.name;
        this.value = params.value;
        makeImmutable(this);
    }
    static fromStorageData(params) {
        return params.promotionFreebies.findWhere((freebie) => freebie.name === params.storageData);
    }
    get storageData() {
        return this.name;
    }
}
