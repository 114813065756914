import { makeAutoObservable } from "mobx";
import { OrderLine } from "../OrderLine";
import { Quantity } from "../Quantity";
export class CrossSellingItemInstanceProductCardBehaviour {
    constructor(params) {
        this.type = "ProductCard";
        this.typeId = params.typeId;
        this.crossSellingItemQuantities = params.crossSellingItemQuantities;
        makeAutoObservable(this);
    }
    get quantity() {
        return this.crossSellingItemQuantities.quantity(this.typeId);
    }
    increment() {
        this.crossSellingItemQuantities.increment(this.typeId);
    }
    decrement() {
        this.crossSellingItemQuantities.decrement(this.typeId);
    }
}
export class CrossSellingItemInstanceCartBehaviour {
    constructor(params) {
        this.type = "Cart";
        this.quantity = Quantity.One;
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        this.productInstanceCreator = params.productInstanceCreator;
        makeAutoObservable(this);
    }
    get productInstanceTrackingId() {
        return this.productInstanceCreator.instance.trackingId;
    }
    add() {
        this.purchaseOrderPayload.addOrderLine(new OrderLine({
            productInstance: this.productInstanceCreator.instance,
            quantity: this.quantity,
        }));
    }
}
