import { makeAutoObservable } from "mobx";
import { RArray } from "../../collections";
import { PAY_PO_CHANNEL_ID } from "../Payment";
/**
 * A parent container for all information about the restaurant (menu, timezone, etc...)
 */
export class Restaurant {
    constructor(params) {
        this.id = params.id;
        this.menu = params.menu;
        this.packingAlgorithm = params.packingAlgorithm;
        this.timezone = params.timezone;
        this.appOrigin = params.appOrigin;
        this.orderingPolicy = params.orderingPolicy;
        this.minOrderValue = params.minOrderValue;
        this.crossSellingListProvider = params.crossSellingListProvider;
        this.fulfillmentMethods = params.fulfillmentMethods;
        this.paymentMethods = params.paymentMethods;
        this._paymentChannels = params.paymentChannels;
        this.tips = params.tips;
        makeAutoObservable(this);
    }
    get paymentChannels() {
        if (!this.paymentMethods.includes("Online")) {
            return RArray.empty();
        }
        if (this.appOrigin === "QrWaiter") {
            return this._paymentChannels.filtered((channel) => {
                return !(channel.id.tag === "P24Channel" &&
                    channel.id.contents === PAY_PO_CHANNEL_ID);
            });
        }
        return this._paymentChannels;
    }
}
