import { ProductInstanceHalves, } from "./ProductInstanceHalves";
import { SingleProductInstancePart, } from "./SingleProductInstancePart";
export const ProductInstanceParts = {
    fromStorageData({ menu, storageData, }) {
        switch (storageData.type) {
            case "SinglePart":
                return SingleProductInstancePart.fromStorageData({ menu, storageData });
            case "Halves":
                return ProductInstanceHalves.fromStorageData({ menu, storageData });
        }
    },
};
