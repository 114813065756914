import { makeAutoObservable } from "mobx";
import { RArray, naturalCompare, } from "../..";
import { PProductConfigCreator } from "./PProductConfigCreator";
import { PProductError } from "./PProductError";
import { PProductInstanceCreatorPart } from "./PProductInstanceCreatorPart";
import { PHalvesProductInstanceCreator, PSinglePartProductInstanceCreator, } from "./PProductInstanceCreatorState";
import { PProductUnavailabilityDetails } from "./PProductInstanceUnavailabilityDetails";
export class PProductInstanceCreator {
    constructor(params) {
        this.domain = params.domain;
        this.orderingHours = params.orderingHours;
        this.localizationProvider = params.localizationProvider;
        this.restaurant = params.restaurant;
        this.possibleProductTypes = params.possibleProductTypes;
        this._state = this.createState();
        makeAutoObservable(this);
    }
    static create(params) {
        const possibleProductTypes = new RArray(params.domain.possibleProductTypeIds)
            .map((possibleProductTypeId) => params.restaurant.productTypes.get(possibleProductTypeId))
            .sorted((a, b) => naturalCompare(a.name, b.name)).raw;
        return new PProductInstanceCreator({
            domain: params.domain,
            orderingHours: params.orderingHours,
            localizationProvider: params.localizationProvider,
            restaurant: params.restaurant,
            possibleProductTypes,
        });
    }
    get scope() {
        return this.domain.scope;
    }
    get instance() {
        return this.domain.instance;
    }
    commonModifierOrder(productTypeIds) {
        const restaurantProductTypes = this.restaurant.productTypes;
        return productTypeIds
            .flatMap((productTypeId) => restaurantProductTypes.get(productTypeId).divisibleModifiersOrder)
            .filter((modifierTypeId, index, array) => array.indexOf(modifierTypeId) === index);
    }
    createState() {
        const parts = this.domain.state;
        const productTypeIds = parts.productTypeIds.rawValues();
        const commonConfigCreator = PProductConfigCreator.create({
            domain: parts.commonConfigCreator,
            restaurant: this.restaurant,
            localizationProvider: this.localizationProvider,
            modifierOrder: this.commonModifierOrder(productTypeIds),
        });
        if (parts.isSplit) {
            const firstHalf = this.partCreator(parts.firstHalf);
            const secondHalf = this.partCreator(parts.secondHalf);
            return new PHalvesProductInstanceCreator({
                commonConfigCreator,
                firstHalf,
                secondHalf,
            });
        }
        else {
            const part = this.partCreator(parts.partCreator);
            return new PSinglePartProductInstanceCreator({
                commonConfigCreator,
                part,
            });
        }
    }
    get state() {
        return this._state;
    }
    partCreator(part) {
        return PProductInstanceCreatorPart.create({
            domain: part,
            orderingHours: this.orderingHours,
            productTypeId: part.productType.id,
            possibleProductTypes: this.possibleProductTypes,
            restaurant: this.restaurant,
            localizationProvider: this.localizationProvider,
        });
    }
    get product() {
        return this.state.product;
    }
    updateState() {
        this._state = this.createState();
    }
    get error() {
        const parts = this.domain.state.productInstanceParts;
        if (parts.type === "Halves" &&
            parts.availability.unavailabilityReasons.includes("HalvingAvailability")) {
            const { parametersDetails } = new PProductUnavailabilityDetails({
                availability: this.domain.instance.availability,
                restaurant: this.restaurant,
            });
            if (parametersDetails.length > 0) {
                return PProductError.notDivisible(parametersDetails);
            }
        }
        return PProductError.none();
    }
    touch() {
        this.state.touch();
    }
}
