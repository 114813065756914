import { makeAutoObservable } from "mobx";
export class ContactDetailsLock {
    constructor(coupon) {
        this.coupon = coupon;
        makeAutoObservable(this);
    }
    get locked() {
        const { state } = this.coupon;
        return state.type === "Applied" && state.source === "FirstOrderDiscount";
    }
    unlock() {
        if (!this.locked) {
            throw new Error("Can't unlock ContactDetailsLock, it is already unlocked.");
        }
        this.coupon.clear();
    }
}
