import { DateRange, DysjointDateRanges, makeImmutable, } from "../../core";
export class RelativeDatePreorderSetting {
    constructor(params) {
        this.type = "RelativeDate";
        this.isSameDayOnly = false;
        this.preorderBuffer = params.preorderBuffer;
        this.maxDays = params.maxDays;
        makeImmutable(this);
    }
    max(other) {
        return new RelativeDatePreorderSetting({
            preorderBuffer: this.preorderBuffer.min(other.preorderBuffer),
            maxDays: Math.max(this.maxDays, other.maxDays),
        });
    }
    dateRanges(now) {
        const begin = this.preorderBuffer.firstDate(now);
        const end = now.add(this.maxDays, "days").endOfDay();
        if (begin === null || begin.isAfter(end)) {
            return DysjointDateRanges.Empty;
        }
        const range = DateRange.fromDates({
            begin,
            end,
        });
        return DysjointDateRanges.singleton(range);
    }
}
