import { RArray } from "../collections";
import { makeImmutable } from "../core/makeImmutable";
import { Price } from "./Price";
import { Quantity } from "../ordering/Quantity";
import { ModifierItemInstancePrice, } from "./ModifierItemInstancePrice";
/**
 * How the modifiers can
 *
 * https://restaumatic.atlassian.net/browse/RS-5851?atlOrigin=eyJpIjoiYTZmZWNlZjM4NGNhNGRmZTlmNWEwOTQwY2I1ODdiNGMiLCJwIjoiaiJ9
 */
export class ModifierExchangePolicy {
    constructor(params) {
        this.type = params.type;
        makeImmutable(this);
    }
    addedQuantity(modifierItem) {
        if (modifierItem.defaultQuantity.geq(modifierItem.quantity)) {
            return Quantity.Zero;
        }
        return modifierItem.quantity.sub(modifierItem.defaultQuantity);
    }
    removedQuantity(modifierItem) {
        if (modifierItem.quantity.geq(modifierItem.defaultQuantity)) {
            return Quantity.Zero;
        }
        return modifierItem.defaultQuantity.sub(modifierItem.quantity);
    }
    removedPrice(modifierItem) {
        const removedPrice = ModifierItemInstancePrice.unitPrice(modifierItem);
        if (removedPrice.hasPossibleValues) {
            const removedQuantity = this.removedQuantity(modifierItem);
            return removedPrice.multiply(removedQuantity);
        }
        return Price.free();
    }
    addedItemPrices(modifierItems) {
        switch (this.type) {
            case "NotIncluded":
                return modifierItems.flatMap((modifierItemInstanceParams) => RArray.repeat(ModifierItemInstancePrice.unitPrice(modifierItemInstanceParams), modifierItemInstanceParams.quantity));
            case "AdditionOnly":
            case "ExchangeWithClamp":
            case "UnlimitedExchange":
                return modifierItems.flatMap((modifierItemInstanceParams) => {
                    return RArray.repeat(ModifierItemInstancePrice.unitPrice(modifierItemInstanceParams), this.addedQuantity(modifierItemInstanceParams));
                });
        }
    }
    removedItemsPrice(modifierItems) {
        switch (this.type) {
            case "NotIncluded":
            case "AdditionOnly":
                return Price.free();
            case "ExchangeWithClamp":
            case "UnlimitedExchange": {
                const removedPrices = modifierItems.map((modifierItemInstanceParams) => this.removedPrice(modifierItemInstanceParams));
                return Price.total(removedPrices);
            }
        }
    }
    price(modifierItems, freeItems) {
        const addedItemPrices = this.addedItemPrices(modifierItems)
            .sorted((a, b) => -1 * a.compare(b))
            .sliceAt(freeItems.value)[1];
        const addedItemsPrice = Price.total(addedItemPrices);
        const removedItemsPrice = this.removedItemsPrice(modifierItems);
        const finalPrice = addedItemsPrice.sub(removedItemsPrice);
        switch (this.type) {
            case "NotIncluded":
            case "UnlimitedExchange":
            case "AdditionOnly":
                return finalPrice;
            case "ExchangeWithClamp":
                return finalPrice.max(Price.free());
        }
    }
}
