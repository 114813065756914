export function optionalEq(a, b) {
    if (a === null) {
        if (b === null) {
            return true;
        }
        return false;
    }
    else {
        if (b === null) {
            return false;
        }
        return a.eq(b);
    }
}
