import { makeAutoObservable } from "mobx";
import { IdentifiableMap } from "../../collections/IdentifiableMap";
import { CustomParameterInstance } from "../CustomParameterInstance/CustomParameterInstance";
import { ModifierInstance } from "../ModifierInstance";
import { ParameterScope } from "../ParameterScope";
import { ProductConfigScope } from "../ParameterScope/ProductConfigScope";
import { ProductConfig } from "../ProductConfig";
/*
 * Utility class for building correct `ProductConfig` - it deals with observing modifierTypes and customParameterTypes and creates according instances
 */
export class ProductConfigCreator {
    constructor(params) {
        this._scope = ParameterScope.Uninitialized;
        this.modifierInstances = params.modifierInstances;
        this.customParameterInstances = params.customParameterInstances;
        makeAutoObservable(this);
    }
    static create({ modifierTypes, modifierSettings, customParameterTypes, initialModifierInstances, initialCustomParameterInstances, }) {
        const modifierInstances = IdentifiableMap.fromIterable("typeId", modifierTypes.objects.map((modifierType) => {
            const initialInstance = initialModifierInstances.find(modifierType.id);
            if (initialInstance === null) {
                return ModifierInstance.createWithDefaultItems({
                    modifierType,
                    modifierSettings: modifierSettings(modifierType),
                });
            }
            return initialInstance.clone();
        }));
        const customParameterInstances = IdentifiableMap.fromIterable("typeId", customParameterTypes.objects.map((customParameterType) => {
            const initialInstance = initialCustomParameterInstances.find(customParameterType.id);
            if (initialInstance === null) {
                return CustomParameterInstance.createWithDefaultChoice(customParameterType);
            }
            return initialInstance.clone();
        }));
        return new ProductConfigCreator({
            modifierInstances,
            customParameterInstances,
        });
    }
    clone() {
        return new ProductConfigCreator({
            modifierInstances: IdentifiableMap.fromIterable("typeId", this.modifierInstances.objects.map((modifierInstance) => modifierInstance.clone())),
            customParameterInstances: IdentifiableMap.fromIterable("typeId", this.customParameterInstances.objects.map((customParameterInstance) => customParameterInstance.clone())),
        });
    }
    get scope() {
        return this._scope;
    }
    setScope(scope) {
        this._scope = scope;
        this.modifierInstances.objects.forEach((modifierInstance) => modifierInstance.setScope(this._scope));
    }
    initWithScope(parentScope) {
        const scope = new ProductConfigScope({
            parentScope,
            customParameterInstances: this.customParameterInstances,
        });
        this.setScope(scope);
    }
    selectInferredDefaults() {
        this.modifierInstances.objects.forEach((modifierInstance) => modifierInstance.selectInferredDefaults());
    }
    get productConfig() {
        return new ProductConfig({
            modifierInstances: this.modifierInstances,
            customParameterInstances: this.customParameterInstances,
        });
    }
    get availability() {
        return this.productConfig.availability;
    }
}
