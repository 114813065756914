import { makeAutoObservable } from "mobx";
import { t, translate } from "../../I18n";
import { RDay, } from "../../api/orderingHoursWidget";
import { PFulfillmentTimeNavigation } from "./PFulfillmentTimeNavigation";
import { WeeklySchedule, } from "../../ordering";
export class PFulfillmentTime {
    constructor(params) {
        this.domain = params.domain;
        this.defaultPreorderSettingsProvider =
            params.defaultPreorderSettingsProvider;
        this.defaultWeeklySchedule = WeeklySchedule.Full;
        this.customPreorderSettings = null;
        this.customWeeklySchedule = null;
        this.navigation = null;
        this._dateItems = [];
        this._dateTimeItems = [];
        this._value = this.domain.value;
        this._activeDate = "";
        // TODO: Currently mobx recomputes this often and it makes performance issues... so for now we compute it once on init
        this.isVisible = this.domain.hasAnyAvailableTime(this.defaultPreorderSettingsProvider.preorderSettings, this.defaultWeeklySchedule);
        this.isEditable = !this.domain.isOnlyAsap(this.defaultPreorderSettingsProvider.preorderSettings, this.defaultWeeklySchedule);
        makeAutoObservable(this);
    }
    get isTodayOnly() {
        return (this.preorderSettings.isSameDayOnly &&
            this._dateItems.every((dateItem) => "date" in dateItem && dateItem.date.isToday));
    }
    get isValid() {
        return this._value !== null;
    }
    get isDelivery() {
        return this.domain.isDelivery;
    }
    get navigationType() {
        var _a;
        return (_a = this.navigation) === null || _a === void 0 ? void 0 : _a.type;
    }
    get hasSavedValue() {
        return this.domain.value !== null;
    }
    get dateItems() {
        return this.formatDateItems(this._dateItems);
    }
    get dateTimeItems() {
        return this.formatDateTimeItems(this._dateTimeItems);
    }
    get activeDate() {
        return this._activeDate;
    }
    get hasPrevItems() {
        const firstDate = getItemsFirstDate(this._dateItems);
        return this.domain.hasPrevious(firstDate, this.preorderSettings);
    }
    get hasNextItems() {
        const lastDate = getItemsLastDate(this._dateItems);
        return this.domain.hasNext(lastDate, this.preorderSettings);
    }
    get formattedValue() {
        var _a;
        switch ((_a = this.domain.value) === null || _a === void 0 ? void 0 : _a.type) {
            case "ASAP":
                return {
                    title: translate(t.orders.form.asap),
                    description: translate(t.orders.form.asap_description),
                };
            case "OnTime":
                return {
                    title: `${getDayName(this.domain.value.date)}${this.domain.value.date.isToday
                        ? ""
                        : `, ${this.domain.value.date.format("D")} ${getAbbrMonthName(this.domain.value.date)}`}, ${this.domain.value.date.fullHour}`,
                };
            default:
                return { title: translate(t.orders.form.choose_date) };
        }
    }
    get formattedValueShort() {
        var _a;
        switch ((_a = this.domain.value) === null || _a === void 0 ? void 0 : _a.type) {
            case "ASAP":
                return translate(t.theme_defaults.hours.now);
            case "OnTime":
                return this.domain.value.date.isToday
                    ? this.domain.value.date.fullHour
                    : this.domain.value.date.format("DD.MM, HH:mm");
            default:
                return translate(t.restaurants.widgets.menu.plan_date);
        }
    }
    get valueDay() {
        if (this._value === null) {
            return null;
        }
        switch (this._value.type) {
            case "ASAP":
                return this.domain.today;
            case "OnTime":
                return RDay.fromRDate(this._value.date);
        }
    }
    get weeklySchedule() {
        return this.customWeeklySchedule || this.defaultWeeklySchedule;
    }
    get preorderSettings() {
        return (this.customPreorderSettings ||
            this.defaultPreorderSettingsProvider.preorderSettings);
    }
    get preorderDateRanges() {
        return this.preorderSettings.dateRanges(this.domain.today.toRDate());
    }
    initializeForMenu(type, pageSize) {
        this.initialize({
            type,
            pageSize,
            value: this.domain.value,
            preorderSettings: null,
            weeklySchedule: null,
        });
    }
    initializeForCreator(type, pageSize, productCardCreator) {
        this.initialize({
            type,
            pageSize,
            value: null,
            preorderSettings: productCardCreator.preorderSettings,
            weeklySchedule: productCardCreator.weeklySchedule,
        });
    }
    initialize(params) {
        this._value = params.value;
        this.customPreorderSettings = params.preorderSettings;
        this.customWeeklySchedule = params.weeklySchedule;
        const minDay = this.preorderDateRanges.begin
            ? RDay.fromRDate(this.preorderDateRanges.begin)
            : this.domain.today;
        const maxDay = this.preorderDateRanges.end
            ? RDay.fromRDate(this.preorderDateRanges.end)
            : this.domain.today;
        const navigationParams = {
            pageSize: params.pageSize,
            getDates: this.getDates.bind(this),
            minDay,
            maxDay,
        };
        this.navigation =
            params.type === "Pagination"
                ? PFulfillmentTimeNavigation.createPaginated(navigationParams)
                : PFulfillmentTimeNavigation.createInfinityScroll(navigationParams);
        // NOTICE: Pagination temp. improvement (temp. because Pagination probably will be removed)
        // Get first available dayItems page and try to set active date from domain value.
        // This should prevent from displaying "left" pagination arrow in the most often cases where prev page has only ~1 item,
        // Example: "Tomorrow" is active, we are displaying "prev" button and allow user to go to the prev page with only "Today" item.
        // We would like to show dayItems from "Today" and select "Tomorrow" as active.
        if (this.navigation.type === "Pagination") {
            const initDateItems = this.navigation.getInit(minDay);
            const activeDay = this.valueDay || minDay;
            const activeDateItem = initDateItems.find((dateItem) => {
                return "date" in dateItem && dateItem.date.isSame(activeDay);
            });
            if (activeDateItem) {
                this._dateItems = initDateItems;
                this.setActiveDate(activeDateItem);
            }
            else {
                // Simply get dayItems from active (always good for InfinityScroll navigation)
                this._dateItems = this.navigation.getInit(activeDay);
                this.setActiveDate(this._dateItems[0]);
            }
        }
        else {
            this._dateItems = this.navigation.getInit(this.valueDay || minDay);
            this.setActiveDate(this._dateItems[0]);
        }
    }
    getNext() {
        if (this.navigation === null) {
            throw new Error("Trying to get next page before initializing (setting navigation strategy)");
        }
        this._dateItems = this.navigation.getNext(this._dateItems);
    }
    getPrev() {
        if (this.navigation === null) {
            throw new Error("Trying to get next page before initializing (setting navigation strategy)");
        }
        this._dateItems = this.navigation.getPrev(this._dateItems);
    }
    setActiveDate(dateItem) {
        switch (dateItem.type) {
            case "Available":
            case "Conflicting":
                this._activeDate = dateItem.date.toString();
                this._dateTimeItems = dateItem.items;
                this._value = this.getSameHourValueFromDay(dateItem);
                break;
            case "Disabled":
                this._activeDate = dateItem.from.toString();
                this._dateTimeItems = [];
                this._value = null;
                break;
        }
    }
    isActiveDate(item) {
        switch (item.type) {
            case "Available":
            case "Conflicting":
                return item.date.toString() === this._activeDate;
            case "Disabled":
                // TODO: is between from & to
                return item.from.toString() === this._activeDate;
        }
    }
    setValue(value) {
        this._value = value;
    }
    isActiveValue(item) {
        if (this._value === null) {
            return false;
        }
        if (this._value.type === "ASAP" && item.value.type === "ASAP") {
            return true;
        }
        return (this._value.type === "OnTime" &&
            item.value.type === "OnTime" &&
            this._value.date.eq(item.value.date));
    }
    isDisabledItem(item) {
        return item.type === "Disabled";
    }
    save() {
        if (this.isValid) {
            this.domain.setValue(this._value);
        }
    }
    hasAnyAvailableTime(preorderSettings, weeklySchedule) {
        return this.domain.hasAnyAvailableTime(preorderSettings, weeklySchedule || WeeklySchedule.Full);
    }
    isAvailable(preorderSettings, weeklySchedule) {
        return this.domain.isProductAvailable({
            preorderSettings,
            schedule: weeklySchedule || WeeklySchedule.Full,
        });
    }
    getDates(from, to) {
        const domainItems = this.domain.getDates(this.preorderSettings, this.weeklySchedule, from, to);
        return domainItems.map((domainItem) => {
            switch (domainItem.type) {
                case "Available":
                case "Conflicting":
                    return domainItem;
                case "Disabled":
                    return {
                        type: "Disabled",
                        from: domainItem.date,
                        to: domainItem.date,
                    };
            }
        });
    }
    getSameHourValueFromDay(dateItem) {
        // Try to set curresponding value for the new selected day (if available) or reset
        if (this._value !== null && this._value.type === "ASAP") {
            const newValue = dateItem.items.find((item) => item.type === "Available" && item.value.type === "ASAP");
            return (newValue === null || newValue === void 0 ? void 0 : newValue.value) || null;
        }
        else if (this._value !== null && this._value.type === "OnTime") {
            // Try to set hour value for the new day or reset
            const currentHour = this._value.date.fullHour;
            const newValue = dateItem.items.find((item) => item.value.type === "OnTime" &&
                item.value.date.fullHour === currentHour) || null;
            return (newValue === null || newValue === void 0 ? void 0 : newValue.value) || null;
        }
        return null;
    }
    formatDateItems(dateItems) {
        return dateItems.map((dateItem) => {
            switch (dateItem.type) {
                case "Available":
                case "Conflicting":
                    return {
                        title: getDayName(dateItem.date, true),
                        description: dateItem.date.toDayAndMonthString(),
                        ...dateItem,
                    };
                case "Disabled":
                    const isSingleDay = dateItem.from.toString() === dateItem.to.toString();
                    return {
                        title: isSingleDay
                            ? dateItem.from.isToday || dateItem.from.isTomorrow
                                ? getDayName(dateItem.from, true)
                                : dateItem.from.toDayAndMonthString()
                            : `${dateItem.from.toDayAndMonthString()}-${dateItem.to.toDayAndMonthString()}`,
                        description: translate(t.orders.form.unavailable),
                        ...dateItem,
                    };
            }
        });
    }
    formatDateTimeItems(dateTimeItems) {
        return dateTimeItems.map((dateTimeItem) => {
            switch (dateTimeItem.value.type) {
                case "ASAP":
                    return {
                        title: translate(t.orders.form.asap),
                        description: translate(t.orders.form.asap_description),
                        stringValue: "ASAP",
                        ...dateTimeItem,
                    };
                case "OnTime":
                    const hourString = dateTimeItem.value.date.format("HH:mm");
                    return {
                        title: hourString,
                        description: undefined,
                        stringValue: hourString,
                        ...dateTimeItem,
                    };
            }
        });
    }
}
function toLowerCase(str) {
    return str.toLowerCase();
}
function getDayName(date, abbreviation = false) {
    if (date.isToday) {
        return translate(t.date.today);
    }
    if (date.isTomorrow) {
        return translate(t.date.tomorrow);
    }
    if (abbreviation) {
        return `${translate(t.date.day.abbr_names[toLowerCase(date.weekday)])}.`;
    }
    const translated = translate(t.date.day.names[toLowerCase(date.weekday)]);
    // Capitalize first letter due to translations missmatch (monday, Friday, etc.)
    return `${translated.charAt(0).toUpperCase()}${translated.slice(1)}`;
}
function getAbbrMonthName(date) {
    return translate(t.date.month.abbr_names[toLowerCase(date.month)]);
}
export function getItemsFirstDate(dateItems) {
    const firstItem = dateItems[0];
    if (!firstItem) {
        return null;
    }
    switch (firstItem.type) {
        case "Available":
        case "Conflicting":
            return firstItem.date;
        case "Disabled":
            return firstItem.from;
    }
}
export function getItemsLastDate(dateItems) {
    const lastItem = dateItems[dateItems.length - 1];
    if (!lastItem) {
        return null;
    }
    switch (lastItem.type) {
        case "Available":
        case "Conflicting":
            return lastItem.date;
        case "Disabled":
            return lastItem.to;
    }
}
