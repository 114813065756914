import { makeAutoObservable } from "mobx";
import { RArray, } from "../..";
import { PProductConfigCreator } from "./PProductConfigCreator";
import { PProductError } from "./PProductError";
import { PProductUnavailabilityDetails } from "./PProductInstanceUnavailabilityDetails";
import { ProductAvailabilityInfo } from "./ProductAvailabilityInfo";
export class PProductInstanceCreatorPart {
    constructor(params) {
        this.domain = params.domain;
        this.orderingHours = params.orderingHours;
        this.possibleProductTypes = params.possibleProductTypes;
        this.productType = params.productType;
        this.partConfigCreator = params.partConfigCreator;
        this.restaurant = params.restaurant;
        makeAutoObservable(this);
    }
    static create(params) {
        const productType = params.restaurant.productTypes.get(params.productTypeId);
        const modifierOrder = productType.divisibleModifiersOrder;
        const partConfigCreator = PProductConfigCreator.create({
            domain: params.domain.productConfigCreator,
            restaurant: params.restaurant,
            localizationProvider: params.localizationProvider,
            modifierOrder,
        });
        return new PProductInstanceCreatorPart({
            domain: params.domain,
            orderingHours: params.orderingHours,
            productType,
            partConfigCreator,
            possibleProductTypes: params.possibleProductTypes,
            restaurant: params.restaurant,
        });
    }
    get productTypeId() {
        return this.productType.id;
    }
    get productName() {
        return this.productType.name;
    }
    get productBaseIngredients() {
        return this.productType.baseIngredients;
    }
    get productTags() {
        return this.productType.tags;
    }
    get productAllergens() {
        return this.productType.allergens;
    }
    get productPhoto() {
        return this.productType.photo;
    }
    get productDescription() {
        return this.productType.description;
    }
    get productFreeModifierItemsCount() {
        return this.productType.freeModifierItemsCount;
    }
    get availability() {
        return this.domain.availability;
    }
    get productAvailabilityInfo() {
        // TODO/FIXME
        // Consider using selected fulfillmentTime from scope instead of now for the calculations
        const { now } = this.domain.scope;
        const fulfillmentTimes = this.orderingHours.fulfillmentTimes({
            now,
            date: now,
        });
        return ProductAvailabilityInfo.create({
            productType: this.productType,
            // FIXME Temporary fix for logic - should include now only when ASAP is available, but this logic is too complex for hotfix
            fulfillmentTimes: RArray.singleton(now).concatenated(fulfillmentTimes),
            now,
        });
    }
    get unavailabilityDetails() {
        return new PProductUnavailabilityDetails({
            availability: this.availability,
            restaurant: this.restaurant,
        });
    }
    get isSubmitVisible() {
        const { error } = this.unavailabilityDetails;
        if (error === null) {
            return true;
        }
        switch (error.type) {
            case "VisibilityUnavailability":
            case "TemporarilyUnavailable":
                return false;
            case "PreorderUnavailability":
            case "ScheduleUnavailability":
            case "ParametersUnavailability":
            case "UnknownUnavailability":
                return true;
        }
    }
    get error() {
        const { parametersDetails, error } = this.unavailabilityDetails;
        if (error !== null && error.type === "ParametersUnavailability") {
            return PProductError.unavailable(parametersDetails);
        }
        return PProductError.none();
    }
    touch() {
        this.partConfigCreator.touch();
    }
    get temporarilyUnavailableIngredients() {
        return this.partConfigCreator.temporarilyUnavailableIngredients;
    }
}
