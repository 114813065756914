import { UnknownElementError } from "../../collections";
import { makeImmutable } from "../../core/makeImmutable";
export class MenuArrangment {
    constructor(params) {
        this.id = params.id;
        this.sections = params.sections;
        makeImmutable(this);
    }
    getProductTypeMenuSection(productTypeId) {
        for (const [_, menuSection] of this.sections) {
            if (menuSection.variants.keys.includes(productTypeId)) {
                return menuSection;
            }
        }
        throw new UnknownElementError();
    }
}
