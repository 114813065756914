import StorageService from "../services/StorageService";
import Device from "./Device";
import SmartBanner from "smart-app-banner";
/**
 * Use Google Analytics Events.
 *
 * @param bannerButton
 */
function useGoogleAnalyticsEvents(bannerButton) {
    bannerButton.setAttribute("data-ga-action", "Smart App Banner clicks");
    bannerButton.setAttribute("data-ga-label", Skubacz.configuration.view_name);
}
/**
 * Iframe support
 *
 * @param bannerElement
 * @param bannerButton
 */
function addIframeSupport(bannerElement, bannerButton) {
    // Open link in parent element.
    bannerButton.setAttribute("target", "_parent");
    // Adjust styles for AM dynamic iframe.
    document.addEventListener("dynamic-iframe.skubacz.active-menu", (e) => {
        const contentWrapperEl = document.querySelector(e.detail.contentWrapper);
        contentWrapperEl.insertBefore(bannerElement, contentWrapperEl.firstChild);
        bannerElement.style.cssText = "position: relative; top: 0;";
        e.detail.activeMenu.adjustSize();
    });
}
/**
 * Fix some edge cases with different themes.
 *
 * @param bannerElement
 * @param bannerButton
 */
function fixThemes(bannerElement, bannerButton) {
    addIframeSupport(bannerElement, bannerButton);
}
export default {
    /**
     * Init Smart App Banner.
     *
     * @param bannerSelector
     */
    init: function (bannerSelector = ".smartbanner") {
        const hasUserOrdered = !!StorageService.getItem("previousOrder") ||
            !!StorageService.existKey((name) => name.includes("client_previousOrder"));
        if (!hasUserOrdered ||
            !Skubacz.configuration.smartAppBanner ||
            Device.isMobileApp()) {
            return;
        }
        const smartAppBanner = new SmartBanner(Skubacz.configuration.smartAppBanner);
        if (!smartAppBanner.hasOwnProperty("appId")) {
            return;
        }
        const bannerElement = document.querySelector(bannerSelector);
        if (!bannerElement)
            return;
        const bannerButton = bannerElement.querySelector(".smartbanner-button");
        if (!bannerButton)
            return;
        useGoogleAnalyticsEvents(bannerButton);
        fixThemes(bannerElement, bannerButton);
    },
};
