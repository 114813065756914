export const MoneySpectrum = {
    union(a, b) {
        switch (a.type) {
            case "null":
                return b;
            case "range":
                switch (b.type) {
                    case "null":
                        return a;
                    case "value":
                        return this.union(b, a);
                    case "range":
                        return {
                            type: "range",
                            min: a.min.min(b.min),
                            max: a.max.max(b.max),
                        };
                }
                break;
            case "value":
                switch (b.type) {
                    case "null":
                        return a;
                    case "value":
                        return a.value.eq(b.value)
                            ? a
                            : {
                                type: "range",
                                min: a.value.min(b.value),
                                max: a.value.max(b.value),
                            };
                    case "range":
                        return {
                            type: "range",
                            min: a.value.min(b.min),
                            max: a.value.max(b.max),
                        };
                }
        }
    },
};
