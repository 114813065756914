import { associationKey } from "../../core";
import { makeImmutable } from "../../core/makeImmutable";
import { ParameterSet } from "../ParameterSet";
/**
 * A mapping between a single ParameterSet and a concrete value of a parametric entity.
 *
 * For example: "size: 30cm, dough: thick -> price: 31zł"
 */
export class ParameterCombination {
    constructor(params) {
        this.premise = params.premise;
        this.value = params.value;
        makeImmutable(this);
    }
    get customParameterTypes() {
        return this.premise.customParameterTypes;
    }
    get isFullyMatched() {
        return this.premise.isEmpty;
    }
    static empty(value) {
        return new ParameterCombination({
            premise: ParameterSet.empty(),
            value,
        });
    }
    map(mapping) {
        return new ParameterCombination({
            premise: this.premise,
            value: mapping(this.value),
        });
    }
    unify(parameters) {
        const result = this.premise.unify(parameters);
        switch (result.type) {
            case "success":
                return {
                    ...result,
                    value: new ParameterCombination({
                        premise: result.value,
                        value: this.value,
                    }),
                };
            case "failure":
                return result;
        }
    }
    /**
     * Combine two ParameterCombinations and apply the provided function to its values
     */
    combine(other, combinationFn) {
        const premise = this.premise.union(other.premise);
        return premise === null
            ? null
            : new ParameterCombination({
                premise,
                value: combinationFn(this.value, other.value),
            });
    }
    eq(other) {
        if (associationKey(this.value) !== associationKey(other.value)) {
            return false;
        }
        const r = this.premise.unify(other.premise);
        if (r.type === "success" && r.value.isEmpty) {
            return true;
        }
        return false;
    }
}
