import { makeAutoObservable } from "mobx";
import { RArray } from "../../../collections";
import { FulfillmentMethodController } from "../FulfillmentMethodController";
import { UserNoteController } from "../UserNoteController";
export class OnlineFulfillmentFormController {
    constructor(params) {
        this.type = "Online";
        this.isFulfillmentEditable = true;
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.fulfillmentMethodController = new FulfillmentMethodController({
            fulfillmentInstructions: params.fulfillmentInstructions,
            fulfillmentMethodTypes: params.fulfillmentMethodTypes,
            deliveryAddress: params.deliveryAddress,
            viewType: params.viewType,
        });
        this.userNoteController = new UserNoteController({
            fulfillmentInstructions: params.fulfillmentInstructions,
        });
        makeAutoObservable(this);
    }
    get fields() {
        return {
            ...this.fulfillmentMethodController.fields,
            ...this.userNoteController.fields,
        };
    }
    get controllersArray() {
        return new RArray([
            this.fulfillmentMethodController,
            this.userNoteController,
        ]);
    }
    get isDirty() {
        return this.controllersArray.some((controller) => controller.isDirty);
    }
    get isTouched() {
        return this.controllersArray.some((controller) => controller.isTouched);
    }
    get isInvalid() {
        return this.controllersArray.some((controller) => controller.isInvalid);
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.fulfillmentInstructions;
    }
    submit() {
        this.controllersArray.forEach((controller) => controller.submit());
    }
}
