import { makeImmutable } from "../core";
import { ProductCategory, ProductCategoryId, } from "../ordering";
import { IdMap } from "../collections";
import { VolumeParser } from "./VolumeParser";
export class ProductCategoryParser {
    constructor(params) {
        this.halvingPolicyParser = params.halvingPolicyParser;
        this.modifierTypes = params.modifierTypes;
        makeImmutable(this);
    }
    parse(id, category) {
        var _a;
        const categoryVolume = (_a = category.packagingContainersConfig) === null || _a === void 0 ? void 0 : _a.volume;
        return new ProductCategory({
            id: new ProductCategoryId(id),
            divisiblePricePolicy: category.divisiblePricePolicy,
            halvingPolicy: this.halvingPolicyParser.parse(category),
            modifierTypes: IdMap.fromIterable(category.categoryModifiers.map((modifierTypeId) => this.modifierTypes.rawGet(modifierTypeId))),
            volume: categoryVolume ? VolumeParser.parse(categoryVolume) : null,
        });
    }
}
