import { makeAutoObservable, runInAction } from "mobx";
import { ErrorBox } from "./ErrorBox";
export class CheckboxFieldController {
    constructor(params) {
        var _a, _b;
        this._value = (_a = params.initialValue) !== null && _a !== void 0 ? _a : false;
        this._isDirty = false;
        this._isTouched = false;
        this.onUpdate = params.onUpdate;
        this.errorBox = (_b = params.errorBox) !== null && _b !== void 0 ? _b : new ErrorBox({ errorMessages: {} });
        makeAutoObservable(this);
    }
    get value() {
        return this._value;
    }
    get isDirty() {
        return this._isDirty;
    }
    get isTouched() {
        return this._isTouched;
    }
    get isValid() {
        return this.errorBox.isEmpty;
    }
    get isInvalid() {
        return !this.errorBox.isEmpty;
    }
    get errors() {
        return this.errorBox.errors;
    }
    blur() {
        this._isTouched = true;
    }
    clear() {
        this._value = false;
        this._isDirty = false;
        this._isTouched = false;
        runInAction(() => this.onUpdate(false));
    }
    change(value) {
        if (this._value === value) {
            return;
        }
        this._value = value;
        this._isDirty = true;
        runInAction(() => this.onUpdate(value));
    }
    /**
     * NOTICE: This sets field value without triggering an update event (setting value in the model).
     * It is used to mark checkbox value to represent the model value, eg. to set marketing
     */
    changeWithoutUpdate(value) {
        if (this._value === value) {
            return;
        }
        this._value = value;
        this._isDirty = true;
    }
}
