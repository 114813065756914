import { observer } from "mobx-react";
import { h } from "preact";
function CreatorCustomParameterChoice(props) {
    const { name, value, label, isChecked, isDisabled, onChange } = props;
    const handleChange = (event) => {
        const checked = event.target.checked;
        if (checked) {
            onChange(value);
        }
    };
    return (h("div", { class: "radio-inline" },
        h("label", { class: `m-radio ${isChecked ? "is-selected" : ""} ${isDisabled ? "is-disabled" : ""}`, "data-option-id": value },
            h("input", { type: "radio", class: "m-radio__input", name: name, value: value, disabled: isDisabled, checked: isChecked, onChange: handleChange }),
            h("span", { class: "m-radio__icon-state", "aria-hidden": "true" }),
            label)));
}
export default observer(CreatorCustomParameterChoice);
