import { makeAutoObservable } from "mobx";
import { RArray, RMutableMultiSet, RSet } from "../../collections";
import { CrossSellingItemInstanceFactory, CrossSellingItemInstanceProductCardBehaviour, } from "../CrossSellingItemInstance";
import { ParameterScope } from "../ParameterScope";
import { MAX_CROSS_SELLING_ITEM_COUNT, } from "./ICrossSellingCreator";
export class ProductCardCrossSellingCreator {
    constructor(params) {
        this.parentScope = ParameterScope.Uninitialized;
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        this.crossSellingItemInstanceFactory =
            params.crossSellingItemInstanceFactory;
        this._items = RArray.empty();
        this.inputProductTypeIds = RSet.empty();
        makeAutoObservable(this);
    }
    static create(params) {
        const crossSellingItemQuantities = RMutableMultiSet.empty();
        const crossSellingItemInstanceFactory = new CrossSellingItemInstanceFactory({
            menu: params.menu,
            listProvider: params.listProvider,
            createBehaviour: (crossSellingItemTypeId) => new CrossSellingItemInstanceProductCardBehaviour({
                typeId: crossSellingItemTypeId,
                crossSellingItemQuantities,
            }),
        });
        return new ProductCardCrossSellingCreator({
            crossSellingItemInstanceFactory,
            purchaseOrderPayload: params.purchaseOrderPayload,
        });
    }
    initWithScope(parentScope) {
        this.parentScope = parentScope;
        this._items.forEach((crossSellingItemInstance) => crossSellingItemInstance.setScope(this.parentScope));
    }
    setInputProductTypeIds(inputProductTypeIds) {
        this.inputProductTypeIds = inputProductTypeIds;
        this._items = this.crossSellingItemInstanceFactory.create({
            inputProductTypeIds,
            sequenceNumber: Math.random(),
            parentScope: this.parentScope,
        });
    }
    get excludedProductTypeIds() {
        return this.purchaseOrderPayload.productTypeIds.union(this.inputProductTypeIds);
    }
    get items() {
        return this._items
            .filtered((crossSellingItemInstance) => crossSellingItemInstance.shouldAppearInCrossSellingList(this.excludedProductTypeIds))
            .take(MAX_CROSS_SELLING_ITEM_COUNT);
    }
    get selectedItems() {
        return this.items.mapOptional(({ itemCreator, quantity, kind }) => {
            if (quantity.isZero) {
                return null;
            }
            return [itemCreator, quantity, kind];
        });
    }
    shuffle() {
        // NOTICE Intentionally empty
    }
}
