import { makeAutoObservable } from "mobx";
import { MAX_CROSS_SELLING_ITEM_COUNT, } from "./ICrossSellingCreator";
import { CrossSellingItemInstanceCartBehaviour, CrossSellingItemInstanceFactory, } from "../CrossSellingItemInstance";
export class CartCrossSellingCreator {
    constructor(params) {
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        this.crossSellingItemInstanceFactory =
            params.crossSellingItemInstanceFactory;
        this.purchaseOrderScope = params.purchaseOrderScope;
        this.sequenceNumber = params.sequenceNumber;
        makeAutoObservable(this);
    }
    static create(params) {
        const crossSellingItemInstanceFactory = new CrossSellingItemInstanceFactory({
            menu: params.menu,
            listProvider: params.listProvider,
            createBehaviour: (_, productInstanceCreator) => new CrossSellingItemInstanceCartBehaviour({
                purchaseOrderPayload: params.purchaseOrderPayload,
                productInstanceCreator,
            }),
        });
        return new CartCrossSellingCreator({
            purchaseOrderPayload: params.purchaseOrderPayload,
            crossSellingItemInstanceFactory,
            purchaseOrderScope: params.purchaseOrderScope,
            sequenceNumber: Math.random(),
        });
    }
    get excludedProductTypeIds() {
        return this.purchaseOrderPayload.productTypeIds;
    }
    get items() {
        return this.crossSellingItemInstanceFactory
            .create({
            inputProductTypeIds: this.purchaseOrderPayload.productTypeIds,
            sequenceNumber: this.sequenceNumber,
            parentScope: this.purchaseOrderScope,
        })
            .filtered((crossSellingItemInstance) => crossSellingItemInstance.shouldAppearInCrossSellingList(this.excludedProductTypeIds))
            .take(MAX_CROSS_SELLING_ITEM_COUNT);
    }
    shuffle() {
        this.sequenceNumber = Math.random();
    }
}
