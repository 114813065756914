import { makeAutoObservable } from "mobx";
import { formatAddress, } from "..";
export class POnlineOrderDetails {
    constructor(params) {
        this.type = "Online";
        this.isFulfillmentEditable = true;
        this.restaurant = params.restaurant;
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.promotionScheme = params.promotionScheme;
        makeAutoObservable(this);
    }
    initialize() {
        this.promotionScheme.initialize();
    }
    dispose() {
        this.promotionScheme.dispose();
    }
    get promotionInstances() {
        return this.promotionScheme.promotionInstances;
    }
    clearCoupon() {
        this.promotionScheme.clearCoupon();
    }
    get couponState() {
        return this.promotionScheme.couponState;
    }
    get isDelivery() {
        return this.fulfillmentInstructions.isDelivery;
    }
    get isPostCodeRequired() {
        return this.fulfillmentInstructions.deliveryAddress.streetAddress
            .isPostCodeRequired;
    }
    get deliveryAddressStatus() {
        return this.fulfillmentInstructions.deliveryAddress.status;
    }
    get isApartmentInfoEmpty() {
        return this.fulfillmentInstructions.deliveryAddress.apartmentInfo.isEmpty;
    }
    get fulfillmentMethodType() {
        return this.fulfillmentInstructions.fulfillmentMethodType;
    }
    get formattedAddress() {
        var _a;
        switch (this.fulfillmentInstructions.fulfillmentMethodType) {
            case "Delivery":
            case "NoContactDelivery": {
                const { streetAddress } = this.fulfillmentInstructions.deliveryAddress;
                return formatAddress(streetAddress, streetAddress.isPostCodeRequired);
            }
            case "DineIn":
            case "Takeaway": {
                const { address } = this.restaurant;
                return formatAddress({
                    street: (_a = address.street) !== null && _a !== void 0 ? _a : "",
                    streetNumber: "",
                    city: address.city,
                    postCode: address.postCode,
                }, true // As default show restaurant's post code
                );
            }
            // TODO: remove this when fulfillmentMethodType will not be nullable
            default:
                return { firstLine: "", secondLine: "" };
        }
    }
    get doesAnyPromotionRequireMarketingConsents() {
        return this.promotionScheme.doesAnyPromotionRequireMarketingConsents;
    }
    disableMarketingPromotionInstances() {
        this.promotionScheme.disableMarketingPromotionInstances();
    }
}
