// TODO: Refactor functionality of CustomerSurvey.ts & TYPSurvey.tsx to avoid code repetition
import StorageService from "../services/StorageService";
import { setCookie, getCookie } from "../services/CookieService";
import Registry from "../utils/Registry";
import CurrentRestaurant from "../utils/CurrentRestaurant";
const formWrapperSelector = ".js-customer-survey";
const formSelector = ".js-customer-survey-form";
const surveyVisibilityCookieName = `survey_hide_${CurrentRestaurant.getCurrentRestaurantSlug()}`;
// See names for all fields in `themes/base/partials/_customer_survey.liquid`
const rating1Name = "entry.183976575";
const rating2Name = "entry.294436083";
const msgName = "entry.1340586078";
const subjectName = "entry.2144080433";
function onSubmit(orderId) {
    var _a;
    (_a = document.querySelector(formSelector)) === null || _a === void 0 ? void 0 : _a.addEventListener("submit", function () {
        var _a, _b;
        (_a = document.getElementById("customer-survey-rating")) === null || _a === void 0 ? void 0 : _a.classList.add("hidden");
        (_b = document
            .getElementById("customer-survey-thanks")) === null || _b === void 0 ? void 0 : _b.classList.remove("hidden");
        StorageService.setItem("customerSurvey", orderId);
        setVisibilityCookie();
    });
}
function setHiddenValues(order) {
    var _a, _b;
    (_a = document
        .querySelector(".js-order-method-input")) === null || _a === void 0 ? void 0 : _a.setAttribute("value", order.origin == "Bar" && order.onlinePayment ? "qrwaiter" : "");
    (_b = document.querySelector(".js-order-id-input")) === null || _b === void 0 ? void 0 : _b.setAttribute("value", order.id);
}
function toggleVisibilityFields(visibilityCondition, sectionSelector, targetFieldSelector, onChangeSelector = formSelector) {
    document.querySelectorAll(onChangeSelector).forEach((el) => {
        el.addEventListener("change", (e) => {
            const section = document.querySelector(sectionSelector);
            const targetField = document.querySelector(targetFieldSelector);
            if (visibilityCondition()) {
                section.classList.remove("hidden");
                targetField.required = true;
            }
            else {
                section.classList.add("hidden");
                targetField.required = false;
            }
        });
    });
}
function getValue(el) {
    if (el.getAttribute("type") === "radio") {
        const checked = document.querySelector(`[name='${el.getAttribute("name")}']:checked`);
        return checked ? checked.value : "";
    }
    else {
        return el.value;
    }
}
function setupConditionalFields() {
    // Show subject field only when ratings are done.
    const subjectCondition = () => {
        const rating1Checked = document.querySelector(`[name='${rating1Name}']:checked`);
        const rating2Checked = document.querySelector(`[name='${rating2Name}']:checked`);
        return ((rating1Checked && parseInt(rating1Checked.value) <= 2) ||
            (rating2Checked && parseInt(rating2Checked.value) <= 2));
    };
    // Show textarea field only when all required fields are done.
    const messageCondition = () => {
        var _a;
        let requiredDone = true;
        (_a = document
            .querySelector(formSelector)) === null || _a === void 0 ? void 0 : _a.querySelectorAll("input[required], select[required]").forEach((el) => {
            if (!getValue(el)) {
                requiredDone = false;
            }
        });
        return requiredDone;
    };
    toggleVisibilityFields(subjectCondition, "#customer-survey-subject-section", `[name='${subjectName}']`, ".js-customer-survey-rating");
    toggleVisibilityFields(messageCondition, "#customer-survey-message-section", `[name='${msgName}']`);
}
export function setVisibilityCookie() {
    setCookie(surveyVisibilityCookieName, "true", 30);
}
export function isSurveyHideByCookie() {
    return Boolean(getCookie(surveyVisibilityCookieName));
}
export default {
    init: function () {
        const formWrapper = document.querySelector(formWrapperSelector);
        if (!formWrapper || isSurveyHideByCookie())
            return;
        // orderId published by orderInfoWidget
        Registry.get("order").then((order) => {
            if (StorageService.getItem("customerSurvey") === String(order.id)) {
                return;
            }
            setupConditionalFields();
            setHiddenValues(order);
            formWrapper.classList.remove("hidden");
            onSubmit(order.id);
        });
    },
};
