export async function retryPromise(fn, maxAttempts, delayMs) {
    let attempts = 0;
    async function attempt() {
        try {
            return await fn();
        }
        catch (error) {
            attempts++;
            if (attempts < maxAttempts) {
                console.warn(`Attempt ${attempts} failed. Retrying in ${delayMs}ms...`);
                await new Promise((resolve) => setTimeout(resolve, delayMs));
                return attempt();
            }
            else {
                throw error; // All attempts failed, propagate the error
            }
        }
    }
    return attempt();
}
