const REQUIRED_NUMBER = 5;
export function fromResponseLoyalty(state, userLoyalty) {
    if (state.tag !== "Completed" || userLoyalty === null) {
        return { type: "Hidden", showModal: false };
    }
    // When user collects all stamps required to get the coupon
    // system returns `userLoyalty.count: 0` and this is ok,
    // but we would like to show that the user has collected all stamps
    const hasCoupon = userLoyalty.couponData.length > 0;
    const ownedNumber = hasCoupon && userLoyalty.count === 0 ? REQUIRED_NUMBER : userLoyalty.count;
    const promotionName = userLoyalty.promotionName;
    const stampsRange = [...Array(REQUIRED_NUMBER).keys()];
    const stamps = stampsRange.map((index) => ({
        index,
        isEmpty: index >= ownedNumber,
    }));
    if (hasCoupon) {
        return { type: "Success", stamps, promotionName, showModal: true };
    }
    return {
        type: "Pending",
        stamps,
        promotionName,
        missingNumber: REQUIRED_NUMBER - ownedNumber,
        showModal: true,
    };
}
