import { ModifierSettings, Quantity, } from "../ordering";
import { RArray, RMap } from "../collections";
export class ModifierSettingsParser {
    parse(modifierItemTypeIds, modifierTypeEditability, settings) {
        return new ModifierSettings({
            editable: modifierTypeEditability,
            freeItems: Quantity.fromNumber(settings.freeItems),
            defaultItems: settings.defaultItems
                ? RMap.fromIterable(new RArray(settings.defaultItems).mapOptional(([itemId, quantity]) => {
                    const itemTypeId = modifierItemTypeIds.rawFind(itemId);
                    if (itemTypeId === null) {
                        return null;
                    }
                    return [itemTypeId, Quantity.fromNumber(quantity)];
                }))
                : null,
            displayDefaultItems: settings.displayDefaultItems,
        });
    }
}
