// Skeleton placeholder is styled in `client/css/modules/_m-skeleton.scss`
const SKELETON_SELECTOR = ".js-skeleton";
function initSkeleton() {
    const skeletons = getSkeletons();
    const observer = new MutationObserver(() => {
        skeletons.forEach(tryToRemoveSkeleton);
        if (skeletons.every((skeleton) => skeleton.isRemoved)) {
            observer.disconnect();
        }
    });
    skeletons
        .map((skeleton) => skeleton.area)
        .forEach((area) => {
        if (area) {
            observer.observe(area, {
                attributes: false,
                childList: true,
                subtree: true,
            });
        }
    });
}
// If target element was rendered in DOM then remove skeleton placeholder associated with it
function tryToRemoveSkeleton(skeleton) {
    var _a;
    if ((_a = skeleton.area) === null || _a === void 0 ? void 0 : _a.querySelector(skeleton.targetSelector)) {
        skeleton.element.remove();
        skeleton.isRemoved = true;
    }
}
function getSkeletons() {
    const skeletons = Array.from(document.querySelectorAll(SKELETON_SELECTOR));
    return skeletons.map((skeleton) => {
        const targetSelector = getSkeletonData(skeleton, "target");
        const areaSelector = getSkeletonData(skeleton, "area");
        return {
            element: skeleton,
            targetSelector,
            area: document.querySelector(areaSelector),
            isRemoved: false,
        };
    });
}
function getSkeletonData(skeleton, param) {
    const data = skeleton.dataset[param];
    if (data === undefined) {
        throw new Error(`Skeleton placeholder must have data-${param}} attribute.`);
    }
    return data;
}
/**
 * Skeleton based on Mutation Observer.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 */
export default {
    init: initSkeleton,
};
