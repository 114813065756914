import Tooltip from "bootstrap/js/dist/tooltip";
/**
 * Default tooltip (Bootstrap 5 tooltip plugin).
 *
 * @link https://getbootstrap.com/docs/5.2/components/tooltips/
 */
export function initTooltip(selector = ".js-tooltip") {
    document.querySelectorAll(selector).forEach((el) => {
        if (!(el instanceof HTMLElement))
            return;
        initTooltipElement(el);
    });
}
/**
 * Initializing single tooltip by specific HTML element. Used e.g. in preact tooltip wrapper `js/components/PreactTooltip.tsx`
 */
export function initTooltipElement(element) {
    if (!(element instanceof HTMLElement))
        return;
    // 1. Tooltip inside modal can cause a "jumping" effect when scrolling on real devices because the `translate3d' value is constantly updated.
    //    Changing the container to a modal element solves this problem. After that `translate3d' doesn't need to be constantly updated while scrolling modal.
    const containingModal = element.closest(".modal");
    new Tooltip(element, {
        container: element.dataset.bsContainer ||
            (!containingModal ? "body" : containingModal), // [1]
    });
}
/**
 * Hide and destroy single element tooltip
 */
export function disposeTooltip(element) {
    if (!(element instanceof HTMLElement))
        return;
    const tooltip = Tooltip.getOrCreateInstance(element);
    tooltip.dispose();
}
