import { translate, t } from "../I18n";
export function createLabel() {
    return (labelParams) => {
        if (labelParams.view === "Cart") {
            return null;
        }
        const reasons = labelParams.reasons;
        const unavailable = reasons.includes("TemporarilyUnavailable") ||
            reasons.includes("Visibility") ||
            reasons.includes("OutdatedPreorderSettings");
        if (unavailable) {
            return {
                message: translate(t.client.labels.temporarily_unavailable.text),
                enabledOnClick: false,
                type: "error",
            };
        }
        return null;
    };
}
