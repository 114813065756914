/**
 * Simple throttle function.
 *
 * @param {function} callback
 * @param {number} wait
 * @returns
 */
export default function (callback, wait = 100) {
  let shouldWait = false;
  let waitingArgs;

  const timeoutFn = () => {
    if (waitingArgs == null) {
      shouldWait = false;
    } else {
      callback(...waitingArgs);
      waitingArgs = null;
      setTimeout(timeoutFn, wait);
    }
  };

  return (...args) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }

    callback(...args);
    shouldWait = true;
    setTimeout(timeoutFn, wait);
  };
}
