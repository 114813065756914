import { makeAutoObservable } from "mobx";
import { Money } from "../../ordering";
import { POrderInfoOrderLine } from "./POrderInfoOrderLine";
export class POrderInfo {
    constructor(params) {
        this.localizationProvider = params.localizationProvider;
        this.orderInfo = params.orderInfo;
        makeAutoObservable(this);
    }
    get orderLines() {
        const items = this.orderInfo.items.map((item) => new POrderInfoOrderLine({
            localizationProvider: this.localizationProvider,
            name: item.name,
            quantity: item.quantity,
            price: item.total,
        }));
        const discounts = this.orderInfo.discounts.map((item) => new POrderInfoOrderLine({
            localizationProvider: this.localizationProvider,
            name: item.name,
            quantity: null,
            price: -item.value,
        }));
        return [...items, ...discounts];
    }
    get deliveryCost() {
        return this.localizationProvider.formatPrice(Money.fromBackend(this.orderInfo.deliveryCost));
    }
    get tipValue() {
        return this.orderInfo.tip !== null
            ? this.localizationProvider.formatPrice(Money.fromBackend(this.orderInfo.tip.contents))
            : null;
    }
    get total() {
        return this.localizationProvider.formatPrice(Money.fromBackend(this.orderInfo.total));
    }
    get orderId() {
        return this.orderInfo.id;
    }
    get isOnlinePayment() {
        return this.orderInfo.onlinePayment;
    }
    get origin() {
        return this.orderInfo.origin;
    }
}
