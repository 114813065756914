import { UserNote } from "../UserNote";
import { OnlineFulfillmentInstructions } from "./OnlineFulfillmentInstructions";
import { QrWaiterFulfillmentInstructions } from "./QrWaiterFulfillmentInstructions";
export const DEFAULT_FULFILLMENT_TYPE = "Delivery";
export const FulfillmentInstructions = {
    create(params) {
        const fulfillmentMethodType = params.fulfillmentMethodType !== null
            ? this.getAvailableFulfillmentMethod(params.fulfillmentMethodType, params.restaurant.fulfillmentMethods)
            : null;
        if (params.dineInLocation === null) {
            return new OnlineFulfillmentInstructions({
                fulfillmentMethodType,
                fulfillmentTime: params.fulfillmentTime,
                deliveryAddress: params.deliveryAddress,
                userNote: UserNote.empty(),
            });
        }
        else {
            return new QrWaiterFulfillmentInstructions({
                userNote: UserNote.empty(),
                dineInLocation: params.dineInLocation,
            });
        }
    },
    getAvailableFulfillmentMethod(fulfillmentMethodType, fulfillmentMethods) {
        return fulfillmentMethods.includes(fulfillmentMethodType)
            ? fulfillmentMethodType
            : fulfillmentMethods.get(0);
    },
};
