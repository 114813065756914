/**
 * Service that returns order summary data (after order is placed and saved to the DB)
 */
export class OrderSummaryService {
    constructor(params) {
        this.rpcService = params.rpcService;
    }
    /**
     * @returns summary of an order that has already been placed
     */
    async getOrderSummary(orderUuid) {
        return await this.rpcService.rpc("GetOrderSummaryRequest", {
            orderId: orderUuid,
        });
    }
}
