import { makeAutoObservable } from "mobx";
export class CrossSellingItemInstance {
    constructor(params) {
        this.typeId = params.typeId;
        this.itemCreator = params.itemCreator;
        this.behaviour = params.behaviour;
        this.kind = params.kind;
        makeAutoObservable(this);
    }
    setScope(parentScope) {
        this.itemCreator.initWithScope(parentScope);
    }
    get productInstance() {
        return this.itemCreator.instance;
    }
    get quantity() {
        return this.behaviour.quantity;
    }
    shouldAppearInCrossSellingList(excludedProductTypeIds) {
        const { availability, productTypeIds } = this.productInstance;
        return (!productTypeIds.isSubsetOf(excludedProductTypeIds) &&
            availability.isAvailable.isPossible);
    }
}
