import { DateRange, DaySecond, RDate, TimeRange } from "../../core";
import { DaySchedule } from "../DaySchedule";
export const Holiday = {
    fromHolidayInfo(timezone, holidayInfo) {
        const startDate = RDate.fromString(holidayInfo.startDate, timezone).startOfDay();
        const endDate = RDate.fromString(holidayInfo.endDate, timezone).endOfDay();
        if (holidayInfo.from > holidayInfo.to) {
            throw new Error(`Invalid holidays from ${holidayInfo.startDate} to ${holidayInfo.endDate}: ${holidayInfo.from} > ${holidayInfo.to}`);
        }
        const from = DaySecond.fromNumber(holidayInfo.from);
        const { fullDays, remainder } = DaySecond.multiDay(holidayInfo.to);
        let to;
        let append;
        switch (fullDays) {
            case 0:
                to = remainder;
                append = null;
                break;
            case 1:
                to = DaySecond.Last;
                append = {
                    dates: DateRange.fromDates({
                        begin: startDate.add(1, "day"),
                        end: endDate.add(1, "day"),
                    }),
                    range: TimeRange.fromRange({ from: DaySecond.First, to: remainder }),
                };
                break;
            default:
                throw new Error(`Invalid holidays from ${holidayInfo.startDate} to ${holidayInfo.endDate}: ${holidayInfo.to} > 2 full days`);
        }
        return {
            override: {
                dates: DateRange.fromDates({ begin: startDate, end: endDate }),
                schedule: DaySchedule.fromRanges([TimeRange.fromRange({ from, to })]),
            },
            append,
        };
    },
};
