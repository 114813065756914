export function unsafeMakeTranslationKeysProxy(prefix) {
    const proxyHandler = {
        get: function (obj, prop) {
            if (prop in obj) {
                return obj[prop];
            }
            return unsafeMakeTranslationKeysProxy((prefix ? prefix + "." : "") + prop);
        },
    };
    return new Proxy({
        toString() {
            return prefix;
        },
    }, proxyHandler);
}
export function unTranslationKey(key) {
    return key.toString();
}
