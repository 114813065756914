import { makeAutoObservable } from "mobx";
import { Availability } from "../Availability";
import { Money } from "../Money";
export class Delivery {
    constructor(params) {
        this.defaultMinOrderValue = params.defaultMinOrderValue;
        this.deliveryAddress = params.deliveryAddress;
        makeAutoObservable(this);
    }
    get lastDeliveryPolicy() {
        return this.deliveryAddress.deliveryPolicy;
    }
    get cost() {
        if (this.lastDeliveryPolicy === null) {
            return Money.zero();
        }
        return this.lastDeliveryPolicy.deliveryCost;
    }
    get minOrderValue() {
        var _a, _b;
        return (_b = (_a = this.lastDeliveryPolicy) === null || _a === void 0 ? void 0 : _a.minOrderValue) !== null && _b !== void 0 ? _b : this.defaultMinOrderValue;
    }
    price(orderValidationPrice) {
        if (this.lastDeliveryPolicy === null) {
            return Money.zero();
        }
        const deliveryValidationResult = this.lastDeliveryPolicy.validate(orderValidationPrice);
        switch (deliveryValidationResult) {
            case "FreeDeliveryPossible":
            case "DeliveryImpossible":
                return Money.zero();
            case "PaidDeliveryPossible":
                return this.cost;
        }
    }
    availability(orderValidationPrice) {
        if (this.lastDeliveryPolicy === null) {
            return Availability.unavailable("LoadingDeliveryPolicy");
        }
        const deliveryValidationResult = this.lastDeliveryPolicy.validate(orderValidationPrice);
        switch (deliveryValidationResult) {
            case "DeliveryImpossible":
                return Availability.unavailable(deliveryValidationResult);
            case "FreeDeliveryPossible":
            case "PaidDeliveryPossible":
                return Availability.available();
        }
    }
}
