import { makeImmutable } from "../../core/makeImmutable";
import { Availability } from "../Availability";
/**
 * Information that the order should be fulfilled as soon as possible.
 */
export class ASAPFulfillmentTime {
    constructor() {
        this.type = "ASAP";
        makeImmutable(this);
    }
    static empty() {
        return new ASAPFulfillmentTime();
    }
    static fromStorageData(data) {
        if (data.version === 1) {
            return new ASAPFulfillmentTime();
        }
        return null;
    }
    get availability() {
        return Availability.available();
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        return null;
    }
    get storageData() {
        return { type: "ASAP", version: 1 };
    }
}
