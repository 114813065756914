import { makeImmutable } from "../../core";
import { ProductInstanceCreator } from "../ProductInstanceCreator";
import { CrossSellingItemInstance } from "./CrossSellingItemInstance";
export class CrossSellingItemInstanceFactory {
    constructor(params) {
        this.menu = params.menu;
        this.listProvider = params.listProvider;
        this.createBehaviour = params.createBehaviour;
        makeImmutable(this);
    }
    create(params) {
        const instances = this.listProvider
            .items(params.inputProductTypeIds, params.sequenceNumber)
            .map((crossSellingItemType) => {
            const itemCreator = ProductInstanceCreator.create({
                productCategories: this.menu.productCategories,
                productTypes: this.menu.productTypes,
                productInstance: crossSellingItemType.productInstance,
            });
            return new CrossSellingItemInstance({
                typeId: crossSellingItemType.id,
                behaviour: this.createBehaviour(crossSellingItemType.id, itemCreator),
                kind: crossSellingItemType.kind,
                itemCreator,
            });
        });
        instances.forEach((crossSellingItemInstance) => crossSellingItemInstance.setScope(params.parentScope));
        return instances;
    }
}
