export class PreorderHours {
    constructor(params) {
        this.preorderSettings = params.preorderSettings;
        this.scope = params.scope;
    }
    canOrderAt(data) {
        const dateRanges = this.preorderSettings.dateRanges(this.scope.now);
        return dateRanges.includes(data);
    }
}
