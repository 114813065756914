import { makeAutoObservable } from "mobx";
import { PPrice } from "./PPrice";
const FIND_URL_REGEXP = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
export class PPromotionInstance {
    constructor(params) {
        this.localizationProvider = params.localizationProvider;
        this.domain = params.domain;
        this.promotionType = params.promotionType;
        makeAutoObservable(this);
    }
    get id() {
        return this.domain.id.value.toString();
    }
    get name() {
        return this.promotionType.name;
    }
    get description() {
        return this.promotionType.description.replace(FIND_URL_REGEXP, "");
    }
    get descriptionUrl() {
        const foundedUrls = this.promotionType.description.match(FIND_URL_REGEXP);
        return foundedUrls ? foundedUrls[0] : null;
    }
    get isActive() {
        return this.domain.active;
    }
    get freebie() {
        var _a;
        return (_a = this.domain.freebie) === null || _a === void 0 ? void 0 : _a.name;
    }
    get availability() {
        return this.domain.availability;
    }
    setActive(active) {
        this.domain.setActive(active);
    }
    get value() {
        return new PPrice(this.localizationProvider, this.domain.value.spectrum)
            .priceValue;
    }
    get effect() {
        return this.promotionType.effect;
    }
    setFreebie(value) {
        const { effect } = this.domain.promotionType;
        if (effect.type !== "Freebie") {
            throw new Error("Cannot set freebie on non-freebie promotion");
        }
        const freebie = effect.freebies.findWhere((freebie) => freebie.name === value);
        this.domain.setFreebie(freebie);
    }
    get isCoupon() {
        return this.promotionType.isCoupon;
    }
}
