import { observer } from "mobx-react";
import { Fragment, h } from "preact";
import { t, translate } from "restaumatic-client";
function CreatorChips(props) {
    const { tags, freeModifierItemsCount } = props;
    const createTagElement = (text, className) => (h("span", { class: `label ${className} label-dish u-mr1` }, text));
    const renderTag = (tag) => {
        switch (tag) {
            case "New":
                return createTagElement(translate(t.theme_defaults.dish_label.new), "label-success");
            case "Recommended":
                return createTagElement(translate(t.theme_defaults.dish_label.recommended), "label-info");
            case "SpecialOffer":
                return createTagElement(translate(t.theme_defaults.dish_label.special), "label-discount");
            default:
                null;
        }
    };
    return (h(Fragment, null,
        tags.map((tag) => renderTag(tag)),
        freeModifierItemsCount > 0 && (h("span", { class: "label label-default label-dish u-mr1", title: translate(t.theme_defaults.dish_label.special) },
            "+",
            freeModifierItemsCount))));
}
export default observer(CreatorChips);
