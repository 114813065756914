import { POnlineOrderDetails } from "./POnlineOrderDetails";
import { PQrWaiterOrderDetails } from "./PQrWaiterOrderDetails";
export const POrderDetails = {
    create(params) {
        switch (params.fulfillmentInstructions.type) {
            case "Online": {
                return new POnlineOrderDetails({
                    restaurant: params.restaurant,
                    fulfillmentInstructions: params.fulfillmentInstructions,
                    promotionScheme: params.promotionScheme,
                });
            }
            case "QrWaiter":
                return new PQrWaiterOrderDetails({
                    promotionScheme: params.promotionScheme,
                });
        }
    },
};
