import { makeImmutable } from "../../core/makeImmutable";
/**
 * A promotion for a whole order.
 *
 * For example: "10% off your first order"
 */
export class PromotionType {
    constructor(params) {
        this.id = params.id;
        this.scope = params.scope;
        this.effect = params.effect;
        this.availabilityParametrization = params.availabilityParametrization;
        this.availabilitySchedule = params.availabilitySchedule;
        this.autoselect = params.autoselect;
        this.combinesWith = params.combinesWith;
        this.requiresMarketing = params.requiresMarketing;
        this.period = params.period;
        makeImmutable(this);
    }
    get defaultFreebie() {
        if (this.effect.type === "Freebie") {
            return this.effect.freebies.get(0);
        }
        return null;
    }
}
