import { makeImmutable } from "../../core/makeImmutable";
export class MinimumValueOrderSelector {
    constructor(minimumValue) {
        this.type = "MinimumValue";
        this.minimumValue = minimumValue;
        makeImmutable(this);
    }
    test(purchaseOrderPayload) {
        const value = purchaseOrderPayload.price.finalValue;
        if (value) {
            return value.geq(this.minimumValue);
        }
        return false;
    }
}
