import { HalvesCreatorState } from "./HalvesCreatorState";
import { SinglePartCreatorState } from "./SinglePartCreatorState";
export const ProductInstanceCreatorState = {
    isSplit(state) {
        return state.isSplit;
    },
    isSplitPossible(state) {
        return state.isSplitPossible;
    },
    fromProductInstanceParts({ productCategory, commonConfig, parts, }) {
        switch (parts.type) {
            case "SinglePart":
                return SinglePartCreatorState.create({
                    productCategory,
                    initialCommonConfig: commonConfig,
                    part: parts.part,
                });
            case "Halves":
                return HalvesCreatorState.create({
                    productCategory,
                    initialCommonConfig: commonConfig,
                    firstHalf: parts.first,
                    secondHalf: parts.second,
                });
        }
    },
};
