import { makeAutoObservable } from "mobx";
import { IdMap } from "../collections";
import { PPromotionInstance } from "./PPromotionInstance";
export class PPromotionScheme {
    constructor(params) {
        this.solverReactionDisposer = null;
        this.showInactivePromotionInstances = params.showInactivePromotionInstances;
        this.domain = params.domain;
        this.localizationProvider = params.localizationProvider;
        this.restaurant = params.restaurant;
        this.coupon = params.coupon;
        makeAutoObservable(this);
    }
    initialize() {
        if (this.solverReactionDisposer !== null) {
            throw new Error("Double solver reaction initialization");
        }
        this.solverReactionDisposer = this.domain.useSolver();
    }
    dispose() {
        if (this.solverReactionDisposer === null) {
            throw new Error("Invalid solver disposal");
        }
        this.solverReactionDisposer();
        this.solverReactionDisposer = null;
    }
    get couponState() {
        return this.coupon.state;
    }
    clearCoupon() {
        this.coupon.clear();
    }
    get doesAnyPromotionRequireMarketingConsents() {
        return this.domain.doesAnyActivePromotionRequireMarketingConsents;
    }
    get promotionTypes() {
        if (this.coupon.type === "Enabled" &&
            this.coupon.couponPromotion !== null) {
            const couponPromotion = IdMap.fromIterable([this.coupon.couponPromotion]);
            return this.restaurant.promotionTypes.union(couponPromotion);
        }
        return this.restaurant.promotionTypes;
    }
    disableMarketingPromotionInstances() {
        this.domain.disableMarketingPromotionInstances();
    }
    get promotionInstances() {
        const promotionInstances = this.showInactivePromotionInstances
            ? this.domain.possiblePromotionInstances
            : this.domain.activePossiblePromotionInstances;
        return promotionInstances.objects.map((promotionInstance) => new PPromotionInstance({
            localizationProvider: this.localizationProvider,
            domain: promotionInstance,
            promotionType: this.promotionTypes.get(promotionInstance.promotionType.id),
        })).raw;
    }
}
