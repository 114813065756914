import { RArray } from "../../collections";
import { makeImmutable } from "../../core";
export class ProductPromotionAssociation {
    constructor(params) {
        this.kind = "Product";
        this.promotionType = params.promotionType;
        this.matchingProducts = params.matchingProducts;
        makeImmutable(this);
    }
    get variableCount() {
        return this.matchingProducts.size;
    }
    get variables() {
        return this.matchingProducts.map((orderLineReference) => [this.promotionType, orderLineReference]);
    }
    eq(other) {
        return (other.kind === "Product" &&
            this.promotionType.id.eq(other.promotionType.id) &&
            RArray.eq(this.matchingProducts, other.matchingProducts));
    }
}
export class WholeOrderPromotionAssociation {
    constructor(params) {
        this.kind = "WholeOrder";
        this.variableCount = 1;
        this.promotionType = params.promotionType;
        makeImmutable(this);
    }
    get variables() {
        return RArray.singleton([this.promotionType, null]);
    }
    eq(other) {
        return (other.kind === "WholeOrder" &&
            this.promotionType.id.eq(other.promotionType.id));
    }
}
