import { makeImmutable } from "../core/makeImmutable";
export class PCustomParameterType {
    constructor(params) {
        this.domain = params.domain;
        this._name = params.name;
        this.choices = params.choices;
        makeImmutable(this);
    }
    get id() {
        return this.domain.id;
    }
    get name() {
        return this._name.value;
    }
}
