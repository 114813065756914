import { makeAutoObservable } from "mobx";
import { Quantity } from "../ordering/Quantity";
import { RMutableMap } from "./RMutableMap";
import { RMap } from "./RMap";
export class RMutableMultiSet {
    constructor(raw) {
        this.raw = RMutableMap.fromIterable(raw);
        makeAutoObservable(this);
    }
    static eq(a, b) {
        return (a.raw.size === b.raw.size &&
            a.raw.entries.every(([keyA, quantityA]) => {
                const quantityB = b.raw.find(keyA);
                if (quantityB === null) {
                    return false;
                }
                return quantityA.eq(quantityB);
            }));
    }
    includes(key) {
        return this.raw.includes(key);
    }
    delete(element) {
        if (this.quantity(element).value > 0) {
            this.raw.delete(element);
        }
    }
    clear() {
        this.raw.clear();
    }
    setQuantity(element, quantity) {
        if (quantity.isZero) {
            this.delete(element);
        }
        else {
            if (this.includes(element)) {
                this.raw.update(element, quantity);
            }
            else {
                this.raw.set(element, quantity);
            }
        }
    }
    increment(element) {
        const quantity = this.quantity(element);
        if (quantity.isZero) {
            this.raw.set(element, Quantity.One);
        }
        else {
            this.raw.update(element, quantity.incremented());
        }
    }
    decrement(element) {
        const quantity = this.quantity(element);
        if (quantity.isOne) {
            this.raw.delete(element);
        }
        else {
            this.raw.update(element, quantity.decremented());
        }
    }
    quantity(element) {
        var _a;
        return (_a = this.raw.find(element)) !== null && _a !== void 0 ? _a : Quantity.Zero;
    }
    get entries() {
        return this.raw.entries;
    }
    fold(arr) {
        const map = new Map();
        arr.forEach((el) => {
            var _a;
            const quanitity = (_a = map.get(el)) !== null && _a !== void 0 ? _a : Quantity.Zero;
            map.set(el, quanitity.incremented());
        });
        return RMutableMultiSet.fromIterable(map);
    }
    get sample() {
        for (const key of this.raw.keys) {
            return key;
        }
        return null;
    }
    clone() {
        return new RMutableMultiSet(RMap.fromIterable(this.raw));
    }
    [Symbol.iterator]() {
        return this.entries[Symbol.iterator]();
    }
    static empty() {
        return new RMutableMultiSet(RMap.empty());
    }
    static fromIterable(iterable) {
        const arr = [...iterable].filter(([, v]) => !v.isZero);
        return new RMutableMultiSet(RMap.fromIterable(arr));
    }
}
