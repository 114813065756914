import { Money } from "../../ordering";
import { localizePath } from "../../locale";
/**
 * Service for checking goegraphical information about the order, such as if the restaurant deliver to an address.
 */
export class GeocodingService {
    constructor(params) {
        this.rpcService = params.rpcService;
        this.siteId = params.siteId;
    }
    /**
     * @returns does the restaurant deliver to the provided address, how much will the delivery cost, how far away is the restaruant from the provided address
     */
    async getAddressInfo(restaurantId, address, source) {
        const response = await this.rpcService.rpc("GetAddressInfoRequest", {
            address,
            restaurantId: restaurantId.value,
            source: source,
        });
        if (response === null) {
            return null;
        }
        return {
            coordinates: response.coordinates,
            distance: response.distance,
            deliveryInfo: response.deliveryInfo !== null
                ? {
                    zoneName: response.deliveryInfo.zoneName,
                    averageTime: response.deliveryInfo.averageTime,
                    distance: response.deliveryInfo.distance,
                    deliveryCost: Money.fromBackend(response.deliveryInfo.deliveryCost),
                    minOrderValue: Money.fromBackend(response.deliveryInfo.minOrderValue),
                    freeDeliveryThreshold: response.deliveryInfo.freeDeliveryThreshold === null
                        ? null
                        : Money.fromBackend(response.deliveryInfo.freeDeliveryThreshold),
                }
                : null,
        };
    }
    async geocode(country, address, restaurantId) {
        var _a;
        const result = await this.rpcService.rpc("GeocodeRequest", {
            streetAddress: address,
            country,
            accountId: null,
            restaurantId: (_a = restaurantId === null || restaurantId === void 0 ? void 0 : restaurantId.value) !== null && _a !== void 0 ? _a : null,
            fromPS: true,
        });
        if (result === null) {
            return null;
        }
        return result.coordinates;
    }
    async getDeliveryAddressStatus(restaurantId, streetAddress) {
        var _a;
        const coordinates = await this.geocode(streetAddress.country, streetAddress.checkoutData, restaurantId);
        if (coordinates === null) {
            return { type: "AddressDoesNotExistOrInaccurate" };
        }
        else if ("error" in coordinates) {
            return { type: "NetworkError" };
        }
        const restaurants = (_a = (await this.rpcService.rpc("GetRestaurants", {
            siteId: this.siteId,
            location: coordinates,
        }))) !== null && _a !== void 0 ? _a : []; // TODO Handle failed request with different message maybe?
        if ("error" in restaurants) {
            return { type: "NetworkError" };
        }
        const deliveringRestaurants = restaurants.filter((r) => r.deliveryInfo !== null);
        if (deliveringRestaurants.length === 0) {
            return {
                type: "RestaurantDoesntDeliverTo",
                streetAddress,
                coordinates,
            };
        }
        if (restaurantId === null) {
            return {
                type: "ChooseRestaurant",
                restaurants,
            };
        }
        const currentRestaurant = deliveringRestaurants.find((r) => r.id === restaurantId.value);
        if (currentRestaurant === undefined) {
            const restaurant = deliveringRestaurants[0];
            return {
                type: "ChangeRestaurant",
                restaurantInfo: {
                    id: restaurant.id,
                    name: restaurant.name,
                    path: localizePath(`/restauracja/${restaurant.slug}`),
                },
                coordinates,
            };
        }
        const { deliveryCost, minOrderValue, freeDeliveryThreshold } = currentRestaurant.deliveryInfo;
        return {
            type: "RestaurantDelivers",
            deliveryCost: Money.fromBackend(deliveryCost),
            minOrderValue: Money.fromBackend(minOrderValue),
            freeDeliveryThreshold: freeDeliveryThreshold === null
                ? null
                : Money.fromBackend(freeDeliveryThreshold),
            coordinates,
        };
    }
    async reverseGeocode(restaurantId, coordinates) {
        var _a;
        const response = await this.rpcService.rpc("ReverseGeocodeRequest", {
            coordinates,
            restaurantId: (_a = restaurantId === null || restaurantId === void 0 ? void 0 : restaurantId.value) !== null && _a !== void 0 ? _a : null,
        });
        if (response === null || response.streetAddress === null) {
            throw new Error(`Failed to fetch address from coordinates: ${JSON.stringify(coordinates)}`);
        }
        return response.streetAddress;
    }
}
