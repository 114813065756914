import { makeImmutable } from "../../core";
export class POrderSummaryCancelled {
    constructor(params) {
        this.type = "Cancelled";
        this.isFinal = true;
        this.reason = params.reason;
        this.isOnlinePayment = params.isOnlinePayment;
        this.restaurantPhones = params.restaurantPhones;
        this.retry = params.retry;
        makeImmutable(this);
    }
}
