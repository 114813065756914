export const NullPreorderBuffer = {
    minDays: 0,
    min(_) {
        return this;
    },
    firstDate(now) {
        return now;
    },
    eq(other) {
        return other === this;
    },
};
