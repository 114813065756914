import { IdMap, RArray } from "../../collections";
import { makeImmutable } from "../../core";
import { PCustomParameterChoice, PCustomParameterType, } from "../../presentation";
export class PCustomParameterTypeParser {
    constructor(params) {
        this.domainCustomParameterTypes = params.domainCustomParameterTypes;
        this.translatedParser = params.translatedParser;
        makeImmutable(this);
    }
    parse(id, customParameter) {
        const domainCustomParameterType = this.domainCustomParameterTypes.rawFind(id);
        if (domainCustomParameterType === null) {
            return null;
        }
        return new PCustomParameterType({
            domain: domainCustomParameterType,
            name: this.translatedParser.parse(customParameter.name),
            choices: this.parseCustomParameterChoices(domainCustomParameterType.choices, customParameter),
        });
    }
    parseCustomParameterChoices(domainCustomParameterChoices, customParameter) {
        const choices = new RArray(customParameter.choices).mapOptional(([id, choice]) => {
            const domainCustomParameterChoice = domainCustomParameterChoices.rawFind(id);
            if (domainCustomParameterChoice === null) {
                return null;
            }
            return new PCustomParameterChoice({
                domain: domainCustomParameterChoice,
                name: this.translatedParser.parse(choice.name),
            });
        });
        return IdMap.fromIterable(choices);
    }
}
