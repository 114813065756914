import { makeAutoObservable } from "mobx";
import { PCustomParameterInstance } from "./PCustomParameterInstance";
import { PModifierInstance } from "./PModifierInstance";
import { PTemporarilyUnavailableIngredients } from "./PTemporarilyUnavailableIngredients";
export class PProductConfigCreator {
    constructor(params) {
        this.domain = params.domain;
        this._modifierInstances = params.modifierInstances;
        this.customParameterInstances = params.customParameterInstances;
        makeAutoObservable(this);
    }
    static create(params) {
        const modifierInstances = params.domain.modifierInstances.objects
            .map((modifierInstance) => new PModifierInstance({
            domain: modifierInstance,
            modifierType: params.restaurant.modifierTypes.get(modifierInstance.typeId),
            localizationProvider: params.localizationProvider,
        }))
            .sorted((a, b) => params.modifierOrder.findIndex((idx) => idx.eq(a.typeId)) -
            params.modifierOrder.findIndex((idx) => idx.eq(b.typeId))).raw;
        const customParameterInstances = params.domain.customParameterInstances.objects
            .map((customParameterInstance) => new PCustomParameterInstance({
            domain: customParameterInstance,
            customParameterType: params.restaurant.customParameterTypes.get(customParameterInstance.typeId),
        }))
            .sorted((a, b) => a.name.localeCompare(b.name)).raw;
        return new PProductConfigCreator({
            domain: params.domain,
            modifierInstances,
            customParameterInstances,
        });
    }
    get availability() {
        return this.domain.availability;
    }
    get modifierInstances() {
        return this._modifierInstances.filter((modifierInstance) => modifierInstance.isVisible);
    }
    touch() {
        this.customParameterInstances.forEach((customParameterInstance) => customParameterInstance.touch());
        this.modifierInstances.forEach((modifierInstance) => modifierInstance.touch());
    }
    get temporarilyUnavailableIngredients() {
        return this._modifierInstances
            .map((modifierInstance) => modifierInstance.temporarilyUnavailableIngredients)
            .reduce((prev, curr) => prev.union(curr), PTemporarilyUnavailableIngredients.empty());
    }
}
