import { makeImmutable } from "../../core/makeImmutable";
export class Schedule {
    constructor(params) {
        this.id = params.id;
        this.schedule = params.schedule;
        makeImmutable(this);
    }
    includes(date) {
        return this.schedule.includes(date);
    }
    get associationKey() {
        return this.id.associationKey;
    }
    toString() {
        return this.schedule.toString();
    }
}
