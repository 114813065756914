export function weightedRandom(variants) {
    // <https://en.wikipedia.org/wiki/Reservoir_sampling#Algorithm_A-Res>,
    // but specialized to k=1, where the priority queue degenerates to just a variable and comparison
    let acc = null;
    for (const [value, weight] of variants) {
        const random = Math.pow(Math.random(), 1 / weight);
        if (!acc || random > acc[1]) {
            acc = [value, random];
        }
    }
    if (!acc) {
        throw new Error("Empty list of variants");
    }
    return acc[0];
}
