import { RArray, naturalCompare, RSet } from "../collections";
export const PDescription = {
    customParameters(parameterMap, customParameterTypes) {
        return new RArray(parameterMap)
            .map(([parameterType, choiceId]) => {
            var _a, _b;
            return (_b = (_a = customParameterTypes.get(parameterType.id).choices.find(choiceId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "empty";
        })
            .raw.sort(naturalCompare);
    },
    modifier(modifierInstance, modifierType) {
        return modifierType.getItemsDescription(modifierInstance.selectedItems.map((itemInstance) => [
            itemInstance.typeId,
            itemInstance.quantity.value,
        ]));
    },
    modifiers(modifierInstances, modifierTypes, comparator) {
        return modifierInstances
            .sorted((a, b) => comparator(a.typeId, b.typeId))
            .map((instance) => this.modifier(instance, modifierTypes.get(instance.typeId)))
            .filtered((description) => description.trim() !== "").raw;
    },
    orderLine(params) {
        const { productInstance, customParameterTypes, modifierTypes, productTypes, } = params;
        const customParameters = PDescription.customParameters(productInstance.commonConfig.customParameters.choices, customParameterTypes);
        const nonDivisibleModifiersOrder = RSet.fromIterable(new RArray(productInstance.productTypeIds)
            .mapOptional((id) => productTypes.find(id))
            .flatMap((productType) => new RArray(productType.nonDivisibleModifiersOrder)), { ignoreDuplicates: true }).rawValues();
        const modifiers = PDescription.modifiers(productInstance.commonConfig.modifierInstances.objects, modifierTypes, (a, b) => nonDivisibleModifiersOrder.indexOf(a) -
            nonDivisibleModifiersOrder.indexOf(b));
        const productDesc = customParameters.concat(modifiers).join(", ");
        // NOTICE: if product is single part type, add also divisible parameters & modifiers
        if (productInstance.parts.type === "SinglePart") {
            const partDesc = PDescription.productPart({
                productInstancePart: productInstance.parts.part,
                customParameterTypes,
                modifierTypes,
                productTypes,
            });
            return [productDesc, partDesc].filter((x) => x.trim() !== "").join(", ");
        }
        return productDesc;
    },
    productPart(params) {
        const { productInstancePart, customParameterTypes, modifierTypes, productTypes, } = params;
        const productType = productTypes.get(productInstancePart.productTypeId);
        const parameters = PDescription.customParameters(productInstancePart.productConfig.customParameters.choices, customParameterTypes);
        const { divisibleModifiersOrder } = productType;
        const modifiers = PDescription.modifiers(productInstancePart.productConfig.modifierInstances.objects, modifierTypes, (a, b) => divisibleModifiersOrder.indexOf(a) - divisibleModifiersOrder.indexOf(b));
        return parameters.concat(modifiers).join(", ");
    },
};
