export class PaymentStorageSerializer {
    serializeForStorage(paymentMedium) {
        return JSON.stringify(paymentMedium);
    }
    deserializeFromStorage(data) {
        try {
            const medium = JSON.parse(data);
            this.assertValid(medium);
            // Support legacy format without method for "channel" type
            if (medium.type === "channel") {
                medium.method = "Online";
            }
            return medium;
        }
        catch (error) {
            // TODO: inject Sentry to not crash the tests
            // Sentry.logError(error);
            return null;
        }
    }
    // TODO: make it better (e.g. with zod?)
    assertValid(data) {
        if (typeof data !== "object" || data === null || !("type" in data)) {
            throw new Error("Invalid payment medium");
        }
    }
}
