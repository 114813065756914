export class OrderItemListStorageSerializer {
    serializeForStorage(orderItemList) {
        return JSON.stringify(orderItemList);
    }
    deserializeFromStorage(data) {
        try {
            const parsed = JSON.parse(data);
            this.assertValid(parsed);
            // Filter legacy format without "tag" property
            const filtered = parsed.filter((itemList) => Array.isArray(itemList) &&
                typeof itemList[0] === "string" &&
                itemList[1] !== null &&
                typeof itemList[1] === "object" &&
                "tag" in itemList[1]);
            return filtered;
        }
        catch (error) {
            // TODO: inject Sentry to not crash the tests
            // Sentry.logError(error);
            return null;
        }
    }
    // TODO: make it better (e.g. with zod?)
    assertValid(data) {
        if (data === null || !Array.isArray(data)) {
            throw new Error("Invalid order item list data");
        }
    }
}
