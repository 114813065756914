import { makeAutoObservable } from "mobx";
import { RSet } from "../../collections";
import { Tristate } from "../../core";
export class PreorderSettingsAvailability {
    constructor(params) {
        this.scope = params.scope;
        this.preorderSettings = params.preorderSettings;
        makeAutoObservable(this);
    }
    get unavailabilityReasons() {
        if (this.isAvailable.isDefinitelyTrue) {
            return RSet.empty();
        }
        const { now } = this.scope;
        const preorderRanges = this.preorderSettings.dateRanges(now);
        const isInThePast = preorderRanges.allBefore(now);
        if (!this.preorderSettings.isSameDayOnly && isInThePast) {
            return RSet.fromIterable(["Preorder", "OutdatedPreorderSettings"]);
        }
        return RSet.singleton("Preorder");
    }
    get reasons() {
        const { fulfillmentTime, now } = this.scope;
        if (fulfillmentTime === null) {
            return ["DateNotSelected"];
        }
        const dateRanges = this.preorderSettings.dateRanges(now);
        if (!dateRanges.includes(fulfillmentTime)) {
            return ["FulfillmentTimeNotInRange"];
        }
        return [];
    }
    get isAvailable() {
        const { fulfillmentTime, now } = this.scope;
        const preorderRanges = this.preorderSettings.dateRanges(now);
        const isInThePast = preorderRanges.allBefore(now);
        if (isInThePast) {
            return Tristate.False;
        }
        // TODO: return False if intersection of the preorderSettings and restaurant ordering hours (with holidays) is empty
        if (fulfillmentTime === null) {
            // FIXME Tristate.Maybe should be there, but such change breaks multiple e2e tests
            return Tristate.True;
        }
        if (preorderRanges.includes(fulfillmentTime)) {
            return Tristate.True;
        }
        return Tristate.False;
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitPreorder(this);
    }
}
