import { makeImmutable } from "../../core";
export class CardinalityConstraint {
    constructor(params) {
        this._premise = params.premise;
        this.cardinality = params.cardinality;
        this.repeatable = params.repeatable;
        this.enabledCount = params.enabledCount;
        makeImmutable(this);
    }
    get premise() {
        return [...this._premise.values()];
    }
    static empty(premise, cardinality, repeatable) {
        return new CardinalityConstraint({
            premise,
            cardinality,
            repeatable,
            enabledCount: 0,
        });
    }
    get isFufilled() {
        if (this.repeatable) {
            return (this.enabledCount % this.cardinality === 0 &&
                this._premise.size < this.cardinality);
        }
        return this.enabledCount === this.cardinality;
    }
    get isWorthConsideration() {
        return this._premise.size + this.enabledCount >= this.cardinality;
    }
    get isActive() {
        return this.enabledCount > 0;
    }
    modified(premise, enabledCount) {
        return new CardinalityConstraint({
            premise,
            cardinality: this.cardinality,
            repeatable: this.repeatable,
            enabledCount: enabledCount,
        });
    }
    eliminate(variable) {
        if (this.premise.includes(variable)) {
            const premise = new Set(this._premise);
            premise.delete(variable);
            return this.modified(premise, this.enabledCount + 1);
        }
        return this;
    }
    exclude(variables) {
        const premise = new Set(this._premise);
        variables.forEach((variable) => premise.delete(variable));
        return this.modified(premise, this.enabledCount);
    }
}
