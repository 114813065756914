import { RSet } from "../../collections";
import { makeImmutable } from "../../core/makeImmutable";
import { InternalParameterMultiSet } from "../ParameterMultiSet";
/**
 * A single combination of different internal parameters.
 *
 * For example: "splitToHalves: true, fulfillmentMethod: delivery"
 *
 * Internal (built in) parameters are:
 *
 * "fulfillmentMethod" - How the order will be fulfilled
 *
 * "splitToHalves" - Is the product split?
 *
 * "orderOrigin" - Where was the order made
 */
export class InternalParameterSet {
    constructor(params) {
        this.fulfillmentMethod = params.fulfillmentMethod;
        this.splitToHalves = params.splitToHalves;
        this.orderOrigin = params.orderOrigin;
        makeImmutable(this);
    }
    static empty() {
        return new InternalParameterSet({
            fulfillmentMethod: null,
            splitToHalves: null,
            orderOrigin: null,
        });
    }
    get isEmpty() {
        return (this.fulfillmentMethod === null &&
            this.splitToHalves === null &&
            this.orderOrigin === null);
    }
    unify(other) {
        const fulfillmentMethod = unifyInternal(this.fulfillmentMethod, other.fulfillmentMethod);
        const splitToHalves = unifyInternal(this.splitToHalves, other.splitToHalves);
        const orderOrigin = unifyInternal(this.orderOrigin, other.orderOrigin);
        const matchingParameters = new InternalParameterMultiSet({
            fulfillmentMethod: fulfillmentMethod.matchingParameters,
            splitToHalves: splitToHalves.matchingParameters,
            orderOrigin: orderOrigin.matchingParameters,
        });
        const incompatibleParameters = new InternalParameterMultiSet({
            fulfillmentMethod: fulfillmentMethod.type === "failure"
                ? fulfillmentMethod.incompatibleParameters
                : RSet.empty(),
            splitToHalves: splitToHalves.type === "failure"
                ? splitToHalves.incompatibleParameters
                : RSet.empty(),
            orderOrigin: orderOrigin.type === "failure"
                ? orderOrigin.incompatibleParameters
                : RSet.empty(),
        });
        if (incompatibleParameters.isEmpty) {
            const value = new InternalParameterSet({
                fulfillmentMethod: fulfillmentMethod.type === "success" ? fulfillmentMethod.value : null,
                splitToHalves: splitToHalves.type === "success" ? splitToHalves.value : null,
                orderOrigin: orderOrigin.type === "success" ? orderOrigin.value : null,
            });
            return { type: "success", matchingParameters, value };
        }
        return { type: "failure", matchingParameters, incompatibleParameters };
    }
    /**
     * Unites two InternalParameterSets
     *
     * Fails if predicates from one set conflict with predicates from another set
     */
    union(other) {
        var _a, _b, _c;
        const fulfillmentMethod = (_a = other.fulfillmentMethod) !== null && _a !== void 0 ? _a : this.fulfillmentMethod;
        if (this.fulfillmentMethod !== null &&
            this.fulfillmentMethod !== fulfillmentMethod) {
            return null;
        }
        const splitToHalves = (_b = other.splitToHalves) !== null && _b !== void 0 ? _b : this.splitToHalves;
        if (this.splitToHalves !== null && this.splitToHalves !== splitToHalves) {
            return null;
        }
        const orderOrigin = (_c = other.orderOrigin) !== null && _c !== void 0 ? _c : this.orderOrigin;
        if (this.orderOrigin !== null && this.orderOrigin !== orderOrigin) {
            return null;
        }
        return new InternalParameterSet({
            fulfillmentMethod,
            splitToHalves,
            orderOrigin,
        });
    }
}
function unifyInternal(thisInternalParameterValue, otherInternalParameterValue) {
    if (thisInternalParameterValue === null) {
        return { type: "success", matchingParameters: RSet.empty(), value: null };
    }
    if (otherInternalParameterValue === null) {
        return {
            type: "success",
            matchingParameters: RSet.empty(),
            value: thisInternalParameterValue,
        };
    }
    if (thisInternalParameterValue === otherInternalParameterValue) {
        return {
            type: "success",
            matchingParameters: RSet.singleton(thisInternalParameterValue),
            value: null, // NOTICE we eliminate matching parameter
        };
    }
    return {
        type: "failure",
        matchingParameters: RSet.empty(),
        incompatibleParameters: RSet.singleton(otherInternalParameterValue),
    };
}
