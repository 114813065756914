import { makeImmutable } from "../core";
import { HalvingPolicy } from "../ordering";
export class HalvingPolicyParser {
    constructor(params) {
        this.parametrizationParser = params.parametrizationParser;
        makeImmutable(this);
    }
    parse(category) {
        return new HalvingPolicy({
            halvingAvailability: this.parametrizationParser.parse(category.divisibleV2),
        });
    }
}
