import { h } from "preact";
import { makeImgUrl, setQueryParams } from "../../utils/URL";
function CreatorPhoto(props) {
    const { photoUrn } = props;
    const params = {
        auto: "compress",
        w: "600",
    };
    const photoUrl = setQueryParams(params, makeImgUrl(photoUrn));
    return h("img", { class: "m-product-card__img", src: photoUrl, alt: "" });
}
export default CreatorPhoto;
