import { makeImmutable } from "../../core";
export class CrossSellingListRepository {
    constructor(params) {
        this.crossSellingLists = params.crossSellingLists;
        this.crossSellingListAssociations = params.crossSellingListAssociations;
        makeImmutable(this);
    }
    get isAnyCrossSellingListUsed() {
        return !this.crossSellingListAssociations.isEmpty;
    }
    get(crossSellingListId) {
        return this.crossSellingLists.get(crossSellingListId);
    }
    getByProductTypeId(productTypeId) {
        return this.crossSellingListAssociations.find(productTypeId);
    }
}
