import { makeAutoObservable } from "mobx";
import { RArray, RSet } from "../../collections";
import { Availability } from "../Availability";
import { ParameterScope } from "../ParameterScope";
import { ProductInstancePart, } from "../ProductInstancePart/ProductInstancePart";
/**
 * A product instance split into halves, such as a pizza, split into half Margherita and half Capriciosa
 */
export class ProductInstanceHalves {
    constructor(params) {
        this.type = "Halves";
        this.isSplitPossible = false;
        this.scope = params.scope;
        this.productCategory = params.productCategory;
        this.first = params.first;
        this.second = params.second;
        makeAutoObservable(this);
    }
    static create(params) {
        if (!params.first.productCategoryId.eq(params.productCategory.id)) {
            return null;
        }
        if (!params.second.productCategoryId.eq(params.productCategory.id)) {
            return null;
        }
        return new ProductInstanceHalves(params);
    }
    static empty(params) {
        return ProductInstanceHalves.create({
            ...params,
            scope: ParameterScope.Uninitialized,
        });
    }
    static fromStorageData({ menu, storageData, }) {
        const first = ProductInstancePart.fromStorageData({
            menu,
            storageData: storageData.first,
        });
        if (first === null) {
            return null;
        }
        const second = ProductInstancePart.fromStorageData({
            menu,
            storageData: storageData.second,
        });
        if (second === null) {
            return null;
        }
        if (!first.productCategoryId.eq(second.productCategoryId)) {
            return null;
        }
        const productCategory = menu.productCategories.find(first.productCategoryId);
        if (productCategory === null) {
            return null;
        }
        return new ProductInstanceHalves({
            scope: ParameterScope.Uninitialized,
            productCategory,
            first,
            second,
        });
    }
    eq(other) {
        return (other.type === "Halves" &&
            this.first.eq(other.first) &&
            this.second.eq(other.second));
    }
    isCartEquivalentTo(other) {
        return (other.type === "Halves" &&
            this.first.isCartEquivalentTo(other.first) &&
            this.second.isCartEquivalentTo(other.second));
    }
    get availability() {
        return Availability.composite([
            Availability.halving(this.productCategory.halvingPolicy, this.scope),
            this.first.availability,
            this.second.availability,
        ]);
    }
    get baseVolume() {
        const fst = this.first.baseVolume;
        const snd = this.second.baseVolume;
        if (fst) {
            if (snd) {
                return { productTypeIds: this.productTypeIds, volume: fst.max(snd) };
            }
            return {
                productTypeIds: RSet.singleton(this.first.productTypeId),
                volume: fst,
            };
        }
        if (snd) {
            return {
                productTypeIds: RSet.singleton(this.second.productTypeId),
                volume: snd,
            };
        }
        return null;
    }
    get configPackingItems() {
        return this.first.configPackingItems.unionWith(this.second.configPackingItems, (a, b) => a.merge(b));
    }
    get productTypeIds() {
        return RSet.fromIterable([this.first.productTypeId, this.second.productTypeId], {
            ignoreDuplicates: true,
        });
    }
    get productWeeklySchedule() {
        var _a;
        if (this.first.weeklySchedule !== null &&
            this.second.weeklySchedule !== null) {
            return this.first.weeklySchedule.intersection(this.second.weeklySchedule);
        }
        return (_a = this.first.weeklySchedule) !== null && _a !== void 0 ? _a : this.second.weeklySchedule;
    }
    get productCategoryId() {
        return this.first.productCategoryId;
    }
    get customParameterChoiceIds() {
        const first = this.first.customParameterChoiceIds;
        const second = this.second.customParameterChoiceIds;
        return first.union(second);
    }
    get parts() {
        return new RArray([this.first, this.second]);
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        return [this.first.checkoutData, this.second.checkoutData];
    }
    get productInstanceParts() {
        return [this.first, this.second];
    }
    get storageData() {
        return {
            type: "Halves",
            first: this.first.storageData,
            second: this.second.storageData,
        };
    }
}
