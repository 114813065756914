import { makeImmutable } from "../../core";
import { RestaumaticRPCService } from "../RestaumaticRPCService";
export class MenuService {
    constructor() {
        makeImmutable(this);
    }
    async getMenu(path) {
        const response = await RestaumaticRPCService.fetchJson(path, {
            headers: {
                Accept: "application/json",
                "x-app-version": "site-latest",
            },
        });
        if (response === null) {
            throw new Error("Unable to load menu");
        }
        return response.result;
    }
}
