import Splitting from "splitting";
import "waypoints/lib/noframework.waypoints";
/**
 * Test for supported animationend event and return its name.
 */
const getAnimationEndEvent = () => {
    let animationendEvent = "animationend";
    const animations = [
        ["animation", animationendEvent],
        ["OAnimation", "oAnimationEnd"],
        ["MozAnimation", animationendEvent],
        ["msAnimation", "MSAnimationEnd"],
        ["WebkitAnimation", "webkitAnimationEnd"],
    ];
    for (let i in animations) {
        if (document.body.style[animations[i][0]] !== undefined) {
            animationendEvent = animations[i][1];
            break;
        }
    }
    return animationendEvent;
};
const animationEndEvent = getAnimationEndEvent();
/**
 * Change animation time unit from seconds to milliseconds.
 *
 * @param {string|number} val Time in seconds.
 * @returns {number} Time in milliseconds.
 */
function convertToMs(val) {
    return String(val).indexOf("ms") === -1
        ? parseFloat(val) * 1000
        : parseInt(val);
}
/**
 * Get the last animated element.
 *
 * Animations order is calculated based on `animation-duration` and `animation-delay` CSS properties.
 * Beware that for the `inview` animations, if CSS `order` property is used, the result may be incorrect.
 * Although in mobile-first approach, CSS `order` property should not mess with elements order on the smallest viewport, if it will be the case, the more advanced elements lookups might be needed.
 *
 * @param {NodeListOf<HTMLElement>} elements Animated elements.
 * @returns {object} Object with `element` and `time` properties.
 * @property {HTMLElement} el The last animated element.
 * @property {number} time {number} Time in milliseconds.
 * @returns {object} Object with element and time properties.
 */
function getLastAnimatedObj(elements) {
    let time = 0; // Time required for all animations to complete.
    let lastAnimatdedElement = elements[0];
    elements.forEach(function (el) {
        const defaultStyles = {
            animationDuration: "0ms",
            animationDelay: "0ms",
        };
        const styles = typeof window.getComputedStyle === "function"
            ? window.getComputedStyle(el)
            : defaultStyles;
        const animationTime = convertToMs(styles.animationDuration || defaultStyles.animationDuration) +
            convertToMs(styles.animationDelay || defaultStyles.animationDelay);
        if (animationTime >= time) {
            // `>` is not enought and `>=` is required, because on mobile, even if animation times are equal, the last one will run at the end if triggered by `inview` variant.
            lastAnimatdedElement = el;
        }
        time = Math.max(time, animationTime);
    });
    return {
        el: lastAnimatdedElement,
        time: time,
    };
}
/**
 * Trigger initial placeholder events.
 *
 * @param {HTMLElement} el Element for splitting animation.
 */
function triggerEvents(el) {
    if (el.classList.contains("js-animate__init-element")) {
        getLastAnimatedObj(el.querySelectorAll(".cell")).el.addEventListener(animationEndEvent, () => el.dispatchEvent(new Event("shown.restaumatic.animate")));
    }
}
/**
 * Prepare elements for animation.
 *
 * @param {HTMLElement} element Animated element.
 * @param {string} type Animation type.
 */
function prepareAnimation(element, type) {
    const wrapper = element.closest(".animate-wrapper--overflow");
    // Hide element before animation.
    // Splitted animation visibility is handled in CSS (including simple fallback for old browsers).
    if (type !== "splitted") {
        element.classList.add("u-visibility-hidden");
    }
    if (!wrapper)
        return;
    getLastAnimatedObj(wrapper.querySelectorAll(".animated")).el.addEventListener(animationEndEvent, () => {
        wrapper.classList.remove("animate-wrapper--overflow");
    });
}
/**
 * Animate element when it enters the viewport.
 */
function animateWhenInView(el, animationClassName) {
    prepareAnimation(el, "inview");
    new window.Waypoint({
        element: el,
        handler: function (direction) {
            if (direction === "down") {
                animate(el, animationClassName);
                this.destroy();
            }
        },
        offset: "90%",
    });
}
/**
 * Run element animation.
 *
 * @param {HTMLElement} el
 * @param {string} animationClassName
 */
function animate(el, animationClassName) {
    el.classList.remove("hide", "u-visibility-hidden");
    el.classList.add(animationClassName);
}
export default {
    /**
     * Init animations.
     *
     * [data-animation] Animation name.
     * [data-delay] Delay before the animation starts (number or "inview" keyword).
     */
    init: function (animateSelector = ".animated[data-animation]") {
        document.querySelectorAll(animateSelector).forEach(function (animated) {
            if (!(animated instanceof HTMLElement))
                return;
            const animation = animated.dataset.animation || "";
            const animationDelay = animated.dataset.delay || "0";
            // TODO: Consider treating all animations as inview animations and support delay only in CSS.
            if (animationDelay === "inview") {
                animateWhenInView(animated, animation);
            }
            else {
                const delay = typeof animationDelay === "undefined" ? 0 : parseInt(animationDelay);
                setTimeout(function () {
                    animate(animated, animation);
                }, delay);
            }
        });
    },
    /**
     * Init splitting animations.
     *
     * [data-animation] Animation name.
     */
    initSplitting: function (selector = ".splitted-animation") {
        const elements = document.querySelectorAll(selector);
        if (!elements)
            return;
        elements.forEach((el) => {
            // TODO: Make better fix that works during animation: https://restaumatic.atlassian.net/browse/RS-8827?focusedCommentId=22305
            // Show static image above splitted cells after animation ends. Fix https://restaumatic.atlassian.net/browse/RS-8827
            el.addEventListener("shown.restaumatic.animate", function () {
                el.classList.add("u-visibility-visible");
            });
            const placeholder = el.querySelector(".cell--placeholder");
            prepareAnimation(el, "splitted");
            if (!placeholder)
                return;
            // Remove placeholder used to calculate animation duration by double animation fix (see more details in `_js_animation_fix.liquid`).
            placeholder.remove();
        });
        // Split images.
        const imageResults = Splitting({
            target: selector,
            image: true,
            by: "cells",
        });
        elements.forEach((el) => {
            triggerEvents(el);
        });
    },
};
