import { PromotionFreebie } from "../PromotionType";
import { ProductPromotionInstance, } from "./ProductPromotionInstance";
import { PromotionInstanceId } from "./PromotionInstanceId";
import { WholeOrderPromotionInstance, } from "./WholeOrderPromotionInstance";
export const PromotionInstance = {
    fromStorageData(params) {
        const { promotionTypes, storageData } = params;
        const id = new PromotionInstanceId(params.id);
        const promotionType = promotionTypes.rawFind(storageData.promotionTypeId);
        if (promotionType === null) {
            return null;
        }
        let freebie = null;
        if (promotionType.effect.type === "Freebie") {
            // NOTICE When freebie is not selected instance cannot be restored
            if (storageData.freebie === null) {
                return null;
            }
            freebie = PromotionFreebie.fromStorageData({
                promotionFreebies: promotionType.effect.freebies,
                storageData: storageData.freebie,
            });
            // NOTICE When selected freebie is no longer available in promotion effect, the instance cannot be restored
            if (freebie === null) {
                return null;
            }
        }
        switch (storageData.kind) {
            case "ProductPromotionInstance":
                return ProductPromotionInstance.fromStorageData({
                    promotionType,
                    purchaseOrderPayload: params.purchaseOrderPayload,
                    freebie,
                    id,
                    storageData,
                });
            case "WholeOrderPromotionInstance":
                return WholeOrderPromotionInstance.fromStorageData({
                    promotionType,
                    purchaseOrderPayload: params.purchaseOrderPayload,
                    freebie,
                    id,
                    storageData,
                });
        }
    },
    excludes(a, b) {
        if (a.id.eq(b.id)) {
            return false;
        }
        if (a.promotionType.id.eq(b.promotionType.id)) {
            return false;
        }
        if (a.promotionType.combinesWith.includes(b.promotionType.id)) {
            return false;
        }
        switch (a.kind) {
            case "Product":
                switch (b.kind) {
                    case "Product": {
                        const referencesA = [...a.discountSource, a.discountTarget];
                        const referencesB = [...b.discountSource, b.discountTarget];
                        return referencesA.some((referenceA) => referencesB.some((referenceB) => referenceA.eq(referenceB)));
                    }
                    case "WholeOrder":
                        return true;
                }
                break;
            case "WholeOrder":
                return true;
        }
    },
};
