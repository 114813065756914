import { makeImmutable } from "../core/makeImmutable";
export class PPromotionType {
    constructor(params) {
        this.domain = params.domain;
        this._name = params.name;
        this._description = params.description;
        this.isCoupon = params.isCoupon;
        makeImmutable(this);
    }
    get id() {
        return this.domain.id;
    }
    get name() {
        return this._name.value;
    }
    get description() {
        return this._description.value;
    }
    get effect() {
        return this.domain.effect;
    }
}
