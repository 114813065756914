import { makeImmutable } from "../../core";
// NOTICE ProductTypeGroup will gradually replace ProductCategory and MenuSection, and is used for grouping settings across multiple ProductTypes
export class ProductTypeGroup {
    constructor(params) {
        this.id = params.id;
        this._preorderSettings = params.preorderSettings;
        this.visibilityScheduleId = params.visibilityScheduleId;
        this.sectionVisibilityService = params.sectionVisibilityService;
        makeImmutable(this);
    }
    get isVisible() {
        return this.sectionVisibilityService.isSectionVisible(this.visibilityScheduleId);
    }
    get preorderSettings() {
        if (this.isVisible) {
            return this._preorderSettings;
        }
        return null;
    }
}
