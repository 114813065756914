export class StorageManager {
    constructor(params) {
        this.storageKey = params.storageKey;
        this.storage = params.storage;
        this.serializer = params.storageSerializer;
    }
    store(toStore) {
        const serialized = this.serializer.serializeForStorage(toStore);
        this.storage.store(this.storageKey, serialized);
    }
    restore() {
        const serialized = this.storage.restore(this.storageKey);
        if (serialized === null) {
            return null;
        }
        return this.serializer.deserializeFromStorage(serialized);
    }
    erase() {
        this.storage.erase(this.storageKey);
    }
}
