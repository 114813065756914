import { makeAutoObservable } from "mobx";
import { IdMap, RArray, RSet } from "../../collections";
import { Price } from "../../pricing/Price";
import { Availability } from "../Availability";
import { OrderLine } from "../OrderLine";
import { Quantity } from "../Quantity";
import { OrderLinePrice } from "../../pricing/OrderLinePrice";
import { PreorderSettings } from "../PreorderSettings";
/*
 * Collection of OrderLines added by user
 * It's price is a nominal order price (without delivery and promotions)
 * It's availability depends on availability of all ordered product instances
 */
export class PurchaseOrderPayload {
    constructor(params) {
        this.menu = params.menu;
        this._orderLines = params.orderLines;
        this.preorderSettingsWhenEmpty = params.preorderSettingsWhenEmpty;
        makeAutoObservable(this);
    }
    static empty({ menu, preorderSettingsWhenEmpty, }) {
        return new PurchaseOrderPayload({
            menu,
            preorderSettingsWhenEmpty,
            orderLines: [],
        });
    }
    static fromStorageData({ menu, storageData, preorderSettingsWhenEmpty, }) {
        const orderLines = new RArray(storageData).mapOptional((orderLineStorageData) => OrderLine.fromStorageData({ menu, storageData: orderLineStorageData }));
        return new PurchaseOrderPayload({
            menu,
            orderLines: orderLines.raw,
            preorderSettingsWhenEmpty,
        });
    }
    mapProductInstances(mapping) {
        return new PurchaseOrderPayload({
            menu: this.menu,
            orderLines: this._orderLines.map((orderLine) => orderLine.mapProductInstance(mapping)),
            preorderSettingsWhenEmpty: this.preorderSettingsWhenEmpty,
        });
    }
    get orderLines() {
        return new RArray(this._orderLines).filtered((orderLine) => !orderLine.isEmpty);
    }
    get numberOfItems() {
        return this.orderLines.reduce((acc, { quantity }) => acc.add(quantity), Quantity.Zero);
    }
    get isEmpty() {
        return this.numberOfItems.isZero;
    }
    get price() {
        return Price.total(this.orderLines.map((orderline) => OrderLinePrice.totalPrice(orderline)));
    }
    get availability() {
        if (this.isEmpty) {
            return Availability.unavailable("EmptyOrder");
        }
        const orderLinesAvailabilities = this.orderLines.map((orderLine) => orderLine.availability).raw;
        return Availability.dependent("OrderLine", "", Availability.composite(orderLinesAvailabilities));
    }
    addOrderLine(orderLine) {
        if (orderLine.isEmpty) {
            throw new Error(`Unable to add an empty OrderLine to PurchaseOrder`);
        }
        if (!this.menu.includes(orderLine.productTypeIds)) {
            throw new Error(`Unable to add an OrderLine, because some of the product types are offered by restaurant`);
        }
        const existingOrderLineIndex = this._orderLines.findIndex((existingOrderLine) => existingOrderLine.productInstance.eq(orderLine.productInstance));
        if (existingOrderLineIndex === -1) {
            this._orderLines.push(orderLine);
            return "added_new_item";
        }
        const existingOrderLine = this._orderLines[existingOrderLineIndex];
        if (existingOrderLine.isEmpty) {
            this._orderLines[existingOrderLineIndex] = orderLine;
            return "added_new_item";
        }
        existingOrderLine.addQuantity(orderLine.quantity);
        return "increased_existing_item";
    }
    cartCount(productInstance) {
        return this._orderLines
            .filter((orderLine) => productInstance.isCartEquivalentTo(orderLine.productInstance))
            .map((orderLine) => orderLine.quantity)
            .reduce((prev, curr) => prev.add(curr), Quantity.Zero);
    }
    clear() {
        this._orderLines = [];
    }
    get productTypeIds() {
        return this.orderLines
            .map((orderLine) => orderLine.productTypeIds)
            .reduce((acc, ids) => acc.union(ids), RSet.empty());
    }
    get packingItems() {
        return this.orderLines
            .map((orderLine) => orderLine.packingItems)
            .reduce((prev, curr) => prev.unionWith(curr, (a, b) => a.merge(b)), IdMap.empty());
    }
    get checkoutData() {
        return this.orderLines.map((orderLine) => orderLine.checkoutData).raw;
    }
    get checkoutLoggingData() {
        return this.orderLines.map((orderLine) => ({
            ...orderLine.checkoutData,
            isAvailable: orderLine.availability.isAvailable.isDefinitelyTrue,
        })).raw;
    }
    get storageData() {
        return this.orderLines.map((orderLine) => orderLine.storageData).raw;
    }
    get preorderSettings() {
        if (this.isEmpty) {
            return this.preorderSettingsWhenEmpty;
        }
        const settings = this.orderLines.map((orderLine) => orderLine.preorderSettings);
        return PreorderSettings.union(settings);
    }
}
