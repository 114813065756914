import { Packing } from "../Packing/Packing";
import { makeImmutable } from "../../core/makeImmutable";
/**
 * Packing algorithm that doesn't pack the products or modifiers at all.
 */
export class NullPackingAlgorithm {
    constructor() {
        makeImmutable(this);
    }
    pack(items) {
        return Packing.empty();
    }
}
