import { makeAutoObservable } from "mobx";
import { RArray, RMap, RMutableMap } from "../../collections";
import { IdentifiableMap } from "../../collections/IdentifiableMap";
import { RMutableMultiSet } from "../../collections/RMutableMultiSet";
import { fromPairs, toPairs } from "../../core";
import { Availability } from "../Availability";
import { ModifierItemInstance } from "../ModifierItemInstance";
import { fromModifierItems } from "../PackingItem";
import { ParameterScope } from "../ParameterScope";
import { PositiveQuantity } from "../PositiveQuantity";
import { Quantity } from "../Quantity";
import { ModifierInstanceEditability } from "./ModifierInstanceEditability";
import { ModifierInstanceTypeAvailability } from "./ModifierInstanceTypeAvailability";
export class ModifierInstance {
    constructor(params) {
        this.scope = ParameterScope.Uninitialized;
        this.behaviour = params.behaviour;
        if (params.modifierType.behaviour !== this.behaviour) {
            throw new Error("Modifier behaviour mismatch, please use instantiate method to obtain the correct instance");
        }
        this.modifierType = params.modifierType;
        this.itemQuantities = params.itemQuantities;
        this.items = params.items;
        this.freeItems = params.freeItems;
        this.editability = params.editability;
        this.modifierTypeAvailability = params.modifierTypeAvailability;
        makeAutoObservable(this);
    }
    static create(params) {
        const { modifierType, modifierSettings } = params;
        const isMultipleChoice = modifierType.behaviour === "MultipleChoice";
        const editability = new ModifierInstanceEditability(modifierSettings.editable);
        const modifierTypeAvailability = new ModifierInstanceTypeAvailability(modifierType);
        const itemQuantities = RMutableMultiSet.fromIterable(params.itemQuantities);
        const items = IdentifiableMap.fromIterable("typeId", modifierType.itemTypes.objects.map((modifierItemType) => {
            var _a;
            return new ModifierItemInstance({
                itemQuantities,
                modifierItemType,
                defaultQuantity: (_a = modifierSettings.defaultItems.find(modifierItemType.id)) !== null && _a !== void 0 ? _a : Quantity.Zero,
                maxDuplicatesPerItem: modifierType.maxDuplicatesPerItem,
                isMultipleChoice,
                editability,
                modifierTypeAvailability,
            });
        }));
        return new ModifierInstance({
            behaviour: modifierType.behaviour,
            modifierType,
            itemQuantities,
            items,
            freeItems: modifierSettings.freeItems,
            editability,
            modifierTypeAvailability,
        });
    }
    static createWithDefaultItems({ modifierType, modifierSettings, }) {
        return ModifierInstance.create({
            modifierType,
            modifierSettings,
            itemQuantities: modifierSettings.defaultItems,
        });
    }
    static fromStorageData(params) {
        const modifierType = params.menu.modifierTypes.rawFind(params.modifierTypeId);
        if (modifierType === null) {
            return null;
        }
        const storedItems = RMap.fromIterable(toPairs(params.storageData.items).mapOptional(([storedModifierItemTypeId, itemStorageData]) => {
            const modifierItemTypeId = modifierType.itemTypeIds.rawFind(storedModifierItemTypeId);
            return modifierItemTypeId === null
                ? null
                : [
                    modifierItemTypeId,
                    {
                        quantity: Quantity.fromNumber(itemStorageData.quantity),
                    },
                ];
        }));
        // NOTICE Restore modifier instance "as it was saved", without appending modifier defaultItems
        return ModifierInstance.create({
            modifierType,
            modifierSettings: params.modifierSettingsProvider(modifierType),
            itemQuantities: storedItems.map((item) => item.quantity),
        });
    }
    eq(other) {
        return (this.typeId.eq(other.typeId) &&
            RMutableMultiSet.eq(this.itemQuantities, other.itemQuantities));
    }
    setScope(scope) {
        this.scope = scope;
        this.editability.setScope(this.scope);
        this.modifierTypeAvailability.setScope(this.scope);
        this.items.objects.forEach((modifierItemInstance) => {
            modifierItemInstance.setScope(this.scope);
        });
    }
    get typeId() {
        return this.modifierType.id;
    }
    /** Item entries, only items which are selected */
    get selectedItems() {
        return this.items.filter((itemInstance) => itemInstance.isSelected).objects;
    }
    get availability() {
        const selectedItemCount = this.selectedItems.reduce((acc, itemInstance) => acc.add(itemInstance.quantity), Quantity.Zero);
        const availabilities = new RArray(this.selectedItems.map((itemInstance) => itemInstance.unitAvailability));
        const itemAvailabilities = Availability.dependent("ModifierItem", "", Availability.composite(availabilities.raw));
        const quantityAvailabilities = selectedItemCount.isZero &&
            this.modifierTypeAvailability.isDefinitelyUnavailable
            ? Availability.available()
            : Availability.boolean({
                NotExpectedItems: this.modifierType.maxItems !== null &&
                    this.modifierType.minItems.eq(this.modifierType.maxItems) &&
                    !selectedItemCount.eq(this.modifierType.minItems),
                MaxItemsExceeded: this.modifierType.maxItems !== null &&
                    selectedItemCount.gt(this.modifierType.maxItems),
                NotEnoughItems: selectedItemCount.lt(this.modifierType.minItems),
            });
        return Availability.composite([itemAvailabilities, quantityAvailabilities]);
    }
    get packingItems() {
        const quantities = this.selectedItems.map((itemInstance) => [
            itemInstance.typeId,
            new PositiveQuantity(itemInstance.quantity.value),
        ]);
        return fromModifierItems(this.modifierType, quantities);
    }
    get selectedItemIds() {
        return this.items.objects.flatMap(({ typeId, quantity }) => RArray.repeat(typeId, quantity));
    }
    set selectedItemIds(selectedItemTypeIds) {
        var _a;
        const counts = RMutableMap.empty();
        for (const itemId of selectedItemTypeIds) {
            const count = counts.getOrCreate(itemId, () => Quantity.Zero);
            counts.update(itemId, count.incremented());
        }
        for (const itemInstance of this.items.objects) {
            const count = (_a = counts.find(itemInstance.typeId)) !== null && _a !== void 0 ? _a : Quantity.Zero;
            this.itemQuantities.setQuantity(itemInstance.typeId, count);
        }
    }
    clone() {
        const itemInstances = this.items.objects;
        const itemQuantities = this.itemQuantities.clone();
        const editability = this.editability.clone();
        const modifierTypeAvailability = this.modifierTypeAvailability.clone();
        const modifierInstance = new ModifierInstance({
            behaviour: this.behaviour,
            modifierType: this.modifierType,
            itemQuantities: itemQuantities,
            items: IdentifiableMap.fromIterable("typeId", itemInstances.map((itemInstance) => itemInstance.clone({
                itemQuantities,
                editability,
                modifierTypeAvailability,
            }))),
            freeItems: this.freeItems,
            editability,
            modifierTypeAvailability,
        });
        return modifierInstance;
    }
    selectInferredDefaults() {
        if (this.selectedItems.isEmpty && this.modifierType.minItems.isOne) {
            const availableItems = this.items.filter((itemInstance) => itemInstance.isEditable);
            if (availableItems.size === 1) {
                availableItems.sample.select();
            }
        }
    }
    get storageData() {
        return {
            items: fromPairs(this.items.objects
                .filtered((instance) => instance.quantity.value > 0)
                .map((modifierItemInstance) => [
                modifierItemInstance.typeId.value,
                {
                    quantity: modifierItemInstance.quantity.value,
                },
            ])),
        };
    }
}
