import { observer } from "mobx-react";
import { h } from "preact";
import { RArray } from "restaumatic-client/src";
import DebugAvailablityVistor from "restaumatic-client/src/ordering/Availability/DebugAvailablityVistor";
import DebugMaybeAvailablityVistor from "restaumatic-client/src/ordering/Availability/DebugMaybeAvailablityVistor";
function AvailabilityDebugInfo(props) {
    const { fieldId, name, availability } = props;
    const fieldProps = {
        "data-field-id": fieldId,
        "data-field-type": "info",
        "aria-hidden": true,
    };
    if (availability.isAvailable.isDefinitelyTrue) {
        return null;
    }
    const isHidden = window.localStorage.getItem("debug") !== "true";
    if (!availability.isAvailable.isDefinite) {
        const rootVistor = new DebugMaybeAvailablityVistor();
        availability.accept(rootVistor);
        return (h("details", { ...fieldProps, class: isHidden ? "hidden" : "", style: "color: orange" },
            h("summary", null,
                name,
                " may be unavailable"),
            h("ul", { class: isHidden ? "hidden" : "", style: "color: orange" }, rootVistor.errors.map((error) => (h("li", null, error))))));
    }
    const rootVistor = new DebugAvailablityVistor();
    availability.accept(rootVistor);
    const reasons = new RArray(availability.unavailabilityReasons).sorted((a, b) => a.localeCompare(b)).raw;
    return (h("details", { ...fieldProps, class: isHidden ? "hidden" : "", style: "color: red;" },
        h("summary", null,
            name,
            " unavailable, reasons:",
            reasons.join(", ")),
        h("ul", null, rootVistor.errors.map((error) => (h("li", null, error))))));
}
export default observer(AvailabilityDebugInfo);
