import { makeImmutable } from "./makeImmutable";
export const FULL_DAY = 24 * 60 * 60;
/*
 * Second of a day (between 0 and 24 * 60 * 60 seconds)
 */
export class DaySecond {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    static fromNumber(value) {
        if (value < 0 || value > FULL_DAY) {
            throw new Error(`Day second ${value} is not in range [0, ${FULL_DAY}]`);
        }
        return new DaySecond(value);
    }
    static multiDay(value) {
        if (value < 0) {
            throw new Error(`Multi day second ${value} cannot be negative`);
        }
        const fullDays = Math.floor(value / FULL_DAY);
        const remainder = new DaySecond(value % FULL_DAY);
        return { fullDays, remainder };
    }
    eq(other) {
        return this.value === other.value;
    }
    isAfter(other) {
        return this.value > other.value;
    }
    isBefore(other) {
        return this.value < other.value;
    }
    isBetweenExcludingEnd(start, end) {
        return start.value <= this.value && this.value < end.value;
    }
    duration(other) {
        return Math.abs(this.value - other.value);
    }
    compare(other) {
        return this.value - other.value;
    }
    min(other) {
        return new DaySecond(Math.min(this.value, other.value));
    }
    max(other) {
        return new DaySecond(Math.max(this.value, other.value));
    }
    get hms() {
        const hour = Math.floor(this.value / 3600);
        const remainingSeconds = this.value % 3600;
        const minute = Math.floor(remainingSeconds / 60);
        const second = remainingSeconds % 60;
        return { hour, minute, second };
    }
    toString() {
        const { hour, minute } = this.hms;
        return `${hour}:${minute <= 10 ? "0" : ""}${minute}`;
    }
    ceil(amountSeconds) {
        const difference = amountSeconds - ((this.value + amountSeconds - 1) % amountSeconds) - 1;
        return new DaySecond(difference + this.value);
    }
    add(seconds) {
        const newValue = this.value + seconds;
        if (newValue >= FULL_DAY) {
            return null;
        }
        return new DaySecond(newValue);
    }
    sub(seconds) {
        const newValue = this.value - seconds;
        if (newValue < 0) {
            return null;
        }
        return new DaySecond(newValue);
    }
}
DaySecond.First = new DaySecond(0);
DaySecond.Last = new DaySecond(FULL_DAY);
