import { makeImmutable } from "../../core";
import { Money } from "../Money";
export class PurchaseOrderValidationPrice {
    constructor(params) {
        this.value = params.value;
        this.deliveryCost = params.deliveryCost;
        makeImmutable(this);
    }
    static create(params) {
        const includedDelivery = params.deliveryInTotal
            ? params.deliveryCost
            : Money.zero();
        const deliveryCost = params.deliveryInTotal
            ? Money.zero()
            : params.deliveryCost;
        return new PurchaseOrderValidationPrice({
            deliveryCost,
            value: params.priceWithoutDelivery.add(includedDelivery),
        });
    }
}
