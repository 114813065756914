import { makeAutoObservable } from "mobx";
export class PCrossSellingItemProductCardBehaviour {
    constructor(domain) {
        this.type = "ProductCard";
        this.domain = domain;
        makeAutoObservable(this);
    }
    get isActive() {
        return !this.domain.quantity.isZero;
    }
    get quantity() {
        return this.domain.quantity.value;
    }
    increment() {
        this.domain.increment();
    }
    get canDecrement() {
        return !this.domain.quantity.isZero;
    }
    decrement() {
        if (this.canDecrement) {
            this.domain.decrement();
        }
    }
}
export class PCrossSellingItemCartBehaviour {
    constructor(params) {
        this.type = "Cart";
        this.isActive = false;
        this.domain = params.domain;
        this.kind = params.kind;
        this.orderItemListTracking = params.orderItemListTracking;
        makeAutoObservable(this);
    }
    add() {
        this.orderItemListTracking.collectProductData(this.domain.productInstanceTrackingId, { tag: "CrossSelling", place: "Cart", kind: this.kind });
        this.domain.add();
    }
}
export const PCrossSellingItemBehaviour = {
    create(params) {
        const { domain, kind, orderItemListTracking } = params;
        switch (domain.type) {
            case "ProductCard":
                return new PCrossSellingItemProductCardBehaviour(domain);
            case "Cart":
                return new PCrossSellingItemCartBehaviour({
                    domain,
                    kind,
                    orderItemListTracking,
                });
        }
    },
};
