import { makeImmutable } from "../../core";
export class PaginationStrategy {
    constructor(params) {
        this.type = "Pagination";
        if (params.pageSize <= 0) {
            throw new Error("pageSize cannot be 0 or less");
        }
        this.pageSize = params.pageSize;
        makeImmutable(this);
    }
    getNext(currentItems, newItems) {
        return newItems;
    }
    getPrev(currentItems, newItems) {
        return newItems;
    }
}
export class InfiniteScrollStrategy {
    constructor(params) {
        this.type = "InfiniteScroll";
        if (params.pageSize <= 0) {
            throw new Error("pageSize cannot be 0 or less");
        }
        this.pageSize = params.pageSize;
        makeImmutable(this);
    }
    get maxItems() {
        return this.pageSize * 6;
    }
    getNext(currentItems, newItems) {
        const result = [...currentItems, ...newItems];
        // Trim from the left for performance reasons
        if (result.length > this.maxItems) {
            result.splice(0, result.length - this.maxItems);
        }
        return result;
    }
    getPrev(currentItems, newItems) {
        const result = [...newItems, ...currentItems];
        // Trim from the right for performance reasons
        if (result.length > this.maxItems) {
            result.splice(this.maxItems);
        }
        return result;
    }
}
