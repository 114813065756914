import { makeImmutable } from "../../core/makeImmutable";
export class OrderingPolicy {
    constructor(params) {
        this.maxOrderValue = params.maxOrderValue;
        this.deliveryInTotal = params.deliveryInTotal;
        this.enableCoupons = params.enableCoupons;
        this.enableFod = params.enableFod;
        this.enableMarketingConsents = params.enableMarketingConsents;
        makeImmutable(this);
    }
    validate({ value: priceToTest }) {
        if (this.maxOrderValue) {
            return priceToTest.leq(this.maxOrderValue);
        }
        return true;
    }
}
