import { translate, t } from "../../I18n";
import { makeAutoObservable } from "mobx";
import { toPairs } from "../../core";
import { CheckboxFieldController, ErrorBox, SelectableFieldController, } from "../../form";
import { DEFAULT_FULFILLMENT_TYPE, FulfillmentInstructions, } from "../../ordering";
import { ApartmentInfoController } from "./ApartmentInfoController";
import { DeliveryAddressFormController } from "./DeliveryAddressFormController";
export class FulfillmentMethodController {
    constructor(params) {
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.fulfillmentMethodTypes = params.fulfillmentMethodTypes;
        this.viewType = params.viewType;
        this.apartmentInfoController = ApartmentInfoController.create({
            apartmentInfo: params.deliveryAddress.apartmentInfo,
            onBlur: () => {
                params.deliveryAddress.save().then(() => void 0, () => void 0);
            },
        });
        this.deliveryAddressController = new DeliveryAddressFormController({
            deliveryAddress: params.deliveryAddress,
            onChange: () => {
                if (params.viewType === "Cart") {
                    this.apartmentInfoController.clear();
                }
            },
        });
        this.noContactDelivery = new CheckboxFieldController({
            initialValue: params.fulfillmentInstructions.fulfillmentMethodType ===
                "NoContactDelivery",
            onUpdate: (value) => {
                params.fulfillmentInstructions.fulfillmentMethodType = value
                    ? "NoContactDelivery"
                    : "Delivery";
            },
        });
        this.fulfillmentMethod = new SelectableFieldController({
            initialValue: params.fulfillmentInstructions.fulfillmentMethodType ===
                "NoContactDelivery" &&
                params.fulfillmentMethodTypes.includes("Delivery")
                ? "Delivery"
                : params.fulfillmentInstructions.fulfillmentMethodType,
            onUpdate: (value) => {
                if (value === "Delivery" &&
                    params.fulfillmentInstructions.fulfillmentMethodType ===
                        "NoContactDelivery") {
                    return;
                }
                params.fulfillmentInstructions.fulfillmentMethodType = value;
                // NOTICE: update no contact delivery checbox state to reflect the current value
                this.noContactDelivery.changeWithoutUpdate(value === "NoContactDelivery");
            },
            onClear: () => {
                params.fulfillmentInstructions.fulfillmentMethodType =
                    FulfillmentInstructions.getAvailableFulfillmentMethod(DEFAULT_FULFILLMENT_TYPE, this.fulfillmentMethodTypes);
            },
            options: this.fulfillmentMethodTypes.raw,
            errorBox: new ErrorBox({
                domainObjects: [this.fulfillmentInstructions],
                errorMessages: {
                    FulfillmentMethodTypeMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        makeAutoObservable(this);
    }
    get fields() {
        return {
            fulfillmentMethod: this.fulfillmentMethod,
            noContactDelivery: this.noContactDelivery,
            ...this.deliveryAddressController.fields,
            ...this.apartmentInfoController.fields,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const { fulfillmentMethodTypeAvailability, deliveryAvailability } = this.fulfillmentInstructions;
        return (fulfillmentMethodTypeAvailability.isAvailable.isDefinitelyFalse ||
            deliveryAvailability.isAvailable.isDefinitelyFalse);
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.fulfillmentInstructions;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
}
