/**
 * Service for phone number validation
 */
export class ValidatePhoneNumberService {
    constructor(params) {
        this.rpcService = params.rpcService;
    }
    /**
     * @returns is the provided phone number valid?
     */
    async validatePhoneNumber(value, country) {
        return await this.rpcService.rpc("ValidatePhoneNumberRequest", {
            country,
            phone: value,
        });
    }
}
