import { makeAutoObservable } from "mobx";
import { Availability, ProductInstanceCreatorPart, RSet, } from "../..";
import { ParameterScope, ProductInstancePartScope, } from "../ParameterScope";
import { SingleProductInstancePart, } from "../ProductInstanceParts";
import { ProductConfigCreator } from "./ProductConfigCreator";
export class SinglePartCreatorState {
    constructor(params) {
        this.isSplit = false;
        this.scope = ParameterScope.Uninitialized;
        this.productCategory = params.productCategory;
        this.commonConfigCreator = params.commonConfigCreator;
        this.partCreator = params.partCreator;
        makeAutoObservable(this);
    }
    static create({ productCategory, initialCommonConfig, part, }) {
        const commonConfigCreator = ProductConfigCreator.create({
            modifierTypes: part.productType.nonDivisibleModifierTypes,
            customParameterTypes: part.productType.nonDivisibleCustomParameterTypes,
            initialModifierInstances: initialCommonConfig.modifierInstances,
            initialCustomParameterInstances: initialCommonConfig.customParameterInstances,
            modifierSettings: (m) => m.defaultSettings,
        });
        const partCreator = ProductInstanceCreatorPart.create(part);
        return new SinglePartCreatorState({
            productCategory,
            commonConfigCreator,
            partCreator,
        });
    }
    initWithScope(parentScope) {
        const isSplitScope = new ProductInstancePartScope({
            parentScope,
            isSplit: this.isSplit,
        });
        this.commonConfigCreator.initWithScope(isSplitScope);
        this.scope = this.commonConfigCreator.scope;
        this.partCreator.initWithScope(this.scope);
    }
    get halvingAvailability() {
        return Availability.halving(this.productCategory.halvingPolicy, this.scope);
    }
    get isSplitPossible() {
        return this.halvingAvailability.isAvailable.isPossible;
    }
    get productTypeIds() {
        return RSet.singleton(this.partCreator.productTypeId);
    }
    get commonProductConfig() {
        return this.commonConfigCreator.productConfig;
    }
    get partProductConfig() {
        return this.partCreator.productConfigCreator.productConfig;
    }
    get productInstanceParts() {
        return new SingleProductInstancePart(this.partCreator.productInstancePart);
    }
    selectInferredDefaults() {
        this.commonConfigCreator.selectInferredDefaults();
        this.partCreator.selectInferredDefaults();
    }
}
