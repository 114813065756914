import { makeAutoObservable } from "mobx";
import { PurchaseOrderUnavailabilityDetails, } from "../ordering";
import * as PurchaseOrderPrice from "../pricing/PurchaseOrderPrice";
import { POrderLine } from "./POrderLine";
import { PPrice } from "./PPrice";
import { formatFulfillmentMethodType, formatFulfillmentMethodTypeShort, } from "./utils";
export class PPurchaseOrder {
    constructor(params) {
        this.domain = params.domain;
        this.restaurant = params.restaurant;
        this.orderingAvailability = params.orderingAvailability;
        this.localizationProvider = params.localizationProvider;
        makeAutoObservable(this);
    }
    get availability() {
        return this.domain.availability;
    }
    get productTypeIds() {
        return this.domain.payload.productTypeIds;
    }
    get error() {
        const unavailabilityDetails = new PurchaseOrderUnavailabilityDetails({
            localizationProvider: this.localizationProvider,
            purchaseOrder: this.domain,
            restaurant: this.restaurant,
        });
        return unavailabilityDetails.error;
    }
    get pPrice() {
        return new PPrice(this.localizationProvider, PurchaseOrderPrice.price(this.domain).spectrum);
    }
    get price() {
        return this.pPrice.priceValue;
    }
    get grandTotal() {
        return this.pPrice.grandPriceValue;
    }
    get finalPrice() {
        return PurchaseOrderPrice.price(this.domain).finalValue;
    }
    get validationPrice() {
        return this.domain.validationPrice;
    }
    get packagingPrice() {
        const packagingPrice = this.domain.packagingPrice;
        if (packagingPrice.isZero) {
            return null;
        }
        return this.localizationProvider.formatPrice(packagingPrice);
    }
    get deliveryPrice() {
        const deliveryPrice = PurchaseOrderPrice.deliveryPrice(this.domain);
        if (deliveryPrice.isZero) {
            return null;
        }
        return this.localizationProvider.formatPrice(deliveryPrice);
    }
    get orderLines() {
        return this.domain.orderLines.map((orderLine) => new POrderLine({
            domain: orderLine,
            restaurant: this.restaurant,
            localizationProvider: this.localizationProvider,
            scope: this.domain.scope,
        })).raw;
    }
    get canPlaceOrder() {
        return this.domain.canPlaceOrder;
    }
    get isReadyForCheckout() {
        return this.domain.isReadyForCheckout;
    }
    get isCheckoutFormValid() {
        return this.domain.isCheckoutFormValid;
    }
    get isAgreedToMarketingConsents() {
        return (this.domain.consents.subscribeEmail || this.domain.consents.subscribePhone);
    }
    addOrderLine(orderLine) {
        return this.domain.addOrderLine(orderLine);
    }
    get numItems() {
        return this.domain.numberOfItems.value;
    }
    clear() {
        this.domain.clear();
    }
    get contactDetailsLocked() {
        return this.domain.contactDetails.locked;
    }
    unlockContactDetails() {
        this.domain.contactDetails.unlock();
    }
    // NOTICE: NoContactDelivery method is not served as an option but as a checkbox inside "Delivery" form
    get fulfillmentMethods() {
        if (!this.restaurant.domain.fulfillmentMethods.includes("Delivery") &&
            this.restaurant.domain.fulfillmentMethods.includes("NoContactDelivery")) {
            return this.restaurant.domain.fulfillmentMethods.raw;
        }
        return this.restaurant.domain.fulfillmentMethods.raw.filter((method) => method !== "NoContactDelivery");
    }
    get fulfillmentMethodOptions() {
        return this.fulfillmentMethods.map((method) => ({
            value: method,
            label: formatFulfillmentMethodType(method),
        }));
    }
    get fulfillmentMethodOptionsShort() {
        return this.fulfillmentMethods.map((method) => ({
            value: method,
            label: formatFulfillmentMethodTypeShort(method),
        }));
    }
    get paymentMedium() {
        return this.domain.payment.medium;
    }
    checkoutData(params) {
        return this.domain.checkoutData(params);
    }
    get checkoutLoggingData() {
        return this.domain.checkoutLoggingData;
    }
    get instanceCounts() {
        return this.domain.orderLines.map((line) => ({
            instance: line.productInstance,
            quantity: line.quantity.value,
        }));
    }
    get isPaymentRestored() {
        return this.domain.payment.isRestored;
    }
    get menuOrderOrigin() {
        return this.domain.origin.menuOrderOrigin;
    }
}
