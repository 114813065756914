import { RArray, RSet, UnknownElementError } from "../../collections";
import { DateRange, RDate } from "../../core";
import { makeImmutable } from "../../core/makeImmutable";
import { Money } from "../Money";
import { InternalParameterSet, ParameterSet } from "../ParameterSet";
import { ParameterCombination, Parametrization } from "../Parametrization";
import { Percentage } from "../Percentage";
import { PositiveQuantity } from "../PositiveQuantity";
import { ProductPromotionSelector, } from "../ProductPromotionSelector";
import { ProductPromotionScope, WholeOrderPromotionScope, } from "../PromotionScope";
import { PromotionFreebie, PromotionType, PromotionTypeId, } from "../PromotionType";
import { WeeklySchedule } from "../WeeklySchedule";
import { WholeOrderPromotionSelector, } from "../WholeOrderPromotionSelector";
import { UnsupportedWholeOrderPromotionSelector } from "./UnsupportedWholeOrderPromotionSelector";
const AVAILABLE_AT_TO_FULFIMENT_METHOD = {
    Everywhere: "Everywhere",
    DeliveryOnly: RArray.singleton("Delivery"),
    RestaurantOnly: RArray.singleton("DineIn"),
    TakeawayOnly: RArray.singleton("Takeaway"),
    DeliveryAndTakeaway: new RArray(["Delivery", "Takeaway"]),
    RestaurantAndTakeaway: new RArray(["DineIn", "Takeaway"]),
};
export class PromotionParser {
    constructor(params) {
        this.productTypeIds = params.menu.productTypes.ids;
        this.productCategoryIds = params.menu.productCategories.ids;
        this.customParameterTypes = params.menu.customParameterTypes;
        this.menuConsumer = params.menuConsumer;
        this.timezone = params.timezone;
        makeImmutable(this);
    }
    parse(promotion) {
        if (!promotion.enabledMenuConsumers.some((menuConsumer) => menuConsumer === this.menuConsumer)) {
            return null;
        }
        if (promotion.promotionType === "InformationOnly") {
            // NOTICE InformationOnly promotions don't influence ordering in any way
            return null;
        }
        try {
            return new PromotionType({
                id: new PromotionTypeId(promotion.id),
                autoselect: promotion.enableAutoselect,
                availabilityParametrization: this.parseAvailableAt(promotion.availableAt),
                availabilitySchedule: WeeklySchedule.fromWeeklyAvailability(promotion.availability),
                effect: this.parseEffect(promotion.prize),
                scope: this.parseScope(promotion.conditions),
                combinesWith: RSet.fromIterable(promotion.combineTo.contents.map((promotionTypeId) => new PromotionTypeId(promotionTypeId))),
                requiresMarketing: promotion.requiresMarketing,
                period: DateRange.fromDates({
                    begin: RDate.fromLocalTimeString(promotion.start, this.timezone).startOfDay(),
                    end: RDate.fromLocalTimeString(promotion.finish, this.timezone).endOfDay(),
                }),
            });
        }
        catch (error) {
            // FIXME Filter invalid promotions in higgs and remove this catch
            if (error instanceof UnknownElementError ||
                error instanceof UnsupportedWholeOrderPromotionSelector) {
                return null;
            }
            throw error;
        }
    }
    parseAvailableAt(availableAt) {
        const fulfillmentMethods = AVAILABLE_AT_TO_FULFIMENT_METHOD[availableAt];
        if (fulfillmentMethods === "Everywhere") {
            return Parametrization.empty(true);
        }
        return Parametrization.fromParameterCombinations(fulfillmentMethods.map((fulfillmentMethod) => new ParameterCombination({
            premise: new ParameterSet({
                internal: new InternalParameterSet({
                    fulfillmentMethod,
                    splitToHalves: null,
                    orderOrigin: null,
                }),
            }),
            value: true,
        })));
    }
    parseEffect(prize) {
        switch (prize.tag) {
            case "Freeby":
                return {
                    type: "Freebie",
                    freebies: new RArray(prize.contents).map((f) => new PromotionFreebie({
                        name: f.productName,
                        value: Money.fromBackend(f.productValue),
                    })),
                };
            case "PercentageDiscount":
                return prize.contents > 0
                    ? {
                        type: "PercentageDiscount",
                        discount: Percentage.fromNumber(prize.contents),
                    }
                    : {
                        type: "PercentageIncrease",
                        increase: Percentage.fromNumber(-prize.contents),
                    };
            case "PriceDiscount":
                return prize.contents > 0
                    ? {
                        type: "MonetaryDiscount",
                        discount: Money.fromBackend(prize.contents),
                    }
                    : {
                        type: "MonetaryIncrease",
                        increase: Money.fromBackend(-prize.contents),
                    };
            case "TextInformation":
                return { type: "TextInformation" };
            case "CouponValue":
                return { type: "CouponValue" };
        }
    }
    parseCartPromotionScope(cartScope) {
        switch (cartScope.tag) {
            case "CartValue": {
                const minimumValue = Money.fromBackend(cartScope.minCartValue);
                return WholeOrderPromotionSelector.minimumValue(minimumValue);
            }
            case "Always":
                return WholeOrderPromotionSelector.everyOrder();
            case "Never":
                return WholeOrderPromotionSelector.noOrder();
            case "HasCoupon":
            case "FirstOrder":
            case "Loyalty":
                throw new UnsupportedWholeOrderPromotionSelector(cartScope);
        }
    }
    parseScope(conditions) {
        switch (conditions.tag) {
            case "CartPromotion": {
                const { contents } = conditions;
                return new WholeOrderPromotionScope({
                    selector: this.parseCartPromotionScope(contents),
                });
            }
            case "ProductPromotion": {
                const { contents } = conditions;
                return new ProductPromotionScope({
                    selector: ProductPromotionSelector.composite(contents.productFilter.map((f) => this.parseSelector(f))),
                    repeatable: contents.repeatable,
                    requiredItems: new PositiveQuantity(contents.numRequiredItems),
                });
            }
        }
    }
    parseSelector(filter) {
        switch (filter.tag) {
            case "ItemIdFilter": {
                const filterIds = new RArray(filter.contents).mapOptional((id) => {
                    switch (id.tag) {
                        case "ComboItem":
                            throw new Error("ComboItems are not supported");
                        case "ProductItem":
                            return this.productTypeIds.rawFind(id.contents);
                    }
                });
                return ProductPromotionSelector.ofProductTypes(RSet.fromIterable(filterIds));
            }
            case "ProductCategoryIdFilter": {
                const categories = RSet.fromIterable(filter.contents.map((c) => this.productCategoryIds.rawGet(c)));
                return ProductPromotionSelector.fromCategories(categories);
            }
            case "ParameterValueFilter": {
                const customParameterType = this.customParameterTypes.rawGet(filter.contents[0]);
                const allowedChoices = new RArray(filter.contents[1]).mapOptional((choiceId) => customParameterType.choices.rawFind(choiceId));
                return ProductPromotionSelector.withCustomParameters(customParameterType.id, RSet.fromIterable(allowedChoices.map((choice) => choice.id)));
            }
            case "AnyComboFilter": {
                throw new Error("AnyComboFilter is not supported");
            }
        }
    }
}
