import { makeImmutable } from "../core/makeImmutable";
export class PModifierItemType {
    constructor(params) {
        this.domain = params.domain;
        this._name = params.name;
        this.photo = params.photo;
        makeImmutable(this);
    }
    get id() {
        return this.domain.id;
    }
    get name() {
        return this._name.value;
    }
    getNameWithQuantity(quantity) {
        return quantity > 1 ? `${quantity}x ${this.name}` : this.name;
    }
}
