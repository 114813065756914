import { observer } from "mobx-react";
import { h } from "preact";
import CreatorDesc from "./CreatorDesc";
import CreatorDetails from "./CreatorDetails";
import Divider from "../../components/Divider";
import CreatorForm from "./CreatorForm";
import CreatorTitle from "./CreatorTitle";
import Alert from "../../components/Alert";
import { translate } from "restaumatic-client";
function CreatorBody(props) {
    const { creator } = props;
    const isSinglePart = creator.state.type === "SinglePartCreator";
    const showDetails = isSinglePart;
    const showDesc = isSinglePart;
    return (h("div", { class: "m-product-card__body modal-body js-popover-container" },
        showDetails && (h(CreatorDetails, { tags: creator.product.tags, freeModifierItemsCount: creator.product.freeModifierItemsCount, productCartCreator: creator, productAvailabilityInfo: creator.state.part.productAvailabilityInfo })),
        h(CreatorTitle, { title: creator.product.name, tags: creator.product.tags, isSinglePart: isSinglePart }),
        showDesc && (h(CreatorDesc, { description: creator.product.description, baseIngredients: creator.product.baseIngredients })),
        creator.temporarilyUnavailableIngredients.alerts.map(({ modifierTypeName, unavailableItemNames }) => unavailableItemNames.map((itemName) => (h(Alert, { type: "warning", message: translate("_core_types_sites.menu.availabilityValidation.modifierItemUnavailable", { itemName }) })))),
        h(Divider, null),
        h(CreatorForm, { creator: creator })));
}
export default observer(CreatorBody);
