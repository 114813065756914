import StickyHeader from "./StickyHeader";
const stickyNavSelector = ".js-sticky-nav";
const stickyNavs = document.querySelectorAll(stickyNavSelector);
/**
 * Set top value to sticky navigation dynamically if sticky header is in affixed state.
 */
function setOffsetToStickyNav() {
    if (!stickyNavs.length)
        return;
    document.addEventListener("affixed.restaumatic.sticky-header", () => {
        stickyNavs.forEach((nav) => {
            // 10px as a safe top gap value from sticky header to sticky navigation
            nav.style.top = `${StickyHeader.utils.getStickyHeaderHeight() + 10}px`;
        });
    });
}
/**
 * Sticky Navigation.
 */
export default {
    init: () => {
        setOffsetToStickyNav();
    },
};
