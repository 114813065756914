import { translate, t } from "../../I18n";
import { makeAutoObservable } from "mobx";
import { toPairs } from "../../core";
import { CheckboxFieldController, ErrorBox } from "../../form";
export class ConsentsFormController {
    constructor(params) {
        this.consents = params.consents;
        this.tosAgreement = new CheckboxFieldController({
            initialValue: this.consents.tosAgreement,
            onUpdate: (value) => (this.consents.tosAgreement = value),
            errorBox: new ErrorBox({
                domainObjects: [this.consents],
                errorMessages: {
                    TermsOfServiceNotAccepted: translate(t.activerecord.errors.models.user.attributes.tos_agreement.invalid),
                },
            }),
        });
        this.subscribeEmail = new CheckboxFieldController({
            initialValue: this.consents.subscribeEmail,
            onUpdate: (value) => {
                this.consents.subscribeEmail = value;
                this.updateMarketingValue();
            },
        });
        this.subscribePhone = new CheckboxFieldController({
            initialValue: this.consents.subscribePhone,
            onUpdate: (value) => {
                this.consents.subscribePhone = value;
                this.updateMarketingValue();
            },
        });
        this.marketingConsent = new CheckboxFieldController({
            initialValue: this.consents.subscribeEmail || this.consents.subscribePhone,
            onUpdate: (value) => {
                this.subscribeEmail.change(value);
                this.subscribePhone.change(value);
            },
        });
        this.subscribeRestaumaticEmail = new CheckboxFieldController({
            initialValue: this.consents.subscribeRestaumaticEmail,
            onUpdate: (value) => {
                this.consents.subscribeRestaumaticEmail = value;
                this.updateMarketingRestaumaticValue();
            },
        });
        this.subscribeRestaumaticPhone = new CheckboxFieldController({
            initialValue: this.consents.subscribeRestaumaticPhone,
            onUpdate: (value) => {
                this.consents.subscribeRestaumaticPhone = value;
                this.updateMarketingRestaumaticValue();
            },
        });
        this.marketingRestaumaticConsent = new CheckboxFieldController({
            initialValue: this.consents.subscribeRestaumaticEmail ||
                this.consents.subscribeRestaumaticPhone,
            onUpdate: (value) => {
                this.subscribeRestaumaticEmail.change(value);
                this.subscribeRestaumaticPhone.change(value);
            },
        });
        makeAutoObservable(this);
    }
    updateMarketingValue() {
        this.marketingConsent.changeWithoutUpdate(this.consents.subscribeEmail || this.consents.subscribePhone);
    }
    updateMarketingRestaumaticValue() {
        this.marketingRestaumaticConsent.changeWithoutUpdate(this.consents.subscribeRestaumaticEmail ||
            this.consents.subscribeRestaumaticPhone);
    }
    get fields() {
        return {
            tosAgreement: this.tosAgreement,
            marketingConsent: this.marketingConsent,
            subscribeEmail: this.subscribeEmail,
            subscribePhone: this.subscribePhone,
            marketingRestaumaticConsent: this.marketingRestaumaticConsent,
            subscribeRestaumaticEmail: this.subscribeRestaumaticEmail,
            subscribeRestaumaticPhone: this.subscribeRestaumaticPhone,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.consents.availability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.consents;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
}
