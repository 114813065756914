import { OnlineContactFormController } from "./OnlineContactFormController";
import { QrWaiterContactFormController } from "./QrWaiterContactFormController";
export const ContactFormController = {
    fromContactDetails(contactDetails) {
        switch (contactDetails.type) {
            case "Online":
                return new OnlineContactFormController({ contactDetails });
            case "QRWaiter":
                return new QrWaiterContactFormController({ contactDetails });
        }
    },
};
