import { makeImmutable } from "../../core";
export class SectionVisibilityService {
    constructor(schedulesAvailableNow) {
        this.schedulesAvailableNow = schedulesAvailableNow;
        makeImmutable(this);
    }
    isSectionVisible(visibilityScheduleId) {
        if (visibilityScheduleId === null) {
            return true;
        }
        return this.schedulesAvailableNow.includes(visibilityScheduleId);
    }
}
