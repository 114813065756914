import TinyQueue from "tinyqueue";
import { PackagingContainerInstance } from "../PackagingContainerInstance";
import { Packing } from "../Packing";
import { makeImmutable } from "../../core/makeImmutable";
const productVolumeComparator = (a, b) => a.productVolume.compare(b.productVolume);
/**
 * Packing algorithm that packs the modifiers and product optimally.
 */
export class PackingAlgorithm {
    constructor(params) {
        this.packagingContainerTypes = params.packagingContainerTypes;
        makeImmutable(this);
    }
    get containerTypesByVolume() {
        return this.packagingContainerTypes.objects.sorted((a, b) => a.capacity.compare(b.capacity));
    }
    makeContainer(item) {
        const availableContainerTypes = this.containerTypesByVolume.filtered((containerType) => containerType.canContain(item.subject));
        if (availableContainerTypes.isEmpty) {
            return null;
        }
        const largestAvailableContainerType = availableContainerTypes.last;
        return new PackagingContainerInstance({
            packagingContainerType: largestAvailableContainerType,
        });
    }
    pack(items) {
        const itemsToPack = new TinyQueue(items.objects.raw, productVolumeComparator);
        const packing = Packing.empty();
        while (itemsToPack.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const itemToPack = itemsToPack.pop();
            const result = packing.pack(itemToPack);
            if (result.type === "packed") {
                continue;
            }
            const nextContainer = this.makeContainer(result.remainder);
            if (nextContainer) {
                packing.addContainer(nextContainer);
                itemsToPack.push(result.remainder);
            }
            else {
                packing.unpackable(result.remainder);
            }
        }
        return packing;
    }
}
