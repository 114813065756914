import { makeImmutable } from "../../core";
import { Availability } from "../Availability";
export class UserNote {
    constructor(params) {
        this.value = params.value;
        makeImmutable(this);
    }
    static empty() {
        return new UserNote({ value: null });
    }
    get isEmpty() {
        return this.value === null;
    }
    get availability() {
        return Availability.boolean({
            MaxLengthExceeded: UserNote.MAX_LENGTH !== null &&
                this.value !== null &&
                this.value.length > UserNote.MAX_LENGTH,
        });
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        var _a;
        return (_a = this.value) !== null && _a !== void 0 ? _a : null;
    }
}
UserNote.MAX_LENGTH = 255;
