import { makeAutoObservable } from "mobx";
import { Availability } from "../Availability";
import { ParameterScope } from "../ParameterScope";
/*
  Parametrized modifier type availability class to optimize unification - calculated once for all ModifierItemInstances
*/
export class ModifierInstanceTypeAvailability {
    constructor(modifierType) {
        this.scope = ParameterScope.Uninitialized;
        this.modifierType = modifierType;
        makeAutoObservable(this);
    }
    setScope(scope) {
        this.scope = scope;
    }
    get availability() {
        const modifierTypeAvailability = Availability.ofModifierType(this.modifierType, this.scope);
        return Availability.dependent("ModifierType", this.modifierType.id.value, modifierTypeAvailability);
    }
    get isDefinitelyUnavailable() {
        return this.availability.isAvailable.isDefinitelyFalse;
    }
    clone() {
        return new ModifierInstanceTypeAvailability(this.modifierType);
    }
}
