import { RArray } from "../../collections";
import { makeImmutable } from "../../core";
export class PromotionSolverVariables {
    constructor(params) {
        this.cardinalityConstraints = params.cardinalityConstraints;
        this.exclusionConstraints = params.exclusionConstraints;
        this.values = params.values;
        this.enabledVariablesCount = params.enabledVariablesCount;
        this.equivalenceClassCount = params.equivalenceClassCount;
        this.equivalenceClasses = params.equivalenceClasses;
        makeImmutable(this);
    }
    get size() {
        return this.values.length;
    }
    at(variable) {
        return this.values[variable];
    }
    static empty(params) {
        return new PromotionSolverVariables({
            values: new Array(params.variableCount).fill(false),
            enabledVariablesCount: 0,
            cardinalityConstraints: params.cardinalityConstraints,
            exclusionConstraints: params.exclusionConstraints,
            equivalenceClasses: params.equivalenceClasses,
            equivalenceClassCount: params.equivalenceClasses.reduce((a, b) => Math.max(a, b), 0),
        });
    }
    processConstraints(variable) {
        const result = [];
        for (const constraint of this.cardinalityConstraints) {
            const eliminated = constraint.eliminate(variable);
            if (eliminated.isFufilled) {
                continue;
            }
            const excluded = eliminated.exclude(this.exclusionConstraints[variable]);
            if (excluded.isWorthConsideration) {
                result.push(excluded);
                continue;
            }
            // NOTICE Constraint colision (enabling variable makes this constraint impossible to fufill)
            if (excluded.isActive) {
                return null;
            }
        }
        return new RArray(result);
    }
    enable(variable) {
        const cardinalityConstraints = this.processConstraints(variable);
        if (cardinalityConstraints === null) {
            return null;
        }
        const values = [...this.values];
        values[variable] = true;
        return new PromotionSolverVariables({
            values,
            enabledVariablesCount: this.enabledVariablesCount + 1,
            cardinalityConstraints,
            exclusionConstraints: this.exclusionConstraints,
            equivalenceClasses: this.equivalenceClasses,
            equivalenceClassCount: this.equivalenceClassCount,
        });
    }
    exclude(variables) {
        const cardinalityConstraints = this.cardinalityConstraints.mapOptional((constraint) => {
            const excluded = constraint.exclude(variables);
            if (excluded.isWorthConsideration) {
                return excluded;
            }
            return null;
        });
        return new PromotionSolverVariables({
            values: this.values,
            enabledVariablesCount: this.enabledVariablesCount,
            cardinalityConstraints,
            exclusionConstraints: this.exclusionConstraints,
            equivalenceClasses: this.equivalenceClasses,
            equivalenceClassCount: this.equivalenceClassCount,
        });
    }
    get areConstraintsMet() {
        // NOTICE We do not check exclusion constraints assuming that we are eliminating exclusions directly at `enable`
        return this.cardinalityConstraints.size === 0;
    }
    get disabled() {
        const usedEquivalenceClasses = new Array(this.equivalenceClassCount).fill(false);
        return this.cardinalityConstraints.raw
            .flatMap((constraint) => constraint.premise)
            .filter((variable) => {
            const equivalenceClass = this.equivalenceClasses[variable];
            if (usedEquivalenceClasses[equivalenceClass]) {
                return false;
            }
            else {
                usedEquivalenceClasses[equivalenceClass] = true;
                return true;
            }
        });
    }
    get label() {
        return String.fromCharCode.apply(null, this.values);
    }
}
