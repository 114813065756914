import { makeImmutable } from "../core/makeImmutable";
import { RMap } from "./RMap";
/*
 * Convenience associative container for domain objects with identifiers
 */
export class IdentifiableMap {
    constructor(raw) {
        this._raw = raw;
        makeImmutable(this);
    }
    get raw() {
        return this._raw;
    }
    static empty() {
        return new IdentifiableMap(RMap.empty());
    }
    static singleton(idKey, object) {
        return new IdentifiableMap(RMap.singleton(object[idKey], object));
    }
    static fromIterable(idKey, objects, options) {
        return new IdentifiableMap(RMap.fromIterable([...objects].map((object) => [object[idKey], object]), options));
    }
    static eq(idKey, a, b) {
        return (a.size === b.size &&
            a.objects.every((objA) => {
                const objB = b.find(objA[idKey]);
                if (objB === null) {
                    return false;
                }
                return objA.eq(objB);
            }));
    }
    [Symbol.iterator]() {
        return this._raw[Symbol.iterator]();
    }
    get size() {
        return this._raw.size;
    }
    get isEmpty() {
        return this._raw.isEmpty;
    }
    includes(id) {
        return this._raw.includes(id);
    }
    rawFind(key) {
        return this._raw.rawFind(key);
    }
    find(id) {
        return this._raw.find(id);
    }
    get(id) {
        return this._raw.get(id);
    }
    rawGet(key) {
        return this._raw.rawGet(key);
    }
    get ids() {
        return this._raw.keys;
    }
    get objects() {
        return this._raw.values;
    }
    union(other) {
        return new IdentifiableMap(this._raw.union(other._raw));
    }
    unionWith(other, merge) {
        return new IdentifiableMap(this._raw.unionWithKey(other._raw, (_, a, b) => merge(a, b)));
    }
    filter(predicate) {
        return new IdentifiableMap(this._raw.filterByValues(predicate));
    }
    partition(predicate) {
        const rawPartition = this._raw.partitionByValues(predicate);
        return {
            true: new IdentifiableMap(rawPartition.true),
            false: new IdentifiableMap(rawPartition.false),
        };
    }
    get sample() {
        return this._raw.sample;
    }
}
