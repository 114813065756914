import { makeAutoObservable } from "mobx";
import { toPairs } from "../../../core";
import { TextFieldController } from "../../../form";
export class DefaultApartmentInfoController {
    constructor(params) {
        this.apartmentInfo = params.apartmentInfo;
        const onBlur = () => {
            if (params.onBlur !== undefined) {
                params.onBlur();
            }
        };
        this.aptNumber = new TextFieldController({
            initialValue: this.apartmentInfo.data.aptNumber,
            onUpdate: (value) => this.apartmentInfo.setData({ aptNumber: value }),
            onBlur,
        });
        this.floor = new TextFieldController({
            initialValue: this.apartmentInfo.data.floor,
            onUpdate: (value) => this.apartmentInfo.setData({ floor: value }),
            onBlur,
        });
        if (!this.apartmentInfo.isEmpty) {
            this.submit();
        }
        makeAutoObservable(this);
    }
    get type() {
        return this.apartmentInfo.type;
    }
    get fields() {
        return {
            aptNumber: this.aptNumber,
            floor: this.floor,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.apartmentInfo.availability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.apartmentInfo;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
    clear() {
        this.aptNumber.clear();
        this.floor.clear();
    }
}
