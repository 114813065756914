import { makeAutoObservable } from "mobx";
import { OrderLineReference, ProductPromotionAssociation, PromotionSolverVariableAssignment, RArray, WholeOrderPromotionAssociation, } from "../..";
export class PromotionSolverVariableAssignmentFactory {
    constructor(params) {
        this.inCartPromotionTypes = params.inCartPromotionTypes;
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        makeAutoObservable(this);
    }
    createProductAssociation(promotionType, promotionScope) {
        const matchingOrderLines = this.purchaseOrderPayload.orderLines.mapOptional(({ productInstance, quantity }, orderLineIndex) => {
            if (promotionScope.selector.test(productInstance)) {
                return { productInstance, quantity: quantity.value, orderLineIndex };
            }
            return null;
        });
        const matchingProducts = matchingOrderLines.flatMap(({ quantity, productInstance, orderLineIndex }) => RArray.range(quantity).map((productInstanceIndex) => new OrderLineReference({
            productInstance,
            orderLineIndex,
            productInstanceIndex,
        })));
        if (matchingProducts.size >= promotionScope.requiredItems.value) {
            return new ProductPromotionAssociation({
                promotionType,
                matchingProducts,
            });
        }
        return null;
    }
    createWholeOrderAssociation(promotionType, promotionScope) {
        if (promotionScope.selector.test(this.purchaseOrderPayload)) {
            return new WholeOrderPromotionAssociation({ promotionType });
        }
        return null;
    }
    get assignment() {
        const promotionAssociations = this.inCartPromotionTypes.promotionTypes.objects.mapOptional((promotionType) => {
            const { scope: promotionScope } = promotionType;
            switch (promotionScope.kind) {
                case "Product":
                    return this.createProductAssociation(promotionType, promotionScope);
                case "WholeOrder":
                    return this.createWholeOrderAssociation(promotionType, promotionScope);
            }
        });
        return new PromotionSolverVariableAssignment({
            promotionAssociations,
            purchaseOrderPayload: this.purchaseOrderPayload,
        });
    }
}
