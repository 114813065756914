import { RMap } from "../../collections";
import { makeImmutable } from "../../core/makeImmutable";
export class ModifierSettings {
    constructor(params) {
        this.editable = params.editable;
        this.freeItems = params.freeItems;
        this._defaultItems = params.defaultItems;
        this.displayDefaultItems = params.displayDefaultItems;
        makeImmutable(this);
    }
    get customParameterTypes() {
        return this.editable.customParameterTypes;
    }
    get defaultItems() {
        return this._defaultItems || RMap.empty();
    }
    merge(productSettings) {
        return new ModifierSettings({
            editable: this.editable.combine(productSettings.editable, (a, b) => a && b),
            freeItems: productSettings.freeItems,
            defaultItems: productSettings._defaultItems || this._defaultItems,
            displayDefaultItems: productSettings.displayDefaultItems,
        });
    }
}
