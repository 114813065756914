import { makeAutoObservable } from "mobx";
export class OrderItemListData {
    constructor(initData) {
        this.data = initData;
        makeAutoObservable(this);
    }
    static empty() {
        return new OrderItemListData(new Map());
    }
    static fromStorageData(storageData) {
        const initData = new Map(storageData);
        return new OrderItemListData(initData);
    }
    get(trackingId) {
        var _a;
        return (_a = this.data.get(trackingId.value)) !== null && _a !== void 0 ? _a : null;
    }
    set(trackingId, list) {
        this.data.set(trackingId.value, list);
    }
    clear() {
        this.data.clear();
    }
    get storageData() {
        return Array.from(this.data.entries());
    }
}
