import { RArray, RMap } from "../../collections";
import { makeImmutable } from "../../core";
import { CrossSellingItemType, CrossSellingItemTypeId, } from "../CrossSellingItemType";
import { ProductInstance } from "../ProductInstance";
function assignUnknownWeights(input, unknownFraction) {
    const unknownCount = input.count(([_, weight]) => weight === null);
    const knownTotal = input
        .mapOptional(([_, weight]) => weight)
        .reduce((acc, weight) => acc + weight, 0);
    const knownScaleFactor = knownTotal > 0 ? (1.0 - unknownFraction) / knownTotal : 0;
    const weightForUnknown = unknownCount > 0 ? unknownFraction / unknownCount : 0;
    return input.map(([el, weight]) => weight === null ? [el, weightForUnknown] : [el, weight * knownScaleFactor]);
}
function random(seed) {
    var x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
}
function weightedRandomShuffle(sequenceNumber, input) {
    return input
        .map(([el, weight], index) => [
        el,
        -Math.pow(random(sequenceNumber + index), 1 / weight),
    ])
        .sorted(([, a], [, b]) => a - b)
        .map(([el]) => el);
}
export class AutomaticCrossSellingListProvider {
    constructor(params) {
        this.productCategoryIds = params.productCategoryIds;
        this.candidateCrossSellingItemTypes = params.candidateCrossSellingItemTypes;
        this.weights = params.weights;
        makeImmutable(this);
    }
    static create(params) {
        const productCategoryIds = RMap.fromIterable(params.menuProductTypes.objects.map((productType) => [
            productType.id,
            productType.productCategoryId,
        ]));
        const candidateCrossSellingProductTypes = params.menuProductTypes.filter((productType) => params.allowedProductCategories.includes(productType.productCategoryId));
        const candidateCrossSellingItemTypes = RMap.fromIterable(candidateCrossSellingProductTypes.objects.map((productType) => [
            productType.id,
            new CrossSellingItemType({
                id: new CrossSellingItemTypeId(productType.id.value),
                productInstance: ProductInstance.fromSingleProductType(productType),
                kind: "Automatic",
            }),
        ]));
        return new AutomaticCrossSellingListProvider({
            productCategoryIds,
            candidateCrossSellingItemTypes,
            weights: params.weights,
        });
    }
    items(inputProductTypeIds, sequenceNumber) {
        if (inputProductTypeIds.isEmpty) {
            return RArray.empty();
        }
        const inputCategoryIds = new RArray(inputProductTypeIds).map((productTypeId) => this.productCategoryIds.get(productTypeId));
        const inputWeightSources = inputCategoryIds.mapOptional((productCategoryId) => this.weights.find(productCategoryId));
        const weightedCandidatesWithMissingWeights = new RArray(this.candidateCrossSellingItemTypes.entries).map(([productTypeId, crossSellingItemType]) => {
            const weight = inputWeightSources
                .mapOptional((inputWeightSource) => inputWeightSource.find(productTypeId))
                .reduce((acc, curr) => acc + curr, 0);
            return [crossSellingItemType, weight > 0 ? weight : null];
        });
        const weightedCandidates = assignUnknownWeights(weightedCandidatesWithMissingWeights, 0.2);
        return weightedRandomShuffle(sequenceNumber, weightedCandidates);
    }
}
