import { observer } from "mobx-react";
import { Fragment, h } from "preact";
import AvailabilityDebugInfo from "../../components/AvailabilityDebugInfo";
import CreatorCustomParameter from "./CreatorCustomParameter";
import CreatorModifier from "./CreatorModifier";
function CreatorConfig(props) {
    const { fieldIdPrefix, config } = props;
    return (h(Fragment, null,
        h(AvailabilityDebugInfo, { fieldId: `${fieldIdPrefix}productConfig.availability`, name: "ProductConfig", availability: config.availability }),
        config.customParameterInstances.map((customParameterInstance) => (h(CreatorCustomParameter, { key: customParameterInstance.instanceId.value, fieldIdPrefix: fieldIdPrefix, customParameterInstance: customParameterInstance }))),
        config.modifierInstances.map((modifierInstance) => (h(CreatorModifier, { key: modifierInstance.instanceId.value, fieldIdPrefix: fieldIdPrefix, modifierInstance: modifierInstance })))));
}
export default observer(CreatorConfig);
