import { makeAutoObservable } from "mobx";
import { IdMap } from "../../collections";
import { Availability } from "../Availability";
import { ParameterScope } from "../ParameterScope";
import { promotionEffectPrice, } from "../PromotionType";
import { Price } from "../../pricing/Price";
export class WholeOrderPromotionInstance {
    constructor(params) {
        this.kind = "WholeOrder";
        this.id = params.id;
        this.promotionType = params.promotionType;
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        this._active = params.active;
        this._freebie = params.freebie;
        this.scope = ParameterScope.Uninitialized;
        this.excludedInstances = IdMap.empty();
        makeAutoObservable(this);
    }
    static fromStorageData(params) {
        const { purchaseOrderPayload, id, storageData } = params;
        return new WholeOrderPromotionInstance({
            id,
            promotionType: params.promotionType,
            active: storageData.active,
            purchaseOrderPayload,
            freebie: params.freebie,
        });
    }
    setScope(scope) {
        this.scope = scope;
    }
    setExcludedInstances(excluded) {
        this.excludedInstances = excluded;
    }
    get availability() {
        const selector = this.promotionType.scope
            .selector;
        return Availability.composite([
            Availability.ofPromotionType(this.promotionType, this.scope),
            Availability.dependent("PurchaseOrderPayload", `${this.id.toString()}`, this.purchaseOrderPayload.availability),
            Availability.boolean({
                UnmetSelector: !selector.test(this.purchaseOrderPayload),
            }),
        ]);
    }
    get active() {
        return this._active;
    }
    setActive(active) {
        this._active = active;
        if (active) {
            this.excludedInstances.objects.forEach((excludedInstance) => excludedInstance.setActive(false));
        }
    }
    get freebie() {
        return this._freebie;
    }
    setFreebie(freebie) {
        this._freebie = freebie;
    }
    get value() {
        if (!this.active) {
            return Price.free();
        }
        return promotionEffectPrice(this.promotionType.effect, this.purchaseOrderPayload.price, this.freebie);
    }
    get checkoutData() {
        const value = this.value.finalValue;
        return {
            promotionId: this.promotionType.id.value,
            promotionalItemId: this.id.value,
            source: [],
            input: this.freebie === null
                ? { tag: "NoInput" }
                : { tag: "FreeProductInput", selection: this.freebie.name },
            active: this.active,
            assignment: null,
            value: value ? value.asNumber : null,
        };
    }
    get storageData() {
        return {
            kind: "WholeOrderPromotionInstance",
            promotionTypeId: this.promotionType.id.value,
            active: this.active,
            freebie: this.freebie === null ? null : this.freebie.storageData,
        };
    }
}
