// Utilities (replacement of a lodash subset)
import { RArray } from "../collections";
export function fromPairs(pairs) {
    const result = {};
    for (const [k, v] of pairs) {
        result[k] = v;
    }
    return result;
}
export function toPairs(r) {
    return new RArray(Object.entries(r));
}
export const cartesianProduct = (parameters) => new RArray(parameters.reduce((acc, [param, options]) => options.reduce((res, option) => [
    ...res,
    ...acc.map((item) => new Map([...item, [param, option]])),
], []), [new Map()]));
