import { observer } from "mobx-react";
import { h } from "preact";
import { t, translate } from "restaumatic-client";
import PreactTooltip from "./PreactTooltip";
const AvailableIcons = {
    Vegetarian: {
        label: translate(t.dish_settings.vegetarian),
        iconName: "svg-icon-vegetarian",
        iconSvg: `<g fill="currentColor"><path d="M9.999 1.058c4.931 0 8.942 4.012 8.942 8.942 0 4.93-4.012 8.941-8.942 8.941-4.93 0-8.941-4.012-8.941-8.941 0-4.931 4.011-8.942 8.941-8.942m0-1.058C4.477 0 0 4.477 0 10c0 5.522 4.477 10 9.999 10C15.522 20 20 15.522 20 10 20 4.477 15.522 0 9.999 0z"/><path d="M7.017 6.546C4.58 7.96 4.719 10.263 4.803 11.115c3.148-3.729 7.856-3.548 7.856-3.548s-6.675 2.289-8.635 6.88c-.154.363.727.833.928.406.601-1.277 1.437-2.234 1.437-2.234 1.234.459 3.368.997 4.881-.067 2.01-1.414 1.805-4.55 4.673-6.075.671-.359-5.624-1.848-8.926.069zm0 0"/></g>`,
    },
    GlutenFree: {
        label: translate(t.dish_settings.gluten_free),
        iconName: "svg-icon-gluten-free",
        iconSvg: `<g fill="currentColor"><path d="M10.523 14.026l2.686-.294 2.271 2.267.537-.534-2.273-2.266.295-2.678-6.54-6.522-.537.534.862.86-.189 1.715-2.189-2.184-.536.534L7.1 7.642l-1.719.189-.862-.86-.537.534 6.541 6.521zm-.393-4.432l.189-1.713 1.121 1.116-.189 1.714-1.121-1.117zm.584 1.652l-1.719.188-1.119-1.116 1.718-.188 1.12 1.116zM7.786 8.327l1.121 1.116-1.719.189-1.12-1.116 1.718-.189zm.726-2.248l1.12 1.117-.19 1.713-1.12-1.117.19-1.713zm4.547 6.435l-1.121-1.116.188-1.714 1.121 1.116-.188 1.714zm-2.257.721l-1.12-1.116 1.718-.188 1.121 1.116-1.719.188zm1.719-.187"/><path d="M10 0C4.461 0-.029 4.477-.029 10c0 5.522 4.49 10 10.029 10s10.029-4.479 10.029-10c0-5.523-4.49-10-10.029-10zm0 18.939a8.942 8.942 0 0 1-5.95-2.258l4.142-4.13-.752-.75-4.141 4.131A8.884 8.884 0 0 1 1.032 10c0-4.93 4.023-8.941 8.968-8.941 2.281 0 4.365.855 5.949 2.259L11.855 7.4l.752.749 4.094-4.081a8.881 8.881 0 0 1 2.268 5.933c0 4.93-4.024 8.938-8.969 8.938z"/></g>`,
    },
    LactoseFree: {
        label: translate(t.dish_settings.lactose_free),
        iconName: "svg-icon-lactose-free",
        iconSvg: `<g fill="currentColor"><path d="M10 0C4.477 0 0 4.477 0 10c0 5.522 4.477 10 10 10 5.522 0 10-4.478 10-10 0-5.523-4.478-10-10-10zm0 18.941a8.905 8.905 0 0 1-5.938-2.264l2.659-2.656-.003-1.495-3.405 3.401A8.905 8.905 0 0 1 1.058 10c0-4.931 4.012-8.942 8.942-8.942 2.273 0 4.352.855 5.931 2.259l-3.243 3.228.501 1.016 3.493-3.493a8.906 8.906 0 0 1 2.26 5.934c-.001 4.928-4.012 8.939-8.942 8.939z"/><path d="M7.775 16.07h4.933a.57.57 0 0 0 .569-.569V9.874c0-.504-.163-1.265-.369-1.732l-.37-.836c-.145-.327-.44-.825-.59-1.071v-.03H8.534v.044c-.129.234-.396.729-.542 1.04l-.396.848c-.219.467-.39 1.23-.39 1.737v5.627a.57.57 0 0 0 .569.569zm0 0M8.534 5.067h3.414a.57.57 0 0 0 0-1.138H8.534a.57.57 0 0 0 0 1.138zm0 0"/></g>`,
    },
    SeaFood: {
        label: translate(t.dish_settings.sea_food),
        iconName: "svg-icon-sea-food",
        iconSvg: `<g fill="currentColor"><path d="M10 1.058c4.931 0 8.941 4.012 8.941 8.942 0 4.93-4.011 8.941-8.941 8.941-4.93 0-8.942-4.011-8.942-8.941 0-4.93 4.012-8.942 8.942-8.942M10 0C4.477 0 0 4.477 0 10c0 5.522 4.477 10 10 10 5.522 0 10-4.478 10-10 0-5.523-4.478-10-10-10z"/><path d="M4.089 9.695c.435.934-.702 1.498-1.017 2.158-.016.034-.015.059.001.074.184.196 2.42-.727 2.959-.998.162-.082.39-.251.477-.18.649.53 1.739 1.148 3.118 1.541.035.01.069.046.027.093l-.621.494s-.059.039.065.074c.142.039.454.077 1.109.189 1.239.211 2.467-.576 2.562-.587 1.167-.122 1.903-.518 1.903-.518.063-.027.054-.11.018-.127-.209-.174-.576-.333-.578-.528 0-.007.011-.039.025-.042.011-.002.029.004.057.017.027.012.72.272.817.302.363.109.528-.01.551-.006 1.084-.514 1.381-.883 1.381-1.433 0-.82-1.91-2.894-4.734-2.99-.124-.016-.187-.042-.247-.063l-.527-.188c-1.036-.369-2.232.033-2.52.126-.06.02-.014.059-.014.059l.581.406c.039.036.01.053-.012.06-1.356.436-2.384 1.218-3.09 1.658-.14.087-.352-.027-.352-.027s-1.218-.587-1.817-.988a6.913 6.913 0 0 0-.663-.403c-.913-.416-.273.611-.263.627l.004.007c.056.092.116.187.178.281v.001c.275.415.577.817.622.911zm10.566-.063a.364.364 0 0 1-.36-.367c0-.203.161-.367.36-.367s.36.164.36.367a.363.363 0 0 1-.36.367zm-1.611 2.087c-2.238-2.012-.327-3.522-.133-3.756.019-.023.02.006.02.006s-.545 1.002-.49 1.82a4.39 4.39 0 0 0 .635 1.937c.011.043-.011.012-.032-.007zm0 0"/></g>@`,
    },
    Vegan: {
        label: translate(t.dish_settings.vegan),
        iconName: "svg-icon-vegan",
        iconSvg: `<g fill="currentColor"><path d="M10 1.059c4.93 0 8.942 4.011 8.942 8.942 0 4.93-4.012 8.941-8.942 8.941S1.058 14.931 1.058 10c0-4.93 4.011-8.941 8.942-8.941M10 0C4.477 0 0 4.477 0 10c0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.478-10-10-10z"/><path d="M15.239 6.478h-1.185l.927-.927a.365.365 0 1 0-.518-.517l-.927.927V4.776a.364.364 0 1 0-.73 0v1.305c-1.051-.682-2.285-.721-2.994-.013a8.871 8.871 0 0 0-.48.555l.738.738a.365.365 0 1 1-.516.516l-.68-.68a54.803 54.803 0 0 0-1.33 1.771l.976.977a.364.364 0 0 1-.258.623.366.366 0 0 1-.259-.107l-.888-.889c-.193.276-.386.557-.574.837-2.438 3.641-2.295 4.564-1.897 4.962.751.752 2.977-.636 3.936-1.232l-.576-.577a.365.365 0 1 1 .517-.517l.69.69a51.728 51.728 0 0 0 2.434-1.702l-.54-.539a.365.365 0 1 1 .517-.517l.613.613c.86-.654 1.513-1.189 1.713-1.389.431-.431.604-1.057.486-1.762a3.245 3.245 0 0 0-.496-1.232h1.302a.366.366 0 0 0 .366-.366.367.367 0 0 0-.367-.363"/></g>`,
    },
    Fit: {
        label: translate(t.dish_settings.fit),
        iconName: "svg-icon-fit",
        iconSvg: `<g fill="currentColor"><path d="M10 1.058c4.931 0 8.941 4.012 8.941 8.942 0 4.93-4.011 8.941-8.941 8.941-4.93 0-8.942-4.011-8.942-8.941 0-4.93 4.012-8.942 8.942-8.942M10 0C4.477 0 0 4.477 0 10c0 5.522 4.477 10 10 10 5.522 0 10-4.478 10-10 0-5.523-4.478-10-10-10z"/><path d="M12.469 10.973a.3.3 0 0 1-.222-.098l-.615-.672-1.358 2.944a.301.301 0 0 1-.273.175l-.013-.001a.3.3 0 0 1-.27-.198L8.396 9.46l-.604 1.335a.3.3 0 0 1-.274.177H5.167c.857 1.476 2.427 2.952 4.669 4.393a.3.3 0 0 0 .325 0c2.243-1.439 3.813-2.917 4.671-4.393h-2.363zm0 0"/><path d="M7.324 10.371l.829-1.834a.3.3 0 0 1 .286-.177.299.299 0 0 1 .271.198l1.324 3.671 1.24-2.69a.301.301 0 0 1 .496-.077l.832.908h2.541c.562-1.249.487-2.236.31-2.867a2.999 2.999 0 0 0-3.442-2.124c-.766.138-1.39.544-1.804 1.174a3.005 3.005 0 0 0-.207.374 2.992 2.992 0 0 0-.207-.373c-.413-.63-1.036-1.036-1.802-1.174a2.998 2.998 0 0 0-3.442 2.124c-.179.631-.253 1.618.308 2.867h2.467zm0 0"/></g>`,
    },
    Spicy: {
        label: translate(t.dish_settings.spicy1),
        iconName: "svg-icon-spicy-1",
        iconSvg: `<g fill="currentColor"><path d="M10 1.058c4.93 0 8.942 4.012 8.942 8.942 0 4.93-4.012 8.941-8.942 8.941-4.931 0-8.942-4.012-8.942-8.941C1.059 5.069 5.07 1.058 10 1.058M10 0C4.478 0 0 4.477 0 10c0 5.522 4.478 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10z"/><path d="M15.443 5.552a.607.607 0 1 0-.846.868c.002.002.201.232.182.674-.009.195-.067.482-.252.853.194.108.374.237.532.393a2.4 2.4 0 0 1 .371.472c.344-.571.538-1.128.561-1.665.042-.992-.487-1.537-.548-1.595zm0 0M13.15 8.41c-.808 0-1.664.402-2.132 1.055-2.396 3.341-4.82 3.513-6.095 3.513-.146 0-.276-.003-.39-.003-.444 0-.633.034-.465.366.398.785 2.212 1.748 4.44 1.748 1.794 0 3.858-.625 5.665-2.469.911-.93 1.249-2.793.318-3.704-.356-.35-.839-.506-1.341-.506zm0 0"/></g>`,
    },
    VerySpicy: {
        label: translate(t.dish_settings.spicy2),
        iconName: "svg-icon-spicy-2",
        iconSvg: `<g fill="currentColor"><path d="M10 1.058c4.93 0 8.941 4.012 8.941 8.942 0 4.93-4.011 8.941-8.941 8.941-4.931 0-8.942-4.012-8.942-8.941 0-4.931 4.012-8.942 8.942-8.942M10 0C4.477 0 0 4.477 0 10c0 5.522 4.477 10 10 10 5.522 0 10-4.478 10-10 0-5.523-4.478-10-10-10z"/><path d="M15.442 5.552a.605.605 0 1 0-.846.868c.002.002.201.232.183.674-.009.195-.067.482-.253.853.194.108.375.237.533.393a2.4 2.4 0 0 1 .371.472c.344-.571.537-1.128.561-1.665.041-.992-.487-1.537-.549-1.595zm0 0M13.149 8.41c-.807 0-1.663.402-2.131 1.055-2.396 3.341-4.82 3.513-6.095 3.513-.146 0-.276-.003-.39-.003-.444 0-.633.034-.465.366.397.785 2.211 1.748 4.439 1.748 1.795 0 3.858-.625 5.665-2.469.911-.93 1.249-2.793.318-3.704-.355-.35-.838-.506-1.341-.506zm0 0"/><path d="M5.563 11.374c-.045-.59.065-1.929 1.824-2.683 0 0-.653 1.58.232 2.379.641-.501 1.093-1.319 1.139-2.11.133-2.281-1.46-3.482-2.494-4.018a.277.277 0 0 0-.38.365c.4.849.668 2.366-1.041 4.133-.522.539-.097 1.531.72 1.934zm0 0"/></g>`,
    },
    SuperSpicy: {
        label: translate(t.dish_settings.spicy3),
        iconName: "svg-icon-spicy-3",
        iconSvg: `<g fill="currentColor"><path d="M10 1.058c4.931 0 8.941 4.012 8.941 8.942 0 4.93-4.011 8.941-8.941 8.941-4.93 0-8.942-4.012-8.942-8.941 0-4.931 4.012-8.942 8.942-8.942M10 0C4.477 0 0 4.477 0 10c0 5.522 4.477 10 10 10 5.522 0 10-4.478 10-10 0-5.523-4.478-10-10-10z"/><path d="M15.442 5.552a.605.605 0 1 0-.846.868c.002.002.201.232.183.674-.009.195-.067.482-.253.853.194.108.375.237.533.393a2.4 2.4 0 0 1 .371.472c.344-.571.537-1.128.561-1.665.041-.992-.487-1.537-.549-1.595zm0 0M13.149 8.41c-.807 0-1.663.402-2.131 1.055-2.396 3.341-4.82 3.513-6.095 3.513-.146 0-.276-.003-.39-.003-.444 0-.633.034-.465.366.397.785 2.211 1.748 4.439 1.748 1.795 0 3.858-.625 5.665-2.469.911-.93 1.249-2.793.318-3.704-.355-.35-.838-.506-1.341-.506zm0 0"/><path d="M9.074 5.599a.168.168 0 0 0-.196.028.168.168 0 0 0-.036.194c.199.424.344 1.121-.182 1.951-.357-1.526-1.56-2.397-2.397-2.83a.277.277 0 0 0-.38.365c.4.849.668 2.366-1.041 4.133-.521.539-.096 1.53.721 1.934-.045-.59.065-1.929 1.824-2.683 0 0-.653 1.58.232 2.379.641-.501 1.093-1.319 1.139-2.11.003-.051 0-.099.002-.148.137-.327.421-.679 1-.927 0 0-.398.963.141 1.45.392-.305.667-.804.695-1.286.08-1.392-.892-2.124-1.522-2.45z"/></g>`,
    },
};
function ProductTagsIcons(props) {
    const { tags, tooltipPlacement = "auto" } = props;
    const icons = tags.map((tag) => AvailableIcons[tag]).filter((v) => v);
    return icons.length > 0 ? (h("span", { class: "dish-icons" }, icons.map((icon) => (h(PreactTooltip, { key: icon.iconName, title: icon.label, placement: tooltipPlacement, class: "dish-icons__list-item" },
        h("svg", { class: icon.iconName, style: "width: 20px; height: 20px" },
            h("svg", { xmlns: "http://www.w3.org/2000/svg\\", height: "20px", viewBox: "0 0 20 20", width: "20px", fill: "currentColor", 
                // TODO: is this good solution to display icons?
                dangerouslySetInnerHTML: { __html: icon.iconSvg } }))))))) : null;
}
export default observer(ProductTagsIcons);
