import { RSet } from "../../collections";
import { makeImmutable } from "../../core/makeImmutable";
import { Tristate } from "../../core/Tristate";
/*
 * Availabity composing strataegy
 */
export class CompositeAvailability {
    constructor(childAvailabilites) {
        this.availabilities = childAvailabilites;
        makeImmutable(this);
    }
    get unavailabilityReasons() {
        return this.availabilities.reduce((a, b) => a.union(b.unavailabilityReasons), RSet.empty());
    }
    get isAvailable() {
        const states = this.availabilities.map((a) => a.isAvailable);
        if (states.some((a) => a.isDefinitelyFalse)) {
            return Tristate.False;
        }
        if (states.every((a) => a.isDefinitelyTrue)) {
            return Tristate.True;
        }
        return Tristate.Maybe;
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitComposite(this);
    }
}
