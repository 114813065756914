import { makeImmutable } from "../../core/makeImmutable";
export class ProductPromotionScope {
    constructor(params) {
        this.kind = "Product";
        this.selector = params.selector;
        this.repeatable = params.repeatable;
        this.requiredItems = params.requiredItems;
        makeImmutable(this);
    }
}
