/**
 * Set cookie in browser by `document.cookie` object.
 *
 * @param {string} name Name of cookie.
 * @param {string } value Value of cookie.
 * @param {number} days Time before the cookie expires, expressed in days.
 * @returns {void} Result is setting cookie in browser
 */
export function setCookie(name, value, days) {
    const date = new Date();
    date.setDate(date.getDate() + days);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/; SameSite=Lax; Secure`;
}
/**
 * Get cookie from `document.cookie` object by name.
 *
 * @param {string} name Name of wanted cookie.
 * @returns {string} Value of found cookie
 */
export function getCookie(name) {
    let cookies = {};
    document.cookie.split(";").forEach((el) => {
        let [key, value] = el.split("=");
        cookies[key.trim()] = value;
    });
    return cookies[name];
}
