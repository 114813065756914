import { action, makeAutoObservable, reaction, runInAction } from "mobx";
import { Availability } from "../ordering";
/**
 * Phone number
 */
export class PhoneNumber {
    constructor(params) {
        this._dispose = null;
        this.value = params.value;
        this.country = params.country;
        this.validatePhoneService = params.validatePhoneService;
        this._status = { type: "NotRequested" };
        makeAutoObservable(this);
        this.initialize();
    }
    static empty(validatePhoneService) {
        return new PhoneNumber({
            value: "",
            country: null,
            validatePhoneService,
        });
    }
    get isEmpty() {
        return this.value.length === 0;
    }
    initialize() {
        if (this._dispose) {
            throw new Error("PhoneNumber has been already initialized");
        }
        this._dispose = reaction(() => this.value, () => {
            runInAction(() => {
                this._status = { type: "Loading" };
            });
            const getStatus = async () => {
                if (this.value.trim() === "" || this.country === null) {
                    return { type: "NoPhoneProvided" };
                }
                const isNumberValid = await this.validatePhoneService.validatePhoneNumber(this.value, this.country);
                return isNumberValid
                    ? { type: "PhoneValid" }
                    : { type: "PhoneInvalid" };
            };
            getStatus()
                .then(action((status) => (this._status = status)))
                .catch(action((error) => {
                this._status = { type: "NetworkError" };
                console.log("Phone number validatin request failed", error);
            }));
        }, { delay: 1000, fireImmediately: true });
    }
    // FIXME: dispose is not called when the component is unmounted
    dispose() {
        if (this._dispose) {
            this._dispose();
            this._dispose = null;
        }
    }
    get availability() {
        return Availability.boolean({
            PhoneMissing: this.value.length === 0,
            InvalidPhoneNumber: this._status.type === "PhoneInvalid",
        });
    }
}
