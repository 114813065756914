import { makeAutoObservable } from "mobx";
import { CustomParameterSet, ParameterSet } from "../ParameterSet";
export class ProductConfigScope {
    constructor(params) {
        this.parent = params.parentScope;
        this.customParameterInstances = params.customParameterInstances;
        makeAutoObservable(this);
    }
    get parameterSet() {
        const selfParameterSet = new ParameterSet({
            custom: CustomParameterSet.fromInstances(this.customParameterInstances),
        });
        const result = this.parent.parameterSet.union(selfParameterSet);
        if (result === null) {
            throw new Error("Incompatible parameter scopes");
        }
        return result;
    }
    get now() {
        return this.parent.now;
    }
    get fulfillmentTime() {
        return this.parent.fulfillmentTime;
    }
}
