import { IdMap } from "../../collections";
import { PackingItem } from "./PackingItem";
import { ModifierItemPackingSubject } from "./PackingSubject";
export function fromModifierItems(modifierType, items) {
    const res = items.mapOptional(([selectedItemId, quantity]) => {
        const itemVolume = modifierType.itemType(selectedItemId).volume;
        return itemVolume
            ? new PackingItem({
                subject: new ModifierItemPackingSubject({
                    modifierTypeId: modifierType.id,
                    modifierItemTypeId: selectedItemId,
                    volume: itemVolume,
                }),
                quantity,
            })
            : null;
    });
    return IdMap.fromIterable(res);
}
