import { makeAutoObservable } from "mobx";
import { PDescription } from "../PDescription";
import { PPrice } from "../PPrice";
import { PProductInstanceCreator } from "../PProductInstanceCreator";
import { CrossSellingItemInstancePrice } from "../../pricing/CrossSellingItemInstancePrice";
export class PCrossSellingItem {
    constructor(params) {
        this.domain = params.domain;
        this.behaviour = params.behaviour;
        this.localizationProvider = params.localizationProvider;
        this.restaurant = params.restaurant;
        this.product = params.product;
        makeAutoObservable(this);
    }
    static create(params) {
        const product = PProductInstanceCreator.create({
            domain: params.domain.itemCreator,
            orderingHours: params.orderingHours,
            localizationProvider: params.localizationProvider,
            restaurant: params.restaurant,
        }).product;
        return new PCrossSellingItem({
            domain: params.domain,
            behaviour: params.behaviour,
            localizationProvider: params.localizationProvider,
            restaurant: params.restaurant,
            product,
        });
    }
    get id() {
        return this.domain.typeId.value;
    }
    get name() {
        return this.product.name;
    }
    get allergens() {
        return this.product.allergens;
    }
    get description() {
        const orderLineDesc = PDescription.orderLine({
            productInstance: this.domain.productInstance,
            customParameterTypes: this.restaurant.customParameterTypes,
            modifierTypes: this.restaurant.modifierTypes,
            productTypes: this.restaurant.productTypes,
        });
        return [orderLineDesc, this.product.description].filter(Boolean).join(". ");
    }
    get image() {
        return this.product.photo;
    }
    get price() {
        const price = new PPrice(this.localizationProvider, CrossSellingItemInstancePrice.unitPrice(this.domain).spectrum);
        return price.priceValue === null ? "" : price.priceValue;
    }
    get crossSellingKind() {
        return this.domain.kind;
    }
}
