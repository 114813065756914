import { h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { translate, t } from "restaumatic-client";
function ShowMore(props) {
    const { containerClass, textClass, children } = props;
    const ref = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const btnText = isExpanded
        ? translate(t.theme_defaults.theme.show_less)
        : translate(t.theme_defaults.theme.show_more);
    useEffect(() => {
        const element = ref.current;
        // Check if the element is truncated - should display the "Show more" / Show less" button
        const resizeObserver = new ResizeObserver((entries) => {
            // Wrap it in requestAnimationFrame to avoid "ResizeObserver loop limit exceeded" errors
            window.requestAnimationFrame(() => {
                entries.forEach((entry) => {
                    const elVisibleHeight = Math.round(entry.contentRect.height);
                    const elFullHeight = entry.target.scrollHeight;
                    const isElementTruncated = elVisibleHeight < elFullHeight;
                    if (isElementTruncated) {
                        setIsActive(true);
                    }
                });
            });
        });
        if (element) {
            resizeObserver.observe(element, { box: "border-box" });
        }
        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        };
    }, []);
    return (h("p", { class: `m-show-more ${containerClass} ${isActive ? "is-active" : ""} ${isExpanded ? "is-expanded" : ""}` },
        h("span", { ref: ref, onClick: () => setIsExpanded(true), class: `m-show-more__text ${textClass}` }, children),
        isActive && (h("button", { type: "button", class: "u-btn-unstyled m-show-more__trigger", onClick: () => setIsExpanded(!isExpanded) }, btnText))));
}
export default ShowMore;
