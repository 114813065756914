import { makeImmutable } from "../core/makeImmutable";
export class PModifierItemSection {
    constructor(params) {
        this._name = params.name;
        this._items = params.items;
        makeImmutable(this);
    }
    get hasName() {
        return this._name !== null;
    }
    get name() {
        var _a;
        return (_a = this._name) === null || _a === void 0 ? void 0 : _a.value;
    }
    get items() {
        return this._items;
    }
}
