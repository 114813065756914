import { ParameterSet, } from "../ordering";
import { memo } from "../utils/Memo";
import { HalvesPricing } from "./HalvesPricing";
import { ModifierInstancePrice, } from "./ModifierInstancePrice";
import { Price } from "./Price";
export const ProductInstancePrice = {
    priceSelector,
    productInstanceKey,
    price,
};
function priceSelector(productInstance) {
    var _a, _b;
    return {
        config: {
            parameterSet: (_b = (_a = productInstance.commonConfig.modifierInstances.sample) === null || _a === void 0 ? void 0 : _a.scope.parameterSet) !== null && _b !== void 0 ? _b : ParameterSet.empty(),
            commonModifierInstancePriceParams: productInstance.commonConfig.modifierInstances.objects.map((modierInstance) => ModifierInstancePrice.priceSelector(modierInstance)).raw,
        },
        parts: productInstance.parts.type === "SinglePart"
            ? {
                type: "SinglePart",
                part: {
                    productTypeId: productInstance.parts.part.productTypeId.value,
                    pricing: productInstance.parts.part.productType.pricing,
                    parameterSet: productInstance.parts.part.scope.parameterSet,
                    modifierInstancePriceParams: productInstance.parts.part.productConfig.modifierInstances.objects.map((modierInstance) => ModifierInstancePrice.priceSelector(modierInstance)).raw,
                },
            }
            : {
                type: "Halves",
                divisiblePricePolicy: productInstance.parts.productCategory.divisiblePricePolicy,
                first: {
                    productTypeId: productInstance.parts.first.productTypeId.value,
                    pricing: productInstance.parts.first.productType.pricing,
                    parameterSet: productInstance.parts.first.scope.parameterSet,
                    modifierInstancePriceParams: productInstance.parts.first.productConfig.modifierInstances.objects.map((modierInstance) => ModifierInstancePrice.priceSelector(modierInstance)).raw,
                },
                second: {
                    productTypeId: productInstance.parts.second.productTypeId.value,
                    pricing: productInstance.parts.second.productType.pricing,
                    parameterSet: productInstance.parts.second.scope.parameterSet,
                    modifierInstancePriceParams: productInstance.parts.second.productConfig.modifierInstances.objects.map((modierInstance) => ModifierInstancePrice.priceSelector(modierInstance)).raw,
                },
            },
    };
}
function priceOriginal(params) {
    const partsPrice = params.parts.type === "Halves"
        ? halvesPrice(params.parts)
        : singlePartPrice(params.parts);
    const commonPrice = Price.total(params.config.commonModifierInstancePriceParams.map((modifierInstancePriceParams) => ModifierInstancePrice.price(modifierInstancePriceParams)));
    return partsPrice.add(commonPrice);
}
function parameterSetKey(parameterSet) {
    var _a, _b, _c, _d, _e, _f;
    return [
        `fulfillmentMethod=${(_b = (_a = parameterSet.internal.fulfillmentMethod) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "null"}`,
        `splitToHalves=${(_d = (_c = parameterSet.internal.splitToHalves) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : "null"}`,
        `orderOrigin=${(_f = (_e = parameterSet.internal.orderOrigin) === null || _e === void 0 ? void 0 : _e.toString()) !== null && _f !== void 0 ? _f : "null"}`,
    ]
        .concat(parameterSet.custom.choices.entries.map(([parameter, choiceId]) => "parameter_" + parameter.id.value + "=" + choiceId.toString()).raw)
        .join(":");
}
function productTypeIdKey(productTypeId) {
    return `productType_${productTypeId}`;
}
function modifierKey(modifierInstance) {
    return modifierInstance
        .map((modifier) => `modifier_${modifier.id}->` +
        modifier.modifierItemInstancesParams
            .map((item) => `${item.id}=${item.quantity}`)
            .raw.join("|"))
        .join(":");
}
function productInstanceKey(params) {
    return [
        `productInstance`,
        [
            parameterSetKey(params.config.parameterSet),
            modifierKey(params.config.commonModifierInstancePriceParams),
        ].join(","),
        params.parts.type === "SinglePart"
            ? [
                productTypeIdKey(params.parts.part.productTypeId),
                parameterSetKey(params.parts.part.parameterSet),
                modifierKey(params.parts.part.modifierInstancePriceParams),
            ].join(",")
            : [
                productTypeIdKey(params.parts.first.productTypeId),
                parameterSetKey(params.parts.first.parameterSet),
                modifierKey(params.parts.first.modifierInstancePriceParams),
                productTypeIdKey(params.parts.second.productTypeId),
                parameterSetKey(params.parts.second.parameterSet),
                modifierKey(params.parts.second.modifierInstancePriceParams),
            ].join(","),
    ].join(";");
}
function price(params) {
    return memo([productInstanceKey(params)], () => priceOriginal(params));
}
function halvesPrice(params) {
    const firstBasePrice = Price.scoped(params.first.pricing, params.first.parameterSet);
    const secondBasePrice = Price.scoped(params.second.pricing, params.second.parameterSet);
    const basePrice = new HalvesPricing(params.divisiblePricePolicy).price(firstBasePrice, secondBasePrice);
    const firstConfigPrice = Price.total(params.first.modifierInstancePriceParams.map((modifierInstance) => ModifierInstancePrice.price(modifierInstance)));
    const secondConfigPrice = Price.total(params.second.modifierInstancePriceParams.map((modifierInstance) => ModifierInstancePrice.price(modifierInstance)));
    const configPrice = firstConfigPrice.add(secondConfigPrice).half();
    return basePrice.add(configPrice);
}
function singlePartPrice(params) {
    const basePrice = Price.scoped(params.part.pricing, params.part.parameterSet);
    const configPrice = Price.total(params.part.modifierInstancePriceParams.map((modifierInstance) => ModifierInstancePrice.price(modifierInstance)));
    return basePrice.add(configPrice);
}
