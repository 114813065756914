import { makeImmutable } from "../core";
export class POrderingAvailability {
    constructor(params) {
        this.domain = params.domain;
        this.backendOrderingAvailability = params.backendOrderingAvailability;
        makeImmutable(this);
    }
    get orderingImpossible() {
        return !this.domain.availability.isAvailable.isDefinitelyTrue;
    }
    get reason() {
        switch (this.backendOrderingAvailability.tag) {
            case "OrderingImpossible":
                return this.backendOrderingAvailability.reason;
            case "OrderingPossible":
                // TODO Handle situation where backend says that ordering is possible but client logic does not allow it
                return "?";
        }
    }
}
