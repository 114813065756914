export class StringStorageSerializer {
    serializeForStorage(data) {
        return JSON.stringify(data);
    }
    deserializeFromStorage(data) {
        try {
            const value = JSON.parse(data);
            this.assertValid(value);
            return value;
        }
        catch (error) {
            return null;
        }
    }
    assertValid(data) {
        if (typeof data !== "string" || data === null) {
            throw new Error("Invalid string value");
        }
    }
}
