import { makeAutoObservable } from "mobx";
import { PModifierItemInstance } from "./PModifierItemInstance";
export class PModifierSectionInstance {
    constructor(params) {
        this.modifierItemSection = params.modifierItemSection;
        this.allItems = params.modifierItemInstances;
        makeAutoObservable(this);
    }
    static create({ domain, modifierItemSection, localizationProvider, }) {
        const modifierItemInstances = modifierItemSection.items.map((item) => new PModifierItemInstance({
            modifierItemType: item,
            domain: domain.items.get(item.id),
            behaviour: domain.behaviour,
            localizationProvider: localizationProvider,
        }));
        return new PModifierSectionInstance({
            modifierItemSection,
            modifierItemInstances,
        });
    }
    get touched() {
        return this.items.some((item) => item.touched);
    }
    get isVisible() {
        return this.items.length > 0;
    }
    get hasName() {
        return this.modifierItemSection.hasName;
    }
    get name() {
        return this.modifierItemSection.name;
    }
    get items() {
        return this.allItems.filter((item) => item.isVisible);
    }
}
