import { IdMap, RArray, RMap } from "../collections";
import { makeImmutable } from "../core";
export class PTemporarilyUnavailableIngredients {
    constructor(items) {
        this.items = items;
        makeImmutable(this);
    }
    static fromInstances(modifierType, modifierItemInstances) {
        const items = RMap.singleton(modifierType.id, {
            modifierType,
            unavailableItems: IdMap.fromIterable(new RArray(modifierItemInstances).mapOptional((modifierItemInstance) => {
                const modifierItemType = modifierItemInstance.modifierItemType;
                const availSpecType = modifierItemType.domain.availabilitySpec.type;
                const isDefault = modifierItemInstance.domain.isDefault;
                if (isDefault &&
                    (availSpecType === "TemporarilyUnavailable" ||
                        availSpecType === "HiddenModifierItem")) {
                    return modifierItemType;
                }
                return null;
            })),
        });
        return new PTemporarilyUnavailableIngredients(items);
    }
    get alerts() {
        return this.items.values.mapOptional(({ modifierType, unavailableItems }) => {
            if (unavailableItems.isEmpty) {
                return null;
            }
            return {
                modifierTypeName: modifierType.name,
                unavailableItemNames: unavailableItems.objects.map((itemType) => itemType.name).raw,
            };
        }).raw;
    }
    static empty() {
        return new PTemporarilyUnavailableIngredients(RMap.empty());
    }
    union(other) {
        const items = this.items.unionWithKey(other.items, (_, { modifierType, unavailableItems: itemsA }, { unavailableItems: itemsB }) => ({ modifierType, unavailableItems: itemsA.union(itemsB) }));
        return new PTemporarilyUnavailableIngredients(items);
    }
}
