import { makeImmutable } from "../core";
import { HiddenModifierItemAvailabilitySpec, ModifierItemType, ModifierItemTypeId, Money, Quantity, } from "../ordering";
import { VolumeParser } from "./VolumeParser";
export class ModifierItemTypeParser {
    constructor(params) {
        this.parametrizationParser = params.parametrizationParser;
        this.availabilitySpecParser = params.availabilitySpecParser;
        makeImmutable(this);
    }
    parse(id, item) {
        var _a;
        const modifierItemVolume = (_a = item.packagingContainersConfig) === null || _a === void 0 ? void 0 : _a.volume;
        return new ModifierItemType({
            id: new ModifierItemTypeId(id),
            pricing: this.parametrizationParser
                .parse(item.price)
                .map((value) => Money.fromBackend(value)),
            volume: modifierItemVolume
                ? VolumeParser.parse(modifierItemVolume)
                : null,
            availabilitySpec: item.availability === "Hidden"
                ? new HiddenModifierItemAvailabilitySpec()
                : this.availabilitySpecParser.parse(item.availability, item.availableWhen),
            defaultSelection: Quantity.fromNumber(item.defaultSelection),
            irremovable: item.irremovable,
        });
    }
}
