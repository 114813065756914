import { makeAutoObservable } from "mobx";
import { InternalParameterSet, ParameterSet } from "../ParameterSet";
export class ProductInstanceCreatorScope {
    constructor(parentScope) {
        this.parent = parentScope;
        makeAutoObservable(this);
    }
    get parameterSet() {
        return new ParameterSet({
            internal: new InternalParameterSet({
                fulfillmentMethod: null,
                orderOrigin: this.parent.parameterSet.internal.orderOrigin,
                splitToHalves: null,
            }),
        });
    }
    get now() {
        return this.parent.now;
    }
    get fulfillmentTime() {
        return null;
    }
    get activeSchedules() {
        return null;
    }
}
