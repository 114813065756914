import { RArray } from "../../collections";
import { makeImmutable } from "../../core";
/**
 * Service for getting information about the order checkout
 */
export class CheckoutInfoService {
    constructor(params) {
        this.rpcService = params.rpcService;
        this.localizationProvider = params.localizationProvider;
        makeImmutable(this);
    }
    /**
     * @returns basic information about the checkout
     */
    async getCheckoutInfo(restaurantId) {
        const checkoutInfo = await this.rpcService.rpc("GetCheckoutInfoRequest", {
            restaurantId: restaurantId.value,
            isQrWaiterOrder: false,
            isIosReviewMode: false,
            locale: this.localizationProvider.locale,
        });
        if (checkoutInfo === null) {
            throw new Error("Unable to load checkout info");
        }
        return {
            paymentMethods: new RArray(checkoutInfo.paymentMethods),
            fulfillmentMethods: new RArray(checkoutInfo.fulfillmentMethods),
            franchiseName: checkoutInfo.franchiseName,
        };
    }
    async getPaymentChannels(restaurantId) {
        const result = await this.rpcService.rpc("GetPaymentChannelsRequest", {
            restaurantId: restaurantId.value,
        });
        if (result === null) {
            console.log("Payment channels retrieval failed");
            return RArray.empty();
        }
        return new RArray(result.channels);
    }
}
