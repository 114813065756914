import { makeAutoObservable } from "mobx";
import { Availability } from "../Availability";
import { Email, PhoneNumber } from "../../form";
import { assertState } from "../../core/state";
export class EnabledFOD {
    constructor(params) {
        this.type = "Enabled";
        this.restaurantId = params.restaurantId;
        this.validatePhoneService = params.validatePhoneService;
        this.checkoutService = params.checkoutService;
        this.contactDetails = params.contactDetails;
        this.setIsClaimed = params.setIsClaimed;
        this._email = Email.Empty;
        this._phone = PhoneNumber.empty(params.validatePhoneService);
        this._state = {
            type: "Init",
            begin: () => this.setFormState(),
            isFinal: false,
        };
        makeAutoObservable(this);
    }
    get state() {
        return this._state;
    }
    get email() {
        return this._email;
    }
    set email(value) {
        assertState(this._state, "Form");
        this._email = value;
    }
    get phone() {
        return this._phone;
    }
    setPhone(value, country) {
        assertState(this._state, "Form");
        this._phone.value = value;
        this._phone.country = country;
    }
    setFormState() {
        assertState(this._state, "Init");
        this._state = {
            type: "Form",
            isLoading: false,
            error: null,
            isFinal: false,
            submit: () => this.submitForm(),
        };
    }
    submitForm() {
        assertState(this._state, "Form");
        if (this.hasError) {
            return;
        }
        this._state = { ...this._state, isLoading: true };
        this.checkoutService
            .claimFirstOrderDiscount({
            restaurantId: this.restaurantId.value,
            email: this.email.value,
            phone: this.phone.value,
            streetAddress: null,
        })
            .then((res) => {
            switch (res.type) {
                case "success":
                    this.setSuccessState();
                    break;
                case "failure":
                    this.setFailureState();
                    break;
                case "validation_failed":
                    assertState(this._state, "Form");
                    this._state = {
                        ...this._state,
                        isLoading: false,
                        error: res.errors[0],
                    };
                    break;
            }
        });
    }
    setSuccessState() {
        assertState(this._state, "Form");
        this._state = { type: "Success", isFinal: true };
        this.setIsClaimed(true);
        this.contactDetails.setData({ email: this._email, phone: this.phone });
    }
    setFailureState() {
        assertState(this._state, "Form");
        this._state = { type: "Failure", isFinal: true };
    }
    get hasError() {
        return this.availability.isAvailable.isDefinitelyFalse;
    }
    get availability() {
        return Availability.composite([
            this.email.availability,
            this.phone.availability,
        ]);
    }
}
