import { CategoryBasedProductSelector } from "./CategoryBasedProductSelector";
import { CompositeProductSelector } from "./CompositeProductSelector";
import { CustomParameterBasedProductSelector } from "./CustomParameterBasedProductSelector";
import { TypesBasedProductSelector } from "./TypesBasedProductSelector";
export const ProductPromotionSelector = {
    ofProductTypes(productTypeIds) {
        return new TypesBasedProductSelector(productTypeIds);
    },
    fromCategories(productCategoryIds) {
        return new CategoryBasedProductSelector(productCategoryIds);
    },
    withCustomParameters(customParameterTypeId, allowedChoices) {
        return new CustomParameterBasedProductSelector({
            customParameterTypeId,
            allowedChoices,
        });
    },
    composite(childSelectors) {
        return new CompositeProductSelector(childSelectors);
    },
};
