import { observer } from "mobx-react";
import { h } from "preact";
import NumericStepper from "../../components/NumericStepper";
import { t, translate } from "restaumatic-client";
function CreatorQuantityStepper(props) {
    const { creator } = props;
    const name = creator.product.name;
    const quantity = creator.quantity;
    return (h("fieldset", { "data-field-type": "info", "data-field-id": "Creator.quantityStepper", class: "m-form__group" },
        h("div", { class: "d-flex justify-content-center align-items-center" },
            h(NumericStepper, { quantity: quantity, canDecrement: creator.canDecrement, canIncrement: creator.canIncrement, onDecrement: () => creator.decrement(), onIncrement: () => creator.increment(), decrementFieldId: "Creator.decrement", incrementFieldId: "Creator.increment", quantityFieldId: "Creator.quantity", decrementAriaLabel: translate(t.theme_defaults.creator.stepper.decrease, {
                    name: name,
                    from: quantity.toString(),
                    to: Math.max(quantity - 1, 0).toString(),
                    price: "",
                }), incrementAriaLabel: translate(t.theme_defaults.creator.stepper.increase, {
                    name: name,
                    from: quantity.toString(),
                    to: (quantity + 1).toString(),
                    price: "",
                }) }))));
}
export default observer(CreatorQuantityStepper);
