import { h } from "preact";
function NumericStepper(props) {
    const { type = "static", size = "medium", quantity, canIncrement = true, incrementClass = "", incrementAriaLabel, incrementTestId, incrementFieldId, canDecrement = true, decrementClass = "", decrementAriaLabel, decrementTestId, decrementFieldId, quantityFieldId, onDecrement, onIncrement, } = props;
    const incrementButton = (h(NumericStepperButton, { key: "increment", type: "increment", className: incrementClass, onClick: onIncrement, ariaLabel: incrementAriaLabel, testId: incrementTestId, fieldId: incrementFieldId, disabled: !canIncrement, size: size }));
    if (type === "dynamic" && quantity === 0) {
        return h("div", { class: "m-numeric-stepper" }, incrementButton);
    }
    return (h("div", { class: "m-numeric-stepper" },
        h(NumericStepperButton, { key: "decrement", type: "decrement", className: decrementClass, onClick: onDecrement, ariaLabel: decrementAriaLabel, testId: decrementTestId, fieldId: decrementFieldId, disabled: !canDecrement, size: size }),
        h("span", { class: "m-numeric-stepper__count", "data-field-type": "info", "data-field-id": quantityFieldId }, quantity),
        incrementButton));
}
export function NumericStepperButton(props) {
    const { className, onClick, ariaLabel, testId, fieldId, disabled = false, size = "medium", } = props;
    const baseBtnClass = `m-numeric-stepper__btn ${size === "large" ? "m-numeric-stepper__btn--large" : ""}`;
    const iconClass = props.type === "decrement" ? "icon-minus" : "icon-plus";
    return (h("button", { type: "button", class: `${baseBtnClass} ${className}`, onClick: onClick, "aria-label": ariaLabel, "data-testid": testId, "data-field-id": fieldId, disabled: disabled },
        h("span", { "aria-hidden": "true", class: `icon-flex-center ${iconClass}` })));
}
export default NumericStepper;
