import { translate, t } from "../../I18n";
import { makeAutoObservable } from "mobx";
import { toPairs } from "../../core";
import { ErrorBox, TextFieldController } from "../../form";
export class StreetAddressFormController {
    // TODO: remove onChange?
    constructor(params) {
        this.streetAddress = params.streetAddress;
        this.street = new TextFieldController({
            initialValue: this.streetAddress.street,
            onUpdate: (str) => {
                this.streetAddress.street = str;
            },
            errorBox: new ErrorBox({
                domainObjects: [this.streetAddress],
                errorMessages: {
                    StreetMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        this.streetNumber = new TextFieldController({
            initialValue: this.streetAddress.streetNumber,
            onUpdate: (str) => {
                this.streetAddress.streetNumber = str;
            },
            errorBox: new ErrorBox({
                domainObjects: [this.streetAddress],
                errorMessages: {
                    StreetNumberMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        this.city = new TextFieldController({
            initialValue: this.streetAddress.city,
            onUpdate: (str) => {
                this.streetAddress.city = str;
            },
            errorBox: new ErrorBox({
                domainObjects: [this.streetAddress],
                errorMessages: {
                    CityMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        this.postCode = new TextFieldController({
            initialValue: this.streetAddress.postCode,
            onUpdate: (str) => {
                this.streetAddress.postCode = str;
            },
            errorBox: new ErrorBox({
                domainObjects: [this.streetAddress],
                errorMessages: {
                    PostCodeMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        // TODO: (re)move this?
        if (!this.streetAddress.isEmpty) {
            this.submit();
        }
        makeAutoObservable(this);
    }
    get fields() {
        return {
            street: this.street,
            streetNumber: this.streetNumber,
            city: this.city,
            postCode: this.postCode,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.streetAddress.availability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.streetAddress;
    }
    // TODO: create "required" flag for fields?
    get isPostCodeRequired() {
        return this.streetAddress.isPostCodeRequired;
    }
    // TODO: rename to blur?
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
}
