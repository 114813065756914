import { PromotionSolution } from "./PromotionSolution";
export class PromotionSolverAlgorithm {
    constructor(params) {
        this.limit = params.limit;
        this.initialSolution = new PromotionSolution(params.variables);
        this.bestSolution = null;
        this.testedSolutions = new Set();
    }
    testSolution(solution) {
        this.testedSolutions.add(solution.label);
        this.bestSolution = PromotionSolution.betterOf(this.bestSolution, solution);
        for (const next of solution) {
            if (this.testedSolutions.size < this.limit &&
                !this.testedSolutions.has(next.label) &&
                next.couldBeBetterThan(this.bestSolution)) {
                this.testSolution(next);
            }
        }
    }
    solve() {
        this.testSolution(this.initialSolution);
        console.log(`Solver used ${this.testedSolutions.size} of limit ${this.limit}. Solution: ${this.bestSolution === null ? "No" : "Yes"}`);
        return this.bestSolution;
    }
}
