import { makeAutoObservable, reaction, runInAction } from "mobx";
import { ParameterScope, fromPairs, toPairs, } from "../..";
import { IdMap } from "../../collections";
import { PromotionInstance, } from "../PromotionInstance";
import { PromotionSolver } from "../PromotionSolver/PromotionSolver";
import { PromotionSolverVariableAssignmentFactory } from "../PromotionSolver/PromotionSolverVariableAssignmentFactory";
export class PromotionScheme {
    constructor(params) {
        this.promotionSolverVariableAssignmentFactory =
            params.promotionSolverVariableAssignmentFactory;
        this._promotionInstances = params.promotionInstances;
        this.scope = ParameterScope.Uninitialized;
        this.recomputeExclusions();
        makeAutoObservable(this, {
            useSolver: false,
        });
    }
    static fromStorageData(params) {
        const promotionInstances = IdMap.fromIterable(toPairs(params.storageData).mapOptional(([instanceId, instanceStorageData]) => PromotionInstance.fromStorageData({
            promotionTypes: params.inCartPromotionTypes.promotionTypes,
            purchaseOrderPayload: params.purchaseOrderPayload,
            id: parseInt(instanceId),
            storageData: instanceStorageData,
        })));
        const promotionSolverVariableAssignmentFactory = new PromotionSolverVariableAssignmentFactory({
            inCartPromotionTypes: params.inCartPromotionTypes,
            purchaseOrderPayload: params.purchaseOrderPayload,
        });
        return new PromotionScheme({
            promotionSolverVariableAssignmentFactory,
            promotionInstances,
        });
    }
    setScope(scope) {
        this.scope = scope;
        this._promotionInstances.objects.forEach((promotionInstance) => promotionInstance.setScope(this.scope));
    }
    setPromotionInstances(promotionInstances) {
        runInAction(() => {
            this._promotionInstances = promotionInstances;
            this.recomputeExclusions();
            this._promotionInstances.objects.forEach((promotionInstance) => promotionInstance.setScope(this.scope));
        });
    }
    clear() {
        this._promotionInstances = IdMap.empty();
    }
    useSolver() {
        return reaction(() => this.promotionSolverVariableAssignmentFactory.assignment, (variableAssignment) => {
            console.log("Solver invoked because cart has changed");
            const solver = new PromotionSolver(variableAssignment);
            this.setPromotionInstances(solver.promotionInstances);
        }, {
            equals: (a, b) => a.eq(b),
            fireImmediately: this._promotionInstances.isEmpty,
        });
    }
    recomputeExclusions() {
        this._promotionInstances.objects.forEach((promotionInstanceA) => {
            const excludedInstances = this._promotionInstances.filter((promotionInstanceB) => PromotionInstance.excludes(promotionInstanceA, promotionInstanceB));
            promotionInstanceA.setExcludedInstances(excludedInstances);
        });
    }
    static empty(params) {
        const promotionSolverVariableAssignmentFactory = new PromotionSolverVariableAssignmentFactory({
            inCartPromotionTypes: params.inCartPromotionTypes,
            purchaseOrderPayload: params.purchaseOrderPayload,
        });
        return new PromotionScheme({
            promotionSolverVariableAssignmentFactory,
            promotionInstances: IdMap.empty(),
        });
    }
    get checkoutData() {
        return {
            promotionalItems: this.activePossiblePromotionInstances.objects.map((promotionInstance) => promotionInstance.checkoutData).raw,
            couponCodes: this.promotionSolverVariableAssignmentFactory.inCartPromotionTypes
                .checkoutData,
        };
    }
    get possiblePromotionInstances() {
        return this._promotionInstances.filter((promotionInstance) => promotionInstance.availability.isAvailable.isPossible);
    }
    get activePossiblePromotionInstances() {
        return this.possiblePromotionInstances.filter((promotionInstance) => promotionInstance.active);
    }
    get doesAnyActivePromotionRequireMarketingConsents() {
        return this.activePossiblePromotionInstances.objects.some((promotionInstance) => promotionInstance.promotionType.requiresMarketing);
    }
    disableMarketingPromotionInstances() {
        this._promotionInstances.objects.forEach((promotionInstance) => {
            if (promotionInstance.promotionType.requiresMarketing) {
                promotionInstance.setActive(false);
            }
        });
    }
    get storageData() {
        return fromPairs(this._promotionInstances.objects.map((promotionInstance) => [
            promotionInstance.id.value,
            promotionInstance.storageData,
        ]));
    }
}
