import SimpleLightbox from "simplelightbox";
const lightboxHash = "gallery";
/**
 * Check if location hash is #lightbox.
 *
 * @returns {boolean}
 */
function isHashLightbox() {
    const lightboxHashRegex = new RegExp(lightboxHash);
    return lightboxHashRegex.test(window.location.hash);
}
/**
 * Init SimpleLightbox library.
 */
function initSimpleLightbox() {
    let galleries = [];
    // When you want use SimpleLightbox all galleries must have "id" attribute started with "gallery"
    document.querySelectorAll("[id^='gallery-']").forEach((item) => {
        galleries.push(item);
    });
    galleries.forEach((item) => {
        const galleryItems = document.querySelectorAll(`#${item.id} a[data-lightbox]`);
        const gallery = new SimpleLightbox(`#${item.id} a[data-lightbox]`, {
            showCounter: false,
            history: false,
            fileExt: false,
            captionSelector: "self",
            swipeClose: false,
            loop: galleryItems.length > 1 ? true : false,
            overlayOpacity: 0.95,
            fadeSpeed: 150,
            animationSpeed: 150,
            scrollZoom: false,
        });
        window.addEventListener("hashchange", () => {
            if (!isHashLightbox()) {
                gallery.close();
            }
        });
        gallery.on("shown.simplelightbox", () => {
            if (!isHashLightbox()) {
                history.pushState(null, "", `${window.location.pathname}#${lightboxHash}`);
                // Set skip_group_scroll to true when entering gallery.
                // The closed.simplelightbox event doesn't fire when closing the gallery with the back button.
                // https://restaumatic.atlassian.net/browse/RS-6021
                Skubacz.configuration.menu.skip_group_scroll = true;
            }
        });
        gallery.on("closed.simplelightbox", () => {
            if (isHashLightbox()) {
                history.back();
            }
        });
    });
}
/**
 * Gallery.
 *
 * https://simplelightbox.com/
 */
export default {
    init: function () {
        initSimpleLightbox();
    },
};
