import { h } from "preact";
import { observer } from "mobx-react";
import { translate, t } from "restaumatic-client";
function CreatorConfigMessage(props) {
    const { error, isRequired, fieldId } = props;
    const fieldProps = fieldId
        ? { "data-field-id": fieldId, "data-field-type": "info" }
        : {};
    const errorMessage = error.message;
    const requiredMessage = isRequired
        ? translate(t._common.form.info.required)
        : null;
    const message = errorMessage || requiredMessage;
    if (!message) {
        return null;
    }
    return (h("p", { class: "help-block", ...fieldProps }, message));
}
export default observer(CreatorConfigMessage);
