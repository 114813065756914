import { FULL_DAY, TimeRange } from "../../core";
import { makeImmutable } from "../../core/makeImmutable";
// NOTICE Solution for case 00:00:00 - 23:59:00
const ROUNDING_DURATION = 60;
/**
 * Represents multiple periods of time, in a day, for example: "from 6:00 to 10:00 and from 12:30 to 15:00"
 */
export class DaySchedule {
    constructor(timeRanges) {
        this.timeRanges = timeRanges;
        makeImmutable(this);
    }
    static fromRanges(timeRanges) {
        if (timeRanges.length === 0) {
            return DaySchedule.Empty;
        }
        const sortedRanges = timeRanges.sort((a, b) => a.from.compare(b.from));
        const mergedRanges = [sortedRanges[0]];
        for (let i = 1; i < sortedRanges.length; ++i) {
            const current = sortedRanges[i];
            const lastMerged = mergedRanges[mergedRanges.length - 1];
            if (current.from.isAfter(lastMerged.to)) {
                mergedRanges.push(current);
            }
            else {
                mergedRanges[mergedRanges.length - 1] = lastMerged.extendEnd(current.to);
            }
        }
        const schedule = new DaySchedule(mergedRanges);
        const { duration } = schedule;
        if (duration > FULL_DAY) {
            throw new Error("Daily schedule cannot span more than one day");
        }
        if (duration <= ROUNDING_DURATION) {
            return DaySchedule.Empty;
        }
        if (duration >= FULL_DAY - ROUNDING_DURATION) {
            return DaySchedule.Full;
        }
        return schedule;
    }
    get duration() {
        return this.timeRanges.reduce((a, b) => a + b.duration, 0);
    }
    get isEmpty() {
        return this.duration === 0;
    }
    get isFull() {
        return this.duration === FULL_DAY;
    }
    includes(date) {
        return this.timeRanges.some((timeRange) => timeRange.includes(date));
    }
    intersection(other) {
        const result = [];
        let i = 0;
        let j = 0;
        while (i < this.timeRanges.length && j < other.timeRanges.length) {
            const thisRange = this.timeRanges[i];
            const otherRange = other.timeRanges[j];
            const intersection = thisRange.intersection(otherRange);
            if (!intersection.isEmpty) {
                result.push(intersection);
            }
            if (thisRange.to.isBefore(otherRange.to)) {
                i++;
            }
            else {
                j++;
            }
        }
        return new DaySchedule(result);
    }
    eq(other) {
        return (this.timeRanges.length === other.timeRanges.length &&
            this.timeRanges.every((value, index) => value.eq(other.timeRanges[index])));
    }
    union(other) {
        return DaySchedule.fromRanges([...this.timeRanges, ...other.timeRanges]);
    }
    get firstRange() {
        const firstRange = this.timeRanges[0];
        if (firstRange === undefined) {
            return null;
        }
        return firstRange;
    }
    get lastRange() {
        const lastRange = this.timeRanges[this.timeRanges.length - 1];
        if (lastRange === undefined) {
            return null;
        }
        return lastRange;
    }
    get maxSpan() {
        const { firstRange, lastRange } = this;
        if (firstRange !== null && lastRange !== null) {
            return firstRange.extendEnd(lastRange.to);
        }
        return null;
    }
    get first() {
        var _a, _b;
        return (_b = (_a = this.firstRange) === null || _a === void 0 ? void 0 : _a.from) !== null && _b !== void 0 ? _b : null;
    }
    get last() {
        var _a, _b;
        return (_b = (_a = this.lastRange) === null || _a === void 0 ? void 0 : _a.to) !== null && _b !== void 0 ? _b : null;
    }
    toString() {
        return this.timeRanges.map((timeRange) => timeRange.toString()).join(", ");
    }
}
DaySchedule.Empty = new DaySchedule([]);
DaySchedule.Full = new DaySchedule([TimeRange.FullDay]);
