const cache = new Map();
export function memo(mem, fn) {
    const key = mem.join(":");
    if (cache.has(key)) {
        //console.log("cache", key)
        return cache.get(key);
    }
    const val = fn();
    cache.set(key, val);
    return val;
}
export function memoWithComparing(mem, fn) {
    const key = mem.join(":");
    const val = fn();
    if (cache.has(key)) {
        const valFromCache = cache.get(key);
        if (!val.eq(valFromCache)) {
            console.error("Not equal");
        }
    }
    cache.set(key, val);
    return val;
}
