import { makeAutoObservable } from "mobx";
import { Availability } from "../Availability";
import { EMPTY_INVOICE_REQUEST_STORAGE_DATA, } from "../Invoice";
/**
 * Represents the customer making the order.
 */
export class OrderReceiver {
    constructor(params) {
        this.invoiceRequest = params.invoiceRequest;
        this.contactDetails = params.contactDetails;
        this.consents = params.consents;
        makeAutoObservable(this);
    }
    get invoiceAvailability() {
        return this.invoiceRequest
            ? this.invoiceRequest.availability
            : Availability.available();
    }
    get availability() {
        return Availability.composite([
            this.contactDetails.availability,
            this.consents.availability,
            this.invoiceAvailability,
        ]);
    }
    get invoiceRequestStorageData() {
        return this.invoiceRequest !== null
            ? this.invoiceRequest.storageData
            : EMPTY_INVOICE_REQUEST_STORAGE_DATA;
    }
    get checkoutData() {
        return {
            customer: {
                ...this.contactDetails.checkoutData,
                ...this.consents.checkoutData,
            },
            vatId: this.invoiceRequestStorageData.vatId,
        };
    }
}
