import { Price } from "../../pricing/Price";
export function promotionEffectPrice(promotionEffect, sourcePrice, selectedFreebie) {
    switch (promotionEffect.type) {
        case "MonetaryDiscount": {
            const discount = sourcePrice.min(Price.unscoped(promotionEffect.discount));
            return discount.negated;
        }
        case "MonetaryIncrease":
            return Price.unscoped(promotionEffect.increase);
        case "PercentageDiscount": {
            const discount = sourcePrice.min(sourcePrice.percentage(promotionEffect.discount));
            return discount.negated;
        }
        case "PercentageIncrease":
            return sourcePrice.percentage(promotionEffect.increase);
        case "Freebie":
            return selectedFreebie === null
                ? Price.free()
                : Price.unscoped(selectedFreebie.value);
        case "TextInformation":
        case "CouponValue":
            return Price.free();
    }
}
