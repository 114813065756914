import { makeImmutable } from "../../core/makeImmutable";
export class OrderCoupon {
    constructor(params) {
        this.code = params.code;
        this.source = params.source;
        this.promotion = params.promotion;
        makeImmutable(this);
    }
    get id() {
        return this.code;
    }
}
