import { makeAutoObservable } from "mobx";
import { Availability } from "../Availability";
/**
 * How and where should the order be fulfilled for Online order.
 */
export class OnlineFulfillmentInstructions {
    constructor(params) {
        this.type = "Online";
        this.deliveryAddress = params.deliveryAddress;
        this.fulfillmentMethodType = params.fulfillmentMethodType;
        this.fulfillmentTime = params.fulfillmentTime;
        this.userNote = params.userNote;
        makeAutoObservable(this);
    }
    get isDelivery() {
        return (this.fulfillmentMethodType === "Delivery" ||
            this.fulfillmentMethodType === "NoContactDelivery");
    }
    get fulfillmentTimeAvailability() {
        return this.fulfillmentTime !== null
            ? this.fulfillmentTime.availability
            : Availability.unavailable("FulfillmentTimeMissing");
    }
    get fulfillmentMethodTypeAvailability() {
        return Availability.boolean({
            FulfillmentMethodTypeMissing: this.fulfillmentMethodType === null,
        });
    }
    get deliveryAvailability() {
        return this.isDelivery
            ? this.deliveryAddress.availability
            : Availability.available();
    }
    get availability() {
        return Availability.composite([
            this.fulfillmentTimeAvailability,
            this.fulfillmentMethodTypeAvailability,
            this.deliveryAvailability,
            this.userNote.availability,
        ]);
    }
    get fulfillmentMethod() {
        if (this.fulfillmentMethodType === "NoContactDelivery") {
            return "Delivery";
        }
        return this.fulfillmentMethodType;
    }
    get fulfillmentMethodCheckoutData() {
        // TODO: default fulfillmentMethodType?
        if (!this.fulfillmentMethodType) {
            return {
                tag: "DineIn",
                location: null,
            };
        }
        switch (this.fulfillmentMethodType) {
            case "Delivery":
                return {
                    tag: this.fulfillmentMethodType,
                    deliveryAddress: this.deliveryAddress.checkoutData,
                    customDeliveryPrice: null,
                };
            case "NoContactDelivery":
                return {
                    tag: this.fulfillmentMethodType,
                    deliveryAddress: this.deliveryAddress.checkoutData,
                };
            case "DineIn":
                return {
                    tag: this.fulfillmentMethodType,
                    location: null,
                };
            case "Takeaway":
                return {
                    tag: this.fulfillmentMethodType,
                };
        }
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        return {
            fulfillmentMethod: this.fulfillmentMethodCheckoutData,
            requestedFulfillmentTime: this.fulfillmentTime !== null
                ? this.fulfillmentTime.checkoutData
                : null,
            userNote: this.userNote.checkoutData,
        };
    }
}
