import { IdMap, RArray } from "../collections";
import { CustomParameterChoice, CustomParameterChoiceId, CustomParameterType, CustomParameterTypeId, } from "../ordering";
export class CustomParameterTypeParser {
    parse(id, customParameter) {
        const choices = IdMap.fromIterable(new RArray(customParameter.choices).mapOptional(([customParameterChoiceId, parameterChoice]) => this.parseCustomParameterChoice(customParameterChoiceId, parameterChoice)));
        return new CustomParameterType({
            id: new CustomParameterTypeId(id),
            isDivisible: customParameter.divisible,
            choices,
            defaultChoice: this.defaultChoice(customParameter.defaultChoice, choices),
        });
    }
    defaultChoice(defaultChoice, choices) {
        const availableChoices = choices.filter((choice) => choice.availability === "Available").ids;
        if (defaultChoice !== null) {
            const choice = availableChoices.rawFind(defaultChoice);
            if (choice !== null) {
                return choice;
            }
        }
        if (availableChoices.size === 1) {
            return availableChoices.sample;
        }
        return null;
    }
    parseCustomParameterChoice(customParameterChoiceId, parameterChoice) {
        if (parameterChoice.availability === "Hidden") {
            return null;
        }
        return new CustomParameterChoice({
            id: new CustomParameterChoiceId(customParameterChoiceId),
            availability: parameterChoice.availability,
        });
    }
}
