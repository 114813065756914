import { Money } from "../ordering";
import { Price } from "./Price";
import { PromotionSchemePrice } from "./PromotionSchemePrice";
export function deliveryPrice(purchaseOrder) {
    if (purchaseOrder.validationPrice === null) {
        return Money.zero();
    }
    if (purchaseOrder.fulfillmentInstructions.fulfillmentMethod === "Delivery") {
        return purchaseOrder.delivery.price(purchaseOrder.validationPrice);
    }
    return Money.zero();
}
export function priceWithoutDelivery(purchaseOrder) {
    const packagingPrice = Price.unscoped(purchaseOrder.packagingPrice);
    return purchaseOrder.payload.price
        .add(packagingPrice)
        .add(PromotionSchemePrice.price(purchaseOrder.promotionScheme));
}
export function priceWithoutTip(purchaseOrder) {
    const calculatedDeliveryPrice = Price.unscoped(deliveryPrice(purchaseOrder));
    return priceWithoutDelivery(purchaseOrder).add(calculatedDeliveryPrice);
}
export function price(purchaseOrder) {
    const tipPrice = Price.unscoped(purchaseOrder.tip.value);
    return priceWithoutTip(purchaseOrder).add(tipPrice);
}
