import { h, Fragment } from "preact";
import { t, translate, } from "restaumatic-client";
import { observer } from "mobx-react";
import { useEffect, useRef } from "preact/hooks";
import Device from "../../utils/Device";
import PreactModal, { ModalHeader } from "../../components/PreactModal";
import Alert from "../../components/Alert";
import { RadioFieldOption } from "../../components/RadioField";
const MODAL_ID = "fulfillment-time";
function FulfillmentTimeModal(props) {
    const { isOpen, fulfillmentTime, productCardCreator, onSubmit, onShown, onHidden, } = props;
    const daysSliderRef = useRef(null);
    const hoursListRef = useRef(null);
    const navigationType = fulfillmentTime.navigationType;
    const hasNavigation = !fulfillmentTime.isTodayOnly;
    const paginationItemsCount = getPaginationItemsCount(Skubacz.configuration.theme_name);
    useEffect(() => {
        if (isOpen) {
            if (Device.screen.isMobileSize()) {
                if (productCardCreator) {
                    fulfillmentTime.initializeForCreator("InfiniteScroll", 14, productCardCreator);
                }
                else {
                    fulfillmentTime.initializeForMenu("InfiniteScroll", 14);
                }
            }
            else {
                if (productCardCreator) {
                    fulfillmentTime.initializeForCreator("Pagination", paginationItemsCount, productCardCreator);
                }
                else {
                    fulfillmentTime.initializeForMenu("Pagination", paginationItemsCount);
                }
            }
        }
    }, [isOpen]);
    return (h(PreactModal, { id: MODAL_ID, modalClass: "modal-dialog--full-height modal-dialog--scrollable", isOpen: isOpen, onShown: onShown, onHidden: onHidden },
        h(ModalHeader, { title: fulfillmentTime.isDelivery
                ? translate(t.order.delivery_time)
                : translate(t.order.pickup_time) }),
        h("div", { className: "modal-body u-pt0" },
            h("article", { className: "m-fulfillment-time" },
                hasNavigation && navigationType === "InfiniteScroll" && (h(InfiniteDaysSlider, { fulfillmentTime: fulfillmentTime, listRef: hoursListRef })),
                hasNavigation && navigationType === "Pagination" && (h(PaginationDaysSlider, { fulfillmentTime: fulfillmentTime, sliderRef: daysSliderRef, listRef: hoursListRef, itemsTargetCount: paginationItemsCount })),
                h(HoursList, { fulfillmentTime: fulfillmentTime, listRef: hoursListRef }))),
        h("div", { className: "modal-footer modal-footer--sticky" },
            h("button", { type: "button", onClick: onSubmit, className: "btn btn-primary btn-block", "data-field-id": "fulfillmentTime.submit", disabled: !fulfillmentTime.isValid }, translate(t.actions.save)))));
}
const PaginationDaysSlider = observer((props) => {
    const { fulfillmentTime, sliderRef, listRef, itemsTargetCount } = props;
    return (h("div", { id: "fulfillment-time-days-slider", className: "m-fulfillment-time__slider m-fulfillment-time__slider--pagination u-sticky-top", "aria-live": "polite", "aria-label": translate(t.orders.form.choose_date) },
        fulfillmentTime.hasPrevItems && (h("button", { type: "button", className: "m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--prev u-btn-unstyled", onClick: () => {
                var _a, _b;
                fulfillmentTime.getPrev();
                (_b = (_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.querySelector("button")) === null || _b === void 0 ? void 0 : _b.focus();
            }, "aria-label": translate(t.views.pagination.previous), "aria-controls": "fulfillment-time-days-slider" },
            h("i", { className: "icon-btn icon-left", "aria-hidden": "true" }))),
        h("ul", { ref: sliderRef, className: "m-fulfillment-time__slider-content u-list-unstyled", "aria-orientation": "horizontal" }, fulfillmentTime.dateItems.map((dateItem) => (h("li", { className: `m-fulfillment-time__slider-item ${fulfillmentTime.dateItems.length < itemsTargetCount
                ? "u-flex-grow-0"
                : ""}` },
            h("button", { type: "button", onClick: () => {
                    var _a;
                    fulfillmentTime.setActiveDate(dateItem);
                    (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.focus({ preventScroll: true });
                }, className: `m-fulfillment-time__day u-btn-unstyled ${fulfillmentTime.isActiveDate(dateItem) ? "is-active" : ""} ${fulfillmentTime.isDisabledItem(dateItem) ? "is-disabled" : ""}` },
                h("span", { className: "u-text-bold u-mb1" }, dateItem.title),
                h("span", { className: "u-text-nowrap" },
                    dateItem.description,
                    dateItem.type === "Conflicting" && (h("i", { className: "icon-btn icon-alert-sign text-warning u-ml1", "aria-hidden": "true" })))))))),
        fulfillmentTime.hasNextItems && (h("button", { type: "button", className: "m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--next u-btn-unstyled", onClick: () => {
                var _a, _b;
                fulfillmentTime.getNext();
                (_b = (_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.querySelector("button")) === null || _b === void 0 ? void 0 : _b.focus();
            }, "aria-label": translate(t.views.pagination.next), "aria-controls": "fulfillment-time-days-slider" },
            h("i", { className: "icon-btn icon-right", "aria-hidden": "true" })))));
});
const InfiniteDaysSlider = observer((props) => {
    const { fulfillmentTime, listRef } = props;
    return (h("div", { id: "fulfillment-time-days-slider", className: "m-fulfillment-time__slider m-fulfillment-time__slider--infinite u-sticky-top", "aria-live": "polite", "aria-label": translate(t.orders.form.choose_date) },
        h("ul", { className: "m-fulfillment-time__slider-content u-list-unstyled", "aria-orientation": "horizontal" },
            fulfillmentTime.hasPrevItems && (h("li", { className: "align-self-center" },
                h("button", { type: "button", className: "m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--prev u-btn-unstyled", onClick: () => {
                        fulfillmentTime.getPrev();
                    }, "aria-label": translate(t.views.pagination.previous), "aria-controls": "fulfillment-time-days-slider" },
                    h("i", { className: "icon-btn icon-left", "aria-hidden": "true" })))),
            fulfillmentTime.dateItems.map((dateItem) => (h("li", { className: "m-fulfillment-time__slider-item" },
                h("button", { type: "button", onClick: () => {
                        var _a;
                        fulfillmentTime.setActiveDate(dateItem);
                        (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.focus({ preventScroll: true });
                    }, className: `m-fulfillment-time__day u-btn-unstyled ${fulfillmentTime.isActiveDate(dateItem) ? "is-active" : ""} ${fulfillmentTime.isDisabledItem(dateItem) ? "is-disabled" : ""}` },
                    h("span", { className: "u-text-bold u-mb1" }, dateItem.title),
                    h("span", { className: "u-text-nowrap" },
                        dateItem.description,
                        dateItem.type === "Conflicting" && (h("i", { className: "icon-btn icon-alert-sign text-warning u-ml1", "aria-hidden": "true" }))))))),
            fulfillmentTime.hasNextItems && (h("li", { className: "align-self-center" },
                h("button", { type: "button", className: "m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--next u-btn-unstyled", onClick: () => {
                        fulfillmentTime.getNext();
                    }, "aria-label": translate(t.views.pagination.next), "aria-controls": "fulfillment-time-days-slider" },
                    h("i", { className: "icon-btn icon-right", "aria-hidden": "true" })))))));
});
const HoursList = observer((props) => {
    const { fulfillmentTime, listRef } = props;
    // Scroll to selected hour item on first render (when modal shown)
    useEffect(() => {
        var _a;
        const selectedHourItem = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(".m-radio.is-active");
        if (selectedHourItem) {
            setTimeout(() => {
                selectedHourItem.scrollIntoView({
                    behavior: "instant",
                    block: "center",
                });
            }, 150); // 0.15s is a hardcoded value in boostrap 3 styles for modal fade transition duration
        }
    }, []);
    // Scroll to selected hour item when active date change
    useEffect(() => {
        var _a;
        const selectedHourItem = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(".m-radio.is-active");
        if (selectedHourItem) {
            selectedHourItem.scrollIntoView({
                behavior: "instant",
                block: "center",
            });
        }
    }, [fulfillmentTime.activeDate]);
    return (h("ul", { ref: listRef, className: "m-fulfillment-time__list u-list-unstyled", "aria-live": "polite", "aria-label": translate(t.orders.form.choose_time), tabIndex: -1, "data-field-id": "fulfillmentTime.value", "data-field-type": "radioGroup" },
        fulfillmentTime.dateTimeItems.map((item) => (h("li", { "data-field-id": "fulfillmentTime." + item.stringValue },
            h(RadioFieldOption, { name: "fulfillment-time-hour", value: item.stringValue, label: h(Fragment, null,
                    item.title,
                    item.type === "Conflicting" && (h("i", { className: "icon-base icon-alert-sign text-warning u-ml1", "aria-hidden": "true" }))), isActive: fulfillmentTime.isActiveValue(item), isDisabled: fulfillmentTime.isDisabledItem(item), childrenCssClass: "m-radio__children--align-to-label", onChange: () => fulfillmentTime.setValue(item.value), onBlur: () => null },
                h(Fragment, null,
                    item.description ? h("span", null, item.description) : undefined,
                    item.type === "Conflicting" && (h(Alert, { type: "warning", extraClasses: "u-my0", message: h(Fragment, null,
                            h("p", null, translate(t.orders.form.conflicting_products_warning)),
                            h("ul", null, item.conflictingProducts.map((productName) => (h("li", null, productName))))) }))))))),
        fulfillmentTime.dateTimeItems.length === 0 && (h(Alert, { type: "danger", message: translate(t.orders.form.unavailable_date) }))));
});
function getPaginationItemsCount(themeName) {
    switch (themeName) {
        case "minimax":
        case "behemot":
        case "pasja":
        case "new_vision":
        case "fiesta":
        case "vesspa":
            return 4;
        default:
            return 5;
    }
}
export default observer(FulfillmentTimeModal);
