import { observer } from "mobx-react";
import { h } from "preact";
import ProductTagsIcons from "../../components/ProductTagsIcons";
function CreatorTitle(props) {
    const { title, tags, isSinglePart } = props;
    return (h("h3", { class: "m-product-card__title u-my2" },
        title,
        " ",
        isSinglePart && h(ProductTagsIcons, { tags: tags })));
}
export default observer(CreatorTitle);
