import { Money } from "../../ordering";
export class POrderInfoOrderLine {
    constructor(params) {
        this.localizationProvider = params.localizationProvider;
        this.name = params.name;
        this.quantity = params.quantity;
        this._price = params.price;
    }
    get price() {
        return this.localizationProvider.formatPrice(Money.fromBackend(this._price));
    }
}
