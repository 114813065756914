let _sessionStorage;
try {
    _sessionStorage = window.sessionStorage;
}
catch (e) {
    _sessionStorage = {
        getItem() {
            return null;
        },
        setItem() { },
    };
}
const genericGetJSONItem = (storage) => (key) => {
    const obj = storage.getItem(key);
    if (obj) {
        return JSON.parse(obj);
    }
    else {
        return obj;
    }
};
const genericSetJSONItem = (storage) => (key, object) => storage.setItem(key, JSON.stringify(object));
export const sessionGetJSONItem = genericGetJSONItem(_sessionStorage);
export const sessionSetJSONItem = genericSetJSONItem(_sessionStorage);
