import { RSet } from "../../collections";
import { makeImmutable } from "../../core";
import { Tristate } from "../../core/Tristate";
/*
 * Availability strategy based on Schedule and active schedules from scope
 */
export class ScheduleAvailability {
    constructor(params) {
        this.availabilitySchedule = params.availabilitySchedule;
        this.isAvailable =
            params.fulfillmentTime === null
                ? Tristate.Maybe
                : Tristate.fromBoolean(params.availabilitySchedule.includes(params.fulfillmentTime));
        this.unavailabilityReasons = this.isAvailable.isDefinitelyTrue
            ? RSet.empty()
            : RSet.singleton(params.unavailabilityReason);
        this.fulfillmentTime = params.fulfillmentTime;
        makeImmutable(this);
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitSchedule(this);
    }
    toString() {
        var _a, _b, _c, _d;
        return `${(_b = (_a = this.fulfillmentTime) === null || _a === void 0 ? void 0 : _a.weekday) !== null && _b !== void 0 ? _b : "??"} ${(_d = (_c = this.fulfillmentTime) === null || _c === void 0 ? void 0 : _c.fullHour) !== null && _d !== void 0 ? _d : "??:??"} -> ${this.availabilitySchedule.toString()}`;
    }
}
