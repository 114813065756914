import { Splide } from "@splidejs/splide";
/**
 * Sync Splide instances if necessary.
 *
 * @param {Splide} splide Splide instance.
 * @param {string} asNavFor Another slider selector.
 */
function syncSlides(splide, asNavFor) {
    if (!asNavFor)
        return;
    const slider = document.querySelector(asNavFor);
    if (!(slider instanceof HTMLElement))
        return;
    const dataOptions = JSON.parse(slider.dataset.slider || "{}");
    const dataResponsive = JSON.parse(slider.dataset.responsive || "{}");
    const secondary = new Splide(slider, getSplideOptions(slider, dataOptions, dataResponsive));
    splide.sync(secondary);
    secondary.mount();
}
/**
 * Add support for multiple arrows (e.g. separate arrows inside each slide).
 *
 * @param {Splide} splide Splide instance.
 * @param {HTMLButtonElement} prev A previous arrow.
 * @param {HTMLButtonElement} next A next arrow.
 */
function supportMultipleArrows(splide, prev, next) {
    const allArrows = splide.root.querySelectorAll(".splide__arrows");
    // Check if slider requires multiple arrows instances.
    if (allArrows.length < 2)
        return;
    // 1. Identify arrows that support next/prev events (original arrows have events from Splide).
    // 2. Exclude arrows that already have next/prev events to avoid multiple initializations.
    prev.classList.add("js-has-arrow-event"); // [1]
    next.classList.add("js-has-arrow-event"); // [1]
    splide.root
        .querySelectorAll(".js-slider__next:not(.js-has-arrow-event)") // [2]
        .forEach((el) => {
        el.addEventListener("click", () => splide.go(">"));
        el.classList.add("js-has-arrow-event");
    });
    splide.root
        .querySelectorAll(".js-slider__prev:not(.js-has-arrow-event)") // [2]
        .forEach((el) => {
        el.addEventListener("click", () => splide.go("<"));
        el.classList.add("js-has-arrow-event");
    });
}
/**
 * Add support for multiple paginations (e.g. separate pagination within each slide).
 * The current implementation only supports slider variant with one slide per page (`perPage: 1`).
 *
 * NOTE: Multiple paginations are not recommended because of accessibility issues.
 * It requires more work to be as accessible as the regular variant, e.g. focus management, arrows navigation, etc.
 *
 * @param {Splide} splide Splide instance.
 * @param {HTMLUListElement} list Pagination list.
 */
function supportMultiplePaginations(splide, list) {
    const allIndicators = splide.root.querySelectorAll(".splide__pagination");
    // Check if slider requires multiple pagination instances.
    if (allIndicators.length < 2)
        return;
    /**
     * Generate pagination markup for each slide.
     */
    function generatePagination() {
        allIndicators.forEach((indicators) => {
            let index = 0;
            indicators.innerHTML = list.innerHTML;
            indicators
                .querySelectorAll(".splide__pagination__page")
                .forEach((btn) => {
                if (!(btn instanceof HTMLElement))
                    return;
                btn.dataset.index = String(index);
                btn.addEventListener("click", () => {
                    if (!btn.dataset.index)
                        return;
                    splide.go(parseInt(btn.dataset.index));
                });
                index++;
            });
        });
    }
    /**
     * Update state of the pagination items.
     */
    function updateState() {
        /**
         * Ensure existing btn index (convert `destIndex` to pagination index).
         * In the loop mode, the index can be negative or greater than the number of slides for clones.
         *
         * @param {number} destIndex Destination slide index.
         * @returns {number} Pagination button index.
         */
        function getBtnIndex(destIndex) {
            const lastSlideIndex = splide.length - 1;
            let targetIndex = destIndex;
            if (destIndex === -1) {
                targetIndex = lastSlideIndex;
            }
            else if (destIndex > lastSlideIndex) {
                targetIndex = 0;
            }
            return targetIndex;
        }
        splide.on("move", function (newIndex, prevIndex, destIndex) {
            const index = getBtnIndex(destIndex);
            splide.root
                .querySelectorAll(".js-slider__indicators")
                .forEach((indicators) => {
                const destBtn = indicators.querySelector(`.splide__pagination__page[data-index="${index}"]`);
                indicators
                    .querySelectorAll(".splide__pagination__page")
                    .forEach((btn) => {
                    btn.classList.remove("is-active");
                    btn.setAttribute("aria-selected", "false");
                });
                if (destBtn) {
                    destBtn.classList.add("is-active");
                    destBtn.setAttribute("aria-selected", "true");
                }
            });
        });
    }
    generatePagination();
    updateState();
}
/**
 * Adjust options when carousel does not have enough slides.
 *
 * @param {Splide} splide Splide instance.
 * @param {boolean} isOverflow True if the total width of slides is wider than the list element (always true for fade carousels).
 * @param {object} options Splide options.
 */
function adjustOverflowOptions(splide, isOverflow, options) {
    // Reset the carousel position
    splide.go(0);
    // Update Splide options.
    splide.options = {
        arrows: isOverflow ? options.arrows : false,
        pagination: isOverflow ? options.pagination : false,
        drag: isOverflow ? options.drag : false,
        clones: isOverflow ? undefined : 0, // toggle clones
    };
}
/**
 * @param {Splide} splide Splide instance.
 * @param {Options} options Splide options.
 */
function attachEvents(splide, options, onVisible) {
    splide.on("arrows:mounted", function (prev, next) {
        supportMultipleArrows(splide, prev, next);
    });
    splide.on("pagination:mounted", function (data) {
        supportMultiplePaginations(splide, data.list);
    });
    splide.on("overflow", function (isOverflow) {
        adjustOverflowOptions(splide, isOverflow, options);
    });
    if (onVisible) {
        splide.on("visible", (slide) => {
            // Splide issues `visible` events even when the element is hidden in the DOM.
            // Detect such situations via zero width.
            if (slide.slide.clientWidth > 0) {
                onVisible(slide.index);
            }
        });
    }
}
/**
 * Convert options from `data-slider` and `data-responsive` attributes.
 */
function getSplideOptions(slider, dataSlider, dataResponsive) {
    const breakpoints = Skubacz.Device.breakpoints;
    let perPage = {
        xsmall: 1,
        small: 1,
        medium: 1,
        extraMedium: 1,
        large: 1,
        extraLarge: 1,
    };
    // Responsive options with mobile-first approach.
    perPage.xsmall = dataResponsive.xsmall || perPage.xsmall;
    perPage.small = dataResponsive.small || perPage.xsmall;
    perPage.medium = dataResponsive.medium || perPage.small;
    perPage.extraMedium = dataResponsive.extraMedium || perPage.medium;
    perPage.large = dataResponsive.large || perPage.extraMedium;
    perPage.extraLarge = dataResponsive.extraLarge || perPage.large;
    const options = {
        type: "loop",
        perPage: perPage.xsmall,
        pagination: slider.querySelector(".js-slider__indicators") ? true : false,
        mediaQuery: "min",
        arrows: slider.querySelector(".splide__arrows") ? true : false,
        rewind: true,
        drag: Skubacz.Device.isTouch(),
        autoplay: false,
        interval: 5000,
        speed: 500,
        classes: {
            pagination: "splide__pagination m-indicators__dots",
            page: "splide__pagination__page m-indicators__btn",
        },
        breakpoints: {
            [breakpoints.smallMin]: {
                perPage: perPage["small"],
                destroy: perPage["small"] ? false : true,
            },
            [breakpoints.mediumMin]: {
                perPage: perPage["medium"],
                destroy: perPage["medium"] ? false : true,
            },
            [breakpoints.extraMediumMin]: {
                perPage: perPage["extraMedium"],
                destroy: perPage["extraMedium"] ? false : true,
            },
            [breakpoints.largeMin]: {
                perPage: perPage["large"],
                destroy: perPage["large"] ? false : true,
            },
            [breakpoints.extraLargeMin]: {
                perPage: perPage["extraLarge"],
                destroy: perPage["extraLarge"] ? false : true,
            },
        },
    };
    return Object.assign(options, dataSlider);
}
/**
 * Init advanced slider (using Slick plugin).
 *
 * @param selector
 */
function initSlider(selector) {
    document.querySelectorAll(selector).forEach((slider) => {
        const sliderList = slider.querySelector(".splide__list");
        if (!(sliderList instanceof HTMLElement) ||
            !(slider instanceof HTMLElement))
            return;
        const baseOptions = JSON.parse(slider.dataset.slider || "{}");
        const responsiveOptions = JSON.parse(slider.dataset.responsive || "{}");
        initSliderWithConfig(slider, { baseOptions, responsiveOptions });
    });
}
/**
 * Init advanced slider, taking configuration from parameters instead of from DOM.
 * (exported for use in dynamic components)
 */
export function initSliderWithConfig(slider, config) {
    const sliderList = slider.querySelector(".splide__list");
    const options = getSplideOptions(slider, config.baseOptions, config.responsiveOptions);
    console.debug("Slider: initSliderWithConfig", slider, options);
    const hasAnimationFix = typeof Skubacz.configuration.slider_animation_fix === "object" &&
        typeof Skubacz.configuration.slider_animation_fix.animation_time ===
            "number" &&
        slider.querySelector(".js-slider__animation-fix");
    const sliderTimeToWait = hasAnimationFix
        ? Skubacz.configuration.slider_animation_fix.animation_time
        : 0;
    displaySliderAfterInitAnimation(slider);
    setTimeout(function () {
        if (!(sliderList instanceof HTMLElement))
            return;
        // Waiting time is required in order to fix: https://trello.com/c/3M1TvpGv
        if (hasAnimationFix) {
            // Prevent double animation issue.
            sliderList.querySelectorAll(".animated").forEach((el) => {
                var _a;
                el.classList.remove("animated");
                (_a = el.querySelector(".splitted-animation")) === null || _a === void 0 ? void 0 : _a.classList.add("splitted-animation--disabled");
            });
        }
        const splide = new Splide(slider, options);
        syncSlides(splide, options.asNavFor);
        attachEvents(splide, options, config.onVisible);
        splide.mount();
        // Slider is sometimes initializes in hidden modals, where the width is not known at initialization time.
        // Update slider offset and dimensions when modal is shown.
        const isModalParent = splide.root.closest(".modal");
        if (isModalParent) {
            document.addEventListener("shown.bs.modal", () => {
                console.debug("Slider: refresh on shown.bs.modal", splide);
                splide.refresh();
            });
        }
    }, sliderTimeToWait);
}
/**
 * Display initially hidden slider list (replace initial placeholders – part of the Animate.js fix).
 *
 * If replacement (`js-animate__init-element`) is present, slider list should have `u-visibility-hidden` class to prevent the visibility of both elements at once.
 * Example use case: replace startup entrance animation element with separate slider items.
 *
 * Also fixes:
 * - 1px cells border bug in grid layout (tested in Firefox 75 with full width splitted animation in the background).
 *
 * @param {HTMLElement} slider Slider root element.
 */
function displaySliderAfterInitAnimation(slider) {
    const placeholder = slider.querySelector(".js-animate__init-element");
    if (!placeholder)
        return;
    placeholder.addEventListener("shown.restaumatic.animate", function () {
        const sliderList = slider.querySelector(".splide__list");
        if (!sliderList)
            return;
        sliderList.classList.remove("u-visibility-hidden");
        placeholder.remove();
    });
}
export default {
    /**
     * Init slider.
     *
     * @param selector
     */
    init: function (selector = ".js-slider") {
        initSlider(selector);
    },
};
