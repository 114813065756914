import { RDay } from "./model/RDay";
export class OrderingHoursBoundry {
    constructor(params) {
        this.preorderSettings = params.preorderSettings;
        this.scope = params.scope;
    }
    maxDay() {
        const maxDay = this.preorderSettings.dateRanges(this.scope.now).end;
        if (maxDay === null) {
            return null;
        }
        return RDay.fromRDate(maxDay);
    }
    minDay() {
        const minDay = this.preorderSettings.dateRanges(this.scope.now).begin;
        if (minDay === null) {
            return null;
        }
        return RDay.fromRDate(minDay);
    }
}
