const imgixUrl = Skubacz.configuration.imgix_url;
export function makeImgUrl(urn) {
    return imgixUrl + urn;
}
export function setQueryParams(params, url) {
    const urlObj = new URL(url);
    Object.entries(params).forEach(([key, value]) => {
        urlObj.searchParams.set(key, value);
    });
    return urlObj.toString();
}
/**
 * Set predefined imgix parameters.
 * Use `setQueryParams` if you need maximum flexibility.
 */
export function adjustImage(params, url) {
    const _params = {
        auto: "compress",
        crop: "focalpoint",
        fit: params.crop ? "crop" : "max",
        h: params.h.toString(),
        w: params.w.toString(),
    };
    return setQueryParams(_params, url);
}
