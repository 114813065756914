import { action, makeAutoObservable } from "mobx";
import { Volume } from "../Volume";
/**
 * A packaging container with items in it.
 *
 * For example a "box with a kebab and fries inside".
 */
export class PackagingContainerInstance {
    constructor(params) {
        this.packagingContainerType = params.packagingContainerType;
        this.items = [];
        makeAutoObservable(this, { pack: action });
    }
    get occupiedCapacity() {
        const itemVolumes = this.items.map((item) => item.totalVolume);
        return Volume.total(itemVolumes);
    }
    get remainingCapacity() {
        return this.packagingContainerType.capacity.sub(this.occupiedCapacity);
    }
    get price() {
        return this.packagingContainerType.price;
    }
    pack(item) {
        if (!this.packagingContainerType.canContain(item.subject)) {
            return { type: "remained", remainder: item };
        }
        const { quotient, remainder } = item.splitToFit(this.remainingCapacity);
        if (quotient) {
            this.items.push(quotient);
        }
        return remainder ? { type: "remained", remainder } : { type: "packed" };
    }
    get checkoutData() {
        return [
            this.packagingContainerType.id.value,
            this.items.flatMap((packingItem) => packingItem.checkoutData),
        ];
    }
}
