import { RArray } from "../../collections";
import { DaySecond, TimeRange, WEEKDAY_NAMES, } from "../../core";
import { makeImmutable } from "../../core/makeImmutable";
import { DaySchedule } from "../DaySchedule";
export function makeDayScheduleMap(factory) {
    const entries = WEEKDAY_NAMES.map((weekdayName) => [
        weekdayName,
        factory(weekdayName),
    ]);
    return Object.fromEntries(entries);
}
/**
 * Time schedule for each day of the week.
 *
 * For example: "9:00 - 16:00 on Monday, 6:00 - 16:00 from Thursday to Saturday, 9:00 - 11:00 and 13:00 - 15:00 on Sunday"
 */
export class WeeklySchedule {
    constructor(params) {
        this.DaySchedules = params;
        makeImmutable(this);
    }
    static fromWeeklyAvailability(availability) {
        const DayScheduleMap = makeDayScheduleMap((dayOfWeek) => {
            const dailyAvailability = availability[dayOfWeek.toLowerCase()];
            if (dailyAvailability.length !== 0) {
                return DaySchedule.fromRanges(dailyAvailability.map(({ from, to }) => TimeRange.fromRange({
                    from: DaySecond.fromNumber(from),
                    to: DaySecond.fromNumber(to),
                })));
            }
            return DaySchedule.Empty;
        });
        return new WeeklySchedule(DayScheduleMap);
    }
    get isEmpty() {
        return WEEKDAY_NAMES.every((weekday) => this.DaySchedules[weekday].isEmpty);
    }
    get isFull() {
        return WEEKDAY_NAMES.every((weekday) => this.DaySchedules[weekday].isFull);
    }
    dayOfWeek(weekday) {
        return this.DaySchedules[weekday];
    }
    includes(date) {
        return this.DaySchedules[date.weekday].includes(date);
    }
    rangesAround(date) {
        return new RArray(this.DaySchedules[date.weekday].timeRanges);
    }
    intersection(other) {
        const intersectedSchedule = makeDayScheduleMap((weekdayName) => this.DaySchedules[weekdayName].intersection(other.DaySchedules[weekdayName]));
        return new WeeklySchedule(intersectedSchedule);
    }
    toString() {
        return WEEKDAY_NAMES.map((weekdayName) => {
            const daySchedule = this.DaySchedules[weekdayName];
            if (daySchedule.isEmpty) {
                return null;
            }
            return `${weekdayName}: ${daySchedule.toString()}`;
        })
            .filter((x) => x !== null)
            .join("; ");
    }
}
WeeklySchedule.Empty = new WeeklySchedule(makeDayScheduleMap(() => DaySchedule.Empty));
WeeklySchedule.Full = new WeeklySchedule(makeDayScheduleMap(() => DaySchedule.Full));
