import { makeImmutable } from "./makeImmutable";
export class Tristate {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    static fromBoolean(value) {
        // NOTICE not using constructor here allows to compare Tristates with (===)
        return value ? Tristate.True : Tristate.False;
    }
    get isDefinitelyTrue() {
        return this.value === true;
    }
    get isPossible() {
        return !this.isDefinitelyFalse;
    }
    get isDefinitelyFalse() {
        return this.value === false;
    }
    get isDefinite() {
        return this.value !== "maybe";
    }
    eq(other) {
        return this.value === other.value;
    }
    toString() {
        return this.value.toString();
    }
}
Tristate.False = new Tristate(false);
Tristate.Maybe = new Tristate("maybe");
Tristate.True = new Tristate(true);
