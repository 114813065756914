import { makeImmutable } from "../../core/makeImmutable";
import { Availability } from "../Availability";
/**
 * Full name of the order reciever.
 */
export class FullName {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    static empty() {
        return new FullName("");
    }
    get isEmpty() {
        return this.value.length === 0;
    }
    get valid() {
        return this.value.split(" ").filter((v) => v !== "").length >= 2;
    }
    get availability() {
        return Availability.boolean({
            FullNameMissing: this.value.length === 0,
            InvalidFullName: !this.valid,
        });
    }
}
