import { Availability } from "../ordering/Availability";
import { makeImmutable } from "../core/makeImmutable";
const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
/**
 * Email
 */
export class Email {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    static create(value) {
        const whitespacesRemoved = value.replace(/\s/g, "");
        return new Email(whitespacesRemoved);
    }
    get isEmpty() {
        return this.value.length === 0;
    }
    toString() {
        return this.value;
    }
    get valid() {
        return EMAIL_REGEX.test(this.value);
    }
    get availability() {
        return Availability.boolean({
            EmailMissing: this.value.length === 0,
            InvalidEmail: !this.valid,
        });
    }
}
Email.Empty = new Email("");
