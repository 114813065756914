import { makeAutoObservable } from "mobx";
import { ASAPFulfillmentTime } from "../FulfillmentTime";
import { Availability } from "../Availability";
/**
 * How and where should the order be fulfilled for QrWaiter order.
 */
export class QrWaiterFulfillmentInstructions {
    constructor(params) {
        this.type = "QrWaiter";
        this.isDelivery = false;
        /**
         * A way in which the order will be physically fulfilled - DineIn
         */
        this.fulfillmentMethodType = "DineIn";
        /**
         * When the order will be fulfilled - ASAP
         */
        this.fulfillmentTime = ASAPFulfillmentTime.empty();
        this.userNote = params.userNote;
        this.dineInLocation = params.dineInLocation;
        makeAutoObservable(this);
    }
    get availability() {
        return Availability.composite([this.userNote.availability]);
    }
    get fulfillmentMethod() {
        return this.fulfillmentMethodType;
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        return {
            fulfillmentMethod: {
                tag: this.fulfillmentMethodType,
                location: this.dineInLocation,
            },
            requestedFulfillmentTime: this.fulfillmentTime.checkoutData,
            userNote: this.userNote.checkoutData,
        };
    }
}
