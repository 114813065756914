import { CustomParameterInstance, ParameterScope, ProductConfig, ProductInstance, ProductInstanceHalves, ProductInstancePart, ProductInstanceTrackingId, SingleProductInstancePart, randomUUID, } from "../..";
import { RArray } from "../../collections";
import { IdentifiableMap } from "../../collections/IdentifiableMap";
import { makeImmutable, toPairs } from "../../core";
import { ModifierInstance } from "../ModifierInstance";
export class ProductInstanceParser {
    constructor(menu) {
        this.productCategories = menu.productCategories;
        this.productTypes = menu.productTypes;
        this.modifierTypes = menu.modifierTypes;
        this.customParameterTypes = menu.customParameterTypes;
        makeImmutable(this);
    }
    // NOTICE WARNING This instance is only DTO for initializing creator - it has uninitialized parameter scopes everywhere
    parseUrl(url) {
        const productTypes = new RArray(url.split("/")).mapOptional((productTypeId) => this.productTypes.rawFind(productTypeId));
        switch (productTypes.size) {
            case 1:
                return ProductInstance.fromSingleProductType(productTypes.get(0));
            case 2: {
                const firstProductType = productTypes.get(0);
                const secondProductType = productTypes.get(1);
                return ProductInstance.fromHalvesProductTypes({
                    productCategory: this.productCategories.get(firstProductType.productCategoryId),
                    firstProductType,
                    secondProductType,
                });
            }
            default:
                return null;
        }
    }
    // NOTICE WARNING This instance is only DTO for initializing creator - it has uninitialized parameter scopes everywhere
    parseCartProductKey(cartProductKey) {
        const result = this.parseInstanceParts(cartProductKey.parts);
        if (result === null) {
            return null;
        }
        const commonConfig = this.parseCommonConfig(cartProductKey);
        return new ProductInstance({
            commonConfig,
            parts: result.state,
            trackingId: new ProductInstanceTrackingId(randomUUID()),
        });
    }
    parseCommonConfig(input) {
        const modifierSettings = (modifier) => modifier.defaultSettings;
        return new ProductConfig({
            customParameterInstances: this.parseCustomParameterInstances(input.customParameters),
            modifierInstances: this.parseModifierInstances(modifierSettings, input.modifiers),
        });
    }
    parseInstanceParts(parts) {
        switch (parts.length) {
            case 1:
                return this.parseSinglePart(parts[0]);
            case 2:
                return this.parseHalves(parts[0], parts[1]);
            default:
                throw new Error(`Unsupported number of product instance parts: ${parts.length}`);
        }
    }
    parseSinglePart(inputPart) {
        const part = this.parsePart(inputPart);
        if (part === null) {
            return null;
        }
        const productCategory = this.productCategories.get(part.productType.productCategoryId);
        const state = new SingleProductInstancePart(part);
        return { state, productCategory };
    }
    parseHalves(inputFirst, inputSecond) {
        const first = this.parsePart(inputFirst);
        if (first === null) {
            return null;
        }
        const second = this.parsePart(inputSecond);
        if (second === null) {
            return null;
        }
        const productCategory = this.productCategories.find(first.productType.productCategoryId);
        if (productCategory === null) {
            return null;
        }
        const state = ProductInstanceHalves.empty({
            productCategory,
            first,
            second,
        });
        if (state === null) {
            return null;
        }
        return { state, productCategory };
    }
    parsePart(input) {
        const productType = this.productTypes.rawFind(input.productId);
        if (productType === null) {
            return null;
        }
        return new ProductInstancePart({
            scope: ParameterScope.Uninitialized,
            productType,
            productConfig: this.parsePartConfig(productType, input),
        });
    }
    parsePartConfig(productType, input) {
        return new ProductConfig({
            modifierInstances: this.parseModifierInstances((m) => productType.modifierSettings(m), input.modifiers),
            customParameterInstances: this.parseCustomParameterInstances(input.customParameters),
        });
    }
    parseModifierInstances(modifierSettingsProvider, modifiers) {
        return IdentifiableMap.fromIterable("typeId", toPairs(modifiers).map(([modifierTypeId, choices]) => {
            const modifierType = this.modifierTypes.rawGet(modifierTypeId);
            const instance = ModifierInstance.createWithDefaultItems({
                modifierType,
                modifierSettings: modifierSettingsProvider(modifierType),
            });
            instance.selectedItemIds = new RArray(choices).mapOptional((x) => modifierType.itemTypeIds.rawFind(x));
            return instance;
        }));
    }
    parseCustomParameterInstances(customParameters) {
        return IdentifiableMap.fromIterable("typeId", toPairs(customParameters).mapOptional(([customParameterTypeId, choiceId]) => {
            const customParameterType = this.customParameterTypes.rawGet(customParameterTypeId);
            const customParameterChoiceId = customParameterType.choiceIds.rawFind(choiceId);
            if (customParameterChoiceId === null) {
                return null;
            }
            return CustomParameterInstance.create(customParameterType, customParameterChoiceId);
        }));
    }
}
