import { makeAutoObservable } from "mobx";
import { RArray } from "../../collections";
import { DatesWithHours, } from "../PurchaseOrderFulfillmentTime/DatesWithHours";
export class AvailableDates {
    constructor(params) {
        this.orderingHours = params.orderingHours;
        this.now = params.now;
        this.preorderSettingsProvider = params.preorderSettingsProvider;
        this.allowOrderingInClosedRestaurant =
            params.allowOrderingInClosedRestaurant;
        makeAutoObservable(this);
    }
    get preorderDateRanges() {
        return this.preorderSettingsProvider.preorderSettings.dateRanges(this.now);
    }
    // NOTICE Performance safeguard for settings with enormous date ranges
    get limitedAvailableDates() {
        const result = [];
        loop: for (const preorderDateRange of this.preorderDateRanges.ranges) {
            for (const date of preorderDateRange.days()) {
                const hours = this.orderingHours
                    .fulfillmentTimes({ now: this.now, date })
                    .filtered((hour) => preorderDateRange.includes(hour));
                result.push(hours);
                if (result.length >= AvailableDates.MAX_DATE_COUNT) {
                    break loop;
                }
            }
        }
        return new RArray(result).flatMap((a) => a);
    }
    /**
     * Returns available days with hours.
     */
    get availableDates() {
        const availableDates = this.limitedAvailableDates;
        if (this.allowOrderingInClosedRestaurant) {
            return DatesWithHours.create(availableDates);
        }
        else {
            return DatesWithHours.create(availableDates.filtered((date) => this.orderingHours.canOrderAt(date)));
        }
    }
    get todayHours() {
        return this.availableDates.hoursForDate(this.now);
    }
    get hasFutureDates() {
        return this.availableDates.totalHoursCount - this.todayHours.size > 0;
    }
}
// TODO Algorithm for dynamic date enumeration
AvailableDates.MAX_DATE_COUNT = 70;
