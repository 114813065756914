import { makeAutoObservable } from "mobx";
import { IdMap, RArray } from "../../collections";
import { IdentifiableMap } from "../../collections/IdentifiableMap";
import { fromPairs, toPairs } from "../../core/utils";
import { Availability } from "../Availability";
import { CustomParameterInstance, } from "../CustomParameterInstance";
import { ModifierInstance, } from "../ModifierInstance";
import { CustomParameterSet } from "../ParameterSet";
/**
 * ProductConfig is a collection of `ModifierInstance`s and `CustomParameterInstance`s
 * It is used in a context of a common config (undivisible modifiers and parameter types) and part config (divisible modifier and parameter types)
 */
export class ProductConfig {
    constructor(params) {
        this.modifierInstances = params.modifierInstances;
        this.customParameterInstances = params.customParameterInstances;
        makeAutoObservable(this);
    }
    static empty() {
        return new ProductConfig({
            modifierInstances: IdentifiableMap.empty(),
            customParameterInstances: IdentifiableMap.empty(),
        });
    }
    static fromStorageData({ menu, modifierSettingsProvider, storageData, }) {
        const modifierInstances = new RArray(toPairs(storageData.modifierInstances)).mapOptional(([modifierTypeId, modifierInstanceStorageData]) => ModifierInstance.fromStorageData({
            menu,
            modifierTypeId,
            modifierSettingsProvider,
            storageData: modifierInstanceStorageData,
        }));
        const customParameterInstances = new RArray(toPairs(storageData.customParameterInstances)).mapOptional(([customParameterTypeId, customParameterStorageData]) => CustomParameterInstance.fromStorageData({
            menu,
            customParameterTypeId,
            storageData: customParameterStorageData,
        }));
        return new ProductConfig({
            modifierInstances: IdentifiableMap.fromIterable("typeId", modifierInstances),
            customParameterInstances: IdentifiableMap.fromIterable("typeId", customParameterInstances),
        });
    }
    eq(other) {
        return (IdentifiableMap.eq("typeId", this.modifierInstances, other.modifierInstances) &&
            IdentifiableMap.eq("typeId", this.customParameterInstances, other.customParameterInstances));
    }
    isCartEquivalentTo(other) {
        for (const [thisParameterType, thisParameterChoice] of this
            .customParameterInstances) {
            const otherParameterChoice = other.customParameterInstances.find(thisParameterType);
            if (otherParameterChoice === null ||
                !otherParameterChoice.eq(thisParameterChoice)) {
                return false;
            }
        }
        return true;
    }
    get customParameters() {
        return CustomParameterSet.fromInstances(this.customParameterInstances);
    }
    get availability() {
        const modifierAvailabilities = this.modifierInstances.objects.map((modifierInstance) => modifierInstance.availability);
        const customParameterAvailabilities = this.customParameterInstances.objects.map((customParameterInstance) => customParameterInstance.availability);
        return Availability.composite([
            Availability.dependent("ModifierInstance", "", Availability.composite(modifierAvailabilities.raw)),
            Availability.dependent("CustomParameterInstance", "", Availability.composite(customParameterAvailabilities.raw)),
        ]);
    }
    get packingItems() {
        return this.modifierInstances.objects
            .map((modifierInstance) => modifierInstance.packingItems)
            .reduce((prev, curr) => prev.unionWith(curr, (a, b) => a.merge(b)), IdMap.empty());
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        const serializedCustomParameters = this.customParameterInstances.objects.mapOptional((customParameterInstance) => {
            const selectedChoice = customParameterInstance.selectedChoice;
            if (!selectedChoice) {
                return null;
            }
            return [customParameterInstance.typeId.value, selectedChoice.value];
        });
        const serializedModifiers = this.modifierInstances.objects.map((modifierInstance) => [
            modifierInstance.typeId.value,
            modifierInstance.selectedItemIds.map((x) => x.value).raw,
        ]);
        return {
            customParameters: fromPairs(serializedCustomParameters),
            modifiers: fromPairs(serializedModifiers),
        };
    }
    matchesCustomParameterValue(k, v) {
        var _a, _b, _c;
        return ((_c = (_b = (_a = this.customParameterInstances.find(k)) === null || _a === void 0 ? void 0 : _a.selectedChoice) === null || _b === void 0 ? void 0 : _b.eq(v)) !== null && _c !== void 0 ? _c : false);
    }
    get storageData() {
        return {
            modifierInstances: fromPairs(this.modifierInstances.objects.map((modifierInstance) => [
                modifierInstance.typeId.value,
                modifierInstance.storageData,
            ])),
            customParameterInstances: fromPairs(this.customParameterInstances.objects.mapOptional((customParameterInstance) => customParameterInstance.storageData === null
                ? null
                : [
                    customParameterInstance.typeId.value,
                    customParameterInstance.storageData,
                ])),
        };
    }
}
