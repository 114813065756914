import { RSet } from "../../collections";
import { Tristate } from "../../core/Tristate";
import { makeImmutable } from "../../core/makeImmutable";
/*
 * `Always available` availabity calcualtaion strategy
 */
export class Available {
    constructor() {
        makeImmutable(this);
    }
    get isAvailable() {
        return Tristate.True;
    }
    get unavailabilityReasons() {
        return RSet.empty();
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitAvailable(this);
    }
}
