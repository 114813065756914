/**
 * Registry is a place to exchange values between different components regardless of their relative initialization order.
 *
 * Suppose we want to pass an `orderId` between component A and component B.
 *
 * When component A obtains the value, it should call `Registry.publish('orderId', orderId)`.
 * Component B can obtain the value using `Registry.get('orderId').then(orderId => ...)`.
 * This will work even if Component B initializes before Component A obtains the value.
 */
export class Registry {
    constructor() {
        this.slots = {};
        console.debug("Registry initialized");
    }
    publish(key, value) {
        if (this.slots[key]) {
            console.debug("Registry.publish: listener was first", key, value);
            // A listener got there first
            this.slots[key].resolve(value);
        }
        else {
            console.debug("Registry.publish: publisher was first", key, value);
            // Publisher is first. Set up an already-resolved slot.
            this.slots[key] = {
                resolve: (newValue) => {
                    this.slots[key].promise = Promise.resolve(newValue);
                },
                promise: Promise.resolve(value),
            };
        }
    }
    get(key) {
        if (!this.slots[key]) {
            // There was no publisher yet. Set up a listening slot.
            console.debug("Registry.get: not published yet, waiting", key);
            let resolve;
            const promise = new Promise((r) => (resolve = r));
            this.slots[key] = {
                resolve: resolve,
                promise,
            };
        }
        else {
            console.debug("Registry.get: already published", key);
        }
        return this.slots[key].promise;
    }
}
// This module is sometimes loaded from different Webpack entry points,
// which causes multiple instances to be created. These instances can't communicate with each other.
// To prevent that, store the global registry in a truly global variable.
if (!global.RestaumaticRegistry) {
    global.RestaumaticRegistry = new Registry();
}
Registry.global = global.RestaumaticRegistry;
/**
 * Publish a value for the given key.
 * Any currently listening `get()` calls for this key will resolve.
 *
 * If `publish` is called a second time for the same key, the value is overwritten, i.e. subsequent `get` calls will receive the new value.
 */
function publish(key, value) {
    return Registry.global.publish(key, value);
}
/**
 * Look up value by the given key.
 * If it is already published, returns an immediately-resolved Promise with the value.
 * Otherwise returns a Promise which will resolve once the value is published.
 */
function get(key) {
    return Registry.global.get(key);
}
export default { publish, get };
