export function formatAddress(address, isPostCodeRequired) {
    return {
        firstLine: `${address.street} ${address.streetNumber}`.trim(),
        secondLine: `${isPostCodeRequired && address.postCode !== null
            ? address.postCode + " "
            : ""}${address.city}`.trim(),
    };
}
export function formatAddressAsSingleLine(address, isPostCodeRequired) {
    const result = formatAddress(address, isPostCodeRequired);
    return `${result.firstLine}, ${result.secondLine}`;
}
