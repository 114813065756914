/**
 * Service that allows for placing orders
 */
export class CheckoutService {
    constructor(params) {
        this.rpcService = params.rpcService;
    }
    /**
     * Send first order discount
     */
    async claimFirstOrderDiscount(request) {
        const result = await this.rpcService.rpc2("ClaimFODRequest", request);
        if (result === null) {
            return { type: "failure" };
        }
        if (typeof result === "boolean") {
            return result ? { type: "success" } : { type: "failure" };
        }
        return {
            type: "validation_failed",
            errors: result.error.details.map((validationDetail) => validationDetail.message),
        };
    }
    /**
     * Place an order
     */
    async checkout(request) {
        return this.rpcService.rpc("PlaceOrderRequest", request);
    }
}
