import { DisabledFOD } from "./DisabledFOD";
import { EnabledFOD } from "./EnabledFOD";
export const FirstOrderDiscount = {
    create(params) {
        // TODO Refactor into storage manager
        const FODStorageKey = "claimedFod";
        const isClaimed = window.localStorage.getItem(FODStorageKey) === "true";
        const setIsClaimed = (value) => window.localStorage.setItem(FODStorageKey, JSON.stringify(value));
        const isFODDisabled = isClaimed || !params.restaurantEnableFod || params.isQrWaiterOrder;
        return isFODDisabled
            ? new DisabledFOD()
            : new EnabledFOD({
                restaurantId: params.restaurantId,
                validatePhoneService: params.validatePhoneService,
                checkoutService: params.checkoutService,
                contactDetails: params.contactDetails,
                setIsClaimed,
            });
    },
};
