import { associationKey, } from "../core";
import { makeImmutable } from "../core/makeImmutable";
import { Sentry } from "../Sentry";
import { AddingDuplicateElementError, UnknownElementError } from "./errors";
import { RArray } from "./RArray";
import { RSet } from "./RSet";
/*
 * Restaumatic Map wrapper including helper map operations. Uses `AssociationKey` as an association
 */
export class RMap {
    constructor(params) {
        this.rawKeys = params.rawKeys;
        this.rawValues = params.rawValues;
        makeImmutable(this);
    }
    static empty() {
        return new RMap({
            rawKeys: new Map(),
            rawValues: new Map(),
        });
    }
    static singleton(key, value) {
        return RMap.fromIterable([[key, value]]);
    }
    static fromIterable(iterable, options) {
        var _a;
        const array = [...iterable];
        const rawKeys = new Map(array.map(([key]) => [associationKey(key), key]));
        const ignoreDuplicates = (_a = options === null || options === void 0 ? void 0 : options.ignoreDuplicates) !== null && _a !== void 0 ? _a : false;
        if (!ignoreDuplicates && rawKeys.size !== array.length) {
            Sentry.logError(AddingDuplicateElementError.fromArray(array.map(([k, _]) => k)));
        }
        const rawValues = new Map(array.map(([key, value]) => [associationKey(key), value]));
        return new RMap({ rawKeys, rawValues });
    }
    [Symbol.iterator]() {
        return this.entries[Symbol.iterator]();
    }
    get size() {
        return this.rawKeys.size;
    }
    get isEmpty() {
        return this.rawKeys.size === 0;
    }
    includes(key) {
        return this.rawKeys.has(associationKey(key));
    }
    filterByValues(predicate) {
        const filteredValues = [...this.rawValues].filter(([_, value]) => predicate(value));
        const filteredAssocKeys = new Set(filteredValues.map(([associationKey, _]) => associationKey));
        return new RMap({
            rawKeys: new Map([...this.rawKeys].filter(([associationKey, _]) => filteredAssocKeys.has(associationKey))),
            rawValues: new Map([...this.rawValues].filter(([associationKey, _]) => filteredAssocKeys.has(associationKey))),
        });
    }
    filterWithKey(predicate) {
        const filteredValues = [...this.rawValues].filter(([rawKey, value]) => 
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        predicate(this.rawKeys.get(rawKey), value));
        const filteredAssocKeys = new Set(filteredValues.map(([associationKey, _]) => associationKey));
        return new RMap({
            rawKeys: new Map([...this.rawKeys].filter(([associationKey, _]) => filteredAssocKeys.has(associationKey))),
            rawValues: new Map([...this.rawValues].filter(([associationKey, _]) => filteredAssocKeys.has(associationKey))),
        });
    }
    partitionByValues(predicate) {
        return {
            true: this.filterByValues(predicate),
            false: this.filterByValues((value) => !predicate(value)),
        };
    }
    rawFind(key) {
        var _a;
        return (_a = this.rawValues.get(key)) !== null && _a !== void 0 ? _a : null;
    }
    find(key) {
        return this.rawFind(associationKey(key));
    }
    rawGet(key) {
        const element = this.rawFind(key);
        if (element === null) {
            throw new UnknownElementError();
        }
        return element;
    }
    get(key) {
        return this.rawGet(associationKey(key));
    }
    get keys() {
        return RSet.fromIterable(this.rawKeys.values());
    }
    get values() {
        return new RArray([...this.rawValues.values()]);
    }
    get entries() {
        return new RArray([...this.rawKeys.entries()].map(([associationKey, key]) => 
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        [key, this.rawValues.get(associationKey)]));
    }
    get sample() {
        for (const value of this.rawValues.values()) {
            return value;
        }
        return null;
    }
    /**
     * Left-biased union: takes elements from both maps, with elements from this map taking precedence in case of conflict.
     */
    union(other) {
        const m = this.shallowClone();
        for (const [otherKey, otherValue] of other) {
            if (!m.includes(otherKey)) {
                m.set(otherKey, otherValue);
            }
        }
        return m;
    }
    unionWithKey(other, merge) {
        const m = this.shallowClone();
        for (const [otherKey, otherValue] of other) {
            const thisValue = m.find(otherKey);
            if (thisValue === null) {
                m.set(otherKey, otherValue);
            }
            else {
                m.delete(otherKey);
                m.set(otherKey, merge(otherKey, thisValue, otherValue));
            }
        }
        return m;
    }
    intersectionWithKey(other, intersect) {
        const m = RMap.empty();
        for (const [otherKey, otherValue] of other) {
            const thisValue = m.find(otherKey);
            if (thisValue !== null) {
                m.set(otherKey, intersect(otherKey, thisValue, otherValue));
            }
        }
        return m;
    }
    map(fn) {
        return new RMap({
            rawKeys: new Map(this.rawKeys),
            rawValues: new Map([...this.rawValues].map(([k, v]) => [k, fn(v)])),
        });
    }
    mapOptional(fn) {
        return new RMap({
            rawKeys: new Map(this.rawKeys),
            rawValues: new Map([...this.rawValues].map(([k, v]) => [k, fn(v)])),
        }).filterByValues((x) => x !== null);
    }
    // Only for use privately in high-level operations (after cloning)
    set(key, value) {
        if (this.includes(key)) {
            Sentry.logError(AddingDuplicateElementError.fromSingleton(key));
        }
        const ak = associationKey(key);
        this.rawKeys.set(ak, key);
        this.rawValues.set(ak, value);
    }
    delete(key) {
        if (!this.includes(key)) {
            throw new UnknownElementError();
        }
        const ak = associationKey(key);
        this.rawKeys.delete(ak);
        this.rawValues.delete(ak);
    }
    shallowClone() {
        return new RMap({
            rawKeys: new Map([...this.rawKeys]),
            rawValues: new Map([...this.rawValues]),
        });
    }
    toString() {
        return `[${this.keys
            .rawValues()
            .map((key) => `${key.toString()} -> ${this.get(key)}`)
            .join(",")}]`;
    }
}
