import { h } from "preact";
import { t, translate } from "restaumatic-client";
function CreatorSplitRow(props) {
    const { onClick } = props;
    return (h("div", { class: "m-product-card__content" },
        h("div", { class: "row m-form__group" },
            h("div", { class: "col-md-12" },
                h("button", { "data-field-id": "Creator.split", class: "btn btn-default", type: "button", onClick: onClick }, translate(t.menu.creator.actions.split))))));
}
export default CreatorSplitRow;
