import { makeAutoObservable } from "mobx";
import { Availability } from "../..";
export class PaymentAddress {
    constructor(params) {
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.streetAddress = params.streetAddress;
        this.apartmentInfo = params.apartmentInfo;
        this.postCode = params.postCode;
        this.isDifferentThanDeliveryAddress = false;
        makeAutoObservable(this);
    }
    get canBeDifferentThanDeliveryAddress() {
        return this.fulfillmentInstructions.isDelivery;
    }
    get isFullAddressRequired() {
        return (!this.canBeDifferentThanDeliveryAddress ||
            this.isDifferentThanDeliveryAddress);
    }
    get availability() {
        return Availability.composite([
            this.isFullAddressRequired
                ? this.streetAddress.availability
                : Availability.available(),
            this.postCode.availability,
        ]);
    }
    get checkoutData() {
        const postCode = this.postCode.value;
        return !this.isFullAddressRequired &&
            this.fulfillmentInstructions.isDelivery // Checking isDelivery again (it is in isFullAddressRequired already) only for linter
            ? {
                ...this.fulfillmentInstructions.deliveryAddress.streetAddress
                    .checkoutData,
                ...this.fulfillmentInstructions.deliveryAddress.apartmentInfo
                    .checkoutData,
                postCode,
            }
            : {
                ...this.streetAddress.checkoutData,
                ...this.apartmentInfo.checkoutData,
                postCode,
            };
    }
}
