// Trigger & element must be inside container in DOM
const containerSelectorClass = "js-showmore";
const elementSelectorClass = "js-showmore-element";
// State input must be first child of container in DOM
const stateSelectorClass = "js-showmore-state";
const activeClass = "is-active";
const elObserver = new ResizeObserver((entries) => {
    // Wrap it in requestAnimationFrame to avoid "ResizeObserver loop limit exceeded" errors
    window.requestAnimationFrame(() => {
        entries.forEach((entry) => {
            const elVisibleHeight = Math.round(entry.contentRect.height);
            const elFullHeight = entry.target.scrollHeight;
            const isElementTruncated = elVisibleHeight < elFullHeight;
            const containerEl = entry.target.parentElement;
            const stateEl = containerEl === null || containerEl === void 0 ? void 0 : containerEl.querySelector(`.${stateSelectorClass}`);
            const isContainerShowMore = containerEl === null || containerEl === void 0 ? void 0 : containerEl.classList.contains(containerSelectorClass);
            const isElementExpanded = stateEl.checked;
            if (isContainerShowMore && isElementTruncated) {
                containerEl === null || containerEl === void 0 ? void 0 : containerEl.classList.add(activeClass);
            }
            else if (!isElementExpanded) {
                // Make sure there is not any redundant active trigger after recalculating
                containerEl === null || containerEl === void 0 ? void 0 : containerEl.classList.remove(activeClass);
            }
        });
    });
});
function handleShowMoreElements() {
    const elArray = document.querySelectorAll(`.${elementSelectorClass}`);
    elArray.forEach((el) => {
        initElement(el);
    });
}
export function initElement(el) {
    if (el) {
        console.debug("ShowMore: init", el);
        elObserver.observe(el, { box: "border-box" });
    }
}
function initShowMore() {
    handleShowMoreElements();
}
/**
 * Show More.
 */
export default {
    init: function () {
        initShowMore();
    },
};
