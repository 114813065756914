import { makeImmutable } from "../../core/makeImmutable";
/**
 * Informs if the halving is allowed and how it will be priced.
 */
export class HalvingPolicy {
    constructor(params) {
        if (params.halvingAvailability.parameterCombinations.some((x) => x.premise.internal.splitToHalves === true)) {
            throw new Error("Halves splitting cannot be parametrized by isSplit parameter");
        }
        this.halvingAvailability = params.halvingAvailability;
        makeImmutable(this);
    }
    get customParameterTypes() {
        return this.halvingAvailability.customParameterTypes;
    }
}
