import { EveryOrderSelector } from "./EveryOrderSelector";
import { MinimumValueOrderSelector } from "./MinimumValueOrderSelector";
import { NoOrderSelector } from "./NoOrderSelector";
export const WholeOrderPromotionSelector = {
    everyOrder() {
        return new EveryOrderSelector();
    },
    noOrder() {
        return new NoOrderSelector();
    },
    minimumValue(minimumValue) {
        return new MinimumValueOrderSelector(minimumValue);
    },
};
