import { RArray } from "../../collections";
import { Tristate } from "../../core";
export const ProductAvailabilityInfo = {
    create(params) {
        var _a, _b;
        if (params.productType.availability === "TemporarilyUnavailable") {
            return { type: "TemporarilyUnavailable" };
        }
        const schedule = (_b = (_a = params.productType.availabilitySchedule) === null || _a === void 0 ? void 0 : _a.schedule) !== null && _b !== void 0 ? _b : null;
        if (schedule === null) {
            return { type: "Available" };
        }
        // Available no matter what fulfillment time we choose -> yes
        if (params.fulfillmentTimes.every((t) => schedule.includes(t))) {
            return {
                type: "Schedule",
                available: Tristate.True,
                hours: RArray.empty(),
            };
        }
        // Available or not, depending on what fulfillment time we choose -> maybe
        if (params.fulfillmentTimes.some((t) => schedule.includes(t))) {
            return {
                type: "Schedule",
                available: Tristate.Maybe,
                hours: schedule.rangesAround(params.now),
            };
        }
        // Not available, no matter what fulfillment time we choose -> no
        return {
            type: "Schedule",
            available: Tristate.False,
            hours: schedule.rangesAround(params.now),
        };
    },
};
