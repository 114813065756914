import { makeAutoObservable } from "mobx";
import { PCrossSellingItem, PCrossSellingItemBehaviour, } from "./PCrossSellingItem";
export class PCrossSellingCreator {
    constructor(params) {
        this.domain = params.domain;
        this.restaurant = params.restaurant;
        this.orderingHours = params.orderingHours;
        this.localizationProvider = params.localizationProvider;
        this.orderItemListTracking = params.orderItemListTracking;
        makeAutoObservable(this);
    }
    get items() {
        return this.domain.items.map((crossSellingItemInstance) => {
            return PCrossSellingItem.create({
                domain: crossSellingItemInstance,
                orderingHours: this.orderingHours,
                behaviour: PCrossSellingItemBehaviour.create({
                    domain: crossSellingItemInstance.behaviour,
                    kind: crossSellingItemInstance.kind,
                    orderItemListTracking: this.orderItemListTracking,
                }),
                localizationProvider: this.localizationProvider,
                restaurant: this.restaurant,
            });
        });
    }
}
