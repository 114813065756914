import { makeAutoObservable } from "mobx";
export class PQrWaiterOrderDetails {
    constructor(params) {
        this.type = "QrWaiter";
        this.isFulfillmentEditable = false;
        this.promotionScheme = params.promotionScheme;
        makeAutoObservable(this);
    }
    initialize() {
        this.promotionScheme.initialize();
    }
    dispose() {
        this.promotionScheme.dispose();
    }
    get promotionInstances() {
        return this.promotionScheme.promotionInstances;
    }
    clearCoupon() {
        this.promotionScheme.clearCoupon();
    }
    get couponState() {
        return this.promotionScheme.couponState;
    }
    get doesAnyPromotionRequireMarketingConsents() {
        return this.promotionScheme.doesAnyPromotionRequireMarketingConsents;
    }
    disableMarketingPromotionInstances() {
        this.promotionScheme.disableMarketingPromotionInstances();
    }
}
