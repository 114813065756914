import { makeAutoObservable } from "mobx";
export class PurchaseOrderStorageData {
    constructor(params) {
        this.purchaseOrder = params.purchaseOrder;
        this.inCartPromotionTypes = params.inCartPromotionTypes;
        makeAutoObservable(this);
    }
    get cartStorageData() {
        return {
            restaurantId: this.purchaseOrder.restaurantId.value,
            couponCode: this.inCartPromotionTypes.couponCode,
            purchaseOrderPayload: this.purchaseOrder.payload.storageData,
            promotionScheme: this.purchaseOrder.promotionScheme.storageData,
            tip: this.purchaseOrder.tip.storageData,
        };
    }
    get fulfillmentTimeStorageData() {
        const { fulfillmentTime } = this.purchaseOrder.fulfillmentInstructions;
        if (fulfillmentTime === null) {
            return null;
        }
        return fulfillmentTime.storageData;
    }
    get streetAddressStorageData() {
        if (!this.purchaseOrder.fulfillmentInstructions.isDelivery) {
            return null;
        }
        return this.purchaseOrder.fulfillmentInstructions.deliveryAddress
            .streetAddress.storageData;
    }
    get apartmentInfoStorageData() {
        if (!this.purchaseOrder.fulfillmentInstructions.isDelivery) {
            return null;
        }
        return this.purchaseOrder.fulfillmentInstructions.deliveryAddress
            .apartmentInfo.storageData;
    }
    get contactDetailsStorageData() {
        return this.purchaseOrder.contactDetails.storageData;
    }
    get invoiceDetailsStorageData() {
        return this.purchaseOrder.orderReceiver.invoiceRequestStorageData;
    }
    get consentsStorageData() {
        return this.purchaseOrder.consents.storageData;
    }
    get paymentMediumStorageData() {
        return this.purchaseOrder.payment.storageData;
    }
    get fulfillmentMethodType() {
        return this.purchaseOrder.fulfillmentInstructions.fulfillmentMethodType;
    }
}
