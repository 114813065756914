import { makeImmutable } from "../core/makeImmutable";
export class Identifier {
    constructor(type, value) {
        this.type = type;
        this.value = value;
        makeImmutable(this);
    }
    toString() {
        var _a, _b;
        return (_b = (_a = this.value) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "null";
    }
    eq(other) {
        return this.value === other.value;
    }
    get associationKey() {
        return this.value;
    }
}
