import { IdMap, RArray } from "../collections";
import { toPairs } from "../core";
import { makeImmutable } from "../core/makeImmutable";
import { PreorderSettings } from "../ordering";
import { Menu } from "../ordering/Menu";
import { AvailabilitySpecParser } from "./AvailabilitySpecParser";
import { CustomParameterTypeParser } from "./CustomParameterTypeParser";
import { HalvingPolicyParser } from "./HalvingPolicyParser";
import { MenuArrangmentParser } from "./MenuArrangmentParser";
import { MenuSectionParser } from "./MenuSectionParser";
import { ModifierItemTypeParser } from "./ModifierItemTypeParser";
import { ModifierSettingsParser } from "./ModifierSettingsParser";
import { ModifierTypeParser } from "./ModifierTypeParser";
import { ParametrizationParser } from "./ParametrizationParser";
import { ProductCategoryParser } from "./ProductCategoryParser";
import { ProductTypeParser } from "./ProductTypeParser";
/**
 * Transforms MenuV2 from backend to restaumatic-client objects.
 */
export class MenuV2Parser {
    constructor(params) {
        this.appOrigin = params.appOrigin;
        this.productTypeGroupParser = params.productTypeGroupParser;
        this.schedules = params.schedules;
        this.onlineOrderingSettings = params.onlineOrderingSettings;
        makeImmutable(this);
    }
    parseMenu(catalogue) {
        const customParameterTypeParser = new CustomParameterTypeParser();
        const customParameterTypes = this.parseCustomParameterTypes(catalogue.customParameters, customParameterTypeParser);
        const parametrizationParser = new ParametrizationParser({
            customParameterTypes,
        });
        const availabilitySpecParser = new AvailabilitySpecParser({
            parametrizationParser,
        });
        const modifierSettingsParser = new ModifierSettingsParser();
        const modifierItemTypeParser = new ModifierItemTypeParser({
            parametrizationParser,
            availabilitySpecParser,
        });
        const modifierTypeParser = new ModifierTypeParser({
            parametrizationParser,
            modifierItemTypeParser,
        });
        const modifierTypes = this.parseModifierTypes(catalogue.modifiers, modifierTypeParser);
        const halvingPolicyParser = new HalvingPolicyParser({
            parametrizationParser,
        });
        const productCategoryParser = new ProductCategoryParser({
            halvingPolicyParser,
            modifierTypes,
        });
        const productCategories = this.parseProductCategories(catalogue.categories, productCategoryParser);
        const activeSections = new RArray(toPairs(catalogue.sections)).filtered(([_, section]) => section.visible &&
            section.visibleIn.some((visbleIn) => visbleIn === this.appOrigin));
        const productTypeGroups = this.productTypeGroupParser.parse(activeSections);
        const productTypeParser = new ProductTypeParser({
            parametrizationParser,
            modifierSettingsParser,
            availabilitySpecParser,
            productCategories,
            modifierTypes,
            schedules: this.schedules,
            productTypeGroups,
        });
        const productTypes = this.parseProductTypes(catalogue.products, productTypeParser);
        const menuSectionParser = new MenuSectionParser({
            productTypes,
            customParameterTypes,
        });
        const menuSections = IdMap.fromIterable(activeSections.mapOptional(([sectionId, section]) => menuSectionParser.parse(sectionId, section)));
        const menuArrangmentParser = new MenuArrangmentParser({ menuSections });
        const menuPreorderSettings = productTypeGroups.values.flatMap((groupsForType) => groupsForType.objects.mapOptional((productTypeGroup) => productTypeGroup.isVisible ? productTypeGroup.preorderSettings : null));
        return new Menu({
            productCategories,
            productTypes,
            modifierTypes,
            customParameterTypes,
            schedules: this.schedules,
            arrangments: this.parseMenuArragments(catalogue.menus, menuArrangmentParser),
            preorderSettings: PreorderSettings.inteligentUnion(menuPreorderSettings),
            allowOrderingInClosedRestaurant: this.onlineOrderingSettings.allowOrderingInClosedRestaurant,
            enableDelayedDelivery: this.onlineOrderingSettings.enableDelayedDelivery,
        });
    }
    parseProductTypes(products, productTypeParser) {
        return IdMap.fromIterable(new RArray(toPairs(products)).mapOptional(([id, productType]) => {
            if (!productType.visibleIn.some((visbleIn) => visbleIn === this.appOrigin)) {
                return null;
            }
            return productTypeParser.parse(id, productType);
        }));
    }
    parseMenuArragments(menus, menuArrangmentParser) {
        return IdMap.fromIterable(toPairs(menus).map(([arragmentId, arragment]) => menuArrangmentParser.parse(arragmentId, arragment)));
    }
    parseModifierTypes(modifiers, modifierTypeParser) {
        return IdMap.fromIterable(toPairs(modifiers).map(([id, modifier]) => modifierTypeParser.parse(id, modifier)));
    }
    parseProductCategories(categories, productCategoryParser) {
        return IdMap.fromIterable(toPairs(categories).map(([id, category]) => productCategoryParser.parse(id, category)));
    }
    parseCustomParameterTypes(customParameters, customParameterTypeParser) {
        return IdMap.fromIterable(toPairs(customParameters).map(([id, customParameter]) => customParameterTypeParser.parse(id, customParameter)));
    }
}
