// Field test experiment docs: https://restaumatic.atlassian.net/browse/B2C-425
//
// Conditions:
// Country: PL
// Platform: Mobile
// Themes: Fiesta, Vesspa & New Vision
// Hero button text: "Menu" (Fiesta & Vesspa) or "Zobacz menu" (New Vision)
// Hero button link: Menu page
export const heroButtonText = {
    name: "HeroButtonText",
    variants: [
        ["Menu", 1],
        ["OrderOnline", 1], // Treatment
    ],
    apply: (getVariant, clearVariant) => {
        var _a, _b, _c, _d;
        const validPage = Skubacz.configuration.view_name === "home";
        const validCountry = Skubacz.configuration.country === "PL";
        const validPlatform = ((_b = (_a = Skubacz.Device) === null || _a === void 0 ? void 0 : _a.screen) === null || _b === void 0 ? void 0 : _b.isExtraSmall()) ||
            ((_d = (_c = Skubacz.Device) === null || _c === void 0 ? void 0 : _c.screen) === null || _d === void 0 ? void 0 : _d.isSmall());
        const validTheme = ["fiesta", "vesspa", "new_vision"].includes(Skubacz.configuration.theme_name);
        if (validPage && validCountry && validPlatform && validTheme) {
            const buttonsSelector = ".m-hero .m-hero__content .m-hero__action a, .m-hero .m-hero__content .m-slider__action a";
            const validElements = Array.from(document.querySelectorAll(buttonsSelector)).filter((el) => {
                var _a, _b;
                return (((_a = el.textContent) === null || _a === void 0 ? void 0 : _a.includes("Menu")) ||
                    ((_b = el.textContent) === null || _b === void 0 ? void 0 : _b.includes("Zobacz menu"))) &&
                    el.getAttribute("href") === "/restauracje";
            });
            if (validElements.length === 0) {
                clearVariant();
                return;
            }
            const variant = getVariant();
            if (variant === "OrderOnline") {
                validElements.forEach((element) => {
                    element.textContent = "Zamów online";
                });
            }
        }
    },
};
