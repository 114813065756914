import { RArray } from "../collections";
import { makeImmutable } from "../core";
import { ParameterCombination, Parametrization, } from "../ordering";
import { ParameterSetBuilder } from "./ParameterSetBuilder";
export class ParametrizationParser {
    constructor(params) {
        this.customParameterTypes = params.customParameterTypes;
        makeImmutable(this);
    }
    parseCombination(parameterCombinationArray, value) {
        const parameterCombination = new ParameterSetBuilder();
        for (const [parameterId, parameterChoiceId] of parameterCombinationArray) {
            if (parameterId.tag === "CustomParameter" &&
                parameterChoiceId.tag === "CustomParameterChoice") {
                const customParameterType = this.customParameterTypes.rawGet(parameterId.contents);
                const choiceId = customParameterType.choices.rawFind(parameterChoiceId.contents);
                if (choiceId === null) {
                    return null;
                }
                parameterCombination.setCustomParameter(customParameterType, choiceId.id);
            }
            else if (parameterId.tag === "InternalParameter" &&
                parameterChoiceId.tag === "InternalParameterChoice") {
                const choice = parameterChoiceId.contents;
                if (choice.tag !== `${parameterId.contents.tag}Choice`) {
                    throw new Error("Impossible happened");
                }
                parameterCombination.setInternalParameter(choice);
            }
        }
        return new ParameterCombination({
            premise: parameterCombination.build(),
            value,
        });
    }
    parse(parametric) {
        const parameterCombinations = new RArray(parametric.values).mapOptional(([parameterCombinationArray, value]) => this.parseCombination(parameterCombinationArray, value));
        return Parametrization.fromParameterCombinations(parameterCombinations);
    }
}
