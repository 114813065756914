/**
 * Automatically transform regular radio input to custom-styled radio.
 *
 * The motivation is to keep the entire design of the page consistent.
 * Additionally it supports legacy markup in old themes and transforms it to the new custom-styled approach.
 */
function initRadioCheckbox(selector, elementClass) {
    const inputs = document.querySelectorAll(`${selector}:not(.${elementClass}__input)`);
    inputs.forEach(function (input) {
        const isDisabled = input.disabled === true;
        const icon = document.createElement("i");
        let label = input.closest("label");
        if (!label) {
            label = document.createElement("label");
            input.replaceWith(label);
            label.appendChild(input);
        }
        icon.classList.add(`${elementClass}__icon-state`);
        input.classList.add(`${elementClass}__input`);
        input.after(icon);
        label.classList.add(elementClass);
        if (isDisabled) {
            label.classList.add("is-disabled");
        }
    });
}
export default {
    /**
     * Init custom styles for checkbox element.
     */
    initCheckbox: function (selector = "input[type=checkbox]", checkboxClass = "m-checkbox") {
        initRadioCheckbox(selector, checkboxClass);
    },
    /**
     * Init custom styles for radio element.
     */
    initRadio: function (selector = "input[type=radio]", radioClass = "m-radio") {
        initRadioCheckbox(selector, radioClass);
    },
    /**
     * Init custom markup in order to style select element (required by `_m-select.scss`).
     */
    initSelect: function (selector = "select", selectClass = "m-select") {
        document
            .querySelectorAll(`${selector}:not([multiple]):not(.${selectClass}__select)`)
            .forEach((select) => {
            const wrapper = document.createElement("span");
            wrapper.classList.add(selectClass);
            select.classList.add(`${selectClass}__select`);
            select.replaceWith(wrapper);
            wrapper.appendChild(select);
        });
    },
};
