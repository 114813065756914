import { IdentifiableMap } from "./IdentifiableMap";
export const IdMap = {
    empty() {
        return IdentifiableMap.empty();
    },
    fromIterable(objects, options) {
        return IdentifiableMap.fromIterable("id", objects, options);
    },
    singleton(object) {
        return IdentifiableMap.singleton("id", object);
    },
};
