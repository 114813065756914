import { Tristate } from "../../core/Tristate";
import { makeImmutable } from "../../core/makeImmutable";
export class UnscopedPriceImpl {
    constructor(value) {
        this.type = "unscoped";
        this.value = value;
        makeImmutable(this);
    }
    compare(other) {
        switch (other.type) {
            case "unscoped":
                return this.value.cents - other.value.cents;
            case "scoped": {
                return -1 * other.compare(this);
            }
        }
    }
    combine(other, combination) {
        return other.map((otherValue) => combination(this.value, otherValue));
    }
    map(mapping) {
        return new UnscopedPriceImpl(mapping(this.value));
    }
    get spectrum() {
        return { type: "value", value: this.value };
    }
    get finalValue() {
        return this.value;
    }
    get hasPossibleValues() {
        return true;
    }
    test(predicate) {
        return Tristate.fromBoolean(predicate(this.value));
    }
}
