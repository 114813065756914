import { RArray } from "../../collections";
import { makeImmutable } from "../../core";
export class SequentialCrossSellingListProvider {
    constructor(sequence) {
        this.sequence = sequence;
        makeImmutable(this);
    }
    items(inputProductTypeIds, sequenceNumber) {
        for (const provider of this.sequence) {
            const crossSellingItems = provider.items(inputProductTypeIds, sequenceNumber);
            if (!crossSellingItems.isEmpty) {
                return crossSellingItems;
            }
        }
        return RArray.empty();
    }
}
