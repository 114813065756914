import { translate, t } from "../../I18n";
import { makeAutoObservable } from "mobx";
import { Email, ErrorBox, PhoneFieldController, TextFieldController, } from "../../form";
import { toPairs } from "../../core";
export class FODFormController {
    constructor(params) {
        this.firstOrderDiscount = params.firstOrderDiscount;
        this.email = new TextFieldController({
            initialValue: this.firstOrderDiscount.email.value,
            onUpdate: (value) => (this.firstOrderDiscount.email = Email.create(value)),
            errorBox: new ErrorBox({
                domainObjects: [this.firstOrderDiscount],
                errorMessages: {
                    EmailMissing: translate(t._common.form.errors.required),
                    InvalidEmail: translate(t.validations.invalid_email),
                },
            }),
        });
        this.phone = new PhoneFieldController({
            initialValue: this.firstOrderDiscount.phone.value,
            initialCountry: this.firstOrderDiscount.phone.country,
            onUpdate: (value, country) => this.firstOrderDiscount.setPhone(value, country),
            errorBox: new ErrorBox({
                domainObjects: [this.firstOrderDiscount],
                errorMessages: {
                    PhoneMissing: translate(t._common.form.errors.required),
                    InvalidPhoneNumber: translate(t.validations.invalid_phone),
                },
            }),
        });
        makeAutoObservable(this);
    }
    static create(firstOrderDiscount) {
        switch (firstOrderDiscount.type) {
            case "Enabled":
                return new FODFormController({
                    firstOrderDiscount,
                });
            case "Disabled":
                return null;
        }
    }
    get fields() {
        return {
            email: this.email,
            phone: this.phone,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.firstOrderDiscount.availability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.firstOrderDiscount;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
}
