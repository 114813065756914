import Modal from "bootstrap/js/dist/modal";

export function wrapNode(node) {
  return jQuery(node);
}

/**
 * Show a Bootstrap 5 modal.
 * Returns Promise which resolves when the modal is shown.
 */
export function _showModalBs5(el) {
  console.debug("There is code for showing bs5 modal from external libs/ps");

  if (el) {
    const modal = Modal.getOrCreateInstance(el);
    if (modal && modal._isShown) {
      console.log("Modal already shown, ignoring showModal()");
      return Promise.resolve();
    }

    const finished = new Promise((resolve) =>
      el.addEventListener("shown.bs.modal", () => resolve())
    );

    if (window.__addTask__) {
      const task = "showModal:" + modal._element.getAttribute("id");
      window.__addTask__(task);
      finished.then(() => window.__doneTask__(task));
    }

    modal.show();
    return finished;
  }
}

/**
 * Show a Bootstrap 5 modal.
 * Returns Promise which resolves when the modal is hidden.
 */
export function _hideModalBs5(el) {
  console.debug("There is code for hiding bs5 modal from external libs/ps");

  if (el) {
    const modal = Modal.getOrCreateInstance(el);
    if (!modal || !modal._isShown) {
      console.log("Modal already hidden, ignoring hideModal()");
      return Promise.resolve();
    }

    const finished = new Promise((resolve) =>
      el.addEventListener("hidden.bs.modal", () => resolve())
    );

    if (window.__addTask__) {
      const task = "hideModal:" + modal._element.getAttribute("id");
      window.__addTask__(task);
      finished.then(() => window.__doneTask__(task));
    }

    modal.hide();
    return finished;
  }
}
