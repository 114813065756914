import { DateRange, DysjointDateRanges, makeImmutable, } from "../../core";
export class SameDayPreorderSetting {
    constructor(orderingHours) {
        this.type = "SameDay";
        this.isSameDayOnly = true;
        this.orderingHours = orderingHours;
        makeImmutable(this);
    }
    dateRanges(now) {
        const endOfDay = this.orderingHours.endOfDay(now);
        if (endOfDay === null) {
            return DysjointDateRanges.Empty;
        }
        return DysjointDateRanges.singleton(DateRange.fromDates({
            begin: now,
            end: endOfDay,
        }));
    }
}
