import { makeImmutable } from "../../core/makeImmutable";
/**
 * Quantity
 */
export class Quantity {
    constructor(value) {
        this.value = value;
        makeImmutable(this);
    }
    static fromNumber(value) {
        if (value < 0 || !Number.isInteger(value)) {
            throw new Error("Quantity must represent positive integer");
        }
        return new Quantity(value);
    }
    get isZero() {
        return this.value === 0;
    }
    get isOne() {
        return this.value === 1;
    }
    eq(other) {
        return this.value === other.value;
    }
    lt(other) {
        return this.value < other.value;
    }
    leq(other) {
        return this.value <= other.value;
    }
    gt(other) {
        return this.value > other.value;
    }
    geq(other) {
        return this.value >= other.value;
    }
    /**
     * Add another Quantity
     */
    add(other) {
        return new Quantity(this.value + other.value);
    }
    sub(other) {
        return Quantity.fromNumber(this.value - other.value);
    }
    /**
     * Multiply by another Quantity
     */
    mul(other) {
        return new Quantity(this.value * other.value);
    }
    incremented() {
        return new Quantity(this.value + 1);
    }
    decremented() {
        return Quantity.fromNumber(this.value - 1);
    }
    toString() {
        return `${this.value}`;
    }
}
Quantity.Zero = new Quantity(0);
Quantity.One = new Quantity(1);
