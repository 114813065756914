import { makeAutoObservable } from "mobx";
import { Availability } from "../Availability";
import { ParameterScope } from "../ParameterScope";
import { ProductConfig } from "../ProductConfig";
/**
 * Part of a product instance
 * Has ProductType and ProductConfig
 */
export class ProductInstancePart {
    constructor(params) {
        this.scope = params.scope;
        this.productType = params.productType;
        this.productConfig = params.productConfig;
        makeAutoObservable(this);
    }
    static empty(productType, scope = ParameterScope.Uninitialized) {
        return new ProductInstancePart({
            scope,
            productType,
            productConfig: ProductConfig.empty(),
        });
    }
    static fromStorageData({ menu, storageData, }) {
        const productType = menu.productTypes.rawFind(storageData.productTypeId);
        if (productType === null) {
            return null;
        }
        const productConfig = ProductConfig.fromStorageData({
            menu,
            modifierSettingsProvider: (modifierType) => productType.modifierSettings(modifierType),
            storageData: storageData.productConfig,
        });
        if (productConfig === null) {
            return null;
        }
        return new ProductInstancePart({
            scope: ParameterScope.Uninitialized,
            productType,
            productConfig,
        });
    }
    eq(other) {
        return (this.productTypeId.eq(other.productTypeId) &&
            this.productConfig.eq(other.productConfig));
    }
    isCartEquivalentTo(other) {
        return (this.productTypeId.eq(other.productTypeId) &&
            this.productConfig.isCartEquivalentTo(other.productConfig));
    }
    get productTypeId() {
        return this.productType.id;
    }
    get productCategoryId() {
        return this.productType.productCategoryId;
    }
    get customParameterChoiceIds() {
        return this.productConfig.customParameters.choiceIds;
    }
    get availability() {
        const productTypeAvailability = Availability.ofProductType(this.productType, this.scope);
        const productTypeTimeBased = Availability.ofProductTypeTimeBased(this.productType, this.scope);
        const productConfigAvalability = this.productConfig.availability;
        return Availability.composite([
            Availability.dependent("ProductType", "", productTypeAvailability),
            Availability.dependent("ProductTypeTimeBased", "", productTypeTimeBased),
            Availability.dependent("ProductConfig", "", productConfigAvalability),
        ]);
    }
    get baseVolume() {
        return this.productType.volume;
    }
    get configPackingItems() {
        return this.productConfig.packingItems;
    }
    get weeklySchedule() {
        var _a, _b;
        return (_b = (_a = this.productType.schedule) === null || _a === void 0 ? void 0 : _a.schedule) !== null && _b !== void 0 ? _b : null;
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        return {
            productId: this.productTypeId.value,
            ...this.productConfig.checkoutData,
        };
    }
    get storageData() {
        return {
            productConfig: this.productConfig.storageData,
            productTypeId: this.productType.id.value,
        };
    }
}
