import { makeAutoObservable } from "mobx";
import { Tracking } from "./Tracking";
export class CheckoutTracking {
    constructor(params) {
        this.pPurchaseOrder = params.pPurchaseOrder;
        this.orderItemListTracking = params.orderItemListTracking;
        makeAutoObservable(this);
    }
    initialize() {
        this.orderItemListTracking.initialize();
        this.sendBeginCheckoutEvent();
    }
    sendBeginCheckoutEvent() {
        const items = this.pPurchaseOrder.orderLines.map((orderLine) => {
            var _a, _b;
            return ({
                ids: orderLine.productTypeIds.rawValues().map((id) => id.toString()),
                name: orderLine.name,
                price: (_a = orderLine.unitPrice) !== null && _a !== void 0 ? _a : 0,
                quantity: orderLine.quantity,
                category: (_b = orderLine.parts[0].categoryName) !== null && _b !== void 0 ? _b : "",
                list: this.orderItemListTracking.getData(orderLine.trackingId),
            });
        });
        Tracking.trackEvent({ tag: "BeginCheckout", contents: { items } });
    }
    get checkoutData() {
        return {
            items: this.pPurchaseOrder.orderLines.map((pOrderLine) => ({
                trackingId: pOrderLine.trackingId.value,
                list: this.orderItemListTracking.getData(pOrderLine.trackingId),
            })),
        };
    }
    dispose() {
        this.orderItemListTracking.dispose();
    }
}
