import { t, translate } from "../I18n";
import * as TemporarilyUnavailableLabel from "./TemporarilyUnavailableLabel";
export function createLabel() {
    return (labelParams) => {
        if (labelParams.fulfillmentTime === null) {
            return null;
        }
        if (TemporarilyUnavailableLabel.createLabel()(labelParams) !== null) {
            return null;
        }
        if (labelParams.reasons.includes("Preorder") &&
            labelParams.productInstances.length > 0) {
            const preorderSettings = labelParams.productInstances[0].preorderSettings;
            const dataRanges = preorderSettings.dateRanges(labelParams.now);
            if (dataRanges.ranges.length === 1 &&
                dataRanges.begin !== null &&
                dataRanges.end !== null) {
                const begin = dataRanges.begin.format("DD.MM").trim();
                const end = dataRanges.end.format("DD.MM").trim();
                if (preorderSettings.isSameDayOnly) {
                    return null;
                }
                const message = begin === end
                    ? translate(t.client.labels.not_available_now.order_for_exact_date, { begin })
                    : translate(t.client.labels.not_available_now.order_for, {
                        begin,
                        end,
                    });
                return {
                    message,
                    enabledOnClick: true,
                    type: "info",
                };
            }
        }
        return null;
    };
}
