import { h } from "preact";
import { useMemo } from "preact/hooks";
import { observer } from "mobx-react";
import { t, translate } from "restaumatic-client";
// TODO: splitPizzaImageUrl
import { splitPizzaImageUrl } from "../../../purs/src/Restaumatic/Site/Components/Creator";
import CreatorSelect from "./CreatorSelect";
import Alert from "../../components/Alert";
function CreatorPartsField(props) {
    const { index, fieldIdPrefix, value, options, error, onChange, onDeleteItem, } = props;
    const fieldId = `${fieldIdPrefix}.productId`;
    const selectOptions = options.map((option) => ({
        value: option.id.value,
        label: option.name,
        disabled: option.disabledForSplitting,
    }));
    const renderImageColumn = useMemo(() => {
        switch (index) {
            case 0:
                return (h("div", { class: "col-auto" },
                    h("div", { class: "creator__half-ornament creator__half-ornament--v2" },
                        h("img", { class: "creator__half-ornament-img", src: splitPizzaImageUrl }))));
            case 1:
                return (h("div", { class: "col-auto" },
                    h("div", { class: "creator__half-ornament creator__half-ornament--v2 creator__half-ornament--2nd" },
                        h("img", { class: "creator__half-ornament-img", src: splitPizzaImageUrl }))));
            default:
                return null;
        }
    }, [index]);
    return (h("div", { class: "row row--flex flex-nowrap align-items-center" },
        renderImageColumn,
        h("fieldset", { class: "col" },
            h("legend", { class: "h4" },
                getTitle(index),
                h("button", { "data-field-id": `${fieldIdPrefix}.delete`, class: "btn-link", type: "button", onClick: onDeleteItem }, translate(t.restaurants.widgets.cart.remove))),
            h("div", { class: "m-form__group", "data-field-id": fieldId },
                h("div", { class: "m-select m-select--inline" },
                    h(CreatorSelect, { options: selectOptions, value: value.toString(), onChange: onChange }))),
            h(Alert, { type: error === null || error === void 0 ? void 0 : error.level, message: error === null || error === void 0 ? void 0 : error.message }))));
}
const getTitle = (index) => {
    switch (index) {
        case 0:
            return translate(t.theme_defaults.creator.pizza_1st_half);
        case 1:
            return translate(t.theme_defaults.creator.pizza_2nd_half);
        default:
            return null;
    }
};
export default observer(CreatorPartsField);
