import { IdMap } from "../collections";
import { makeImmutable } from "../core/makeImmutable";
export class PModifierType {
    constructor(params) {
        this.domain = params.domain;
        this.displayMode = params.displayMode;
        this._name = params.name;
        this._description = params.description;
        this.sections = params.sections;
        this.itemTypes = IdMap.fromIterable(this.sections.flatMap((section) => section.items));
        makeImmutable(this);
    }
    get id() {
        return this.domain.id;
    }
    get name() {
        return this._name.value;
    }
    get description() {
        var _a, _b;
        return (_b = (_a = this._description) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
    }
    getItemsDescription(items) {
        return items
            .mapOptional(([id, quantity]) => {
            const item = this.itemTypes.find(id);
            return item ? item.getNameWithQuantity(quantity) : null;
        })
            .raw.join(", ");
    }
}
