import { makeImmutable } from "../../core/makeImmutable";
/**
 * A container for products and modifiers.
 *
 * For example: a cardboard box for pizzas.
 */
export class PackagingContainerType {
    constructor(params) {
        this.id = params.id;
        this.capacity = params.capacity;
        this.price = params.price;
        this.allowedProductTypes = params.allowedProductTypes;
        this.allowedModifierItemTypes = params.allowedModifierItemTypes;
        makeImmutable(this);
    }
    canContain(subject) {
        var _a, _b;
        if (subject.volume.compare(this.capacity) > 0) {
            // NOTICE If base volume exceeds container capacity it impossible and invalid menu configuration
            return false;
        }
        switch (subject.type) {
            case "ProductInstance":
                return subject.productTypeIds.isSubsetOf(this.allowedProductTypes);
            case "ModifierItem":
                return ((_b = (_a = this.allowedModifierItemTypes
                    .find(subject.modifierTypeId)) === null || _a === void 0 ? void 0 : _a.includes(subject.modifierItemTypeId)) !== null && _b !== void 0 ? _b : false);
        }
    }
}
