import { ASAPFulfillmentTime, ParameterSet, } from "..";
import { PreorderHours } from "../../api/preorderHours";
export class FulfillmentTimeStorageDataValidator {
    constructor(params) {
        this.orderingHours = params.orderingHours;
        this.now = params.now;
        this.asapOnlyRestaurant = !params.enableDelayedDelivery;
        this.preorderHours = new PreorderHours({
            preorderSettings: params.preorderSettings,
            scope: {
                now: this.now,
                parameterSet: ParameterSet.empty(),
                fulfillmentTime: null,
            },
        });
        this.isSameDayOnly = params.preorderSettings.isSameDayOnly;
    }
    validate(input) {
        let fulfillmentTime;
        if (input === null) {
            if (this.isSameDayOnly) {
                if (this.asapOnlyRestaurant) {
                    // NOTICE Early return skipping the validation
                    return new ASAPFulfillmentTime();
                }
                // NOITCE Select ASAP as default in same day restaurant
                fulfillmentTime = new ASAPFulfillmentTime();
            }
            else {
                // NOTICE Nothing selected, but nothing can be defaulted
                return null;
            }
        }
        else {
            // NOTICE Today date chosen, but asapOnly means that ASAP is only option for today dates
            const todayEndOfDay = this.orderingHours.endOfDay(this.now);
            if (this.asapOnlyRestaurant &&
                input.type === "OnTime" &&
                (todayEndOfDay !== null
                    ? input.date.isBefore(todayEndOfDay)
                    : input.date.isToday)) {
                fulfillmentTime = new ASAPFulfillmentTime();
            }
            else {
                // NOTICE Just validate user input without any alterations
                fulfillmentTime = input;
            }
        }
        let date;
        switch (fulfillmentTime.type) {
            case "ASAP":
                date = this.now;
                break;
            case "OnTime":
                date = fulfillmentTime.date;
                break;
        }
        if (!this.orderingHours.canOrderAt(date)) {
            return null;
        }
        if (!this.preorderHours.canOrderAt(date)) {
            return null;
        }
        return fulfillmentTime;
    }
}
