import { RSet } from "../../collections";
import { PCartError } from "../../presentation";
class PurchaseOrderAvailabilityVisitor {
    constructor() {
        this.reasons = RSet.empty();
    }
    visitAvailable() {
        // NOTICE Intentionally empty
    }
    visitUnavailable(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
    visitMaybe() {
        // NOTICE Intentionally empty
    }
    visitAsync(availability) {
        availability.asyncAvailability.accept(this);
    }
    visitBoolean(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
    visitComposite(availability) {
        availability.availabilities.forEach((childAvailability) => {
            return childAvailability.accept(this);
        });
    }
    visitDependent(availability) {
        availability.dependency.accept(this);
    }
    // TODO Ask Mikołaj why separate visitor
    visitParametrized() {
        // NOTICE Intentionally empty
    }
    visitSchedule() {
        // NOTICE Intentionally empty
    }
    visitPreorder(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
    visitOrderingHours(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
}
export class PurchaseOrderUnavailabilityDetails {
    constructor(params) {
        this.localizationProvider = params.localizationProvider;
        this.purchaseOrder = params.purchaseOrder;
        this.restaurant = params.restaurant;
    }
    get reasons() {
        const visitor = new PurchaseOrderAvailabilityVisitor();
        this.purchaseOrder.availability.accept(visitor);
        return visitor.reasons;
    }
    get insufficientOrderValueDetails() {
        const validationPrice = this.purchaseOrder.validationPrice;
        const minOrderValue = this.purchaseOrder.minOrderValue;
        const missingValue = minOrderValue.sub(validationPrice.value);
        return {
            minValue: minOrderValue.add(validationPrice.deliveryCost),
            missingValue,
        };
    }
    get error() {
        if (this.reasons.includes("OrderingAvailability")) {
            return PCartError.orderingNotPossible();
        }
        if (this.reasons.includes("InsufficientOrderValue")) {
            const { minValue, missingValue } = this.insufficientOrderValueDetails;
            return PCartError.minOrderValueWarning(this.localizationProvider.formatPrice(minValue), this.localizationProvider.formatPrice(missingValue));
        }
        if (this.reasons.includes("MaximumOrderValueExceeded")) {
            const maxValue = this.localizationProvider.formatPrice(this.restaurant.maxOrderValue);
            return PCartError.maxOrderValueWarning(maxValue);
        }
        if (!this.reasons.includes("OrderingHours") &&
            this.purchaseOrder.orderLines.size > 1 &&
            (this.reasons.includes("Preorder") ||
                this.reasons.includes("PreorderDatesEmpty") ||
                this.reasons.includes("FulfillmentTimeOptionsMissing"))) {
            return PCartError.notOverlappingDishes();
        }
        if (!this.reasons.includes("OrderingHours") &&
            this.purchaseOrder.orderLines.size === 1 &&
            this.reasons.includes("FulfillmentTimeOptionsMissing")) {
            return PCartError.productNotAvailable();
        }
        return PCartError.none();
    }
}
