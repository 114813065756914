import { FieldTest, LocalStorage, StorageManagerFactory, } from "restaumatic-client";
import { heroButtonText } from "./experiments/heroButtonText";
/**
 * Experiments to apply.
 * Add a new experiment by creating a new function and adding it to the fieldTests array
 */
const experiments = [heroButtonText];
export default {
    init() {
        const storageManagerFactory = new StorageManagerFactory({
            storage: LocalStorage.create(),
        });
        const fieldTest = new FieldTest({
            fieldTestsStorageManager: storageManagerFactory.forFieldTests(),
            experiments,
        });
        // Apply all experiments
        fieldTest.applyExperiments();
    },
};
