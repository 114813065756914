export class ConsentsStorageSerializer {
    serializeForStorage(data) {
        return JSON.stringify(data);
    }
    deserializeFromStorage(data) {
        try {
            const info = JSON.parse(data);
            this.assertValid(info);
            if (info.subscribeRestaumaticEmail === undefined) {
                info.subscribeRestaumaticEmail = false;
            }
            if (info.subscribeRestaumaticPhone === undefined) {
                info.subscribeRestaumaticPhone = false;
            }
            return info;
        }
        catch (error) {
            return null;
        }
    }
    assertValid(data) {
        if (typeof data !== "object" ||
            data === null ||
            !("subscribeEmail" in data) ||
            !("subscribePhone" in data) ||
            !("tosAgreement" in data)) {
            throw new Error("Invalid consents data");
        }
    }
}
