import { DysjointDateRanges, makeImmutable } from "../../core";
export class IntersectionPreorderSettings {
    constructor(settings) {
        this.type = "Intersection";
        this.settings = settings;
        makeImmutable(this);
    }
    dateRanges(now) {
        return this.settings
            .map((setting) => setting.dateRanges(now))
            .reduce((dateRange, preorderDateRange) => 
        // NOTICE Order of arguments matters: X ∩ Empty = X, but Empty ∩ X = Empty
        preorderDateRange.intersection(dateRange), DysjointDateRanges.Empty);
    }
    get isSameDayOnly() {
        return this.settings.every((settings) => settings.isSameDayOnly);
    }
}
