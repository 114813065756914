import { OnlineFulfillmentFormController } from "./OnlineFulfillmentFormController";
import { QrWaiterFulfillmentFormController } from "./QrWaiterFulfillmentFormController";
export const FulfillmentFormController = {
    create(params) {
        switch (params.fulfillmentInstructions.type) {
            case "Online": {
                if (params.deliveryAddress === null) {
                    throw new Error("FulfillmentInstructions and PDeliveryAddress type mismatch");
                }
                return new OnlineFulfillmentFormController({
                    fulfillmentInstructions: params.fulfillmentInstructions,
                    fulfillmentMethodTypes: params.fulfillmentMethodTypes,
                    deliveryAddress: params.deliveryAddress,
                    viewType: params.viewType,
                });
            }
            case "QrWaiter":
                return new QrWaiterFulfillmentFormController({
                    fulfillmentInstructions: params.fulfillmentInstructions,
                });
        }
    },
};
