import { RDate, makeImmutable } from "../../core";
import { Availability } from "../Availability";
/**
 * Information that the orrder should be fulfilled on a specified hour.
 */
export class OnTimeFulfillmentTime {
    constructor(params) {
        this.type = "OnTime";
        this.date = params.date;
        makeImmutable(this);
    }
    static fromStorageData(params) {
        const date = RDate.fromString(params.storageData.date, params.storageData.tz);
        if (date.isBefore(params.now)) {
            // NOTICE Past dates are invalid
            return null;
        }
        return new OnTimeFulfillmentTime({
            date,
        });
    }
    get availability() {
        return Availability.boolean({
            InvalidDate: !this.date.isValid,
        });
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        return this.date.toStringDerived;
    }
    get storageData() {
        return {
            type: "OnTime",
            date: this.date.toStringDerived,
            tz: this.date.tz,
        };
    }
}
