import { makeAutoObservable } from "mobx";
import { UserNoteController } from "../UserNoteController";
export class QrWaiterFulfillmentFormController {
    constructor(params) {
        this.type = "QrWaiter";
        this.isFulfillmentEditable = false;
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.userNoteController = new UserNoteController({
            fulfillmentInstructions: params.fulfillmentInstructions,
        });
        makeAutoObservable(this);
    }
    get fields() {
        return { ...this.userNoteController.fields };
    }
    get isDirty() {
        return this.userNoteController.isDirty;
    }
    get isTouched() {
        return this.userNoteController.isTouched;
    }
    get isInvalid() {
        return this.userNoteController.isInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.fulfillmentInstructions;
    }
    submit() {
        this.userNoteController.submit();
    }
}
