export class POrderLineProductType {
    constructor(params) {
        this.productType = params.productType;
        this.description = params.description;
    }
    get name() {
        return this.productType.name;
    }
    get photo() {
        return this.productType.photo;
    }
    get categoryName() {
        return this.productType.categoryName;
    }
}
