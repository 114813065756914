import { Availability } from "../Availability";
export class DisabledFOD {
    constructor() {
        this.type = "Disabled";
        this.state = { type: "Disabled", isFinal: true };
    }
    get availability() {
        return Availability.available();
    }
}
