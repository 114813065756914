const toggleSelectorClassName = "js-toggle";
const toggleSelector = `.${toggleSelectorClassName}`;
const activeClassName = "is-active";
function activateItem(trigger, target) {
    trigger.classList.add(activeClassName);
    target.classList.add(activeClassName);
    trigger.setAttribute("aria-expanded", "true");
}
function deactivateItem(trigger, target) {
    trigger.classList.remove(activeClassName);
    target.classList.remove(activeClassName);
    trigger.setAttribute("aria-expanded", "false");
}
function enableBackdrop(target) {
    var _a;
    const extraClassName = (_a = document
        .querySelector(target)) === null || _a === void 0 ? void 0 : _a.getAttribute("data-backdrop-classes");
    const modalBackdrop = document.createElement("div");
    modalBackdrop.classList.add("modal-backdrop", "in", `${toggleSelectorClassName}`);
    if (extraClassName) {
        modalBackdrop.classList.add(extraClassName);
    }
    modalBackdrop.setAttribute("data-target", `${target}`);
    document.body.appendChild(modalBackdrop);
    modalBackdrop.addEventListener("click", handleClick);
    document.body.classList.add("modal-open");
}
function disableBackdrop(target) {
    document.querySelector(".modal-backdrop").remove();
    document.body.classList.remove("modal-open");
    const triggerEl = document.querySelector(`[href="${target}"]`) ||
        document.querySelector(`[data-target="${target}"]`);
    const targetEl = document.querySelector(target);
    deactivateItem(triggerEl, targetEl);
}
/**
 * Handle backdrop using Bootstrap modal-backdrop.
 */
function handleBackdrop(target) {
    const targetEl = document.querySelector(target);
    const isTargetActive = targetEl.classList.contains(activeClassName);
    isTargetActive ? enableBackdrop(target) : disableBackdrop(target);
}
/**
 * Get target selector from toggle element.
 */
function getTarget(trigger) {
    return trigger.getAttribute("data-target") || trigger.getAttribute("href");
}
/**
 * Handle click on `js-toggle` elements.
 */
function handleClick(e) {
    const triggerEl = e.currentTarget;
    const target = getTarget(triggerEl);
    const targetEl = document.querySelector(target);
    const isTargetActive = targetEl === null || targetEl === void 0 ? void 0 : targetEl.classList.contains(activeClassName);
    const targetOverlay = targetEl === null || targetEl === void 0 ? void 0 : targetEl.getAttribute("data-overlay");
    isTargetActive
        ? deactivateItem(triggerEl, targetEl)
        : activateItem(triggerEl, targetEl);
    if (targetOverlay != undefined && targetOverlay !== "false") {
        handleBackdrop(target);
    }
    e.preventDefault();
}
export default {
    /**
     * Init Universal Toggle.
     */
    init: function () {
        document.querySelectorAll(toggleSelector).forEach((item) => {
            item.addEventListener("click", handleClick);
        });
    },
};
