import { makeImmutable } from "../core/makeImmutable";
export class PProductType {
    constructor(params) {
        this.domain = params.domain;
        this._name = params.name;
        this.categoryName = params.categoryName;
        this.tags = params.tags;
        this.photo = params.photo;
        this._description = params.description;
        this.allergens = params.allergens;
        this.freeModifierItemsCount = params.freeModifierItemsCount;
        this._baseIngredients = params.baseIngredients;
        this.availabilitySchedule = params.availabilitySchedule;
        this.availability = params.availability;
        this.nonDivisibleModifiersOrder = params.nonDivisibleModifiersOrder;
        this.divisibleModifiersOrder = params.divisibleModifiersOrder;
        makeImmutable(this);
    }
    get id() {
        return this.domain.id;
    }
    get name() {
        return this._name.value;
    }
    get description() {
        return this._description.value;
    }
    get baseIngredients() {
        return this._baseIngredients;
    }
    get disabledForSplitting() {
        return this.availability !== "Available";
    }
}
