import { makeImmutable } from "../../core/makeImmutable";
/**
 * A custom parameter, such as a "size" or "dough type" of a pizza.
 *
 * A parameter is a value on which Parametric values can depend.
 */
export class CustomParameterType {
    constructor(params) {
        this.id = params.id;
        this.isDivisible = params.isDivisible;
        this.choices = params.choices;
        this.defaultChoice = params.defaultChoice;
        makeImmutable(this);
    }
    get choiceIds() {
        return this.choices.ids;
    }
    includesChoice(choiceId) {
        return this.choices.includes(choiceId);
    }
    choice(choiceId) {
        return this.choices.get(choiceId);
    }
    get associationKey() {
        return this.id.associationKey;
    }
    toString() {
        return this.id.value.toString();
    }
}
